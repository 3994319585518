/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a calendar date, represented as an ISO-8601 encoded date string. */
  Date: any;
  /** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
  DateTime: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** The `Iterable` scalar type represents an array or a Traversable with any kind of data. */
  Iterable: any;
  JSON: any;
  JSONObject: any;
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   * [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: any;
  /** The `Void` scalar type represents no value being returned. */
  Void: any;
};

export type AcademicStandardInput = {
  currentSchoolId?: InputMaybe<Scalars['String']>;
  documentUuid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  standard?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type AcademicStandardsPayload = {
  __typename?: 'AcademicStandardsPayload';
  adaptDate?: Maybe<Scalars['DateTime']>;
  child: Array<AcademicStandardsPayload>;
  description?: Maybe<Scalars['String']>;
  documentId: Scalars['String'];
  id: Scalars['Int'];
  isRoot: Scalars['Boolean'];
  standard: Scalars['String'];
};

export type Access = Node & {
  __typename?: 'Access';
  code: AccessesEnum;
  id: Scalars['ID'];
};

export enum AccessesEnum {
  All = 'ALL',
  CreateStudentAssignment = 'CREATE_STUDENT_ASSIGNMENT',
  GradeStudentAssignments = 'GRADE_STUDENT_ASSIGNMENTS',
  SeeGradebook = 'SEE_GRADEBOOK',
}

export type AccessPolicy = {
  __typename?: 'AccessPolicy';
  applications?: Maybe<Array<Application>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<ScopedPermission>;
};

export type AccessPolicyPageConnection = {
  __typename?: 'AccessPolicyPageConnection';
  collection: Array<AccessPolicy>;
  paginationInfo?: Maybe<AccessPolicyPaginationInfo>;
};

export type AccessPolicyPaginationInfo = {
  __typename?: 'AccessPolicyPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AccessPolicyPayload = {
  __typename?: 'AccessPolicyPayload';
  accessPolicy: AccessPolicy;
};

export type AclApplication = {
  __typename?: 'AclApplication';
  name: Scalars['String'];
  modules: Array<ApplicationModule>;
  schools: Array<ApplicationSchool>;
};

/** Activity params input for status reseting */
export type ActivityParamsInput = {
  activityId: Scalars['String'];
  studentId: Scalars['String'];
};

export enum ActivityTypeEnum {
  Assessment = 'ASSESSMENT',
  AssessmentPackageBox = 'ASSESSMENT_PACKAGE_BOX',
  BankQuestion = 'BANK_QUESTION',
  AssessmentBenchmark = 'ASSESSMENT_BENCHMARK',
}

export type AddLinkedVideoInput = {
  glossaryTerm?: InputMaybe<Scalars['String']>;
  groupIdentifier?: InputMaybe<Scalars['String']>;
  groupType: VideoGroupEnum;
  k5?: Scalars['Boolean'];
  videoId: Scalars['String'];
  videoIndex?: InputMaybe<Scalars['String']>;
  force?: Scalars['Boolean'];
};

export type AddPermissionInput = {
  id: Scalars['ID'];
  scopedPermission: ScopedPermissionInput;
};

export type AddQuestionsToVideoInput = {
  questionList: Array<Scalars['String']>;
  videoId: Scalars['String'];
};

/** AddSchoolTos a Student. */
export type AddSchoolToStudentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
  studentId: Scalars['String'];
};

/** AddSchoolTos a Student. */
export type AddSchoolToStudentPayload = {
  __typename?: 'addSchoolToStudentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** AddStudentsTos a Class. */
export type AddStudentsToClassInput = {
  classId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentIds: Scalars['Iterable'];
};

/** AddStudentsTos a Class. */
export type AddStudentsToClassPayload = {
  __typename?: 'addStudentsToClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** AddStudentsTos a StudentSubgroup. */
export type AddStudentsToStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentIds: Scalars['Iterable'];
  subgroupId: Scalars['String'];
};

/** AddStudentsTos a StudentSubgroup. */
export type AddStudentsToStudentSubgroupPayload = {
  __typename?: 'addStudentsToStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<StudentSubgroup>;
};

/** AddStudentTos a Classes. */
export type AddStudentToClassesInput = {
  classesIds: Scalars['Iterable'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentId: Scalars['String'];
};

/** AddStudentTos a Classes. */
export type AddStudentToClassesPayload = {
  __typename?: 'addStudentToClassesPayload';
  classes?: Maybe<Classes>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** AddTeachersTos a StudentSubgroup. */
export type AddTeachersToStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  subgroupId: Scalars['String'];
  teacherIds: Scalars['Iterable'];
};

/** AddTeachersTos a StudentSubgroup. */
export type AddTeachersToStudentSubgroupPayload = {
  __typename?: 'addTeachersToStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<StudentSubgroup>;
};

export type AdminKnowledgeTreeInput = {
  grade?: InputMaybe<Scalars['String']>;
  currentSchoolId?: InputMaybe<Scalars['String']>;
  isPlatformAdmin?: Scalars['Boolean'];
};

export type AdoptionAccount = {
  __typename?: 'AdoptionAccount';
  active: Scalars['Boolean'];
  adoptionEvent: AdoptionEvent;
  beginDate?: Maybe<Scalars['Date']>;
  district: District;
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  registrationToken: Scalars['String'];
  reviewers: Array<AdoptionReviewer>;
  state: StateEnum;
  subscriptionPackage: SubscriptionPackage;
};

export type AdoptionAccountFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  adoptionEventId?: InputMaybe<Scalars['ID']>;
  beginDate?: InputMaybe<Scalars['DateTime']>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<StateEnum>;
};

export type AdoptionAccountOrderInput = {
  active?: InputMaybe<SortEnum>;
  beginDate?: InputMaybe<SortEnumMissingLast>;
  expirationDate?: InputMaybe<SortEnumMissingLast>;
  id?: InputMaybe<SortEnum>;
  name?: InputMaybe<SortEnum>;
  state?: InputMaybe<SortEnum>;
};

export type AdoptionAccountPageConnection = {
  __typename?: 'AdoptionAccountPageConnection';
  collection: Array<AdoptionAccount>;
  paginationInfo?: Maybe<AdoptionAccountPaginationInfo>;
};

export type AdoptionAccountPaginationInfo = {
  __typename?: 'AdoptionAccountPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdoptionAccountPayload = {
  __typename?: 'AdoptionAccountPayload';
  adoptionAccount: AdoptionAccount;
};

export type AdoptionEvent = {
  __typename?: 'AdoptionEvent';
  active: Scalars['Boolean'];
  adoptionAccounts: Array<AdoptionAccount>;
  beginDate?: Maybe<Scalars['Date']>;
  category: AdoptionEventCategoryEnum;
  contentTimestamp?: Maybe<Scalars['DateTime']>;
  gradeAndActivities: Array<GradeAndActivity>;
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
  uiCustomization?: Maybe<Scalars['String']>;
  urlCode: Scalars['String'];
};

export enum AdoptionEventCategoryEnum {
  Mathnation = 'mathnation',
  Stemscopes = 'stemscopes',
}

export type AdoptionEventFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  beginDate?: InputMaybe<Scalars['DateTime']>;
  category?: InputMaybe<AdoptionEventCategoryEnum>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AdoptionEventGradeAndActivityInput = {
  activities: Array<Scalars['String']>;
  grade: Scalars['String'];
};

export type AdoptionEventOrderInput = {
  active?: InputMaybe<SortEnum>;
  beginDate?: InputMaybe<SortEnumMissingLast>;
  category?: InputMaybe<SortEnum>;
  expirationDate?: InputMaybe<SortEnumMissingLast>;
  id?: InputMaybe<SortEnum>;
  name?: InputMaybe<SortEnum>;
};

export type AdoptionEventPageConnection = {
  __typename?: 'AdoptionEventPageConnection';
  collection: Array<AdoptionEvent>;
  paginationInfo?: Maybe<AdoptionEventPaginationInfo>;
};

export type AdoptionEventPaginationInfo = {
  __typename?: 'AdoptionEventPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdoptionEventPayload = {
  __typename?: 'AdoptionEventPayload';
  adoptionEvent: AdoptionEvent;
};

export type AdoptionReviewer = {
  __typename?: 'AdoptionReviewer';
  active: Scalars['Boolean'];
  adoptionAccount: AdoptionAccount;
  beginDate?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  school: School;
  user: User;
};

export type AdoptionReviewerFilterInput = {
  adoptionAccountId?: InputMaybe<Scalars['ID']>;
  adoptionEventId?: InputMaybe<Scalars['ID']>;
  beginDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
};

export type AdoptionReviewerOrderInput = {
  active?: InputMaybe<SortEnum>;
  adoptionAccountId?: InputMaybe<SortEnum>;
  adoptionAccountName?: InputMaybe<SortEnum>;
  beginDate?: InputMaybe<SortEnumMissingLast>;
  expirationDate?: InputMaybe<SortEnumMissingLast>;
  username?: InputMaybe<SortEnum>;
};

export type AdoptionReviewerPageConnection = {
  __typename?: 'AdoptionReviewerPageConnection';
  collection: Array<AdoptionReviewer>;
  paginationInfo?: Maybe<AdoptionReviewerPaginationInfo>;
};

export type AdoptionReviewerPaginationInfo = {
  __typename?: 'AdoptionReviewerPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdoptionReviewerPayload = {
  __typename?: 'AdoptionReviewerPayload';
  adoptionReviewer: AdoptionReviewer;
};

export type AiChatHistoryPayload = {
  __typename?: 'AiChatHistoryPayload';
  history: Array<AiChatMessage>;
};

export type AiChatInput = {
  query: Scalars['String'];
  scopeId?: InputMaybe<Scalars['Int']>;
  edition?: InputMaybe<Scalars['String']>;
  gradeLevel: Scalars['String'];
  clearHistory?: Scalars['Boolean'];
};

export type AiChatMessage = {
  __typename?: 'AiChatMessage';
  userContent: Scalars['String'];
  systemContent: Scalars['String'];
};

export type AiChatPayload = {
  __typename?: 'AiChatPayload';
  answer: Scalars['String'];
  historyContext?: Maybe<Scalars['String']>;
};

export enum ApiModeEnum {
  YearSpecific = 'YEAR_SPECIFIC',
  SharedInstance = 'SHARED_INSTANCE',
}

export type Application = {
  __typename?: 'Application';
  name: Scalars['String'];
  modules: Array<ApplicationModule>;
  split: Scalars['Boolean'];
};

export type ApplicationInput = {
  name: Scalars['String'];
  modules: Array<ApplicationModuleInput>;
  split?: InputMaybe<Scalars['Boolean']>;
};

export type ApplicationModule = {
  __typename?: 'ApplicationModule';
  name: Scalars['String'];
  operations: Array<ApplicationModuleOperation>;
  modules: Array<ApplicationModule>;
};

export type ApplicationModuleInput = {
  name: Scalars['String'];
  operations: Array<ApplicationModuleOperationInput>;
  modules?: InputMaybe<Array<ApplicationModuleInput>>;
};

export type ApplicationModuleOperation = {
  __typename?: 'ApplicationModuleOperation';
  name: Scalars['String'];
  scopes: Array<Scalars['String']>;
};

export type ApplicationModuleOperationInput = {
  name: Scalars['String'];
  scopes?: InputMaybe<Array<PermissionScope>>;
};

export type ApplicationSchool = {
  __typename?: 'ApplicationSchool';
  schoolIds: Array<Scalars['String']>;
  modules: Array<ApplicationModule>;
};

export type ApplicationSettings = Node & {
  __typename?: 'ApplicationSettings';
  id: Scalars['ID'];
  value: Scalars['Iterable'];
};

export enum ArchiveAssignmentAction {
  Archive = 'ARCHIVE',
  Unarchive = 'UNARCHIVE',
}

export enum ArchiveStatuses {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Both = 'BOTH',
}

export type AssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesAssessmentInput>;
};

export type AssessmentPackageBoxPayload = {
  __typename?: 'AssessmentPackageBoxPayload';
  stemscopesPayload?: Maybe<StemscopesAssessmentPackageBoxPayload>;
};

export type AssessmentPayload = {
  __typename?: 'AssessmentPayload';
  edgexlPayload?: Maybe<EdgexlAssessmentPayload>;
  platformAdminPayload?: Maybe<PlatformAdminUpdateAssessmentPayload>;
  statusPayload?: Maybe<StatusPayload>;
  stemscopesPayload?: Maybe<StemscopesAssessmentPayload>;
};

export type AssessmentPoolInput = {
  edgexlInput?: InputMaybe<EdgexlAssessmentPoolInput>;
  platformAdminInput?: InputMaybe<PlatformAdminAssessmentPoolInput>;
  stemscopesInput?: InputMaybe<StemscopesAssessmentPoolInput>;
};

export type AssessmentPoolPayload = {
  __typename?: 'AssessmentPoolPayload';
  edgexlPayload?: Maybe<EdgexlAssessmentPoolPayload>;
  platformAdminPayload?: Maybe<PlatformAdminAssessmentPoolPayload>;
  stemscopesPayload?: Maybe<StemscopesAssessmentPoolPayload>;
};

/** Assessment views enum */
export enum AssessmentViewEnum {
  Teacher = 'teacher',
  Student = 'student',
}

/** Assignment Object Type */
export type Assignment = {
  __typename?: 'Assignment';
  archived?: Maybe<Scalars['Boolean']>;
  assessmentId?: Maybe<Scalars['String']>;
  assignmentId: Scalars['String'];
  assignmentListId: Scalars['String'];
  assignmentSource?: Maybe<AssignmentSource>;
  calculator?: Maybe<Calculator>;
  canBePaused?: Maybe<Scalars['Boolean']>;
  classId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  immediatelyShareResults?: Maybe<ImmediatelyShareResults>;
  labels?: Maybe<Array<Scalars['String']>>;
  lateTurnIn?: Maybe<Scalars['Boolean']>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  originLearnosityId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  releaseFeedback?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
  startDate: Scalars['ISO8601Date'];
  status: AssignmentStatus;
  studentAssignments?: Maybe<Array<StudentAssignment>>;
  studentIds?: Maybe<Array<Scalars['String']>>;
  subgroupIds?: Maybe<Array<Scalars['String']>>;
};

/** AssigneesInput */
export type AssignmentAssigneesInput = {
  studentIds?: InputMaybe<Array<Scalars['String']>>;
  subgroups?: InputMaybe<Array<AssignmentSubgroupInput>>;
};

/** Class Input */
export type AssignmentClassInput = {
  id: Scalars['String'];
  studentIds?: InputMaybe<Array<Scalars['String']>>;
  subgroupIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AssignmentDataPayload = {
  __typename?: 'AssignmentDataPayload';
  classId?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  learnosityActivityId: Scalars['String'];
  learnosityAssignmentId: Scalars['String'];
  modified: Scalars['String'];
  sessionUuid: Scalars['String'];
  status: Scalars['String'];
  studentUuid: Scalars['String'];
  teacherUuid: Scalars['String'];
  type: Scalars['String'];
  standard: Scalars['String'];
  displayName: Scalars['String'];
  domainName: Scalars['String'];
};

/** Attributes for filtering assignments by start/end date from/to */
export type AssignmentDateFilterInput = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

/** Assignment Ids Type */
export type AssignmentId = {
  __typename?: 'AssignmentId';
  assignmentId?: Maybe<Scalars['String']>;
};

export enum AssignmentLearnosityRenderingType {
  Assess = 'assess',
  Inline = 'inline',
  Unspecified = 'unspecified',
}

export enum AssignmentLearnosityState {
  Initial = 'initial',
  Preview = 'preview',
  Resume = 'resume',
  Review = 'review',
  Unspecified = 'unspecified',
}

export enum AssignmentLearnosityType {
  Feedback = 'feedback',
  LocalPractice = 'local_practice',
  SubmitPractice = 'submit_practice',
  Unspecified = 'unspecified',
}

/** Assignments Object Type */
export type Assignments = {
  __typename?: 'Assignments';
  collection?: Maybe<Array<Assignment>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AssignmentsFilter = {
  assignmentListId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<Scalars['String']>>;
};

/** AssignmentSource Object Type */
export type AssignmentSource = {
  __typename?: 'AssignmentSource';
  assessment?: Maybe<AssignmentSourceAssessment>;
  element?: Maybe<Element>;
};

/** assignment source Assessment */
export type AssignmentSourceAssessment = {
  __typename?: 'AssignmentSourceAssessment';
  assessmentId: Scalars['String'];
};

/** assignment source AssessmentInput */
export type AssignmentSourceAssessmentInput = {
  assessmentId: Scalars['String'];
};

/** AssignmentSourceInput */
export type AssignmentSourceInput = {
  assessment?: InputMaybe<AssignmentSourceAssessmentInput>;
  element?: InputMaybe<ElementInput>;
  /** Use for all types of MN assignments */
  mnContentId?: InputMaybe<Scalars['String']>;
};

export enum AssignmentStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Paused = 'PAUSED',
}

/** SubgroupInput */
export type AssignmentSubgroupInput = {
  studentIds: Array<Scalars['String']>;
  subgroupId: Scalars['String'];
};

/** Assignment Union */
export type AssignmentUnion =
  | AssignmentId
  | DistrictAssignmentType
  | EdgexlAssignmentType
  | LtiEdgexlAssignmentType
  | StemscopesAssignment;

export type AthenaDistrict = Node & {
  __typename?: 'athenaDistrict';
  _id: Scalars['String'];
  city: Scalars['String'];
  externalId: Scalars['String'];
  externalSystem: Scalars['String'];
  globalName: Scalars['String'];
  id: Scalars['ID'];
  mathnationId?: Maybe<Scalars['String']>;
  source: DistrictSourceEnum;
  state: Scalars['String'];
};

/** Page connection for athenaDistrict. */
export type AthenaDistrictPageConnection = {
  __typename?: 'athenaDistrictPageConnection';
  collection?: Maybe<Array<Maybe<AthenaDistrict>>>;
  paginationInfo: AthenaDistrictPaginationInfo;
};

/** Information about the pagination. */
export type AthenaDistrictPaginationInfo = {
  __typename?: 'athenaDistrictPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AttachQuestionContainerInput = {
  platformAdminInput?: InputMaybe<PlatformAdminAttachQuestionContainerInput>;
};

export enum Audience {
  Admin = 'ADMIN',
  Leader = 'LEADER',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export enum AudienceEnum {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export type AuthorSignRequestServiceInput = {
  /** JSON string */
  config?: InputMaybe<Scalars['String']>;
};

export type BaseScope = {
  __typename?: 'BaseScope';
  contentGroups: Array<Scalars['String']>;
  displayName: Scalars['String'];
  editionName: Scalars['String'];
  elementTitles: Array<Scalars['String']>;
  id: Scalars['String'];
  image: Scalars['String'];
  internalName: Scalars['String'];
  jsonUrl: Scalars['String'];
  metadata: Scalars['JSON'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sections: Array<Scalars['JSON']>;
  state: Scalars['String'];
  subscriptionContentGroups?: Maybe<Array<Scalars['String']>>;
  taxonomy: Array<Scalars['JSON']>;
};

export type BenchmarkInput = {
  currentSchoolId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type BenchmarkObjectsPayload = {
  __typename?: 'BenchmarkObjectsPayload';
  benchmarks: Array<BenchmarkPayload>;
};

export type BenchmarkPayload = {
  __typename?: 'BenchmarkPayload';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  standard: Scalars['String'];
  state: Scalars['String'];
  subject: Scalars['String'];
};

export type BulkSelectionInput = {
  all?: Scalars['Boolean'];
  excludedIds?: Array<Scalars['ID']>;
  includedIds?: Array<Scalars['ID']>;
};

export type BulkUpdate = {
  __typename?: 'BulkUpdate';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  itemType: BulkUpdateItemTypeEnum;
  itemIds: Array<Scalars['ID']>;
  status: BulkUpdateStatusEnum;
};

export type BulkUpdateAdoptionAccountsInput = {
  active: Scalars['Boolean'];
};

export type BulkUpdateContentsInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
};

export type BulkUpdatedLogMessages = {
  __typename?: 'BulkUpdatedLogMessages';
  path: Scalars['String'];
  values: Array<Scalars['String']>;
};

export enum BulkUpdateItemTypeEnum {
  AdoptionAccount = 'ADOPTION_ACCOUNT',
}

export type BulkUpdateLog = {
  __typename?: 'BulkUpdateLog';
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  success: Scalars['Boolean'];
  messages: Array<BulkUpdatedLogMessages>;
};

export type BulkUpdatePayload = {
  __typename?: 'BulkUpdatePayload';
  id: Scalars['String'];
};

export enum BulkUpdateStatusEnum {
  Completed = 'COMPLETED',
  Initiated = 'INITIATED',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED',
}

/** Calculator Object Type */
export type Calculator = {
  __typename?: 'Calculator';
  fourFunction?: Maybe<Scalars['Boolean']>;
  grading?: Maybe<Scalars['Boolean']>;
  scientific?: Maybe<Scalars['Boolean']>;
};

/** Calculator Input */
export type CalculatorInput = {
  fourFunction?: InputMaybe<Scalars['Boolean']>;
  grading?: InputMaybe<Scalars['Boolean']>;
  scientific?: InputMaybe<Scalars['Boolean']>;
};

export type Calendar = Node & {
  __typename?: 'Calendar';
  _id: Scalars['String'];
  calendarEntries?: Maybe<EntryPageConnection>;
  calendarId?: Maybe<Scalars['String']>;
  calendarTemplateId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  eventId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopePlanId?: Maybe<Scalars['String']>;
  scopePlanType?: Maybe<ScopePlanTypeEnum>;
  searchName?: Maybe<Scalars['String']>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type CalendarCalendarEntriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  entryType?: InputMaybe<EntryTypeEnum>;
  filter?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
};

export type CalendarFilter = {
  calendarId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  endDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CalendarFilter_Order = {
  name?: InputMaybe<SortEnum>;
};

/** Page connection for Calendar. */
export type CalendarPageConnection = {
  __typename?: 'CalendarPageConnection';
  collection?: Maybe<Array<Maybe<Calendar>>>;
  paginationInfo: CalendarPaginationInfo;
};

/** Information about the pagination. */
export type CalendarPaginationInfo = {
  __typename?: 'CalendarPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CalendarTemplateFilter = {
  calendarType?: InputMaybe<CalendarTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
};

/** Calendar type: Individual / Team / Template */
export enum CalendarTypeEnum {
  /** Individual Type */
  Individual = 'Individual',
  /** Team Type */
  Team = 'Team',
  /** Template Type */
  Template = 'Template',
}

/** Cancels a Impersonation. */
export type CancelImpersonationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Cancels a Impersonation. */
export type CancelImpersonationPayload = {
  __typename?: 'cancelImpersonationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  impersonation?: Maybe<CancelImpersonationPayloadData>;
};

/** Cancels a Impersonation. */
export type CancelImpersonationPayloadData = Node & {
  __typename?: 'cancelImpersonationPayloadData';
  id: Scalars['ID'];
  user?: Maybe<User>;
};

export type CanvasConfig = {
  __typename?: 'CanvasConfig';
  jsonEntry: Scalars['String'];
  redirectUrl: Scalars['String'];
};

export type CanvasSsoConfig = {
  __typename?: 'CanvasSsoConfig';
  xml: Scalars['String'];
};

export type ChatbotResponse = {
  __typename?: 'ChatbotResponse';
  answer?: Maybe<Scalars['String']>;
  threadId?: Maybe<Scalars['String']>;
};

export type ChatGptDistrictPayload = {
  __typename?: 'ChatGPTDistrictPayload';
  districtId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  id: Scalars['String'];
};

export type Class = Node & {
  __typename?: 'Class';
  _id: Scalars['String'];
  archived: Scalars['Boolean'];
  classStudents?: Maybe<ClassStudentPageConnection>;
  classTeachers?: Maybe<ClassTeacherPageConnection>;
  curriculumArea?: Maybe<CurriculumAreaEnum>;
  externalId?: Maybe<Scalars['String']>;
  gradeOrCourse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importId?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  lmsId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  school: School;
  schoolId: Scalars['String'];
  status?: Maybe<StatusEnum>;
  /** Populated only for GraphQL API. */
  studentCount: Scalars['Int'];
  subgroups?: Maybe<StudentSubgroupPageConnection>;
};

export type ClassClassStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ClassClassTeachersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ClassSubgroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type Classes = Node & {
  __typename?: 'Classes';
  id: Scalars['ID'];
};

export type ClassFilter = {
  archived?: InputMaybe<Scalars['Boolean']>;
  hasLmsId?: InputMaybe<Scalars['Boolean']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ClassFilter_Order>>>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusEnum>;
  studentsId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
};

export type ClassFilter_Order = {
  archived?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  name?: InputMaybe<SortEnum>;
};

/** Attributes for creating stemscopes class other assignment */
export type ClassOtherAssignment = {
  classId: Scalars['String'];
  otherAssignment: OtherAssignmentInput;
};

/** Page connection for Class. */
export type ClassPageConnection = {
  __typename?: 'ClassPageConnection';
  collection?: Maybe<Array<Maybe<Class>>>;
  paginationInfo: ClassPaginationInfo;
};

/** Information about the pagination. */
export type ClassPaginationInfo = {
  __typename?: 'ClassPaginationInfo';
  hasNextPage: Scalars['Boolean'];
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ClassStudent = Node & {
  __typename?: 'ClassStudent';
  _id: Scalars['String'];
  id: Scalars['ID'];
  student: Student;
  /** @deprecated Use the student property instead */
  studentId: Scalars['String'];
  subgroups?: Maybe<StudentSubgroupPageConnection>;
};

export type ClassStudentSubgroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

/** Page connection for ClassStudent. */
export type ClassStudentPageConnection = {
  __typename?: 'ClassStudentPageConnection';
  collection?: Maybe<Array<Maybe<ClassStudent>>>;
  paginationInfo: ClassStudentPaginationInfo;
};

/** Information about the pagination. */
export type ClassStudentPaginationInfo = {
  __typename?: 'ClassStudentPaginationInfo';
  hasNextPage: Scalars['Boolean'];
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ClassTeacher = Node & {
  __typename?: 'ClassTeacher';
  _id: Scalars['String'];
  accesses?: Maybe<Array<Maybe<AccessesEnum>>>;
  id: Scalars['ID'];
  mainTeacher: Scalars['Boolean'];
  orderListStudents: Scalars['String'];
  subgroups?: Maybe<StudentSubgroupPageConnection>;
  teacher: Teacher;
  /** @deprecated Use the teacher property instead */
  teacherId: Scalars['String'];
};

export type ClassTeacherAccessesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
};

export type ClassTeacherSubgroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

/** Page connection for ClassTeacher. */
export type ClassTeacherPageConnection = {
  __typename?: 'ClassTeacherPageConnection';
  collection?: Maybe<Array<Maybe<ClassTeacher>>>;
  paginationInfo: ClassTeacherPaginationInfo;
};

/** Information about the pagination. */
export type ClassTeacherPaginationInfo = {
  __typename?: 'ClassTeacherPaginationInfo';
  hasNextPage: Scalars['Boolean'];
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CleverSchool = {
  __typename?: 'CleverSchool';
  id: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['Int'];
  stateId?: Maybe<Scalars['String']>;
};

/** Completes a DistrictSetupStatus. */
export type CompleteDistrictSetupStatusInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Completes a DistrictSetupStatus. */
export type CompleteDistrictSetupStatusPayload = {
  __typename?: 'completeDistrictSetupStatusPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  districtSetupStatus?: Maybe<CompleteDistrictSetupStatusPayloadData>;
};

/** Completes a DistrictSetupStatus. */
export type CompleteDistrictSetupStatusPayloadData = Node & {
  __typename?: 'completeDistrictSetupStatusPayloadData';
  id: Scalars['ID'];
  status: SetupStatusEnum;
};

export enum ConsumerEnum {
  MathNation = 'MathNation',
  StemScopes = 'StemScopes',
}

export type ContainersVisibilityPayload = {
  __typename?: 'ContainersVisibilityPayload';
  visibilityPayload?: Maybe<VisibilityPayload>;
};

export type Content = {
  __typename?: 'Content';
  active: Scalars['Boolean'];
  grade?: Maybe<Scalars['String']>;
  editionName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['String']>;
  projectType: ProjectTypeEnum;
};

export type ContentGroup = {
  __typename?: 'ContentGroup';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  contents: Array<Content>;
  /** Use only for merging data! */
  _id: Scalars['ID'];
};

export type ContentGroupFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  contentIds?: InputMaybe<Array<Scalars['ID']>>;
  productType?: InputMaybe<ProductTypeEnum>;
};

export type ContentGroupMapping = {
  contentGroup: Scalars['String'];
  grade: Scalars['String'];
};

export type ContentGroupOrderInput = {
  active?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
};

export type ContentGroupPageConnection = {
  __typename?: 'ContentGroupPageConnection';
  collection: Array<ContentGroup>;
  paginationInfo?: Maybe<ContentGroupPaginationInfo>;
};

export type ContentGroupPaginationInfo = {
  __typename?: 'ContentGroupPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ContentGroupPayload = {
  __typename?: 'ContentGroupPayload';
  contentGroup: ContentGroup;
};

export type ContentGroupWithSchoolPayload = {
  __typename?: 'ContentGroupWithSchoolPayload';
  schoolId: Scalars['String'];
  contentGroups?: Maybe<Array<Scalars['String']>>;
};

export type ContentIdInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

/** Must be used for STEMscopes LTI 1.3 */
export type ContentItem = {
  elementId: Scalars['String'];
  elementView: ElementViewEnum;
  projectId: Scalars['String'];
  scopeId: Scalars['String'];
  sectionId: Scalars['String'];
  viewId: Scalars['String'];
};

export type ContentItemLinkData = {
  __typename?: 'ContentItemLinkData';
  fields: GpbJwt;
  ltiVersion: Scalars['String'];
  url: Scalars['String'];
};

export type ContentPageConnection = {
  __typename?: 'ContentPageConnection';
  collection: Array<Content>;
  paginationInfo?: Maybe<ContentPaginationInfo>;
};

export type ContentPaginationInfo = {
  __typename?: 'ContentPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ContentPayload = {
  __typename?: 'ContentPayload';
  content: Content;
};

export enum ControlActionEnum {
  Show = 'SHOW',
  Hide = 'HIDE',
}

export type ControlAssessmentInput = {
  platformAdminInput?: InputMaybe<PlatformAdminControlAssessmentInput>;
};

export type ControlContainerInput = {
  platformAdminInput?: InputMaybe<PlatformAdminControlContainerInput>;
};

export type CopyAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlCopyAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesCopyAssessmentInput>;
};

export type CreateAccessPolicyInput = {
  applications?: InputMaybe<Array<ApplicationInput>>;
  name: Scalars['String'];
  permissions: Array<ScopedPermissionInput>;
};

export type CreateAdoptionAccountInput = {
  active: Scalars['Boolean'];
  adoptionEventId: Scalars['ID'];
  name: Scalars['String'];
  state: StateEnum;
  beginDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
};

export type CreateAdoptionEventInput = {
  active: Scalars['Boolean'];
  category: AdoptionEventCategoryEnum;
  gradeAndActivities?: InputMaybe<
    Array<InputMaybe<AdoptionEventGradeAndActivityInput>>
  >;
  name: Scalars['String'];
  uiCustomization?: InputMaybe<Scalars['String']>;
  urlCode: Scalars['String'];
  beginDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  productGroupIds: Array<Scalars['ID']>;
  productIds: Array<Scalars['ID']>;
};

export type CreateAdoptionReviewerInput = {
  active: Scalars['Boolean'];
  adoptionAccountId: Scalars['ID'];
  plainPassword: Scalars['String'];
  username: Scalars['String'];
  beginDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
};

export type CreateAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlCreateAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesCreateAssessmentInput>;
};

export type CreateAssessmentPackageBoxInput = {
  stemscopesInput?: InputMaybe<StemscopesCreateAssessmentPackageBoxInput>;
};

/** Attributes for creating assignment */
export type CreateAssignmentInput = {
  assessmentId?: InputMaybe<Scalars['String']>;
  assignmentSource?: InputMaybe<AssignmentSourceInput>;
  calculator?: InputMaybe<CalculatorInput>;
  canBePaused?: InputMaybe<Scalars['Boolean']>;
  classes: Array<AssignmentClassInput>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  immediatelyShareResults?: InputMaybe<ImmediatelyShareResults>;
  labels: Array<Scalars['String']>;
  lateTurnIn: Scalars['Boolean'];
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  notes: Scalars['String'];
  releaseFeedback: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

/** Creates a Calendar. */
export type CreateCalendarInput = {
  calendarId?: InputMaybe<Scalars['String']>;
  calendarTemplateId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  firstDayOfSchool?: InputMaybe<Scalars['Iterable']>;
  gradingPeriods?: InputMaybe<Scalars['Iterable']>;
  holidays?: InputMaybe<Scalars['Iterable']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  lastDayOfSchool?: InputMaybe<Scalars['Iterable']>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Creates a Calendar. */
export type CreateCalendarPayload = {
  __typename?: 'createCalendarPayload';
  calendar?: Maybe<Calendar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a Class. */
export type CreateClassInput = {
  archived?: Scalars['Boolean'];
  classTeachers?: InputMaybe<Array<InputMaybe<CreateClassTeacherNestedInput>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<CurriculumAreaEnum>;
  gradeOrCourse?: InputMaybe<Scalars['String']>;
  language?: Scalars['String'];
  lmsId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  schoolId: Scalars['String'];
  status?: InputMaybe<StatusEnum>;
};

/** Creates a Class. */
export type CreateClassPayload = {
  __typename?: 'createClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a ClassTeacher. */
export type CreateClassTeacherInput = {
  _id: Scalars['String'];
  accesses?: InputMaybe<Array<InputMaybe<AccessesEnum>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  mainTeacher?: Scalars['Boolean'];
  orderListStudents: Scalars['String'];
};

/** Creates a ClassTeacher. */
export type CreateClassTeacherNestedInput = {
  _id: Scalars['String'];
  accesses?: InputMaybe<Array<InputMaybe<AccessesEnum>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mainTeacher?: Scalars['Boolean'];
  orderListStudents: Scalars['String'];
};

/** Creates a ClassTeacher. */
export type CreateClassTeacherPayload = {
  __typename?: 'createClassTeacherPayload';
  classTeacher?: Maybe<ClassTeacher>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContentGroupInput = {
  active?: Scalars['Boolean'];
  id: Scalars['ID'];
  contents: Array<ContentIdInput>;
};

/** Creates a District. */
export type CreateDistrictInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystem?: InputMaybe<Scalars['String']>;
  globalName: Scalars['String'];
  localName: Scalars['String'];
  mathNationId?: InputMaybe<Scalars['String']>;
  state: StateEnum;
};

/** Creates a District. */
export type CreateDistrictPayload = {
  __typename?: 'createDistrictPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
};

export type CreateEmailTemplateInput = {
  attachments: Array<InputMaybe<EmailTemplateAttachmentInput>>;
  body: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  subject: Scalars['String'];
  type: EmailTemplateTypeEnum;
};

/** Creates a Entry. */
export type CreateEntryInput = {
  calendarId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EntryTypeEnum>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Creates a Entry. */
export type CreateEntryPayload = {
  __typename?: 'createEntryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
};

export type CreateImageCategoryInput = {
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
};

export type CreateImageFileInput = {
  language?: InputMaybe<LanguageEnum>;
  originalFileName: Scalars['String'];
  path: Scalars['String'];
};

export type CreateImageInput = {
  altText?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  files: Array<CreateImageFileInput>;
  keywordIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  tagAssociations?: InputMaybe<Array<ImageTagAssociationInput>>;
};

export type CreateImageKeywordInput = {
  name: Scalars['String'];
};

export type CreateImageTagInput = {
  name: Scalars['String'];
};

export type CreateImageTagValueInput = {
  name: Scalars['String'];
  tagId: Scalars['ID'];
};

/** Creates a Impersonation. */
export type CreateImpersonationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** Creates a Impersonation. */
export type CreateImpersonationPayload = {
  __typename?: 'createImpersonationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  impersonation?: Maybe<CreateImpersonationPayloadData>;
};

/** Creates a Impersonation. */
export type CreateImpersonationPayloadData = Node & {
  __typename?: 'createImpersonationPayloadData';
  id: Scalars['ID'];
  user?: Maybe<CreateUserNestedPayload>;
};

export type CreateKeywordInput = {
  name: Scalars['String'];
};

/** Creates a PacingGuide. */
export type CreatePacingGuideInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumArea: Scalars['String'];
  grade: Scalars['String'];
  name: Scalars['String'];
  pacingGuideTemplateId?: InputMaybe<Scalars['String']>;
  pacingGuideType: PacingGuideTypeEnum;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  selectedCalendar?: InputMaybe<Scalars['Iterable']>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Creates a PacingGuide. */
export type CreatePacingGuidePayload = {
  __typename?: 'createPacingGuidePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuide?: Maybe<PacingGuide>;
};

/** Creates a PasswordChange. */
export type CreatePasswordChangeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  repeatedPassword: Scalars['String'];
};

/** Creates a PasswordChange. */
export type CreatePasswordChangePayload = {
  __typename?: 'createPasswordChangePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  passwordChange?: Maybe<CreatePasswordChangePayloadData>;
};

/** Creates a PasswordChange. */
export type CreatePasswordChangePayloadData = Node & {
  __typename?: 'createPasswordChangePayloadData';
  id: Scalars['ID'];
};

export type CreatePdfAssessmentInput = {
  /** Signed request for items to be included in PDF. */
  signedRequest: Scalars['String'];
  /** Assessment title. */
  title: Scalars['String'];
  /** Assessment view: teacher's with keys or student's. */
  view: AssessmentViewEnum;
  /** Print date inside PDF. */
  date?: InputMaybe<Scalars['String']>;
  /** Print variant number inside PDF. */
  variant?: InputMaybe<Scalars['String']>;
  /** Print only one question per page. Default = false. */
  printQuestionPerPage?: InputMaybe<Scalars['Boolean']>;
  /** image access token */
  imagesAccessToken?: InputMaybe<Scalars['String']>;
};

/** Creates a Post. */
export type CreatePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  currentSchoolId: Scalars['String'];
  documentKey?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  isSticky?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Int']>;
  wallId?: InputMaybe<Scalars['String']>;
};

/** Creates a Post. */
export type CreatePostPayload = {
  __typename?: 'createPostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
};

export type CreatePreviewAccountInput = {
  activityPeriodEnd: Scalars['DateTime'];
  activityPeriodStart: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  state: StateEnum;
  username: Scalars['String'];
  productGroupIds: Array<Scalars['ID']>;
  productIds: Array<Scalars['ID']>;
};

export type CreatePreviewAccountsCsvPostObjectInput = {
  fileName: Scalars['String'];
};

export type CreateProductGroupInput = {
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  productIds: Array<Scalars['ID']>;
};

export type CreateProductInput = {
  contentGroups?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  productType: ProductTypeEnum;
  scopeGroups?: InputMaybe<Array<Scalars['ID']>>;
  sku: Scalars['String'];
};

export type CreateQrcodeTemplateInput = {
  className?: InputMaybe<Scalars['String']>;
  users: Array<QrcodeUserData>;
  type: QrcodeRenderEnum;
};

export type CreateQuestionContainerInput = {
  platformAdminInput?: InputMaybe<PlatformAdminCreateQuestionContainerInput>;
};

export type CreateReaderLibraryInput = {
  title: Scalars['String'];
  enPdfFile?: InputMaybe<Scalars['Upload']>;
  esPdfFile?: InputMaybe<Scalars['Upload']>;
  description?: InputMaybe<Scalars['String']>;
};

/** Attributes for creating resource */
export type CreateResourceInput = {
  classes: Array<AssignmentClassInput>;
  gradeLevel?: InputMaybe<Scalars['String']>;
  labels: Array<Scalars['String']>;
  learnosityReferenceId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes: Scalars['String'];
  resourceSource: ResourceSourceInput;
};

export type CreateRoleInput = {
  code?: InputMaybe<RoleCodeEnum>;
  name: Scalars['String'];
  policies: Array<Scalars['String']>;
};

/** Creates a School. */
export type CreateSchoolInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  externalSystem?: InputMaybe<Scalars['String']>;
  globalName: Scalars['String'];
  localName: Scalars['String'];
  mathNationId?: InputMaybe<Scalars['String']>;
  sisId?: InputMaybe<Scalars['String']>;
};

/** Creates a School. */
export type CreateSchoolPayload = {
  __typename?: 'createSchoolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
};

/** Creates a ScopePlan. */
export type CreateScopePlanInput = {
  approachType?: InputMaybe<ScopePlanApproachEnum>;
  classSize?: InputMaybe<Scalars['Int']>;
  classTime?: InputMaybe<Scalars['Int']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopePlanTemplateId?: InputMaybe<Scalars['String']>;
  scopePlanType: ScopePlanTypeEnum;
  scopes?: InputMaybe<Scalars['Iterable']>;
  teacherExp?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Creates a ScopePlan. */
export type CreateScopePlanPayload = {
  __typename?: 'createScopePlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlan?: Maybe<ScopePlan>;
};

export type CreateSignRequestInput = {
  authorService?: InputMaybe<AuthorSignRequestServiceInput>;
  itemsService?: InputMaybe<ItemsSignRequestRequestInput>;
  state?: InputMaybe<Scalars['String']>;
  devDomain?: InputMaybe<Scalars['String']>;
};

export type CreateStemscopesMediaPackInput = {
  area?: StemscopesVideoAreaEnum;
  items: Array<CreateStemscopesMediaPostObjectInput>;
  title: Scalars['String'];
};

export type CreateStemscopesMediaPackPayload = {
  __typename?: 'CreateStemscopesMediaPackPayload';
  pack: StemscopesMediaPack;
  items: Array<StemscopesMediaPostObjectPayload>;
};

export type CreateStemscopesMediaPostObjectInput = {
  area?: StemscopesVideoAreaEnum;
  forceCreate?: Scalars['Boolean'];
  language?: LanguageEnum;
  mediaType?: MediaTypeEnum;
  quality?: VideoQualityEnum;
  subtitles?: Scalars['Boolean'];
  subtitlesSrtFormat?: Scalars['Boolean'];
  title: Scalars['String'];
  uniquePackTitle?: InputMaybe<Scalars['String']>;
};

export type CreateStreamingVideoPostObjectInput = {
  date?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  forceCreate?: Scalars['Boolean'];
  language?: LanguageEnum;
  mediaType?: MediaTypeEnum;
  quality?: VideoQualityEnum;
  title: Scalars['String'];
};

export type CreateSubscriptionInput = {
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  productIds: Array<Scalars['ID']>;
  quantity: Scalars['Int'];
};

export type CreateSubscriptionPackageInput = {
  districtId?: InputMaybe<Scalars['String']>;
  schoolIds: Array<Scalars['ID']>;
  scope?: InputMaybe<SubscriptionScopeEnum>;
  subscriptionIds: Array<Scalars['ID']>;
};

export type CreateTagInput = {
  name: Scalars['String'];
};

export type CreateTagValueInput = {
  name: Scalars['String'];
  tagId: Scalars['ID'];
};

export type CreateTeamInput = {
  context: TeamContextEnum;
  /** User IDs */
  members: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreateTutorInput = {
  depthScale: Scalars['Int'];
  firstName: Scalars['String'];
  initialTag: LanguageTagEnum;
  mainProfilePhoto: Scalars['Upload'];
  profilePhoto: Scalars['Upload'];
  bioText?: InputMaybe<Scalars['String']>;
  bioVideo?: InputMaybe<Scalars['Upload']>;
  lastName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** Creates a UserExportRequest. */
export type CreateUserExportRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  filters: Scalars['Iterable'];
};

/** Creates a UserExportRequest. */
export type CreateUserExportRequestPayload = {
  __typename?: 'createUserExportRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userExportRequest?: Maybe<UserExportRequest>;
};

/** Creates a User. */
export type CreateUserInput = {
  active?: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  districtUuid?: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystemName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<GradeLevelEnum>;
  language?: LanguageEnum;
  lastName?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  manualMaintenance?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['String']>;
  plainPassword?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  sisId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  type: UserTypeEnum;
  userRoles?: InputMaybe<Array<InputMaybe<CreateUserRoleNestedInput>>>;
  userRolesExplicitDelete?: InputMaybe<
    Array<InputMaybe<CreateUserRoleNestedInput>>
  >;
  username: Scalars['String'];
};

/** Creates a User. */
export type CreateUserNestedPayload = Node & {
  __typename?: 'createUserNestedPayload';
  _id: Scalars['String'];
  active: Scalars['Boolean'];
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdByUsername?: Maybe<Scalars['String']>;
  curriculumAreas?: Maybe<Scalars['Iterable']>;
  district?: Maybe<District>;
  /** @deprecated Used from Session User */
  districtUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gradeLevel?: Maybe<GradeLevelEnum>;
  id: Scalars['ID'];
  importId?: Maybe<Scalars['String']>;
  inactivityReason?: Maybe<UserInactivityReasonEnum>;
  language: LanguageEnum;
  lastName?: Maybe<Scalars['String']>;
  lmsId?: Maybe<Scalars['String']>;
  manualMaintenance?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  plainPassword?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  purpose?: Maybe<UserPurposeEnum>;
  sisId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type: UserTypeEnum;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByUsername?: Maybe<Scalars['String']>;
  userRoles?: Maybe<CreateUserRoleNestedPayloadPageConnection>;
  username: Scalars['String'];
};

/** Creates a User. */
export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Creates a UserRole. */
export type CreateUserRoleInput = {
  allSchoolsAccess?: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  roleId?: Scalars['String'];
  schoolIds: Scalars['Iterable'];
};

/** Creates a UserRole. */
export type CreateUserRoleNestedInput = {
  allSchoolsAccess?: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  roleId?: Scalars['String'];
  schoolIds: Scalars['Iterable'];
};

/** Creates a UserRole. */
export type CreateUserRoleNestedPayload = Node & {
  __typename?: 'createUserRoleNestedPayload';
  allSchoolsAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  role: Role;
  /** @deprecated Use the Role property instead */
  roleId: Scalars['String'];
  schoolIds: Scalars['Iterable'];
};

/** Page connection for createUserRoleNestedPayload. */
export type CreateUserRoleNestedPayloadPageConnection = {
  __typename?: 'createUserRoleNestedPayloadPageConnection';
  collection?: Maybe<Array<Maybe<CreateUserRoleNestedPayload>>>;
  paginationInfo: CreateUserRoleNestedPayloadPaginationInfo;
};

/** Information about the pagination. */
export type CreateUserRoleNestedPayloadPaginationInfo = {
  __typename?: 'createUserRoleNestedPayloadPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Creates a UserRole. */
export type CreateUserRolePayload = {
  __typename?: 'createUserRolePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
};

export type CreateVideoPostObjectInput = {
  description?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  forceCreate?: Scalars['Boolean'];
  glossaryTerm?: InputMaybe<Scalars['String']>;
  groupIdentifier?: InputMaybe<Scalars['String']>;
  groupType: VideoGroupEnum;
  k5?: Scalars['Boolean'];
  mediaType?: MediaTypeEnum;
  tutorId?: InputMaybe<Scalars['String']>;
  videoIndex?: InputMaybe<Scalars['String']>;
};

/** Creates a WallAgeControl. */
export type CreateWallAgeControlInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  isVerified: Scalars['Boolean'];
  studentId: Scalars['String'];
};

/** Creates a WallAgeControl. */
export type CreateWallAgeControlPayload = {
  __typename?: 'createWallAgeControlPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  wallAgeControl?: Maybe<WallAgeControl>;
};

/** Creates a WallPostPin. */
export type CreateWallPostPinInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  isSticky: Scalars['Boolean'];
  postId: Scalars['String'];
};

/** Creates a WallPostPin. */
export type CreateWallPostPinPayload = {
  __typename?: 'createWallPostPinPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  wallPostPin?: Maybe<WallPostPin>;
};

export type CreateWidgetInput = {
  x: Scalars['Int'];
  y: Scalars['Int'];
  w: Scalars['Int'];
  h: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  expandedH: Scalars['Int'];
  expandedW: Scalars['Int'];
  minH: Scalars['Int'];
  minW: Scalars['Int'];
  type: WidgetType;
  config?: InputMaybe<Scalars['JSONObject']>;
};

export enum CurriculumAreaEnum {
  Math = 'Math',
  Science = 'Science',
}

export type CurriculumAreas = {
  __typename?: 'CurriculumAreas';
  curriculumAreas?: Maybe<Array<Scalars['String']>>;
};

export type CyuQuestionContainerInput = {
  category: Scalars['String'];
  grade: Scalars['String'];
  lesson: Scalars['String'];
  unit: Scalars['String'];
};

export enum DaAssignType {
  GradeLevEl = 'GRADE_LEVEl',
  School = 'SCHOOL',
}

/** DefaultElementObject Type */
export type DefaultElement = {
  __typename?: 'DefaultElement';
  id: Scalars['String'];
  name: Scalars['String'];
  scopeId: Scalars['String'];
  studentView: Array<Scalars['JSON']>;
};

export type DeleteAdoptionAccountInput = {
  id: Scalars['ID'];
};

export type DeleteAdoptionEventInput = {
  id: Scalars['ID'];
};

export type DeleteAdoptionReviewerInput = {
  id: Scalars['ID'];
};

export type DeleteAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlDeleteAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesDeleteAssessmentInput>;
};

/** Deletes a Class. */
export type DeleteClassInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Class. */
export type DeleteClassPayload = {
  __typename?: 'deleteClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a District. */
export type DeleteDistrictInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a District. */
export type DeleteDistrictPayload = {
  __typename?: 'deleteDistrictPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
};

export type DeleteImageCategoryInput = {
  id: Scalars['ID'];
};

export type DeleteImageInput = {
  id: Scalars['ID'];
};

export type DeleteImageKeywordInput = {
  id: Scalars['ID'];
};

export type DeleteImageTagInput = {
  id: Scalars['ID'];
};

export type DeleteImageTagValueInput = {
  id: Scalars['ID'];
};

export type DeleteKeywordInput = {
  id: Scalars['ID'];
};

export type DeletePreviewAccountInput = {
  id: Scalars['ID'];
};

export type DeleteProductGroupInput = {
  id: Scalars['ID'];
};

export type DeleteProductInput = {
  id: Scalars['ID'];
};

/** Deletes a School. */
export type DeleteSchoolInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a School. */
export type DeleteSchoolPayload = {
  __typename?: 'deleteSchoolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
};

export type DeleteStemscopesMediaPackPayload = {
  __typename?: 'DeleteStemscopesMediaPackPayload';
  pack: StemscopesMediaPack;
};

export type DeleteSubscriptionInput = {
  id: Scalars['ID'];
};

export type DeleteSubscriptionPackageInput = {
  id: Scalars['ID'];
};

export type DeleteTagInput = {
  id: Scalars['ID'];
};

export type DeleteTagValueInput = {
  id: Scalars['ID'];
};

export type DeleteTeacherAssignmentInput = {
  assignmentId: Scalars['Int'];
  currentSchoolId: Scalars['String'];
};

export type DeleteTeacherAssignmentPayload = {
  __typename?: 'DeleteTeacherAssignmentPayload';
  response: Scalars['String'];
};

/** Deletes a UserCalendar. */
export type DeleteUserCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a UserCalendar. */
export type DeleteUserCalendarPayload = {
  __typename?: 'deleteUserCalendarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userCalendar?: Maybe<UserCalendar>;
};

/** Deletes a User. */
export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a User. */
export type DeleteUserPayload = {
  __typename?: 'deleteUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type DeleteVideoInput = {
  groupIdentifier?: InputMaybe<Scalars['String']>;
  groupType: VideoGroupEnum;
  mediaType?: MediaTypeEnum;
  tutorId?: InputMaybe<Scalars['String']>;
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type DeleteWidgetInput = {
  id: Scalars['String'];
};

export enum DeliveryEnum {
  Digital = 'DIGITAL',
  Paper = 'PAPER',
}

export type DigitalNotebookCover = {
  __typename?: 'DigitalNotebookCover';
  curriculumArea: Scalars['String'];
  gradeLevel: Scalars['String'];
  href: Scalars['String'];
  id: Scalars['String'];
  projectId: Scalars['String'];
  title: Scalars['String'];
};

export type DigitalNotebookCoverFilter = {
  curriculumArea?: InputMaybe<Array<Scalars['String']>>;
  gradeLevel?: InputMaybe<Array<Scalars['String']>>;
};

export type DigitalNotebookCovers = {
  __typename?: 'DigitalNotebookCovers';
  collection?: Maybe<Array<DigitalNotebookCover>>;
};

/** Use only for merging data! */
export type District = Node & {
  __typename?: 'District';
  id: Scalars['ID'];
  _id: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystem?: Maybe<Scalars['String']>;
  globalName: Scalars['String'];
  localName: Scalars['String'];
  mathNationId?: Maybe<Scalars['String']>;
  ncesId?: Maybe<Scalars['String']>;
  state: StateEnum;
};

/** Attributes for filtering district assignments by start/end date from/to */
export type DistrictAssignmentDateFilterInput = {
  from?: InputMaybe<Scalars['ISO8601Date']>;
  to?: InputMaybe<Scalars['ISO8601Date']>;
};

/** DA page info input */
export type DistrictAssignmentPageInfoInput = {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type DistrictAssignmentsFilter = {
  teacherFilter?: InputMaybe<TeacherDistrictAssignmentsFilter>;
};

/** Params to start District Assignments */
export type DistrictAssignmentSignRequestInput = {
  referenceId: Scalars['String'];
  signItem?: InputMaybe<ItemSignRequestInput>;
};

/** Start District Assignment response type */
export type DistrictAssignmentSignRequestType = {
  __typename?: 'DistrictAssignmentSignRequestType';
  sessionId?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
};

/** Attributes for District Student Assignments share_results update */
export type DistrictAssignmentsShareResultsInput = {
  assignmentId: Scalars['String'];
  shareResults: ShareResults;
  studentIds: Array<Scalars['String']>;
};

/** District Assignment Object Type */
export type DistrictAssignmentType = {
  __typename?: 'DistrictAssignmentType';
  daId: Scalars['String'];
};

/** District Base Assignments Object Type */
export type DistrictBaseAssignmentsType = {
  __typename?: 'DistrictBaseAssignmentsType';
  collection?: Maybe<Array<DistrictBaseAssignmentType>>;
  pageInfo?: Maybe<PageInfo>;
};

/** District Base Assignment Object Type */
export type DistrictBaseAssignmentType = {
  __typename?: 'DistrictBaseAssignmentType';
  assignType?: Maybe<DaAssignType>;
  daId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  name?: Maybe<Scalars['String']>;
  schoolGradeLevels?: Maybe<Array<SchoolGradeLevels>>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
};

/** District Class Assignments Object Type */
export type DistrictClassAssignmentsType = {
  __typename?: 'DistrictClassAssignmentsType';
  collection: Array<DistrictClassAssignmentType>;
  pageInfo?: Maybe<PageInfo>;
};

/** District Class Assignment Object Type */
export type DistrictClassAssignmentType = {
  __typename?: 'DistrictClassAssignmentType';
  assessmentId: Scalars['String'];
  assignmentId: Scalars['String'];
  assignmentSource?: Maybe<AssignmentSource>;
  availableDate?: Maybe<Scalars['ISO8601Date']>;
  classId: Scalars['String'];
  daId: Scalars['String'];
  districtId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  gradeLevel: Scalars['String'];
  name: Scalars['String'];
  originLearnosityId?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
  startDate: Scalars['ISO8601Date'];
  studentAssignments?: Maybe<Array<DistrictStudentAssignmentType>>;
  teacherId: Scalars['String'];
};

/** Attributes for creating district assignment */
export type DistrictCreateInput = {
  assessmentId: Scalars['String'];
  assignType?: InputMaybe<DaAssignType>;
  assignmentSource?: InputMaybe<AssignmentSourceInput>;
  districtId: Scalars['String'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  name: Scalars['String'];
  schoolGradeLevels: Array<SchoolGradeLevelsInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

/** Contains string types to be used for filtering. */
export type DistrictFilter = {
  /** Filter by ID */
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter by name */
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<DistrictFilter_Order>>>;
  /** Filter by state */
  state?: InputMaybe<Scalars['String']>;
  /** Filter by source */
  source?: InputMaybe<DistrictSourceEnumFilterType>;
};

export type DistrictFilter_Order = {
  city?: InputMaybe<SortEnum>;
  globalName?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  state?: InputMaybe<SortEnum>;
};

export type DistrictInput = {
  id: Scalars['String'];
};

/** Page connection for District. */
export type DistrictPageConnection = {
  __typename?: 'DistrictPageConnection';
  collection?: Maybe<Array<Maybe<District>>>;
  paginationInfo: DistrictPaginationInfo;
};

/** Information about the pagination. */
export type DistrictPaginationInfo = {
  __typename?: 'DistrictPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type DistrictSettingsInput = {
  teacherCanUsePerryBot: Scalars['Boolean'];
  studentCanUsePerryBot: Scalars['Boolean'];
};

export type DistrictSettingsKnowledgeBase = {
  __typename?: 'DistrictSettingsKnowledgeBase';
  studentCanUsePerryBot: Scalars['Boolean'];
  teacherCanUsePerryBot: Scalars['Boolean'];
};

export type DistrictSettingsLessonPlanner = Node & {
  __typename?: 'DistrictSettingsLessonPlanner';
  id: Scalars['ID'];
  teacherCanUseAiAssistedLessonPlan: Scalars['Boolean'];
};

export type DistrictSettingsLogin = {
  __typename?: 'DistrictSettingsLogin';
  ltiHighestScore: Scalars['Boolean'];
};

export type DistrictSettingsLoginInput = {
  ltiHighestScore?: Scalars['Boolean'];
};

export type DistrictSettingsRoster = Node & {
  __typename?: 'DistrictSettingsRoster';
  id: Scalars['ID'];
  teacherCanAddAdditionalTeacher: Scalars['Boolean'];
  teacherCanCreateClasses: Scalars['Boolean'];
  teacherCanEditClassRoster: Scalars['Boolean'];
};

export type DistrictSettingsUserManagement = Node & {
  __typename?: 'DistrictSettingsUserManagement';
  id: Scalars['ID'];
  teacherCanCreateStudents: Scalars['Boolean'];
  teacherCanEditStudentPassword: Scalars['Boolean'];
  teacherCanImpersonateStudents: Scalars['Boolean'];
};

export type DistrictSetupStatus = Node & {
  __typename?: 'DistrictSetupStatus';
  id: Scalars['ID'];
  status: SetupStatusEnum;
};

/** DistrictSort type: city / globalName / id / source / state by ASC or DESC */
export type DistrictSort = {
  /** sort by city asc or desc */
  city?: InputMaybe<SortEnum>;
  /** sort by globalName asc or desc */
  globalName?: InputMaybe<SortEnum>;
  /** sort by id asc or desc */
  id?: InputMaybe<SortEnum>;
  /** sort by source asc or desc */
  source?: InputMaybe<SortEnum>;
  /** sort by state asc or desc */
  state?: InputMaybe<SortEnum>;
};

export enum DistrictSourceEnum {
  Manual = 'MANUAL',
  Unspecified = 'UNSPECIFIED',
  OneRosterCsv = 'ONE_ROSTER_CSV',
  Clever = 'CLEVER',
  Classlink = 'CLASSLINK',
  EdFi = 'ED_FI',
  Mathnation = 'MATHNATION',
  Skyward = 'SKYWARD',
}

/** DistrictSourceEnum type: MANUAL, UNSPECIFIED, ONE_ROSTER_CSV, CLEVER, CLASSLINK, ED_FI, MATHNATION, SKYWARD */
export enum DistrictSourceEnumFilterType {
  Manual = 'MANUAL',
  Unspecified = 'UNSPECIFIED',
  OneRosterCsv = 'ONE_ROSTER_CSV',
  Clever = 'CLEVER',
  Classlink = 'CLASSLINK',
  EdFi = 'ED_FI',
  Mathnation = 'MATHNATION',
  Skyward = 'SKYWARD',
}

/** District Student Assignments Object Type */
export type DistrictStudentAssignmentsType = {
  __typename?: 'DistrictStudentAssignmentsType';
  collection: Array<DistrictStudentAssignmentType>;
  pageInfo?: Maybe<PageInfo>;
};

/** District Student Assignment Object Type */
export type DistrictStudentAssignmentType = {
  __typename?: 'DistrictStudentAssignmentType';
  answers?: Maybe<Scalars['JSON']>;
  assessmentId: Scalars['String'];
  assignmentId: Scalars['String'];
  availableDate?: Maybe<Scalars['ISO8601Date']>;
  classId: Scalars['String'];
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  daId: Scalars['String'];
  districtId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  grade?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  reopenDate?: Maybe<Scalars['ISO8601Date']>;
  schoolId: Scalars['String'];
  shareResults?: Maybe<ShareResults>;
  startDate: Scalars['ISO8601Date'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<StudentAssignmentStatus>;
  studentAvailability: Scalars['String'];
  studentId: Scalars['String'];
  studentNotes?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  teacherId: Scalars['String'];
};

export type DownloadTccPackage = {
  __typename?: 'DownloadTccPackage';
  downloadLink: Scalars['String'];
};

export type EdfiGradeLevel = {
  __typename?: 'EdfiGradeLevel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EdfiJobRole = {
  __typename?: 'EdfiJobRole';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EdfiSchool = {
  __typename?: 'EdfiSchool';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EdgePayload = {
  __typename?: 'EdgePayload';
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type EdgexlAssessmentInput = {
  field: FieldEnum;
  value: Scalars['String'];
  currentSchoolId: Scalars['String'];
};

export type EdgexlAssessmentItemPayload = {
  __typename?: 'EdgexlAssessmentItemPayload';
  status: Scalars['String'];
  tags: Array<TagPayload>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserPayload>;
  items: Array<Scalars['String']>;
};

export type EdgexlAssessmentPayload = {
  __typename?: 'EdgexlAssessmentPayload';
  item?: Maybe<EdgexlAssessmentItemPayload>;
};

export type EdgexlAssessmentPoolInput = {
  library: EdgexlLibraryEnum;
  sort?: InputMaybe<SortInput>;
  category?: InputMaybe<EdgexlCategoryEnum>;
  grade?: InputMaybe<EdgexlGradeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Array<Scalars['String']>>;
  next?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type EdgexlAssessmentPoolPayload = {
  __typename?: 'EdgexlAssessmentPoolPayload';
  list: Array<EdgexlAssessmentItemPayload>;
  next: Scalars['String'];
};

export type EdgexlAssignmentProgressInfoType = {
  __typename?: 'EdgexlAssignmentProgressInfoType';
  activityId?: Maybe<Scalars['String']>;
  maxScore?: Maybe<Scalars['String']>;
  responses?: Maybe<Scalars['JSON']>;
  score?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Settings for edgexl assignment */
export type EdgexlAssignmentSettingsInput = {
  classesSettings: Array<EdgexlClassSettingsInput>;
  settings: EdgexlSettingsInput;
};

/** info about last attemption or all attemptions */
export type EdgexlAssignmentsProgressInfoInput = {
  activityId?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
};

export type EdgexlAssignmentsProgressInfoType = {
  __typename?: 'EdgexlAssignmentsProgressInfoType';
  collection?: Maybe<Array<EdgexlAssignmentProgressInfoType>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Edgexl Assignments Object Type */
export type EdgexlAssignmentsType = {
  __typename?: 'EdgexlAssignmentsType';
  assignments: Array<EdgexlAssignmentType>;
  pageInfo?: Maybe<PageInfo>;
  studentAssignments?: Maybe<Array<EdgexlStudentAssignmentType>>;
};

/** Edgexl Assignment Object Type */
export type EdgexlAssignmentType = {
  __typename?: 'EdgexlAssignmentType';
  assessmentId?: Maybe<Scalars['String']>;
  assignmentId?: Maybe<Scalars['String']>;
  assignmentSettings?: Maybe<EdgexlSettingsType>;
  classesSettings?: Maybe<Scalars['JSON']>;
  schoolId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum EdgexlCategoryEnum {
  Fl = 'FL',
  Im = 'IM',
  Le = 'LE',
  Mf = 'MF',
  On = 'ON',
  Nl = 'NL',
  Sc = 'SC',
  Ms = 'MS',
}

/** Settings for class */
export type EdgexlClassSettingsInput = {
  classId: Scalars['String'];
  settings: EdgexlSettingsInput;
  students: Array<EdgexlStudentSettingsInput>;
};

export type EdgexlCopyAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

export type EdgexlCreateAssessmentInput = {
  attemptsNumber?: InputMaybe<Scalars['Int']>;
  deliveries: Array<DeliveryEnum>;
  isAllowViewCorrectAnswers?: InputMaybe<Scalars['Boolean']>;
  isAssigned?: Scalars['Boolean'];
  isRetakeOnlyIncorrect?: InputMaybe<Scalars['Boolean']>;
  isSameQuestionNumber?: InputMaybe<Scalars['Boolean']>;
  isScramble?: InputMaybe<Scalars['Boolean']>;
  items: Array<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  category?: InputMaybe<EdgexlCategoryEnum>;
  grade?: InputMaybe<EdgexlGradeEnum>;
  currentSchoolId: Scalars['String'];
};

/** Attributes for creating edgexl assignment */
export type EdgexlCreateInput = {
  assignmentSettings: EdgexlAssignmentSettingsInput;
  assignmentSource: AssignmentSourceInput;
  learnosityReferenceId: Scalars['String'];
  schoolId: Scalars['String'];
  title: Scalars['String'];
};

export type EdgexlDeleteAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

/** Attributes for editing edgexl assignment */
export type EdgexlEditInput = {
  assignmentId: Scalars['String'];
  assignmentSettings: EdgexlAssignmentSettingsInput;
};

export enum EdgexlGradeEnum {
  G01 = 'G01',
  G02 = 'G02',
  G03 = 'G03',
  G04 = 'G04',
  G05 = 'G05',
  G06 = 'G06',
  G6A = 'G6A',
  G07 = 'G07',
  G7A = 'G7A',
  G08 = 'G08',
  Ga1 = 'GA1',
  Ga2 = 'GA2',
  Gac = 'GAC',
  Ggo = 'GGO',
  Gkg = 'GKG',
  Gon = 'GON',
  Gp8 = 'GP8',
  Gpt = 'GPT',
  Gsa = 'GSA',
}

export enum EdgexlLibraryEnum {
  Individual = 'INDIVIDUAL',
  Mathnation = 'MATHNATION',
  District = 'DISTRICT',
  Others = 'OTHERS',
}

export type EdgexlQuestionContainerInput = {
  category: Scalars['String'];
  grade: Scalars['String'];
  unit: Scalars['String'];
};

export type EdgexlQuestionPoolInput = {
  category: EdgexlCategoryEnum;
  grade: EdgexlGradeEnum;
  units?: InputMaybe<Array<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  standards?: InputMaybe<Array<Scalars['String']>>;
  currentSchoolId: Scalars['String'];
};

/** Shared type for settings */
export type EdgexlSettingsInput = {
  attemptsNumber: Scalars['String'];
  endDate: Scalars['ISO8601DateTime'];
  notes: Scalars['String'];
  startDate: Scalars['ISO8601DateTime'];
};

/** Edgexl Settings Object Type */
export type EdgexlSettingsType = {
  __typename?: 'EdgexlSettingsType';
  attemptsNumber?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  notes?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};

export type EdgexlShareAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

/** Edgexl Student Assignments Object Type */
export type EdgexlStudentAssignmentsType = {
  __typename?: 'EdgexlStudentAssignmentsType';
  collection?: Maybe<Array<EdgexlStudentAssignmentType>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Edgexl Student Assignment Object Type */
export type EdgexlStudentAssignmentType = {
  __typename?: 'EdgexlStudentAssignmentType';
  assignmentId: Scalars['String'];
  classId?: Maybe<Scalars['String']>;
  completedAttemptsNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAllowViewCorrectAnswers?: Maybe<Scalars['String']>;
  isRetakeOnlyIncorrect?: Maybe<Scalars['String']>;
  isSameQuestionNumber?: Maybe<Scalars['String']>;
  isScramble?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Scalars['String']>>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  maxScore?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  studentSettings?: Maybe<EdgexlSettingsType>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  title?: Maybe<Scalars['String']>;
};

/** Student assignment settings */
export type EdgexlStudentSettingsInput = {
  settings: EdgexlSettingsInput;
  studentId: Scalars['String'];
};

export type EdgexlUpdateAssessmentInput = {
  id: Scalars['ID'];
  attemptsNumber?: InputMaybe<Scalars['Int']>;
  deliveries: Array<DeliveryEnum>;
  isAllowViewCorrectAnswers?: InputMaybe<Scalars['Boolean']>;
  isAssigned?: Scalars['Boolean'];
  isRetakeOnlyIncorrect?: InputMaybe<Scalars['Boolean']>;
  isSameQuestionNumber?: InputMaybe<Scalars['Boolean']>;
  isScramble?: InputMaybe<Scalars['Boolean']>;
  items: Array<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  category?: InputMaybe<EdgexlCategoryEnum>;
  grade?: InputMaybe<EdgexlGradeEnum>;
  currentSchoolId: Scalars['String'];
};

/** Edit Assignment Input */
export type EditAssignmentInput = {
  edgexlEditAssignmentInput: EdgexlEditInput;
};

/** Element Object Type */
export type Element = {
  __typename?: 'Element';
  elementId: Scalars['String'];
  elementName: Scalars['String'];
  projectId: Scalars['String'];
  scopeId: Scalars['String'];
  scopeName: Scalars['String'];
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
  viewId?: Maybe<Scalars['String']>;
};

export type ElementFilter = {
  curriculumArea?: InputMaybe<Array<Scalars['String']>>;
  gradeLevel?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Array<Scalars['String']>>;
  projectId?: InputMaybe<Scalars['String']>;
  resourceDefault?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
};

/** ElementInput */
export type ElementInput = {
  elementId: Scalars['String'];
  elementName: Scalars['String'];
  projectId: Scalars['String'];
  scopeId: Scalars['String'];
  scopeName: Scalars['String'];
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
  viewId: Scalars['String'];
};

export type Elements = {
  __typename?: 'Elements';
  collection?: Maybe<Array<SearchableElement>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum ElementViewEnum {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  id: Scalars['ID'];
  body: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  subject: Scalars['String'];
  type: EmailTemplateTypeEnum;
  attachments: Array<EmailTemplateAttachment>;
};

export type EmailTemplateAttachment = {
  __typename?: 'EmailTemplateAttachment';
  id: Scalars['ID'];
  emailTemplateId: Scalars['String'];
  originalFileName: Scalars['String'];
  downloadLink: Scalars['String'];
  path: Scalars['String'];
};

export type EmailTemplateAttachmentInput = {
  id: Scalars['String'];
  originalFileName: Scalars['String'];
  path: Scalars['String'];
};

export type EmailTemplateAttachmentUploadForm = {
  __typename?: 'EmailTemplateAttachmentUploadForm';
  id: Scalars['String'];
  fileName: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
};

export type EmailTemplateAttachmentUploadFormPayload = {
  __typename?: 'EmailTemplateAttachmentUploadFormPayload';
  collection: Array<EmailTemplateAttachmentUploadForm>;
};

export type EmailTemplateFilterInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  subject?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTemplateTypeEnum>;
};

export type EmailTemplateImageUploadForm = {
  __typename?: 'EmailTemplateImageUploadForm';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type EmailTemplatePageConnection = {
  __typename?: 'EmailTemplatePageConnection';
  collection: Array<EmailTemplate>;
  paginationInfo?: Maybe<EmailTemplatePaginationInfo>;
};

export type EmailTemplatePaginationInfo = {
  __typename?: 'EmailTemplatePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type EmailTemplatePayload = {
  __typename?: 'EmailTemplatePayload';
  emailTemplate: EmailTemplate;
};

export type EmailTemplatePlaceholder = {
  __typename?: 'EmailTemplatePlaceholder';
  code: EmailTemplatePlaceholderEnum;
  exampleValue: Scalars['String'];
};

export enum EmailTemplatePlaceholderEnum {
  DistrictGlobalName = 'district_global_name',
  MfaCode = 'mfa_code',
  ParentRegistrationLink = 'parent_registration_link',
  Password = 'password',
  PreviewAccountActivityEndDate = 'preview_account_activity_end_date',
  PreviewAccountActivityStartDate = 'preview_account_activity_start_date',
  PreviewAccountId = 'preview_account_id',
  UserFirstName = 'user_first_name',
  UserLastName = 'user_last_name',
  Username = 'username',
  UserPasswordRecoveryApplication = 'user_password_recovery_application',
  UserPasswordRecoveryResetLink = 'user_password_recovery_reset_link',
  UserPasswordRecoveryResetLinkLifetimeHours = 'user_password_recovery_reset_link_lifetime_hours',
}

export type EmailTemplatePlaceholdersPayload = {
  __typename?: 'EmailTemplatePlaceholdersPayload';
  collection: Array<EmailTemplatePlaceholder>;
};

export enum EmailTemplateTypeEnum {
  AddChildToParent = 'addChildToParent',
  AdminMfa = 'adminMfa',
  PostReplyAdded = 'postReplyAdded',
  PreviewAccountActivityPeriodUpdated = 'previewAccountActivityPeriodUpdated',
  PreviewAccountCreated = 'previewAccountCreated',
  PreviewAccountExpires = 'previewAccountExpires',
  PreviewAccountFinished = 'previewAccountFinished',
  PreviewAccountStarted = 'previewAccountStarted',
  ParentRegistration = 'parentRegistration',
  UserPasswordRecovery = 'userPasswordRecovery',
}

export type EmbedUrlResponse = {
  __typename?: 'EmbedUrlResponse';
  EmbedUrl: Scalars['String'];
};

export type Entry = Node & {
  __typename?: 'Entry';
  _id: Scalars['String'];
  calendarId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionalDays?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopeId?: Maybe<Scalars['String']>;
  scopePlanId?: Maybe<Scalars['String']>;
  scopePlanType?: Maybe<ScopePlanTypeEnum>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  startDate: Scalars['String'];
  type: EntryTypeEnum;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type EntryFilter = {
  endDate?: InputMaybe<Scalars['String']>;
  entryType?: InputMaybe<EntryTypeEnum>;
  startDate?: InputMaybe<Scalars['String']>;
};

/** Page connection for Entry. */
export type EntryPageConnection = {
  __typename?: 'EntryPageConnection';
  collection?: Maybe<Array<Maybe<Entry>>>;
  paginationInfo: EntryPaginationInfo;
};

/** Information about the pagination. */
export type EntryPaginationInfo = {
  __typename?: 'EntryPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Entry types available */
export enum EntryTypeEnum {
  /** Entry type: Event */
  Event = 'Event',
  /** Entry type: First Day Of School */
  FirstDayOfSchool = 'FirstDayOfSchool',
  /** Entry type: Grading Period */
  GradingPeriod = 'GradingPeriod',
  /** Entry type: Holiday */
  Holiday = 'Holiday',
  /** Entry type: Last Day Of School */
  LastDayOfSchool = 'LastDayOfSchool',
  /** Entry type: Non Instructional Day */
  NonInstructionalDay = 'NonInstructionalDay',
  /** Entry type: Note */
  Note = 'Note',
  /** Entry type: Pacing Guide Scope */
  Scope = 'Scope',
  /** Entry type: Testing Day */
  TestingDay = 'TestingDay',
}

export type EventInput = {
  payload: Scalars['String'];
  type: Scalars['String'];
};

export type EventStatusPayload = {
  __typename?: 'EventStatusPayload';
  success: Scalars['Boolean'];
};

export enum ExternalFieldEnum {
  Email = 'EMAIL',
  Id = 'ID',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Username = 'USERNAME',
  LmsId = 'LMS_ID',
}

export enum ExternalSystemNameEnum {
  SystemManual = 'SYSTEM_MANUAL',
  SystemOnerosterCsv = 'SYSTEM_ONEROSTER_CSV',
  SystemClever = 'SYSTEM_CLEVER',
  SystemClasslink = 'SYSTEM_CLASSLINK',
  SystemEdFi = 'SYSTEM_ED_FI',
  SystemMathnation = 'SYSTEM_MATHNATION',
  SystemSkyward = 'SYSTEM_SKYWARD',
}

export type ExtractOneRosterSchoolsInput = {
  districtId: Scalars['String'];
  file: Scalars['Upload'];
};

export enum FieldEnum {
  Title = 'TITLE',
  Id = 'ID',
}

export type FilterIconInput = {
  /** Used for retrieving specific icon information based in elementIconType */
  type?: InputMaybe<Scalars['String']>;
};

export type FilterTeamInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  membersActive?: InputMaybe<Scalars['Boolean']>;
};

export type FromMoveVideoInput = {
  groupIdentifier: Scalars['String'];
  tutorId?: InputMaybe<Scalars['String']>;
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type GenerateQuestionResponse = {
  __typename?: 'GenerateQuestionResponse';
  jsonResponse?: Maybe<Scalars['String']>;
};

export type GetQuestionHintResponse = {
  __typename?: 'GetQuestionHintResponse';
  hint?: Maybe<Scalars['String']>;
};

export enum GlossaryFilterConditionEnum {
  Contain = 'CONTAIN',
  BeginWith = 'BEGIN_WITH',
}

export type GpbJwt = {
  __typename?: 'GpbJwt';
  JWT: Scalars['String'];
};

export type GradeAndActivity = {
  __typename?: 'GradeAndActivity';
  activities: Array<Scalars['String']>;
  grade: Scalars['String'];
};

export enum GradeLevelEnum {
  GlPreK = 'gl_pre_k',
  GlK = 'gl_k',
  Gl_1 = 'gl_1',
  Gl_2 = 'gl_2',
  Gl_3 = 'gl_3',
  Gl_4 = 'gl_4',
  Gl_5 = 'gl_5',
  Gl_6 = 'gl_6',
  Gl_7 = 'gl_7',
  Gl_8 = 'gl_8',
  Gl_9 = 'gl_9',
  Gl_10 = 'gl_10',
  Gl_11 = 'gl_11',
  Gl_12 = 'gl_12',
}

export enum GradeLevelFilterEnum {
  GlUndefined = 'gl_undefined',
  GlPreK = 'gl_pre_k',
  GlK = 'gl_k',
  Gl_1 = 'gl_1',
  Gl_2 = 'gl_2',
  Gl_3 = 'gl_3',
  Gl_4 = 'gl_4',
  Gl_5 = 'gl_5',
  Gl_6 = 'gl_6',
  Gl_7 = 'gl_7',
  Gl_8 = 'gl_8',
  Gl_9 = 'gl_9',
  Gl_10 = 'gl_10',
  Gl_11 = 'gl_11',
  Gl_12 = 'gl_12',
}

export enum GradeLevels {
  /** 3-5 grade levels */
  Gl_35 = 'GL_35',
  /** 6-8(Middle School) grade levels */
  Gl_68 = 'GL_68',
  /** High School grade levels */
  GlHs = 'GL_HS',
  /** K-2 grade levels */
  GlK2 = 'GL_K2',
}

/** Available grade levels sorted by grades */
export type GradeLevelsByGrade = {
  __typename?: 'GradeLevelsByGrade';
  gl1?: Maybe<Array<GradeLevels>>;
  gl10?: Maybe<Array<GradeLevels>>;
  gl11?: Maybe<Array<GradeLevels>>;
  gl12?: Maybe<Array<GradeLevels>>;
  gl2?: Maybe<Array<GradeLevels>>;
  gl3?: Maybe<Array<GradeLevels>>;
  gl4?: Maybe<Array<GradeLevels>>;
  gl5?: Maybe<Array<GradeLevels>>;
  gl6?: Maybe<Array<GradeLevels>>;
  gl7?: Maybe<Array<GradeLevels>>;
  gl8?: Maybe<Array<GradeLevels>>;
  gl9?: Maybe<Array<GradeLevels>>;
  glk?: Maybe<Array<GradeLevels>>;
  glpk?: Maybe<Array<GradeLevels>>;
};

/** Available grade levels sorted by grades */
export type GradeLevelsByGradeInput = {
  gl1?: InputMaybe<Array<GradeLevels>>;
  gl10?: InputMaybe<Array<GradeLevels>>;
  gl11?: InputMaybe<Array<GradeLevels>>;
  gl12?: InputMaybe<Array<GradeLevels>>;
  gl2?: InputMaybe<Array<GradeLevels>>;
  gl3?: InputMaybe<Array<GradeLevels>>;
  gl4?: InputMaybe<Array<GradeLevels>>;
  gl5?: InputMaybe<Array<GradeLevels>>;
  gl6?: InputMaybe<Array<GradeLevels>>;
  gl7?: InputMaybe<Array<GradeLevels>>;
  gl8?: InputMaybe<Array<GradeLevels>>;
  gl9?: InputMaybe<Array<GradeLevels>>;
  glk?: InputMaybe<Array<GradeLevels>>;
  glpk?: InputMaybe<Array<GradeLevels>>;
};

export type Grades = {
  __typename?: 'Grades';
  grades: Array<Scalars['String']>;
};

/** Grading Session Type */
export type GradingSession = {
  __typename?: 'GradingSession';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum GradingTypeEnum {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

export type HelpPageInput = {
  audience: Audience;
};

export type HelpPageStructure = {
  __typename?: 'HelpPageStructure';
  audience: Audience;
  link: Scalars['String'];
};

export type Icon = {
  __typename?: 'Icon';
  id: Scalars['ID'];
  fileName: Scalars['String'];
  type: Scalars['String'];
  filePath: Scalars['String'];
  languageFilePath: Array<LanguageFilePath>;
  consumer: ConsumerEnum;
};

export type IconPageConnection = {
  __typename?: 'IconPageConnection';
  collection: Array<Icon>;
  paginationInfo?: Maybe<IconPaginationInfo>;
};

export type IconPaginationInfo = {
  __typename?: 'IconPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type IconPayload = {
  __typename?: 'IconPayload';
  icon: Icon;
};

export type IdentifyUserFilter = {
  districtId?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  sisId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  altText?: Maybe<Scalars['String']>;
  categories: Array<ImageCategory>;
  createdAt: Scalars['DateTime'];
  files: Array<ImageFile>;
  id: Scalars['ID'];
  keywords: Array<ImageKeyword>;
  name: Scalars['String'];
  tagAssociations: Array<ImageTagAssociation>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageCategory = {
  __typename?: 'ImageCategory';
  children: Array<ImageCategory>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<ImageCategory>;
  path: Array<ImageCategoryPathPart>;
};

export type ImageCategoryFilterInput = {
  parentId?: InputMaybe<Scalars['ID']>;
};

export type ImageCategoryPageConnection = {
  __typename?: 'ImageCategoryPageConnection';
  collection: Array<ImageCategory>;
  paginationInfo?: Maybe<ImageCategoryPaginationInfo>;
};

export type ImageCategoryPaginationInfo = {
  __typename?: 'ImageCategoryPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImageCategoryPathPart = {
  __typename?: 'ImageCategoryPathPart';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImageCategoryPayload = {
  __typename?: 'ImageCategoryPayload';
  imageCategory: ImageCategory;
};

export type ImageFile = {
  __typename?: 'ImageFile';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  height?: Maybe<Scalars['Int']>;
  language?: Maybe<LanguageEnum>;
  mimeType?: Maybe<Scalars['String']>;
  originalFileName: Scalars['String'];
  path: Scalars['String'];
  processed: Scalars['Boolean'];
  sha256: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageFileUploadForm = {
  __typename?: 'ImageFileUploadForm';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type ImageFilterInput = {
  language?: InputMaybe<LanguageEnum>;
  categoryId?: InputMaybe<Scalars['ID']>;
  keywordIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  tagAssociations?: InputMaybe<Array<ImageTagAssociationInput>>;
};

export type ImageKeyword = {
  __typename?: 'ImageKeyword';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImageKeywordFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type ImageKeywordPageConnection = {
  __typename?: 'ImageKeywordPageConnection';
  collection: Array<ImageKeyword>;
  paginationInfo?: Maybe<ImageKeywordPaginationInfo>;
};

export type ImageKeywordPaginationInfo = {
  __typename?: 'ImageKeywordPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImageKeywordPayload = {
  __typename?: 'ImageKeywordPayload';
  imageKeyword: ImageKeyword;
};

export type ImagePageConnection = {
  __typename?: 'ImagePageConnection';
  collection: Array<Image>;
  paginationInfo?: Maybe<ImagePaginationInfo>;
};

export type ImagePaginationInfo = {
  __typename?: 'ImagePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImagePayload = {
  __typename?: 'ImagePayload';
  image: Image;
};

export type ImageTag = {
  __typename?: 'ImageTag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImageTagAssociation = {
  __typename?: 'ImageTagAssociation';
  id: Scalars['ID'];
  tag: ImageTag;
  values: Array<ImageTagValue>;
};

export type ImageTagAssociationInput = {
  tagId: Scalars['ID'];
  valueIds: Array<Scalars['ID']>;
};

export type ImageTagFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type ImageTagPageConnection = {
  __typename?: 'ImageTagPageConnection';
  collection: Array<ImageTag>;
  paginationInfo?: Maybe<ImageTagPaginationInfo>;
};

export type ImageTagPaginationInfo = {
  __typename?: 'ImageTagPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImageTagPayload = {
  __typename?: 'ImageTagPayload';
  imageTag: ImageTag;
};

export type ImageTagValue = {
  __typename?: 'ImageTagValue';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ImageTagValueFilterInput = {
  tagId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type ImageTagValuePageConnection = {
  __typename?: 'ImageTagValuePageConnection';
  collection: Array<ImageTagValue>;
  paginationInfo?: Maybe<ImageTagValuePaginationInfo>;
};

export type ImageTagValuePaginationInfo = {
  __typename?: 'ImageTagValuePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ImageTagValuePayload = {
  __typename?: 'ImageTagValuePayload';
  imageTagValue: ImageTagValue;
};

export enum ImmediatelyShareResults {
  Disabled = 'DISABLED',
  WithoutCorrectAnswers = 'WITHOUT_CORRECT_ANSWERS',
  WithCorrectAnswers = 'WITH_CORRECT_ANSWERS',
}

export type ImportError = {
  __typename?: 'ImportError';
  id: Scalars['String'];
  message: Scalars['String'];
};

export type ImportErrorPageConnection = {
  __typename?: 'ImportErrorPageConnection';
  collection: Array<ImportError>;
  paginationInfo: ImportErrorPaginationInfo;
};

export type ImportErrorPaginationInfo = {
  __typename?: 'ImportErrorPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ImportResult = {
  __typename?: 'ImportResult';
  endDate?: Maybe<Scalars['String']>;
  errorCount: Scalars['Int'];
  id: Scalars['String'];
  logs: Array<ImportResultLog>;
  statistics: Array<ImportStatistic>;
  source: IntegrationEnum;
  startDate: Scalars['String'];
  status: ImportStatusEnum;
};

export type ImportResultErrorFilterInput = {
  message?: InputMaybe<Scalars['String']>;
};

export type ImportResultErrorInput = {
  importResultId: Scalars['String'];
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ImportResultErrorFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
};

export type ImportResultErrorsLink = {
  __typename?: 'ImportResultErrorsLink';
  url: Scalars['String'];
};

export type ImportResultErrorsLinkInput = {
  importResultId: Scalars['String'];
};

export type ImportResultInput = {
  id: Scalars['String'];
};

export type ImportResultLog = {
  __typename?: 'ImportResultLog';
  code: ImportStepCodeEnum;
  endDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  status: ImportStepStatusEnum;
};

export type ImportStatistic = {
  __typename?: 'ImportStatistic';
  changed: Scalars['Int'];
  notChanged: Scalars['Int'];
  total: Scalars['Int'];
  type: ImportStatisticTypeEnum;
};

export enum ImportStatisticTypeEnum {
  Staff = 'STAFF',
  Student = 'STUDENT',
}

export enum ImportStatusEnum {
  Completed = 'COMPLETED',
  CompletedWithWarnings = 'COMPLETED_WITH_WARNINGS',
  Error = 'ERROR',
  InQueue = 'IN_QUEUE',
  Started = 'STARTED',
}

export enum ImportStepCodeEnum {
  ArchiveExtractor = 'ARCHIVE_EXTRACTOR',
  CourseImporter = 'COURSE_IMPORTER',
  CourseOfferingImporter = 'COURSE_OFFERING_IMPORTER',
  CsvAcademicSessionImporter = 'CSV_ACADEMIC_SESSION_IMPORTER',
  CsvClassImporter = 'CSV_CLASS_IMPORTER',
  CsvCourseImporter = 'CSV_COURSE_IMPORTER',
  CsvEnrollmentImporter = 'CSV_ENROLLMENT_IMPORTER',
  CsvOrganizationImporter = 'CSV_ORGANIZATION_IMPORTER',
  CsvUserImporter = 'CSV_USER_IMPORTER',
  DataExporter = 'DATA_EXPORTER',
  DataRemover = 'DATA_REMOVER',
  DistrictAdminImporter = 'DISTRICT_ADMIN_IMPORTER',
  EtlJob = 'ETL_JOB',
  Exporter = 'EXPORTER',
  ImportFinalizer = 'IMPORT_FINALIZER',
  ImportInitializer = 'IMPORT_INITIALIZER',
  Initializer = 'INITIALIZER',
  OldDataRemover = 'OLD_DATA_REMOVER',
  RestAcademicSessionImporter = 'REST_ACADEMIC_SESSION_IMPORTER',
  RestClassImporter = 'REST_CLASS_IMPORTER',
  RestCourseImporter = 'REST_COURSE_IMPORTER',
  RestEnrollmentImporter = 'REST_ENROLLMENT_IMPORTER',
  RestOrganizationImporter = 'REST_ORGANIZATION_IMPORTER',
  RestUserImporter = 'REST_USER_IMPORTER',
  UserImporter = 'USER_IMPORTER',
  SectionImporter = 'SECTION_IMPORTER',
  SchoolImporter = 'SCHOOL_IMPORTER',
  StaffImporter = 'STAFF_IMPORTER',
  StaffRoleImporter = 'STAFF_ROLE_IMPORTER',
  StaffSchoolImporter = 'STAFF_SCHOOL_IMPORTER',
  StaffSectionImporter = 'STAFF_SECTION_IMPORTER',
  StudentImporter = 'STUDENT_IMPORTER',
  StudentSchoolImporter = 'STUDENT_SCHOOL_IMPORTER',
  StudentSectionImporter = 'STUDENT_SECTION_IMPORTER',
  TermImporter = 'TERM_IMPORTER',
}

export enum ImportStepStatusEnum {
  InProcess = 'IN_PROCESS',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

/** Initiates a UserSelection. */
export type InitiateUserSelectionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Initiates a UserSelection. */
export type InitiateUserSelectionPayload = {
  __typename?: 'initiateUserSelectionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userSelection?: Maybe<UserSelection>;
};

export type InlineCss = {
  __typename?: 'InlineCss';
  id: Scalars['ID'];
  inlineCssContent: Scalars['String'];
  filePath: Scalars['String'];
};

export type InlineCssInput = {
  /** Path of the source file used for converting as Inline Css */
  filePath?: Scalars['String'];
  /** Raw HTML content of a entire page */
  html?: InputMaybe<Scalars['String']>;
  /** Css for the page to be applied as Inline Css */
  iframes: Array<Scalars['ID']>;
};

export type InlineCssPayload = {
  __typename?: 'InlineCssPayload';
  inlineCss: InlineCss;
};

/** Object of Post Input Request Type */
export type InputPostRequest = {
  /** ID of selected school */
  currentSchoolId: Scalars['String'];
  /** District Id of author user */
  districtId?: InputMaybe<Scalars['String']>;
  /** Id of post */
  id?: InputMaybe<Scalars['String']>;
  /** Get answered / unanswered posts */
  isAnswered?: InputMaybe<Scalars['Boolean']>;
  /** Get hidden / unhidden posts */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Offset */
  offset?: InputMaybe<Scalars['Int']>;
  /** School Id of author user */
  schoolId?: InputMaybe<Scalars['String']>;
  /** Size */
  size?: InputMaybe<Scalars['Int']>;
  /** Two character state code of author user */
  stateCode?: InputMaybe<Scalars['String']>;
  /** User Id of post author */
  userId?: InputMaybe<Scalars['String']>;
  /** Array of Wall Ids */
  wallIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Object of Input Request Type */
export type InputRequest = {
  /** Config for the request */
  config?: InputMaybe<Scalars['String']>;
  /** Mode Inline or Assess */
  mode?: InputMaybe<ModeEnum>;
  /** Reference Id to be provided in the request */
  referenceId?: InputMaybe<Scalars['String']>;
  /** Type Activity or Item */
  type?: InputMaybe<TypeEnum>;
};

export type Integration = {
  __typename?: 'Integration';
  config?: Maybe<IntegrationConfig>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  gradeMap: Array<IntegrationGradeMap>;
  integration: IntegrationEnum;
  roleMap: Array<IntegrationRoleMap>;
  schoolMap: Array<IntegrationSchoolMap>;
  settings?: Maybe<IntegrationSettings>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<ImportStatusEnum>;
  active: Scalars['Boolean'];
  autoSync: Scalars['Boolean'];
};

export type IntegrationConfig = {
  __typename?: 'IntegrationConfig';
  apiMode?: Maybe<ApiModeEnum>;
  baseUrl?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  sshConfigured?: Maybe<Scalars['Boolean']>;
  sshKey?: Maybe<Scalars['String']>;
  tokenUrl?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type IntegrationConfigInput = {
  baseUrl?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  sshConfigured?: InputMaybe<Scalars['Boolean']>;
  sshKey?: InputMaybe<Scalars['String']>;
  apiMode?: InputMaybe<ApiModeEnum>;
  year?: InputMaybe<Scalars['Int']>;
  tokenUrl?: InputMaybe<Scalars['String']>;
};

export enum IntegrationEnum {
  Classlink = 'CLASSLINK',
  Clever = 'CLEVER',
  EdFi = 'ED_FI',
  OneRosterCsv = 'ONE_ROSTER_CSV',
  Skyward = 'SKYWARD',
}

export type IntegrationGradeMap = {
  __typename?: 'IntegrationGradeMap';
  externalId: Scalars['String'];
  internalId?: Maybe<InternalGradeLevelEnum>;
  name?: Maybe<Scalars['String']>;
};

export type IntegrationGradeMapInput = {
  externalId: Scalars['String'];
  internalId?: InputMaybe<InternalGradeLevelEnum>;
  name?: InputMaybe<Scalars['String']>;
};

export type IntegrationInput = {
  districtId: Scalars['String'];
  integration: IntegrationEnum;
  config?: InputMaybe<IntegrationConfigInput>;
  schoolMap?: InputMaybe<Array<IntegrationSchoolMapInput>>;
  gradeMap?: InputMaybe<Array<IntegrationGradeMapInput>>;
  roleMap?: InputMaybe<Array<IntegrationRoleMapInput>>;
  settings?: InputMaybe<IntegrationSettingsInput>;
  autoSync?: InputMaybe<Scalars['Boolean']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type IntegrationKeyInput = {
  districtId: Scalars['String'];
  integration: IntegrationEnum;
};

export type IntegrationRoleMap = {
  __typename?: 'IntegrationRoleMap';
  externalId: Scalars['String'];
  internalId?: Maybe<Scalars['String']>;
  mapToTitle?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  processRole?: Maybe<Scalars['Boolean']>;
};

export type IntegrationRoleMapInput = {
  externalId: Scalars['String'];
  internalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  processRole?: InputMaybe<Scalars['Boolean']>;
  mapToTitle?: InputMaybe<Scalars['Boolean']>;
};

export type IntegrationSchoolMap = {
  __typename?: 'IntegrationSchoolMap';
  externalId: Scalars['String'];
  internalId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type?: Maybe<OneRosterSchoolTypeEnum>;
  status?: Maybe<OneRosterSchoolStatusEnum>;
};

export type IntegrationSchoolMapInput = {
  externalId: Scalars['String'];
  internalId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OneRosterSchoolTypeEnum>;
  status?: InputMaybe<OneRosterSchoolStatusEnum>;
};

export type IntegrationSettings = {
  __typename?: 'IntegrationSettings';
  ignoreTermDates: Scalars['Boolean'];
  passwordOverwriting: Scalars['Boolean'];
  sisStaffIdField: OneRosterSisStaffIdFieldEnum;
  unlistedClassesDeactivation: Scalars['Boolean'];
  unlistedUsersDeactivation: Scalars['Boolean'];
};

export type IntegrationSettingsInput = {
  ignoreTermDates?: InputMaybe<Scalars['Boolean']>;
  passwordOverwriting?: InputMaybe<Scalars['Boolean']>;
  sisStaffIdField?: InputMaybe<OneRosterSisStaffIdFieldEnum>;
  unlistedClassesDeactivation?: InputMaybe<Scalars['Boolean']>;
  unlistedUsersDeactivation?: InputMaybe<Scalars['Boolean']>;
};

export type IntegrationsInput = {
  districtId?: InputMaybe<Scalars['String']>;
};

export enum InternalFieldEnum {
  Email = 'EMAIL',
  ExternalId = 'EXTERNAL_ID',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  LmsId = 'LMS_ID',
  SisId = 'SIS_ID',
  StaffId = 'STAFF_ID',
  StateId = 'STATE_ID',
  Username = 'USERNAME',
}

export type InternalFieldInput = {
  value: InternalFieldEnum;
  name?: InputMaybe<InternalFieldEnum>;
};

export enum InternalGradeLevelEnum {
  PreK = 'PRE_K',
  K = 'K',
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3',
  Level_4 = 'LEVEL_4',
  Level_5 = 'LEVEL_5',
  Level_6 = 'LEVEL_6',
  Level_7 = 'LEVEL_7',
  Level_8 = 'LEVEL_8',
  Level_9 = 'LEVEL_9',
  Level_10 = 'LEVEL_10',
  Level_11 = 'LEVEL_11',
  Level_12 = 'LEVEL_12',
}

/** Invites a Parent. */
export type InviteParentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  studentId: Scalars['String'];
};

/** Invites a Parent. */
export type InviteParentPayload = {
  __typename?: 'inviteParentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parent?: Maybe<InviteParentPayloadData>;
};

/** Invites a Parent. */
export type InviteParentPayloadData = Node & {
  __typename?: 'inviteParentPayloadData';
  id: Scalars['ID'];
};

/** Item Signed Request type */
export type ItemSignedRequest = SignedRequestInterface & {
  __typename?: 'ItemSignedRequest';
  items?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  renderingType?: Maybe<Scalars['String']>;
  signedRequest: Scalars['JSON'];
  state?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** Base Sign Request Input */
export type ItemSignRequestInput = {
  action?: InputMaybe<SignRequestActionInput>;
  config?: InputMaybe<Scalars['JSON']>;
  domain?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<Scalars['JSON']>>;
  name?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  renderingType?: InputMaybe<AssignmentLearnosityRenderingType>;
  schoolId?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<SignAssignmentServices>;
  sessionId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<AssignmentLearnosityState>;
  type?: InputMaybe<AssignmentLearnosityType>;
};

export type ItemsSignRequestRequestInput = {
  items: Array<Scalars['String']>;
  /** JSON string */
  config?: InputMaybe<Scalars['String']>;
};

export type KarmaAward = Node & {
  __typename?: 'KarmaAward';
  _id: Scalars['Int'];
  created: Scalars['String'];
  districtId: Scalars['String'];
  id: Scalars['ID'];
  karma: Scalars['Int'];
  karmaTotal: Scalars['Int'];
  modified: Scalars['String'];
  queryTotal: Scalars['Int'];
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
  stateCode: Scalars['String'];
  subjectCode: Scalars['String'];
  user: Scalars['Iterable'];
  userId: Scalars['String'];
  wallId?: Maybe<Scalars['String']>;
  wallPost?: Maybe<Post>;
};

/** Page connection for KarmaAward. */
export type KarmaAwardPageConnection = {
  __typename?: 'KarmaAwardPageConnection';
  collection?: Maybe<Array<Maybe<KarmaAward>>>;
  paginationInfo: KarmaAwardPaginationInfo;
};

/** Information about the pagination. */
export type KarmaAwardPaginationInfo = {
  __typename?: 'KarmaAwardPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Keyword = {
  __typename?: 'Keyword';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type KeywordFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type KeywordOrderInput = {
  id?: InputMaybe<SortEnum>;
  name?: InputMaybe<SortEnum>;
};

export type KeywordPageConnection = {
  __typename?: 'KeywordPageConnection';
  collection: Array<Keyword>;
  paginationInfo?: Maybe<KeywordPaginationInfo>;
};

export type KeywordPaginationInfo = {
  __typename?: 'KeywordPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type KeywordPayload = {
  __typename?: 'KeywordPayload';
  keyword: Keyword;
};

export type KnowledgeTreeInput = {
  studentIdInput?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type KnowledgeTreePayload = {
  __typename?: 'KnowledgeTreePayload';
  edges: Array<EdgePayload>;
  standards: Array<StandardPayload>;
};

export enum LanguageEnum {
  En = 'en',
  Es = 'es',
}

export type LanguageFilePath = {
  __typename?: 'LanguageFilePath';
  languageCode: Scalars['ID'];
  filePath: Scalars['ID'];
};

export enum LanguageTagEnum {
  En = 'EN',
  Asl = 'ASL',
  Ht = 'HT',
  Pt = 'PT',
  Sp = 'SP',
}

export type LeaderDistrictAssignmentsFilter = {
  daId?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  endDateFilter?: InputMaybe<DistrictAssignmentDateFilterInput>;
  gradeLevels?: InputMaybe<Array<Scalars['String']>>;
  schoolIds?: InputMaybe<Array<Scalars['String']>>;
  startDateFilter?: InputMaybe<DistrictAssignmentDateFilterInput>;
};

/** LevelEnum type: LEVEL1, LEVEL2, LEVEL3, LEVEL4, LEVEL5, LEVEL6 */
export enum LevelEnumType {
  Level1 = 'LEVEL1',
  Level2 = 'LEVEL2',
  Level3 = 'LEVEL3',
  Level4 = 'LEVEL4',
  Level5 = 'LEVEL5',
  Level6 = 'LEVEL6',
}

export type Lti = {
  __typename?: 'Lti';
  districtId: Scalars['String'];
  type: LtiTypeEnum;
  v1p1?: Maybe<LtiV1p1>;
  v1p3?: Maybe<LtiV1p3>;
  policies: Array<SsoPolicy>;
};

/** Attributes for creating LTI STEMscopes assignment */
export type LtiCreateInput = {
  assignmentSource: AssignmentSourceInput;
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

/** LTI Edgexl Assignment Object Type */
export type LtiEdgexlAssignmentType = {
  __typename?: 'LtiEdgexlAssignmentType';
  assignmentId?: Maybe<Scalars['String']>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
};

/** Attributes for creating LTI MathNation EdgeXl assignment */
export type LtiEdgeXlCreateInput = {
  assignmentId: Scalars['String'];
  assignmentSource: AssignmentSourceInput;
  gradeLevel: Scalars['String'];
  learnosityReferenceId: Scalars['String'];
  ownerId: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type LtiInput = {
  ltiKey?: InputMaybe<LtiKeyInput>;
  v1p3?: InputMaybe<LtiV1p3Input>;
};

export type LtiKeyInput = {
  districtId: Scalars['String'];
  type: LtiTypeEnum;
};

export type LtiListInput = {
  districtId?: InputMaybe<Scalars['String']>;
};

export enum LtiTypeEnum {
  CanvasV1P1 = 'CANVAS_V1P1',
  CanvasV1P3 = 'CANVAS_V1P3',
  ImsGlobalV1P3 = 'IMS_GLOBAL_V1P3',
  SafariMontageV1P1 = 'SAFARI_MONTAGE_V1P1',
  SafariMontageV1P3 = 'SAFARI_MONTAGE_V1P3',
  SchoologyV1P1 = 'SCHOOLOGY_V1P1',
  SchoologyV1P3 = 'SCHOOLOGY_V1P3',
}

export type LtiV1p1 = {
  __typename?: 'LtiV1p1';
  clientKey: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type LtiV1p3 = {
  __typename?: 'LtiV1p3';
  deploymentId: Scalars['String'];
  jwksUrl?: Maybe<Scalars['String']>;
};

export type LtiV1p3Input = {
  deploymentId: Scalars['String'];
  jwksUrl?: InputMaybe<Scalars['String']>;
};

export type LtiWithPoliciesInput = {
  policies?: InputMaybe<Array<PolicyInput>>;
  ltiKey?: InputMaybe<LtiKeyInput>;
  v1p3?: InputMaybe<LtiV1p3Input>;
};

/** Make Available District Assignment Input */
export type MakeAvailableDistrictAssignmentInput = {
  assignmentId: Scalars['String'];
  availableDate: Scalars['ISO8601Date'];
};

/** Manage District Assignment input */
export type ManageDistrictAssignmentInput = {
  assignmentId: Scalars['String'];
  studentIds: Array<Scalars['String']>;
};

export type MathNationGrades = {
  __typename?: 'MathNationGrades';
  grades: Array<Scalars['String']>;
};

export type MathNationScope = {
  __typename?: 'MathNationScope';
  active: Scalars['String'];
  category: Scalars['String'];
  contentAudience: AudienceEnum;
  contentGroups?: Maybe<Array<Scalars['String']>>;
  contentLocation: Scalars['String'];
  created: Scalars['String'];
  displayName: Scalars['String'];
  grade: Scalars['String'];
  id: Scalars['String'];
  lastUpdated: Scalars['String'];
  metadata: Scalars['JSON'];
  projectIdentifiers?: Maybe<Scalars['JSON']>;
  projectType: Scalars['String'];
  units: Array<Scalars['JSON']>;
};

export type MathNationScopeFilter = {
  category?: InputMaybe<Scalars['String']>;
  contentAudience?: InputMaybe<AudienceEnum>;
  grade?: InputMaybe<Scalars['String']>;
};

export type MathNationScopes = {
  __typename?: 'MathNationScopes';
  collection?: Maybe<Array<SearchableMathNationScope>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MediaPostObject = {
  __typename?: 'MediaPostObject';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
};

export enum MediaTypeEnum {
  MediaTypeAudio = 'MEDIA_TYPE_AUDIO',
  MediaTypeAudioMp4 = 'MEDIA_TYPE_AUDIO_MP4',
  MediaTypePdf = 'MEDIA_TYPE_PDF',
  MediaTypeVideo = 'MEDIA_TYPE_VIDEO',
  MediaTypeCaption = 'MEDIA_TYPE_CAPTION',
  MediaTypeCaptionSrt = 'MEDIA_TYPE_CAPTION_SRT',
}

/** Must be used for MathNation LTI 1.1 or 1.3 */
export type MnContentItem = {
  componentId?: InputMaybe<Scalars['String']>;
  componentView: ElementViewEnum;
  identifier?: InputMaybe<Scalars['String']>;
  lessonId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  unitId?: InputMaybe<Scalars['String']>;
};

export type MnContentItemLinkData = {
  __typename?: 'MnContentItemLinkData';
  fields: Scalars['JSON'];
  ltiVersion: Scalars['String'];
  url: Scalars['String'];
};

export type MnCoursework = Node & {
  __typename?: 'mnCoursework';
  _id: Scalars['String'];
  id: Scalars['ID'];
  /** A token used for paginating through results. Pass this token in subsequent requests to retrieve the next page of results. */
  nextToken: Scalars['String'];
  resultSetJson: Scalars['String'];
};

export type MnCourseworkDownloadReport = Node & {
  __typename?: 'mnCourseworkDownloadReport';
  _id: Scalars['String'];
  id: Scalars['ID'];
  signedUrl: Scalars['String'];
};

export type MnCourseworkOrderType = {
  studentName?: InputMaybe<SortEnum>;
};

/** Must be used for MathNation LTI 1.3 */
export type MnEdgexl = {
  assessmentId: Scalars['String'];
  title: Scalars['String'];
};

/** MathNation LTI Input */
export type MnLtiContent = {
  courseworkAssignmentInput?: InputMaybe<Array<MnContentItem>>;
  edgexlAssignmentInput?: InputMaybe<Array<MnEdgexl>>;
};

export type MnOnRamp = Node & {
  __typename?: 'mnOnRamp';
  _id: Scalars['String'];
  id: Scalars['ID'];
  /** A token used for paginating through results. Pass this token in subsequent requests to retrieve the next page of results. */
  nextToken: Scalars['String'];
  resultSetJson: Scalars['String'];
};

export type MnOnRampDownloadReport = Node & {
  __typename?: 'mnOnRampDownloadReport';
  _id: Scalars['String'];
  id: Scalars['ID'];
  signedUrl: Scalars['String'];
};

export type MnOnRampOrderType = {
  studentName?: InputMaybe<SortEnum>;
};

/** Success Object Type */
export type MnPersonalAssignment = {
  __typename?: 'MnPersonalAssignment';
  activityId?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
};

export enum MnPersonalAssignmentType {
  Cyu = 'CYU',
  Or = 'OR',
  Ss = 'SS',
  Ty = 'TY',
}

/** One of the mode either inline or assess */
export enum ModeEnum {
  /** Inline Mode */
  Inline = 'inline',
  /** Assess Mode */
  Assess = 'assess',
}

export type MoveVideoInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  fromMovedInput: FromMoveVideoInput;
  glossaryTerm?: InputMaybe<Scalars['String']>;
  groupType: VideoGroupEnum;
  k5?: InputMaybe<Scalars['Boolean']>;
  mediaType?: InputMaybe<MediaTypeEnum>;
  toMovedInput: ToMoveVideoInput;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  attachQuestionContainer: QuestionContainerPayload;
  controlAssessment: AssessmentPayload;
  controlContainer: AssessmentPayload;
  copyAssessment: AssessmentPayload;
  createAssessment: AssessmentPayload;
  createAssessmentPackageBox: AssessmentPackageBoxPayload;
  createQuestionContainer: QuestionContainerPayload;
  /** @deprecated Deprecated `$items` use `CreateSignRequestInput $input` instead */
  createSignRequest: SignRequestPayload;
  deleteAssessment: AssessmentPayload;
  shareAssessment: AssessmentPayload;
  updateAssessment: AssessmentPayload;
  bulkUpdateContents: Array<Content>;
  createContentGroup?: Maybe<ContentGroupPayload>;
  createKeyword: KeywordPayload;
  createTag: TagPayload;
  createTagValue: TagValuePayload;
  deleteKeyword: KeywordPayload;
  deleteTag: TagPayload;
  deleteTagValue: TagValuePayload;
  updateContent?: Maybe<ContentPayload>;
  updateContentGroup?: Maybe<ContentGroupPayload>;
  updateKeyword: KeywordPayload;
  updateTag: TagPayload;
  updateTagValue: TagValuePayload;
  /** Archive assignments */
  archiveAssignments?: Maybe<Assignments>;
  /**
   * Creating Assignment
   * @deprecated Has been deprecated, use PutAssignment instead
   */
  createAssignment?: Maybe<Array<Assignment>>;
  /** Create Resource */
  createResource?: Maybe<Array<Resource>>;
  /** Get sign to start district assignment */
  daSignRequest?: Maybe<DistrictAssignmentSignRequestType>;
  /** Edit assignment */
  editAssignment?: Maybe<Success>;
  /** Edit stemscopes assignment */
  editStemscopesAssignment?: Maybe<Assignment>;
  /** Make Available District Assignment */
  makeAvailableDistrictAssignment?: Maybe<DistrictClassAssignmentType>;
  /** LTI. When a student opens the link, we must create or find the required activity and assignment */
  openLtiAssignmentLink?: Maybe<AssignmentUnion>;
  /** LTI. When a student opens the link, we must create or find the required activity and assignment */
  openLtiEdgexlAssignmentLink?: Maybe<AssignmentUnion>;
  /** Updating Activities share_results attribute */
  putActivitiesShareResults?: Maybe<Success>;
  /** Sign request */
  putAssignment?: Maybe<Array<AssignmentUnion>>;
  /** Updating District Student Assignments share_results attribute */
  putDistrictAssignmentsShareResults?: Maybe<DistrictStudentAssignmentsType>;
  /** PutDistrictStudentAssignments */
  putDistrictStudentAssignments?: Maybe<DistrictStudentAssignmentsType>;
  /** PutStudentAssignments */
  putStudentAssignments?: Maybe<StemscopesAssignment>;
  /** Reopen Assignment */
  reopenAssignment?: Maybe<Success>;
  /** Reopen District Assignment */
  reopenDistrictAssignment?: Maybe<DistrictStudentAssignmentsType>;
  /** Reset assignment */
  resetAssignment?: Maybe<Success>;
  /** Reset District Assignment */
  resetDistrictAssignment?: Maybe<DistrictStudentAssignmentsType>;
  /** Return assignment */
  returnAssignment?: Maybe<Success>;
  /** Return District Assignment */
  returnDistrictAssignment?: Maybe<DistrictStudentAssignmentsType>;
  /** Save Grading session */
  saveGradingSession?: Maybe<GradingSession>;
  /** Save students resources filter */
  saveStudentsResourcesFilters?: Maybe<StudentsResourcesSettings>;
  /** Sign Request. Add one of fragments: ReportSignedRequest, ItemSignedRequest */
  signRequest?: Maybe<SignedRequestUnion>;
  /** Start MN personal assignment */
  startMnPersonalAssignment?: Maybe<MnPersonalAssignment>;
  /** Reset reset and return statuses */
  startStemscopesAssignment?: Maybe<Success>;
  generateTcc: TccPackages;
  submitContentItemLinks: ContentItemLinkData;
  submitMnContentItemLinksV11: MnContentItemLinkData;
  submitMnContentItemLinksV13: ContentItemLinkData;
  /** A placeholder query used by thecodingmachine/graphqlite when there are no declared mutations. */
  dummyMutation?: Maybe<Scalars['String']>;
  createWidget: Widget;
  updateWidget: Widget;
  updateWidgets: Array<Maybe<Widget>>;
  deleteWidget: Widget;
  createAdoptionAccount: AdoptionAccountPayload;
  createAdoptionEvent: AdoptionEventPayload;
  createAdoptionReviewer: AdoptionReviewerPayload;
  createBulkUpdateAdoptionAccounts: BulkUpdatePayload;
  createPreviewAccount: PreviewAccountPayload;
  createPreviewAccountsCsvPostObject: PreviewAccountsCsvPostObjectPayload;
  createProductGroup: ProductGroupPayload;
  deleteAdoptionAccount: AdoptionAccountPayload;
  deleteAdoptionEvent: AdoptionEventPayload;
  deleteAdoptionReviewer: AdoptionReviewerPayload;
  deletePreviewAccount: PreviewAccountPayload;
  deleteProductGroup: ProductGroupPayload;
  resetPreviewAccountActivityPeriodEnd?: Maybe<Scalars['Void']>;
  updateAdoptionAccount: AdoptionAccountPayload;
  updateAdoptionEvent: AdoptionEventPayload;
  updateAdoptionReviewer: AdoptionReviewerPayload;
  updatePreviewAccount: PreviewAccountPayload;
  updatePreviewAccountTeacher: PreviewAccountTeacherPayload;
  updateProductGroup: ProductGroupPayload;
  addPermission: SuccessPayload;
  createAccessPolicy: AccessPolicyPayload;
  createRole: RolePayload;
  deleteAccessPolicy: AccessPolicyPayload;
  deleteRole: RolePayload;
  removePermission: SuccessPayload;
  updateAccessPolicy: AccessPolicyPayload;
  updateRole: RolePayload;
  convertInlineCss: InlineCssPayload;
  scopePrintDownload: ScopePrintDownloadPayload;
  createImage: ImagePayload;
  createImageCategory: ImageCategoryPayload;
  createImageKeyword: ImageKeywordPayload;
  createImageTag: ImageTagPayload;
  createImageTagValue: ImageTagValuePayload;
  deleteImage: ImagePayload;
  deleteImageCategory: ImageCategoryPayload;
  deleteImageKeyword: ImageKeywordPayload;
  deleteImageTag: ImageTagPayload;
  deleteImageTagValue: ImageTagValuePayload;
  updateImage: ImagePayload;
  updateImageCategory: ImageCategoryPayload;
  updateImageKeyword: ImageKeywordPayload;
  updateImageTag: ImageTagPayload;
  updateImageTagValue: ImageTagValuePayload;
  createIntegration: Integration;
  deleteIntegration: Integration;
  extractOneRosterSchools: Array<OneRosterSchool>;
  forcePullIntegration: Integration;
  forceUploadOneRosterFile: Scalars['String'];
  pullIntegration: Integration;
  syncSchools: Integration;
  updateIntegration: Integration;
  uploadOneRosterFile: Scalars['String'];
  /** Creates a Calendar. */
  createCalendar?: Maybe<CreateCalendarPayload>;
  /** Creates a Entry. */
  createEntry?: Maybe<CreateEntryPayload>;
  /** Creates a PacingGuide. */
  createPacingGuide?: Maybe<CreatePacingGuidePayload>;
  /** Creates a ScopePlan. */
  createScopePlan?: Maybe<CreateScopePlanPayload>;
  /** Deletes a UserCalendar. */
  deleteUserCalendar?: Maybe<DeleteUserCalendarPayload>;
  /** Initiates a UserSelection. */
  initiateUserSelection?: Maybe<InitiateUserSelectionPayload>;
  /** Removes a Calendar. */
  removeCalendar?: Maybe<RemoveCalendarPayload>;
  /** Removes a Entry. */
  removeEntry?: Maybe<RemoveEntryPayload>;
  /** Removes a PacingGuide. */
  removePacingGuide?: Maybe<RemovePacingGuidePayload>;
  /** Removes a ScopePlan. */
  removeScopePlan?: Maybe<RemoveScopePlanPayload>;
  /** Shares a Calendar. */
  shareCalendar?: Maybe<ShareCalendarPayload>;
  /** ShareTemplates a PacingGuide. */
  shareTemplatePacingGuide?: Maybe<ShareTemplatePacingGuidePayload>;
  /** ShareTemplates a ScopePlan. */
  shareTemplateScopePlan?: Maybe<ShareTemplateScopePlanPayload>;
  /** Updates a Calendar. */
  updateCalendar?: Maybe<UpdateCalendarPayload>;
  /** Updates a DistrictSettingsLessonPlanner. */
  updateDistrictSettingsLessonPlanner?: Maybe<UpdateDistrictSettingsLessonPlannerPayload>;
  /** Updates a Entry. */
  updateEntry?: Maybe<UpdateEntryPayload>;
  /** Updates a PacingGuide. */
  updatePacingGuide?: Maybe<UpdatePacingGuidePayload>;
  /** Updates a PacingGuideScope. */
  updatePacingGuideScope?: Maybe<UpdatePacingGuideScopePayload>;
  /** Updates a ScopePlan. */
  updateScopePlan?: Maybe<UpdateScopePlanPayload>;
  /** Updates a ScopePlanScope. */
  updateScopePlanScope?: Maybe<UpdateScopePlanScopePayload>;
  /** Updates a UserCalendar. */
  updateUserCalendar?: Maybe<UpdateUserCalendarPayload>;
  /** Updates a UserSelection. */
  updateUserSelection?: Maybe<UpdateUserSelectionPayload>;
  /** Completes a DistrictSetupStatus. */
  completeDistrictSetupStatus?: Maybe<CompleteDistrictSetupStatusPayload>;
  /** Creates a District. */
  createDistrict?: Maybe<CreateDistrictPayload>;
  /** Creates a School. */
  createSchool?: Maybe<CreateSchoolPayload>;
  /** Deletes a District. */
  deleteDistrict?: Maybe<DeleteDistrictPayload>;
  /** Deletes a School. */
  deleteSchool?: Maybe<DeleteSchoolPayload>;
  /** Updates a District. */
  updateDistrict?: Maybe<UpdateDistrictPayload>;
  /** Updates a School. */
  updateSchool?: Maybe<UpdateSchoolPayload>;
  createPasswordRecovery: Scalars['Boolean'];
  createPasswordRecoveryRequest: Scalars['Boolean'];
  registerAdoptionReviewer: RegisterAdoptionReviewerPayload;
  registerParent?: Maybe<PublicParent>;
  registerPreviewAccountTeacher: PublicPreviewAccountTeacher;
  /** AddStudentTos a Classes. */
  addStudentToClasses?: Maybe<AddStudentToClassesPayload>;
  /** AddStudentsTos a Class. */
  addStudentsToClass?: Maybe<AddStudentsToClassPayload>;
  /** AddStudentsTos a StudentSubgroup. */
  addStudentsToStudentSubgroup?: Maybe<AddStudentsToStudentSubgroupPayload>;
  /** AddTeachersTos a StudentSubgroup. */
  addTeachersToStudentSubgroup?: Maybe<AddTeachersToStudentSubgroupPayload>;
  /** Creates a Class. */
  createClass?: Maybe<CreateClassPayload>;
  /** Deletes a Class. */
  deleteClass?: Maybe<DeleteClassPayload>;
  /** RemoveStudentFroms a Classes. */
  removeStudentFromClasses?: Maybe<RemoveStudentFromClassesPayload>;
  /** RemoveStudentsFroms a Class. */
  removeStudentsFromClass?: Maybe<RemoveStudentsFromClassPayload>;
  /** RemoveStudentsFroms a StudentSubgroup. */
  removeStudentsFromStudentSubgroup?: Maybe<RemoveStudentsFromStudentSubgroupPayload>;
  /** RemoveTeachersFroms a StudentSubgroup. */
  removeTeachersFromStudentSubgroup?: Maybe<RemoveTeachersFromStudentSubgroupPayload>;
  /** Updates a Class. */
  updateClass?: Maybe<UpdateClassPayload>;
  /** Updates a DistrictSettingsRoster. */
  updateDistrictSettingsRoster?: Maybe<UpdateDistrictSettingsRosterPayload>;
  createProduct: ProductPayload;
  createSubscription: SubscriptionPayload;
  createSubscriptionPackage: SubscriptionPackagePayload;
  deleteProduct: ProductPayload;
  deleteSubscription: SubscriptionPayload;
  deleteSubscriptionPackage: SubscriptionPackagePayload;
  updateProduct: ProductPayload;
  updateSubscription: SubscriptionPayload;
  updateSubscriptionPackage: SubscriptionPackagePayload;
  createTeam: TeamPayload;
  updateTeam: TeamPayload;
  createQrcodeTemplate: QrcodeTemplate;
  createPdfAssessment: PdfAssessment;
  /** AddSchoolTos a Student. */
  addSchoolToStudent?: Maybe<AddSchoolToStudentPayload>;
  /** Cancels a Impersonation. */
  cancelImpersonation?: Maybe<CancelImpersonationPayload>;
  /** Creates a Impersonation. */
  createImpersonation?: Maybe<CreateImpersonationPayload>;
  /** Creates a PasswordChange. */
  createPasswordChange?: Maybe<CreatePasswordChangePayload>;
  /** Creates a User. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a UserExportRequest. */
  createUserExportRequest?: Maybe<CreateUserExportRequestPayload>;
  /**
   * Creates a UserRole.
   * @deprecated Use nested mutation
   */
  createUserRole?: Maybe<CreateUserRolePayload>;
  /**
   * Deletes a User.
   * @deprecated For QA use
   */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Invites a Parent. */
  inviteParent?: Maybe<InviteParentPayload>;
  /** ResetPasswordSetTimes a User. */
  resetPasswordSetTimeUser?: Maybe<ResetPasswordSetTimeUserPayload>;
  /** RestrictInvitations a Parent. */
  restrictInvitationParent?: Maybe<RestrictInvitationParentPayload>;
  /** Updates a DistrictSettingsUserManagement. */
  updateDistrictSettingsUserManagement?: Maybe<UpdateDistrictSettingsUserManagementPayload>;
  /** Updates a User. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** UpdateUserActivitys a User. */
  updateUserActivity?: Maybe<UpdateUserActivityPayload>;
  /**
   * Updates a UserRole.
   * @deprecated Use nested mutation
   */
  updateUserRole?: Maybe<UpdateUserRolePayload>;
  /** VerifyEmailss a Parent. */
  verifyEmailsParent?: Maybe<VerifyEmailsParentPayload>;
  addLinkedVideo: VideoPayload;
  addQuestionsToVideo: VideoPayload;
  createReaderLibrary: ReaderLibraryPayload;
  createStemscopesMediaPack: CreateStemscopesMediaPackPayload;
  createStemscopesMediaPostObject: StemscopesMediaPostObjectPayload;
  createStreamingVideoPostObject: VideoPostObjectPayload;
  createTutor: TutorPayload;
  createVideoPostObject: VideoPostObjectPayload;
  deleteReaderLibrary: ReaderLibraryPayload;
  deleteStemscopesMedia: StemscopesMediaPayload;
  deleteStemscopesMediaPack: DeleteStemscopesMediaPackPayload;
  deleteStreamingVideo: StreamingVideoPayload;
  deleteTutor: TutorPayload;
  deleteVideo: VideoPayload;
  moveVideo: VideoPayload;
  updateReaderLibrary: ReaderLibraryPayload;
  updateStemscopesMedia: StemscopesMediaPostObjectPayload;
  updateStemscopesMediaPack: UpdateStemscopesMediaPackPayload;
  updateStreamingVideo: VideoPostObjectPayload;
  updateTutor: TutorPayload;
  districtEnabled: ChatGptDistrictPayload;
  /** get the text and prepare the conversation */
  sendTextScienceBot: ScienceBotPayload;
  deleteNote: NotePayload;
  putNote: NotePayload;
  onrampDeleteTeacherAssignment: DeleteTeacherAssignmentPayload;
  /** Onramp Teacher Assignment */
  onrampStudentAssignmentIdUpdate: StudentAssignmentIdUpdatePayload;
  /** Onramp Teacher Assignment */
  onrampStudentAssignmentResponse: StudentResponsePayload;
  /** Onramp Teacher Assignment Status */
  onrampStudentAssignmentStatus: StudentAssignmentStatusPayload;
  /** Reset start up test */
  resetOnrampStartUp: ResetStudentStartupPayload;
  /** Creates a Post. */
  createPost?: Maybe<CreatePostPayload>;
  /** Creates a WallAgeControl. */
  createWallAgeControl?: Maybe<CreateWallAgeControlPayload>;
  /** Creates a WallPostPin. */
  createWallPostPin?: Maybe<CreateWallPostPinPayload>;
  /** Removes a Post. */
  removePost?: Maybe<RemovePostPayload>;
  /** ToggleVerifySchoolAges a WallAgeControl. */
  toggleVerifySchoolAge?: Maybe<ToggleVerifySchoolAgePayload>;
  /** Toggles a WallPostLike. */
  toggleWallPostLike?: Maybe<ToggleWallPostLikePayload>;
  /** Updates a Post. */
  updatePost?: Maybe<UpdatePostPayload>;
  createAiChat: AiChatPayload;
  updateDistrictSettingsKnowledgeBase: DistrictSettingsKnowledgeBase;
  createEmailTemplate: EmailTemplatePayload;
  updateEmailTemplate: EmailTemplatePayload;
  createLti: Lti;
  createLtiWithPolicies: Lti;
  createSso: Sso;
  createSsoWithPolicies: Sso;
  deleteLti: Lti;
  deleteSso: Sso;
  regenerateLtiV1p1Credentials: Lti;
  regenerateSsoCredentials: Sso;
  updateDistrictSettingsLogin: DistrictSettingsLogin;
  updateLti: Lti;
  updateLtiWithPolicies: Lti;
  updateSso: Sso;
  updateSsoWithPolicies: Sso;
  publishEvent: EventStatusPayload;
};

export type MutationAttachQuestionContainerArgs = {
  input: AttachQuestionContainerInput;
};

export type MutationControlAssessmentArgs = {
  input: ControlAssessmentInput;
};

export type MutationControlContainerArgs = {
  input: ControlContainerInput;
};

export type MutationCopyAssessmentArgs = {
  input: CopyAssessmentInput;
};

export type MutationCreateAssessmentArgs = {
  input: CreateAssessmentInput;
};

export type MutationCreateAssessmentPackageBoxArgs = {
  input: CreateAssessmentPackageBoxInput;
};

export type MutationCreateQuestionContainerArgs = {
  input: CreateQuestionContainerInput;
};

export type MutationCreateSignRequestArgs = {
  items?: InputMaybe<Array<Scalars['String']>>;
  input?: InputMaybe<CreateSignRequestInput>;
};

export type MutationDeleteAssessmentArgs = {
  input: DeleteAssessmentInput;
};

export type MutationShareAssessmentArgs = {
  input: ShareAssessmentInput;
};

export type MutationUpdateAssessmentArgs = {
  input: UpdateAssessmentInput;
};

export type MutationBulkUpdateContentsArgs = {
  input: BulkUpdateContentsInput;
};

export type MutationCreateContentGroupArgs = {
  input: CreateContentGroupInput;
};

export type MutationCreateKeywordArgs = {
  input: CreateKeywordInput;
};

export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

export type MutationCreateTagValueArgs = {
  input: CreateTagValueInput;
};

export type MutationDeleteKeywordArgs = {
  input: DeleteKeywordInput;
};

export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};

export type MutationDeleteTagValueArgs = {
  input: DeleteTagValueInput;
};

export type MutationUpdateContentArgs = {
  input: UpdateContentInput;
};

export type MutationUpdateContentGroupArgs = {
  input: UpdateContentGroupInput;
};

export type MutationUpdateKeywordArgs = {
  input: UpdateKeywordInput;
};

export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type MutationUpdateTagValueArgs = {
  input: UpdateTagValueInput;
};

export type MutationArchiveAssignmentsArgs = {
  action: ArchiveAssignmentAction;
  assignmentIds: Array<Scalars['String']>;
};

export type MutationCreateAssignmentArgs = {
  params: CreateAssignmentInput;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type MutationCreateResourceArgs = {
  params: CreateResourceInput;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type MutationDaSignRequestArgs = {
  params: DistrictAssignmentSignRequestInput;
};

export type MutationEditAssignmentArgs = {
  assignmentParams: EditAssignmentInput;
};

export type MutationEditStemscopesAssignmentArgs = {
  assignmentParams: StemscopesEditInput;
};

export type MutationMakeAvailableDistrictAssignmentArgs = {
  params: MakeAvailableDistrictAssignmentInput;
};

export type MutationOpenLtiAssignmentLinkArgs = {
  assignmentId: Scalars['String'];
  assignmentParams: PutAssignmentInput;
  curriculumArea: Scalars['String'];
  gradeLevel: Scalars['String'];
  ownerId: Scalars['String'];
};

export type MutationOpenLtiEdgexlAssignmentLinkArgs = {
  assignmentParams: PutAssignmentInput;
};

export type MutationPutActivitiesShareResultsArgs = {
  params: PutActivitiesShareResultsInput;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type MutationPutAssignmentArgs = {
  assignmentParams: PutAssignmentInput;
};

export type MutationPutDistrictAssignmentsShareResultsArgs = {
  params: DistrictAssignmentsShareResultsInput;
};

export type MutationPutDistrictStudentAssignmentsArgs = {
  params: PutDistrictStudentAssignmentsInput;
};

export type MutationPutStudentAssignmentsArgs = {
  params: PutStudentAssignmentsInput;
};

export type MutationReopenAssignmentArgs = {
  sessionsParams: ReopenAssignmentInput;
};

export type MutationReopenDistrictAssignmentArgs = {
  params: ReopenDistrictAssignmentInput;
};

export type MutationResetAssignmentArgs = {
  sessionsParams: ResetAssignmentInput;
};

export type MutationResetDistrictAssignmentArgs = {
  params: ManageDistrictAssignmentInput;
};

export type MutationReturnAssignmentArgs = {
  sessionsParams: ReturnAssignmentInput;
};

export type MutationReturnDistrictAssignmentArgs = {
  params: ManageDistrictAssignmentInput;
};

export type MutationSaveGradingSessionArgs = {
  sessionsParams: Array<SaveGradingSessionInput>;
};

export type MutationSaveStudentsResourcesFiltersArgs = {
  studentsFilters: StudentResourcesFilterInput;
};

export type MutationSignRequestArgs = {
  params: SignRequestInput;
};

export type MutationStartMnPersonalAssignmentArgs = {
  assignmentParams: StartMnPersonalAssignmentInput;
  ltiAssignment?: InputMaybe<Scalars['String']>;
};

export type MutationStartStemscopesAssignmentArgs = {
  activityParams: ActivityParamsInput;
};

export type MutationGenerateTccArgs = {
  contentGroupMapping?: InputMaybe<Array<ContentGroupMapping>>;
  contentGroups?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  ltiConsumer?: InputMaybe<TccConsumerEnum>;
  productName: ProductNameEnum;
  schoolIds: Array<Scalars['String']>;
  version: TccVersionEnum;
};

export type MutationSubmitContentItemLinksArgs = {
  contentItems: Array<ContentItem>;
  schoolId: Scalars['String'];
};

export type MutationSubmitMnContentItemLinksV11Args = {
  mnContentItems: Array<MnContentItem>;
  schoolId: Scalars['String'];
};

export type MutationSubmitMnContentItemLinksV13Args = {
  mnLtiContentTypes: MnLtiContent;
  schoolId: Scalars['String'];
};

export type MutationCreateWidgetArgs = {
  input: CreateWidgetInput;
};

export type MutationUpdateWidgetArgs = {
  input: UpdateWidgetInput;
};

export type MutationUpdateWidgetsArgs = {
  input: Array<InputMaybe<UpdateWidgetInput>>;
};

export type MutationDeleteWidgetArgs = {
  input: DeleteWidgetInput;
};

export type MutationCreateAdoptionAccountArgs = {
  input: CreateAdoptionAccountInput;
};

export type MutationCreateAdoptionEventArgs = {
  input: CreateAdoptionEventInput;
};

export type MutationCreateAdoptionReviewerArgs = {
  input: CreateAdoptionReviewerInput;
};

export type MutationCreateBulkUpdateAdoptionAccountsArgs = {
  input: BulkUpdateAdoptionAccountsInput;
  selection: BulkSelectionInput;
  filter: AdoptionAccountFilterInput;
};

export type MutationCreatePreviewAccountArgs = {
  input: CreatePreviewAccountInput;
};

export type MutationCreatePreviewAccountsCsvPostObjectArgs = {
  input: CreatePreviewAccountsCsvPostObjectInput;
};

export type MutationCreateProductGroupArgs = {
  input: CreateProductGroupInput;
};

export type MutationDeleteAdoptionAccountArgs = {
  input: DeleteAdoptionAccountInput;
};

export type MutationDeleteAdoptionEventArgs = {
  input: DeleteAdoptionEventInput;
};

export type MutationDeleteAdoptionReviewerArgs = {
  input: DeleteAdoptionReviewerInput;
};

export type MutationDeletePreviewAccountArgs = {
  input: DeletePreviewAccountInput;
};

export type MutationDeleteProductGroupArgs = {
  input: DeleteProductGroupInput;
};

export type MutationResetPreviewAccountActivityPeriodEndArgs = {
  input: ResetPreviewAccountActivityPeriodEndInput;
};

export type MutationUpdateAdoptionAccountArgs = {
  input: UpdateAdoptionAccountInput;
};

export type MutationUpdateAdoptionEventArgs = {
  input: UpdateAdoptionEventInput;
};

export type MutationUpdateAdoptionReviewerArgs = {
  input: UpdateAdoptionReviewerInput;
};

export type MutationUpdatePreviewAccountArgs = {
  input: UpdatePreviewAccountInput;
};

export type MutationUpdatePreviewAccountTeacherArgs = {
  input: UpdatePreviewAccountTeacherInput;
};

export type MutationUpdateProductGroupArgs = {
  input: UpdateProductGroupInput;
};

export type MutationAddPermissionArgs = {
  input: AddPermissionInput;
};

export type MutationCreateAccessPolicyArgs = {
  input: CreateAccessPolicyInput;
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationDeleteAccessPolicyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};

export type MutationRemovePermissionArgs = {
  input: RemovePermissionInput;
};

export type MutationUpdateAccessPolicyArgs = {
  input: UpdateAccessPolicyInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationConvertInlineCssArgs = {
  input: InlineCssInput;
};

export type MutationScopePrintDownloadArgs = {
  input: ScopePrintDownloadInput;
};

export type MutationCreateImageArgs = {
  input: CreateImageInput;
};

export type MutationCreateImageCategoryArgs = {
  input: CreateImageCategoryInput;
};

export type MutationCreateImageKeywordArgs = {
  input: CreateImageKeywordInput;
};

export type MutationCreateImageTagArgs = {
  input: CreateImageTagInput;
};

export type MutationCreateImageTagValueArgs = {
  input: CreateImageTagValueInput;
};

export type MutationDeleteImageArgs = {
  input: DeleteImageInput;
};

export type MutationDeleteImageCategoryArgs = {
  input: DeleteImageCategoryInput;
};

export type MutationDeleteImageKeywordArgs = {
  input: DeleteImageKeywordInput;
};

export type MutationDeleteImageTagArgs = {
  input: DeleteImageTagInput;
};

export type MutationDeleteImageTagValueArgs = {
  input: DeleteImageTagValueInput;
};

export type MutationUpdateImageArgs = {
  input: UpdateImageInput;
};

export type MutationUpdateImageCategoryArgs = {
  input: UpdateImageCategoryInput;
};

export type MutationUpdateImageKeywordArgs = {
  input: UpdateImageKeywordInput;
};

export type MutationUpdateImageTagArgs = {
  input: UpdateImageTagInput;
};

export type MutationUpdateImageTagValueArgs = {
  input: UpdateImageTagValueInput;
};

export type MutationCreateIntegrationArgs = {
  integration: IntegrationInput;
};

export type MutationDeleteIntegrationArgs = {
  integrationInput: IntegrationKeyInput;
};

export type MutationExtractOneRosterSchoolsArgs = {
  schoolsInput: ExtractOneRosterSchoolsInput;
};

export type MutationForcePullIntegrationArgs = {
  integrationInput: IntegrationKeyInput;
};

export type MutationForceUploadOneRosterFileArgs = {
  districtId: Scalars['String'];
  file: Scalars['Upload'];
};

export type MutationPullIntegrationArgs = {
  integrationInput: IntegrationKeyInput;
};

export type MutationSyncSchoolsArgs = {
  integrationInput: IntegrationKeyInput;
};

export type MutationUpdateIntegrationArgs = {
  integration: IntegrationInput;
};

export type MutationUploadOneRosterFileArgs = {
  districtId: Scalars['String'];
  file: Scalars['Upload'];
};

export type MutationCreateCalendarArgs = {
  input: CreateCalendarInput;
};

export type MutationCreateEntryArgs = {
  input: CreateEntryInput;
};

export type MutationCreatePacingGuideArgs = {
  input: CreatePacingGuideInput;
};

export type MutationCreateScopePlanArgs = {
  input: CreateScopePlanInput;
};

export type MutationDeleteUserCalendarArgs = {
  input: DeleteUserCalendarInput;
};

export type MutationInitiateUserSelectionArgs = {
  input: InitiateUserSelectionInput;
};

export type MutationRemoveCalendarArgs = {
  input: RemoveCalendarInput;
};

export type MutationRemoveEntryArgs = {
  input: RemoveEntryInput;
};

export type MutationRemovePacingGuideArgs = {
  input: RemovePacingGuideInput;
};

export type MutationRemoveScopePlanArgs = {
  input: RemoveScopePlanInput;
};

export type MutationShareCalendarArgs = {
  input: ShareCalendarInput;
};

export type MutationShareTemplatePacingGuideArgs = {
  input: ShareTemplatePacingGuideInput;
};

export type MutationShareTemplateScopePlanArgs = {
  input: ShareTemplateScopePlanInput;
};

export type MutationUpdateCalendarArgs = {
  input: UpdateCalendarInput;
};

export type MutationUpdateDistrictSettingsLessonPlannerArgs = {
  input: UpdateDistrictSettingsLessonPlannerInput;
};

export type MutationUpdateEntryArgs = {
  input: UpdateEntryInput;
};

export type MutationUpdatePacingGuideArgs = {
  input: UpdatePacingGuideInput;
};

export type MutationUpdatePacingGuideScopeArgs = {
  input: UpdatePacingGuideScopeInput;
};

export type MutationUpdateScopePlanArgs = {
  input: UpdateScopePlanInput;
};

export type MutationUpdateScopePlanScopeArgs = {
  input: UpdateScopePlanScopeInput;
};

export type MutationUpdateUserCalendarArgs = {
  input: UpdateUserCalendarInput;
};

export type MutationUpdateUserSelectionArgs = {
  input: UpdateUserSelectionInput;
};

export type MutationCompleteDistrictSetupStatusArgs = {
  input: CompleteDistrictSetupStatusInput;
};

export type MutationCreateDistrictArgs = {
  input: CreateDistrictInput;
};

export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput;
};

export type MutationDeleteDistrictArgs = {
  input: DeleteDistrictInput;
};

export type MutationDeleteSchoolArgs = {
  input: DeleteSchoolInput;
};

export type MutationUpdateDistrictArgs = {
  input: UpdateDistrictInput;
};

export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput;
};

export type MutationCreatePasswordRecoveryArgs = {
  input: PasswordRecoveryInput;
};

export type MutationCreatePasswordRecoveryRequestArgs = {
  input: PasswordRecoveryRequestInput;
};

export type MutationRegisterAdoptionReviewerArgs = {
  input: RegisterAdoptionReviewerInput;
};

export type MutationRegisterParentArgs = {
  input: RegisterParentInput;
};

export type MutationRegisterPreviewAccountTeacherArgs = {
  input: RegisterPreviewAccountTeacherInput;
};

export type MutationAddStudentToClassesArgs = {
  input: AddStudentToClassesInput;
};

export type MutationAddStudentsToClassArgs = {
  input: AddStudentsToClassInput;
};

export type MutationAddStudentsToStudentSubgroupArgs = {
  input: AddStudentsToStudentSubgroupInput;
};

export type MutationAddTeachersToStudentSubgroupArgs = {
  input: AddTeachersToStudentSubgroupInput;
};

export type MutationCreateClassArgs = {
  input: CreateClassInput;
};

export type MutationDeleteClassArgs = {
  input: DeleteClassInput;
};

export type MutationRemoveStudentFromClassesArgs = {
  input: RemoveStudentFromClassesInput;
};

export type MutationRemoveStudentsFromClassArgs = {
  input: RemoveStudentsFromClassInput;
};

export type MutationRemoveStudentsFromStudentSubgroupArgs = {
  input: RemoveStudentsFromStudentSubgroupInput;
};

export type MutationRemoveTeachersFromStudentSubgroupArgs = {
  input: RemoveTeachersFromStudentSubgroupInput;
};

export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};

export type MutationUpdateDistrictSettingsRosterArgs = {
  input: UpdateDistrictSettingsRosterInput;
};

export type MutationCreateProductArgs = {
  createProductInput: CreateProductInput;
};

export type MutationCreateSubscriptionArgs = {
  createSubscriptionInput: CreateSubscriptionInput;
};

export type MutationCreateSubscriptionPackageArgs = {
  createSubscriptionPackageInput: CreateSubscriptionPackageInput;
};

export type MutationDeleteProductArgs = {
  deleteProductInput: DeleteProductInput;
};

export type MutationDeleteSubscriptionArgs = {
  deleteSubscriptionInput: DeleteSubscriptionInput;
};

export type MutationDeleteSubscriptionPackageArgs = {
  deleteSubscriptionPackageInput: DeleteSubscriptionPackageInput;
};

export type MutationUpdateProductArgs = {
  updateProductInput: UpdateProductInput;
};

export type MutationUpdateSubscriptionArgs = {
  updateSubscriptionInput: UpdateSubscriptionInput;
};

export type MutationUpdateSubscriptionPackageArgs = {
  updateSubscriptionPackageInput: UpdateSubscriptionPackageInput;
};

export type MutationCreateTeamArgs = {
  createTeamInput: CreateTeamInput;
};

export type MutationUpdateTeamArgs = {
  updateTeamInput: UpdateTeamInput;
};

export type MutationCreateQrcodeTemplateArgs = {
  input: CreateQrcodeTemplateInput;
};

export type MutationCreatePdfAssessmentArgs = {
  input: CreatePdfAssessmentInput;
};

export type MutationAddSchoolToStudentArgs = {
  input: AddSchoolToStudentInput;
};

export type MutationCancelImpersonationArgs = {
  input: CancelImpersonationInput;
};

export type MutationCreateImpersonationArgs = {
  input: CreateImpersonationInput;
};

export type MutationCreatePasswordChangeArgs = {
  input: CreatePasswordChangeInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserExportRequestArgs = {
  input: CreateUserExportRequestInput;
};

export type MutationCreateUserRoleArgs = {
  input: CreateUserRoleInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationInviteParentArgs = {
  input: InviteParentInput;
};

export type MutationResetPasswordSetTimeUserArgs = {
  input: ResetPasswordSetTimeUserInput;
};

export type MutationRestrictInvitationParentArgs = {
  input: RestrictInvitationParentInput;
};

export type MutationUpdateDistrictSettingsUserManagementArgs = {
  input: UpdateDistrictSettingsUserManagementInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserActivityArgs = {
  input: UpdateUserActivityInput;
};

export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};

export type MutationVerifyEmailsParentArgs = {
  input: VerifyEmailsParentInput;
};

export type MutationAddLinkedVideoArgs = {
  input: AddLinkedVideoInput;
};

export type MutationAddQuestionsToVideoArgs = {
  input: AddQuestionsToVideoInput;
};

export type MutationCreateReaderLibraryArgs = {
  input: CreateReaderLibraryInput;
};

export type MutationCreateStemscopesMediaPackArgs = {
  input: CreateStemscopesMediaPackInput;
};

export type MutationCreateStemscopesMediaPostObjectArgs = {
  input: CreateStemscopesMediaPostObjectInput;
};

export type MutationCreateStreamingVideoPostObjectArgs = {
  input: CreateStreamingVideoPostObjectInput;
};

export type MutationCreateTutorArgs = {
  input: CreateTutorInput;
};

export type MutationCreateVideoPostObjectArgs = {
  input: CreateVideoPostObjectInput;
};

export type MutationDeleteReaderLibraryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteStemscopesMediaArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteStemscopesMediaPackArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteStreamingVideoArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTutorArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVideoArgs = {
  input: DeleteVideoInput;
};

export type MutationMoveVideoArgs = {
  input: MoveVideoInput;
};

export type MutationUpdateReaderLibraryArgs = {
  input: UpdateReaderLibraryInput;
};

export type MutationUpdateStemscopesMediaArgs = {
  input: UpdateStemscopesMediaInput;
};

export type MutationUpdateStemscopesMediaPackArgs = {
  input: UpdateStemscopesMediaPackInput;
};

export type MutationUpdateStreamingVideoArgs = {
  input: UpdateStreamingVideoInput;
};

export type MutationUpdateTutorArgs = {
  input: UpdateTutorInput;
};

export type MutationDistrictEnabledArgs = {
  districtId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSendTextScienceBotArgs = {
  conversationID?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
};

export type MutationPutNoteArgs = {
  id: Scalars['ID'];
  type: NoteInputTypeEnum;
  data: Scalars['String'];
};

export type MutationOnrampDeleteTeacherAssignmentArgs = {
  input: DeleteTeacherAssignmentInput;
};

export type MutationOnrampStudentAssignmentIdUpdateArgs = {
  input: OnrampStudentAssignmentIdUpdateInput;
};

export type MutationOnrampStudentAssignmentResponseArgs = {
  input: OnrampStudentAssignmentResponseInput;
};

export type MutationOnrampStudentAssignmentStatusArgs = {
  input: OnrampAssignmentStatusInput;
};

export type MutationResetOnrampStartUpArgs = {
  input: ResetOnrampStartupTestInput;
};

export type MutationCreatePostArgs = {
  input: CreatePostInput;
};

export type MutationCreateWallAgeControlArgs = {
  input: CreateWallAgeControlInput;
};

export type MutationCreateWallPostPinArgs = {
  input: CreateWallPostPinInput;
};

export type MutationRemovePostArgs = {
  input: RemovePostInput;
};

export type MutationToggleVerifySchoolAgeArgs = {
  input: ToggleVerifySchoolAgeInput;
};

export type MutationToggleWallPostLikeArgs = {
  input: ToggleWallPostLikeInput;
};

export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};

export type MutationCreateAiChatArgs = {
  input: AiChatInput;
};

export type MutationUpdateDistrictSettingsKnowledgeBaseArgs = {
  input: DistrictSettingsInput;
};

export type MutationCreateEmailTemplateArgs = {
  input: CreateEmailTemplateInput;
};

export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};

export type MutationCreateLtiArgs = {
  ltiInput: LtiInput;
};

export type MutationCreateLtiWithPoliciesArgs = {
  ltiInput: LtiWithPoliciesInput;
};

export type MutationCreateSsoArgs = {
  ssoInput: SsoInput;
};

export type MutationCreateSsoWithPoliciesArgs = {
  ssoInput: SsoWithPoliciesInput;
};

export type MutationDeleteLtiArgs = {
  ltiKeyInput: LtiKeyInput;
};

export type MutationDeleteSsoArgs = {
  ssoKeyInput: SsoKeyInput;
};

export type MutationRegenerateLtiV1p1CredentialsArgs = {
  ltiKeyInput: LtiKeyInput;
};

export type MutationRegenerateSsoCredentialsArgs = {
  ssoKeyInput: SsoKeyInput;
};

export type MutationUpdateDistrictSettingsLoginArgs = {
  districtSettingsInput: DistrictSettingsLoginInput;
};

export type MutationUpdateLtiArgs = {
  ltiInput: LtiInput;
};

export type MutationUpdateLtiWithPoliciesArgs = {
  ltiInput: LtiWithPoliciesInput;
};

export type MutationUpdateSsoArgs = {
  ssoInput: SsoInput;
};

export type MutationUpdateSsoWithPoliciesArgs = {
  ssoInput: SsoWithPoliciesInput;
};

export type MutationPublishEventArgs = {
  input: EventInput;
};

export type NewDistrict = {
  __typename?: 'NewDistrict';
  city?: Maybe<Scalars['String']>;
  globalName: Scalars['String'];
  id: Scalars['String'];
  localName: Scalars['String'];
  ssoIntegrations: Array<SsoTypeEnum>;
  state: Scalars['String'];
  urlCode?: Maybe<Scalars['String']>;
};

export type NewDistrictPageConnection = {
  __typename?: 'NewDistrictPageConnection';
  collection: Array<NewDistrict>;
  paginationInfo?: Maybe<NewDistrictPaginationInfo>;
};

export type NewDistrictPaginationInfo = {
  __typename?: 'NewDistrictPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A node, according to the Relay specification. */
export type Node = {
  /** The id of this node. */
  id: Scalars['ID'];
};

export enum NoteInputTypeEnum {
  FreeResponse = 'FREE_RESPONSE',
  Goboard = 'GOBOARD',
  Checkbox = 'CHECKBOX',
  Radio = 'RADIO',
  Input = 'INPUT',
  Overlay = 'OVERLAY',
  ShortAnswer = 'SHORT_ANSWER',
  DesmosCalculator = 'DESMOS_CALCULATOR',
  DesmosUrl = 'DESMOS_URL',
}

export type NoteItemPayload = {
  __typename?: 'NoteItemPayload';
  data: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
};

export type NotePayload = {
  __typename?: 'NotePayload';
  item?: Maybe<NoteItemPayload>;
};

export type OneRosterSchool = {
  __typename?: 'OneRosterSchool';
  externalId: Scalars['String'];
  name: Scalars['String'];
  status: OneRosterSchoolStatusEnum;
  type?: Maybe<OneRosterSchoolTypeEnum>;
};

export enum OneRosterSchoolStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Tobedeleted = 'TOBEDELETED',
}

export enum OneRosterSchoolTypeEnum {
  District = 'DISTRICT',
  School = 'SCHOOL',
}

export enum OneRosterSisStaffIdFieldEnum {
  Identifier = 'IDENTIFIER',
  SourcedId = 'SOURCED_ID',
}

export type OnrampAssignmentStatusInput = {
  id?: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampQuestionContainerInput = {
  category: Scalars['String'];
  domain: Scalars['String'];
  grade: Scalars['String'];
  standard: Scalars['String'];
  topic: Scalars['String'];
};

/** OnRampReport type: Pathway / Startup / TeacherAssigned */
export enum OnRampReportTypeEnum {
  /** Pathway Type */
  Pathway = 'Pathway',
  /** Startup Type */
  Startup = 'Startup',
  /** TeacherAssigned Type */
  TeacherAssigned = 'TeacherAssigned',
}

export type OnrampStartupTestInput = {
  studentGrade?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampStatusPayload = {
  __typename?: 'OnrampStatusPayload';
  /** // More specific type hint for clarity */
  onrampStatus: Array<Array<Scalars['String']>>;
  assignmentIds: Array<Array<Scalars['String']>>;
};

export type OnrampStudentAssignmentIdUpdateInput = {
  id?: Scalars['Int'];
  learnosityAssignmentId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampStudentAssignmentInput = {
  typeInput?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampStudentAssignmentResponseInput = {
  id?: Scalars['Int'];
  learnosityAssignmentId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type OnrampStudentStatusInput = {
  studentIds: Array<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  districtId?: Maybe<Scalars['String']>;
  district: District;
  schools: Array<School>;
  subscriptionScope: SubscriptionScopeEnum;
  lineItems: Array<OrderLineItem>;
  active: Scalars['Boolean'];
  systemAdministratorEmail?: Maybe<Scalars['String']>;
  systemAdministratorName?: Maybe<Scalars['String']>;
};

export enum OrderByEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderByInput = {
  field: Scalars['String'];
  order: OrderByEnum;
};

export type OrderFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  id: Scalars['ID'];
  productId: Scalars['String'];
  product?: Maybe<Product>;
  quantity: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type OrderPageConnection = {
  __typename?: 'OrderPageConnection';
  collection: Array<Order>;
  paginationInfo?: Maybe<OrderPaginationInfo>;
};

export type OrderPaginationInfo = {
  __typename?: 'OrderPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** Attributes for creating stemscopes other assignment */
export type OtherAssignmentInput = {
  otherAssignment?: InputMaybe<Scalars['Boolean']>;
  otherAssignmentScope?: InputMaybe<Scalars['String']>;
};

/** Owner Assignments Object Type */
export type OwnerAssignments = {
  __typename?: 'OwnerAssignments';
  assignments: Array<Assignment>;
  pageInfo?: Maybe<PageInfo>;
  studentAssignments: Array<StudentAssignment>;
};

export type PacingGuide = Node & {
  __typename?: 'PacingGuide';
  _id: Scalars['String'];
  assignedTo?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  curriculumArea?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  grade?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSelected?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopeEditable?: Maybe<Scalars['Boolean']>;
  scopes?: Maybe<PacingGuideScopePageConnection>;
  searchName?: Maybe<Scalars['String']>;
  selectedCalendar?: Maybe<Scalars['Iterable']>;
  sharedBy?: Maybe<Scalars['String']>;
  sharedData?: Maybe<Scalars['Iterable']>;
  sharedOption?: Maybe<Scalars['String']>;
  sharedType?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type PacingGuideScopesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<PacingGuideScopeFilter>>>;
};

export type PacingGuideFilter = {
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  pacingGuideType?: InputMaybe<PacingGuideTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PacingGuideFilter_Order = {
  name?: InputMaybe<SortEnum>;
};

/** Page connection for PacingGuide. */
export type PacingGuidePageConnection = {
  __typename?: 'PacingGuidePageConnection';
  collection?: Maybe<Array<Maybe<PacingGuide>>>;
  paginationInfo: PacingGuidePaginationInfo;
};

/** Information about the pagination. */
export type PacingGuidePaginationInfo = {
  __typename?: 'PacingGuidePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PacingGuideScope = Node & {
  __typename?: 'PacingGuideScope';
  _id: Scalars['String'];
  calendarId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionalDays?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopeId?: Maybe<Scalars['String']>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  type: EntryTypeEnum;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type PacingGuideScopeFilter = {
  pacingGuideId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scopeId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Page connection for PacingGuideScope. */
export type PacingGuideScopePageConnection = {
  __typename?: 'PacingGuideScopePageConnection';
  collection?: Maybe<Array<Maybe<PacingGuideScope>>>;
  paginationInfo: PacingGuideScopePaginationInfo;
};

/** Information about the pagination. */
export type PacingGuideScopePaginationInfo = {
  __typename?: 'PacingGuideScopePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Pacing Guide type: Individual / Team / Template */
export enum PacingGuideTypeEnum {
  /** Individual Type */
  Individual = 'Individual',
  /** Team Type */
  Team = 'Team',
  /** Template Type */
  Template = 'Template',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginationInfoPayload = {
  __typename?: 'PaginationInfoPayload';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Parent = Node & {
  __typename?: 'Parent';
  _id?: Maybe<Scalars['String']>;
  districtUuid: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  inviteCount: Scalars['Int'];
  inviteStatus: ParentInviteStatusEnum;
  invitedAt: Scalars['String'];
  parent?: Maybe<User>;
  student: User;
};

export enum ParentInviteStatusEnum {
  InviteSent = 'INVITE_SENT',
  Active = 'ACTIVE',
}

export type ParentStudent = Node & {
  __typename?: 'ParentStudent';
  /** @deprecated Used from the student property */
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Used from the student property */
  lastName?: Maybe<Scalars['String']>;
  student: Student;
  /** @deprecated Used from the student property */
  studentId: Scalars['String'];
};

export enum PasswordRecoveryApplication {
  MathNation = 'MathNation',
  StemScopes = 'StemScopes',
}

export type PasswordRecoveryInput = {
  districtActivation?: Scalars['Boolean'];
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordRecoveryRequestInput = {
  application?: PasswordRecoveryApplication;
  email: Scalars['String'];
};

export type PasswordRecoveryToken = {
  __typename?: 'PasswordRecoveryToken';
  expired: Scalars['Boolean'];
};

export type Pdf = {
  __typename?: 'Pdf';
  url: Scalars['String'];
  groupIdentifier: Scalars['String'];
  id: Scalars['String'];
};

export type PdfAssessment = {
  __typename?: 'PdfAssessment';
  /** base64 representation of pdf or string representation of html */
  content: Scalars['String'];
};

export enum PermissionScope {
  Platform = 'Platform',
  District = 'District',
  School = 'School',
  Klass = 'Klass',
  User = 'User',
  Owner = 'Owner',
}

export type PlannerTeam = Node & {
  __typename?: 'PlannerTeam';
  _id: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Page connection for PlannerTeam. */
export type PlannerTeamPageConnection = {
  __typename?: 'PlannerTeamPageConnection';
  collection?: Maybe<Array<Maybe<PlannerTeam>>>;
  paginationInfo: PlannerTeamPaginationInfo;
};

/** Information about the pagination. */
export type PlannerTeamPaginationInfo = {
  __typename?: 'PlannerTeamPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PlatformAdminActivityPoolPayload = {
  __typename?: 'PlatformAdminActivityPoolPayload';
  items: Array<PlatformAdminQuestionItemPayload>;
  tags: Array<TagPayload>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type PlatformAdminAssessmentItemPayload = {
  __typename?: 'PlatformAdminAssessmentItemPayload';
  status: Scalars['String'];
  tags: Array<TagPayload>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserPayload>;
  items: Array<Scalars['String']>;
};

export type PlatformAdminAssessmentPoolInput = {
  items: Array<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  next?: InputMaybe<Scalars['String']>;
};

export type PlatformAdminAssessmentPoolPayload = {
  __typename?: 'PlatformAdminAssessmentPoolPayload';
  list: Array<PlatformAdminAssessmentItemPayload>;
  next: Scalars['String'];
};

export type PlatformAdminAttachQuestionContainerInput = {
  id: Scalars['ID'];
  cyuQuestionContainerInput?: InputMaybe<CyuQuestionContainerInput>;
  edgexlQuestionContainerInput?: InputMaybe<EdgexlQuestionContainerInput>;
  onrampQuestionContainerInput?: InputMaybe<OnrampQuestionContainerInput>;
  ssQuestionContainerInput?: InputMaybe<SsQuestionContainerInput>;
  tyQuestionContainerInput?: InputMaybe<TyQuestionContainerInput>;
};

export type PlatformAdminControlAssessmentInput = {
  ids: Array<Scalars['String']>;
  schoolId: Scalars['String'];
  action: ControlActionEnum;
};

export type PlatformAdminControlContainerInput = {
  action: ControlActionEnum;
};

export type PlatformAdminCreateQuestionContainerInput = {
  cyuQuestionContainerInput?: InputMaybe<CyuQuestionContainerInput>;
  edgexlQuestionContainerInput?: InputMaybe<EdgexlQuestionContainerInput>;
  onrampQuestionContainerInput?: InputMaybe<OnrampQuestionContainerInput>;
  ssQuestionContainerInput?: InputMaybe<SsQuestionContainerInput>;
  tyQuestionContainerInput?: InputMaybe<TyQuestionContainerInput>;
};

export type PlatformAdminQuestionInput = {
  signedRequest?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type PlatformAdminQuestionItemPayload = {
  __typename?: 'PlatformAdminQuestionItemPayload';
  questionId: Scalars['String'];
  questionType?: Maybe<Scalars['String']>;
  tags: Array<TagPayload>;
  id: Scalars['String'];
};

export type PlatformAdminQuestionPayload = {
  __typename?: 'PlatformAdminQuestionPayload';
  item?: Maybe<PlatformAdminQuestionItemPayload>;
  signedRequest: Scalars['String'];
};

export type PlatformAdminQuestionPoolInput = {
  domains?: InputMaybe<Array<Scalars['String']>>;
  lesson?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductEnum>;
  purpose?: InputMaybe<PurposeEnum>;
  topics?: InputMaybe<Array<Scalars['String']>>;
  category?: InputMaybe<EdgexlCategoryEnum>;
  grade?: InputMaybe<EdgexlGradeEnum>;
  standards?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Array<Scalars['String']>>;
  next?: InputMaybe<Scalars['String']>;
};

export type PlatformAdminQuestionPoolPayload = {
  __typename?: 'PlatformAdminQuestionPoolPayload';
  list: Array<PlatformAdminActivityPoolPayload>;
  next: Scalars['String'];
};

export type PlatformAdminQuestionsInput = {
  standards?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  next?: InputMaybe<Scalars['String']>;
};

export type PlatformAdminQuestionsPayload = {
  __typename?: 'PlatformAdminQuestionsPayload';
  list: Array<PlatformAdminQuestionItemPayload>;
  next: Scalars['String'];
};

export type PlatformAdminUpdateAssessmentInput = {
  isRemove?: InputMaybe<Scalars['Boolean']>;
  items: Array<Scalars['String']>;
  id: Scalars['ID'];
};

export type PlatformAdminUpdateAssessmentPayload = {
  __typename?: 'PlatformAdminUpdateAssessmentPayload';
  item?: Maybe<PlatformAdminAssessmentItemPayload>;
};

export type PolicyInput = {
  internalFields: Array<InternalFieldInput>;
  externalField?: InputMaybe<ExternalFieldEnum>;
  name: PolicyNameEnum;
  sequence: Scalars['Int'];
  userType: SsoUserTypeEnum;
};

export enum PolicyNameEnum {
  Configured = 'CONFIGURED',
  Email = 'EMAIL',
  Name = 'NAME',
  LmsId = 'LMS_ID',
  Username = 'USERNAME',
}

export type PoolEnumsPayload = {
  __typename?: 'PoolEnumsPayload';
  curriculumAreas: Array<Scalars['String']>;
  editions: Array<Scalars['String']>;
  grades: Array<Scalars['String']>;
  keywords: Array<Scalars['String']>;
  questionTypes: Array<Scalars['String']>;
  scopes: Array<Scalars['String']>;
  standards: Array<Scalars['String']>;
};

export type PoolQuestionTotalPayload = {
  __typename?: 'PoolQuestionTotalPayload';
  total: Scalars['Int'];
};

export type Post = Node & {
  __typename?: 'Post';
  _id: Scalars['Int'];
  comment: Scalars['String'];
  conversationId?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  districtId: Scalars['String'];
  documentKey?: Maybe<Scalars['String']>;
  documentPath?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isAnswered: Scalars['Boolean'];
  isBlocked: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isRobotAttempt: Scalars['Boolean'];
  isRobotFailed: Scalars['Boolean'];
  isStared: Scalars['Boolean'];
  isSticky: Scalars['Boolean'];
  isWelcomePost: Scalars['Boolean'];
  likedByCaller: Scalars['Boolean'];
  likes: Scalars['Iterable'];
  modified: Scalars['String'];
  parent?: Maybe<Post>;
  replies: Scalars['Iterable'];
  robotParent?: Maybe<Post>;
  robotReply?: Maybe<Post>;
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
  sessionId: Scalars['String'];
  stateCode: Scalars['String'];
  user: Scalars['Iterable'];
  userId: Scalars['String'];
  wallId: Scalars['String'];
  wallPostLikes?: Maybe<Array<Maybe<WallPostLike>>>;
  wallPostPin?: Maybe<WallPostPin>;
  wallPostReplies?: Maybe<PostPageConnection>;
};

export type PostWallPostRepliesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

/** Page connection for Post. */
export type PostPageConnection = {
  __typename?: 'PostPageConnection';
  collection?: Maybe<Array<Maybe<Post>>>;
  paginationInfo: PostPaginationInfo;
};

/** Information about the pagination. */
export type PostPaginationInfo = {
  __typename?: 'PostPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PreviewAccount = {
  __typename?: 'PreviewAccount';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  activityPeriodEnd: Scalars['DateTime'];
  activityPeriodStart: Scalars['DateTime'];
  district: District;
  firstLoginDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
  registrationToken: Scalars['String'];
  school: School;
  state: StateEnum;
  subscriptionPackage: SubscriptionPackage;
  teachers: Array<PreviewAccountTeacher>;
};

export type PreviewAccountFilterInput = {
  activityPeriodStartFrom?: InputMaybe<Scalars['DateTime']>;
  activityPeriodStartTo?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
};

export type PreviewAccountImportLog = {
  __typename?: 'PreviewAccountImportLog';
  id: Scalars['ID'];
  errors: Array<PreviewAccountImportLogError>;
  originalFilename: Scalars['String'];
  importEnd?: Maybe<Scalars['DateTime']>;
  importStart: Scalars['DateTime'];
  importSucceeded: Scalars['Boolean'];
  message: Scalars['String'];
  type: PreviewAccountImportLogTypeEnum;
};

export type PreviewAccountImportLogError = {
  __typename?: 'PreviewAccountImportLogError';
  id: Scalars['ID'];
  field: Scalars['String'];
  importLogId: Scalars['String'];
  value: Scalars['String'];
};

export type PreviewAccountImportLogPageConnection = {
  __typename?: 'PreviewAccountImportLogPageConnection';
  collection: Array<PreviewAccountImportLog>;
  paginationInfo?: Maybe<PreviewAccountImportLogPaginationInfo>;
};

export type PreviewAccountImportLogPaginationInfo = {
  __typename?: 'PreviewAccountImportLogPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum PreviewAccountImportLogTypeEnum {
  Csv = 'csv',
}

export type PreviewAccountOrderInput = {
  active?: InputMaybe<SortEnum>;
  activityPeriodEnd?: InputMaybe<SortEnum>;
  activityPeriodStart?: InputMaybe<SortEnum>;
  firstLoginDate?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  state?: InputMaybe<SortEnum>;
};

export type PreviewAccountPageConnection = {
  __typename?: 'PreviewAccountPageConnection';
  collection: Array<PreviewAccount>;
  paginationInfo?: Maybe<PreviewAccountPaginationInfo>;
};

export type PreviewAccountPaginationInfo = {
  __typename?: 'PreviewAccountPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PreviewAccountPayload = {
  __typename?: 'PreviewAccountPayload';
  previewAccount: PreviewAccount;
};

export type PreviewAccountsCsvPostObjectPayload = {
  __typename?: 'PreviewAccountsCsvPostObjectPayload';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
};

export type PreviewAccountTeacher = {
  __typename?: 'PreviewAccountTeacher';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  previewAccount: PreviewAccount;
  user: User;
};

export type PreviewAccountTeacherFilterInput = {
  previewAccountId?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type PreviewAccountTeacherOrderInput = {
  email?: InputMaybe<SortEnum>;
  firstName?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  lastName?: InputMaybe<SortEnum>;
  username?: InputMaybe<SortEnum>;
};

export type PreviewAccountTeacherPageConnection = {
  __typename?: 'PreviewAccountTeacherPageConnection';
  collection: Array<PreviewAccountTeacher>;
  paginationInfo?: Maybe<PreviewAccountTeacherPaginationInfo>;
};

export type PreviewAccountTeacherPaginationInfo = {
  __typename?: 'PreviewAccountTeacherPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PreviewAccountTeacherPayload = {
  __typename?: 'PreviewAccountTeacherPayload';
  previewAccountTeacher: PreviewAccountTeacher;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  /** Use only for merging data! */
  _id: Scalars['ID'];
  sku: Scalars['String'];
  productType: ProductTypeEnum;
  description?: Maybe<Scalars['String']>;
  scopeGroups: Array<ScopeGroup>;
  contentGroups: Array<ContentGroup>;
};

export enum ProductEnum {
  Mathnation = 'MATHNATION',
  Science = 'SCIENCE',
  Stemscopesmath = 'STEMSCOPESMATH',
}

export type ProductFilterInput = {
  contentGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductTypeEnum>;
  scopeGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  sku?: InputMaybe<Scalars['String']>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  products: Array<Product>;
};

export type ProductGroupFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductGroupPageConnection = {
  __typename?: 'ProductGroupPageConnection';
  collection: Array<ProductGroup>;
  paginationInfo?: Maybe<ProductGroupPaginationInfo>;
};

export type ProductGroupPaginationInfo = {
  __typename?: 'ProductGroupPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductGroupPayload = {
  __typename?: 'ProductGroupPayload';
  productGroup: ProductGroup;
};

export enum ProductNameEnum {
  Mathnation = 'MATHNATION',
  Stemscopes = 'STEMSCOPES',
}

export type ProductPageConnection = {
  __typename?: 'ProductPageConnection';
  collection: Array<Product>;
  paginationInfo?: Maybe<ProductPaginationInfo>;
};

export type ProductPaginationInfo = {
  __typename?: 'ProductPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductPayload = {
  __typename?: 'ProductPayload';
  product: Product;
};

export enum ProductTypeEnum {
  MathNation = 'MathNation',
  StemScopes = 'StemScopes',
}

export enum ProjectTypeEnum {
  DigitalNotebook = 'DigitalNotebook',
  HelpPage = 'HelpPage',
  Lesson = 'Lesson',
  MathNation = 'MathNation',
  Scope = 'Scope',
  Streaming = 'Streaming',
  TeacherResource = 'TeacherResource',
  DailyNumeracy = 'DailyNumeracy',
  FactFluency = 'FactFluency',
  HowTo = 'HowTo',
  Undefined = 'Undefined',
}

export type PublicAdoptionAccount = {
  __typename?: 'PublicAdoptionAccount';
  active: Scalars['Boolean'];
  districtId: Scalars['String'];
};

export type PublicAdoptionEvent = {
  __typename?: 'PublicAdoptionEvent';
  active: Scalars['Boolean'];
  uiCustomization?: Maybe<Scalars['String']>;
  adoptionAccounts: Array<PublicAdoptionAccount>;
};

export type PublicParent = {
  __typename?: 'PublicParent';
  districtId: Scalars['String'];
  id: Scalars['String'];
};

export type PublicPreviewAccount = {
  __typename?: 'PublicPreviewAccount';
  district: NewDistrict;
  id: Scalars['String'];
};

export type PublicPreviewAccountTeacher = {
  __typename?: 'PublicPreviewAccountTeacher';
  id: Scalars['String'];
  previewAccount: PublicPreviewAccount;
};

export enum PurposeEnum {
  Cyu = 'CYU',
  Edgexl = 'EDGEXL',
  Onramp = 'ONRAMP',
  Ss = 'SS',
  Stemscopes = 'STEMSCOPES',
  Ty = 'TY',
}

export type PurposeEnumInput = {
  curriculumAreas?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  gradingTypes?: InputMaybe<Array<GradingTypeEnum>>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  purpose: PurposeEnum;
  questionBanks?: InputMaybe<Array<QuestionBankEnum>>;
  questionTypes?: InputMaybe<Array<Scalars['String']>>;
  scopeNames?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<Scalars['String']>;
};

/** Attributes for Activities share_results update */
export type PutActivitiesShareResultsInput = {
  assignmentId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  shareResults: ShareResults;
  studentIds: Array<Scalars['String']>;
};

/** Put Assignment Input */
export type PutAssignmentInput = {
  districtPutAssignmentInput?: InputMaybe<DistrictCreateInput>;
  edgexlPutAssignmentInput?: InputMaybe<EdgexlCreateInput>;
  ltiPutAssignmentInput?: InputMaybe<LtiCreateInput>;
  mnEdgexlPutAssignmentInput?: InputMaybe<LtiEdgeXlCreateInput>;
  putTeacherAssignmentInput?: InputMaybe<TeacherAssignmentsCreateInput>;
  stemscopesPutAssignmentInput?: InputMaybe<StemscopesCreateInput>;
};

/** PutDistrictStudentAssignmentsInput */
export type PutDistrictStudentAssignmentsInput = {
  assignmentId: Scalars['String'];
  newStudentIds: Array<Scalars['String']>;
};

/** PutStudentAssignmentsInput */
export type PutStudentAssignmentsInput = {
  assignees: AssignmentAssigneesInput;
  assignmentId: Scalars['String'];
};

/** Qrcode render types */
export enum QrcodeRenderEnum {
  Pdf = 'pdf',
  Html = 'html',
}

export type QrcodeTemplate = {
  __typename?: 'QrcodeTemplate';
  /** base64 representation of pdf or string representation of html */
  content: Scalars['String'];
};

export type QrcodeUserData = {
  /** teacher firstname and lastname */
  teacher: Scalars['String'];
  plainPassword?: InputMaybe<Scalars['String']>;
  qrcode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  assessment: AssessmentPayload;
  assessmentLeader: AssessmentPayload;
  assessmentPool: AssessmentPoolPayload;
  assessmentPoolLeader: AssessmentPoolPayload;
  /** @deprecated Deprecated `$purpose` use `PurposeEnumInput $input` instead */
  poolEnums: PoolEnumsPayload;
  poolQuestionTotal: PoolQuestionTotalPayload;
  question: QuestionPayload;
  questionPool: QuestionPoolPayload;
  questionPoolLeader: QuestionPoolPayload;
  questions: QuestionsPayload;
  readContainer: ContainersVisibilityPayload;
  content?: Maybe<Content>;
  contentGroup?: Maybe<ContentGroup>;
  contentGroups: ContentGroupPageConnection;
  contents: ContentPageConnection;
  keyword: Keyword;
  keywords: KeywordPageConnection;
  tag: Tag;
  tagValue: TagValue;
  tagValues: TagValuePageConnection;
  tags: TagPageConnection;
  assignment?: Maybe<Assignment>;
  assignments?: Maybe<Assignments>;
  baseDistrictAssignments?: Maybe<DistrictBaseAssignmentsType>;
  /** Return District Class Assignments and District Students Assignments for teacher */
  districtAssignments?: Maybe<DistrictClassAssignmentsType>;
  /** Return District Class Assignments and District Students Assignments for district leader */
  districtAssignmentsForLeader?: Maybe<DistrictClassAssignmentsType>;
  districtStudentAssignments?: Maybe<DistrictStudentAssignmentsType>;
  edgexlAssignment?: Maybe<EdgexlAssignmentType>;
  /** Return edgexl assignments */
  edgexlAssignments?: Maybe<EdgexlAssignmentsType>;
  edgexlAssignmentsProgress?: Maybe<EdgexlAssignmentsProgressInfoType>;
  edgexlStudentAssignments?: Maybe<EdgexlStudentAssignmentsType>;
  mnPersonalAssignmentAttempts?: Maybe<EdgexlAssignmentsProgressInfoType>;
  ownerAssignments?: Maybe<OwnerAssignments>;
  /** @deprecated Has been deprecated, use StudentStudentAssignments instead */
  studentNotebook?: Maybe<StudentAssignments>;
  studentNotebooks?: Maybe<StudentNotebooks>;
  studentResources?: Maybe<StudentResources>;
  studentResourcesFilters?: Maybe<StudentsResourcesSettings>;
  studentStudentAssignments?: Maybe<StudentAssignments>;
  teacherFeedback?: Maybe<TeacherFeedback>;
  teacherStudentAssignments?: Maybe<StudentAssignments>;
  tccPackageDownloadLink: DownloadTccPackage;
  tccPackages: TccPackages;
  tccPackagesCount: TccPackagesCount;
  baseScope?: Maybe<BaseScope>;
  curriculumAreas?: Maybe<CurriculumAreas>;
  digitalNotebookCovers?: Maybe<DigitalNotebookCovers>;
  elements?: Maybe<Elements>;
  grades?: Maybe<Grades>;
  helpPage: HelpPageStructure;
  mnGrades?: Maybe<MathNationGrades>;
  mnScope?: Maybe<MathNationScope>;
  mnScopes: MathNationScopes;
  recentlyViewed?: Maybe<RecentlyViewed>;
  scope?: Maybe<Scope>;
  scopes: Scopes;
  streaming?: Maybe<Streaming>;
  streamingContentTypes?: Maybe<StreamingContentTypes>;
  streamingCurriculumAreas?: Maybe<StreamingCurriculumAreas>;
  streamingGrades?: Maybe<StreamingGrades>;
  streamingTags?: Maybe<StreamingTags>;
  streamingTopics?: Maybe<StreamingTopics>;
  streamings?: Maybe<Streamings>;
  teacherResource?: Maybe<TeacherResource>;
  teacherResources?: Maybe<TeacherResources>;
  topics?: Maybe<Topics>;
  widget: Widget;
  widgets: Array<Maybe<Widget>>;
  dashboardEmbedUrl: EmbedUrlResponse;
  dashboardEmbedUrlForAnonymousUser: EmbedUrlResponse;
  adoptionAccount: AdoptionAccount;
  adoptionAccounts: AdoptionAccountPageConnection;
  adoptionEvent: AdoptionEvent;
  adoptionEvents: AdoptionEventPageConnection;
  adoptionReviewer: AdoptionReviewer;
  adoptionReviewers: AdoptionReviewerPageConnection;
  latestPreviewAccountImportLog: PreviewAccountImportLog;
  previewAccount: PreviewAccount;
  previewAccountImportLog: PreviewAccountImportLog;
  previewAccountImportLogs: PreviewAccountImportLogPageConnection;
  previewAccountTeacher: PreviewAccountTeacher;
  previewAccountTeachers: PreviewAccountTeacherPageConnection;
  previewAccounts: PreviewAccountPageConnection;
  productGroup: ProductGroup;
  productGroups: ProductGroupPageConnection;
  node?: Maybe<Node>;
  accessPolicies: AccessPolicyPageConnection;
  accessPolicy: AccessPolicy;
  aclApplications: Array<AclApplication>;
  /** Use only for merging data! */
  lookupRoles: Array<Role>;
  role?: Maybe<Role>;
  roles: RolePageConnection;
  icon: Icon;
  icons: IconPageConnection;
  /** Use only for merging data! */
  lookupIcons: Array<Icon>;
  image: Image;
  imageCategories: ImageCategoryPageConnection;
  imageCategory: ImageCategory;
  imageFileUploadForm: ImageFileUploadForm;
  imageKeyword: ImageKeyword;
  imageKeywords: ImageKeywordPageConnection;
  imageTag: ImageTag;
  imageTagValue: ImageTagValue;
  imageTagValues: ImageTagValuePageConnection;
  imageTags: ImageTagPageConnection;
  images: ImagePageConnection;
  cleverSchools: Array<CleverSchool>;
  edfiGradeLevels: Array<EdfiGradeLevel>;
  edfiJobRoles: Array<EdfiJobRole>;
  edfiSchools: Array<EdfiSchool>;
  importResult: ImportResult;
  importResultErrors: ImportErrorPageConnection;
  importResultErrorsLink: ImportResultErrorsLink;
  importResults: Array<ImportResult>;
  integration?: Maybe<Integration>;
  integrations: Array<Integration>;
  oneRosterRestOauth2Schools: Array<OneRosterSchool>;
  oneRosterRestSchools: Array<OneRosterSchool>;
  getReport?: Maybe<Report>;
  getSignedRequests?: Maybe<Array<Maybe<SignedRequest>>>;
  calendar?: Maybe<Calendar>;
  calendarTemplate?: Maybe<Calendar>;
  calendarTemplates?: Maybe<CalendarPageConnection>;
  calendars?: Maybe<CalendarPageConnection>;
  districtSettingsLessonPlanner?: Maybe<DistrictSettingsLessonPlanner>;
  entries?: Maybe<EntryPageConnection>;
  entry?: Maybe<Entry>;
  pacingGuide?: Maybe<PacingGuide>;
  pacingGuideScope?: Maybe<PacingGuideScope>;
  pacingGuideScopes?: Maybe<PacingGuideScopePageConnection>;
  pacingGuideTemplate?: Maybe<PacingGuide>;
  pacingGuideTemplates?: Maybe<PacingGuidePageConnection>;
  pacingGuides?: Maybe<PacingGuidePageConnection>;
  plannerTeams?: Maybe<PlannerTeamPageConnection>;
  scopePlan?: Maybe<ScopePlan>;
  scopePlanScope?: Maybe<ScopePlanScope>;
  scopePlanScopes?: Maybe<ScopePlanScopePageConnection>;
  scopePlanTemplate?: Maybe<ScopePlan>;
  scopePlanTemplates?: Maybe<ScopePlanPageConnection>;
  scopePlans?: Maybe<ScopePlanPageConnection>;
  searchCalendar?: Maybe<Calendar>;
  userCalendar?: Maybe<UserCalendar>;
  userCalendars?: Maybe<UserCalendarPageConnection>;
  userSelection?: Maybe<UserSelection>;
  userSelections?: Maybe<UserSelectionPageConnection>;
  studentAssignmentsMock: StudentAssignmentMockPageConnection;
  district?: Maybe<District>;
  districtSetupStatus?: Maybe<DistrictSetupStatus>;
  districts?: Maybe<DistrictPageConnection>;
  lookupDistricts?: Maybe<Array<Maybe<District>>>;
  lookupSchools?: Maybe<Array<Maybe<School>>>;
  school?: Maybe<School>;
  schools?: Maybe<SchoolPageConnection>;
  publicDistrict: NewDistrict;
  newDistricts: NewDistrictPageConnection;
  passwordRecoveryToken?: Maybe<PasswordRecoveryToken>;
  previewAccountDistricts: NewDistrictPageConnection;
  publicAdoptionEvent: PublicAdoptionEvent;
  systemAdmins: SystemAdminPageConnection;
  /** @deprecated Used to display enum in the schema */
  accesses?: Maybe<Array<Maybe<Access>>>;
  class?: Maybe<Class>;
  classStudents?: Maybe<ClassStudentPageConnection>;
  classTeachers?: Maybe<ClassTeacherPageConnection>;
  classes?: Maybe<ClassPageConnection>;
  districtSettingsRoster?: Maybe<DistrictSettingsRoster>;
  lookupRosterStudents?: Maybe<Array<Maybe<Student>>>;
  student?: Maybe<Student>;
  studentSubgroup?: Maybe<StudentSubgroup>;
  /** @deprecated Framework issue */
  students?: Maybe<StudentPageConnection>;
  teacher?: Maybe<Teacher>;
  teacherStudents?: Maybe<StudentPageConnection>;
  teachers?: Maybe<TeacherPageConnection>;
  applicationSettings?: Maybe<ApplicationSettings>;
  activeContentGroups: Array<ContentGroup>;
  activeContentGroupsWithSchools: Array<ContentGroupWithSchoolPayload>;
  /** Use only for merging data! */
  lookupProducts: Array<Product>;
  order: Order;
  orders: OrderPageConnection;
  product: Product;
  products: ProductPageConnection;
  subscription: Subscription;
  subscriptionPackage: SubscriptionPackage;
  subscriptionPackages: SubscriptionPackagePageConnection;
  subscriptions: SubscriptionPageConnection;
  team: Team;
  teams: TeamPageConnection;
  uptime: Scalars['Float'];
  districtSettingsUserManagement?: Maybe<DistrictSettingsUserManagement>;
  identifyUsers?: Maybe<Array<Maybe<User>>>;
  lookupStudents?: Maybe<Array<Maybe<Student>>>;
  lookupTeachers?: Maybe<Array<Maybe<Teacher>>>;
  lookupUsers?: Maybe<Array<Maybe<User>>>;
  parentStudents?: Maybe<Array<Maybe<ParentStudent>>>;
  parents?: Maybe<Array<Maybe<Parent>>>;
  user?: Maybe<User>;
  userExportRequest?: Maybe<UserExportRequest>;
  userRoles?: Maybe<UserRolePageConnection>;
  users?: Maybe<UserPageConnection>;
  readerLibraries: ReaderLibraryPageConnection;
  readerLibrary: ReaderLibraryPayload;
  stemscopesMedia: StemscopesMediaPayload;
  stemscopesMediaPack: ReadStemscopesMediaPackPayload;
  stemscopesMediaPacks: StemscopesMediaPackPageConnection;
  stemscopesMedias: StemscopesMediaPageConnection;
  streamingVideo: StreamingVideoPayload;
  streamingVideos: StreamingVideoPageConnection;
  tutor: TutorPayload;
  tutors: TutorPageConnection;
  video: VideoPayload;
  videos: UnionVideoPdfPageConnection;
  videosByGroup: UnionVideoPdfPageConnection;
  /** Init science Bot to get the conversation id */
  initScienceBot: ScienceBotPayload;
  /** Get all the conversation by ID */
  scienceBots: Array<ScienceBotPayload>;
  note: NotePayload;
  academicStandards: AcademicStandardsPayload;
  adminKnowledgeTree: KnowledgeTreePayload;
  benchmarkList: BenchmarkObjectsPayload;
  createTeacherAssignment: TeacherAssignmentPayload;
  knowledgeTreeData: KnowledgeTreePayload;
  /** Onramp Continue Assignment */
  onrampContinueStudentAssignment: StudentAssignmentPayload;
  /** After complete StartUp, prepare progress */
  onrampStartUpCompleted: StudentResponsePayload;
  /** Init start up test */
  onrampStartUpTest: SignRequestStartUpPayload;
  /** Onramp Teacher Assignment */
  onrampStudentAssignment: StudentAssignmentPayload;
  /** Onramp Student dashboard */
  onrampStudentDashboard: StudentPayload;
  onrampStudentStatus: OnrampStatusPayload;
  onrampTeacherAssignmentsList: TeacherAssignmentListPayload;
  standardList: StandardListPayload;
  standardsByName: Array<Standards>;
  /** Onramp Student Certificate */
  studentCertificate: StudentCertificatePayload;
  teacherStandardList: StandardListPayload;
  karmaAwards?: Maybe<KarmaAwardPageConnection>;
  posts?: Maybe<PostPageConnection>;
  s3UploadSignedUrl?: Maybe<S3UploadSignedUrl>;
  unfilteredPosts?: Maybe<PostPageConnection>;
  wallAgeControls?: Maybe<Array<Maybe<WallAgeControl>>>;
  wallControls?: Maybe<Array<Maybe<WallControl>>>;
  wallPostLikes?: Maybe<Array<Maybe<WallPostLike>>>;
  wallPostList: WallPostConnect;
  standards: Array<Standard>;
  aiChat: AiChatHistoryPayload;
  districtSettingsKnowledgeBase: DistrictSettingsKnowledgeBase;
  wallDisplayNameList: WallListConnect;
  emailTemplate: EmailTemplate;
  emailTemplateAttachmentUploadForm: EmailTemplateAttachmentUploadFormPayload;
  emailTemplateImageUploadForm: EmailTemplateImageUploadForm;
  emailTemplatePlaceholders: EmailTemplatePlaceholdersPayload;
  emailTemplates: EmailTemplatePageConnection;
  fromEmails: StringPageConnection;
  canvasConfig: CanvasConfig;
  canvasSsoConfig: CanvasSsoConfig;
  districtSettingsLogin: DistrictSettingsLogin;
  listLti: Array<Lti>;
  listSso: Array<Sso>;
  readLti: Lti;
  readSso: Sso;
  askChatbot?: Maybe<ChatbotResponse>;
  getQuestionHint?: Maybe<GetQuestionHintResponse>;
  generateQuestion?: Maybe<GenerateQuestionResponse>;
  /** A placeholder query used by thecodingmachine/graphqlite when there are no declared queries. */
  dummyQuery?: Maybe<Scalars['String']>;
  athenaDistricts?: Maybe<AthenaDistrictPageConnection>;
  mnCoursework?: Maybe<MnCoursework>;
  mnCourseworkDownloadReport?: Maybe<MnCourseworkDownloadReport>;
  mnOnRamp?: Maybe<MnOnRamp>;
  mnOnRampDownloadReport?: Maybe<MnOnRampDownloadReport>;
  ssStandards?: Maybe<SsStandards>;
  ssStandards2?: Maybe<SsStandards2>;
  ssStandardsItems?: Maybe<Array<Maybe<SsStandardsItem>>>;
};

export type QueryAssessmentArgs = {
  input: AssessmentInput;
};

export type QueryAssessmentLeaderArgs = {
  input: AssessmentInput;
};

export type QueryAssessmentPoolArgs = {
  input: AssessmentPoolInput;
};

export type QueryAssessmentPoolLeaderArgs = {
  input: AssessmentPoolInput;
};

export type QueryPoolEnumsArgs = {
  purpose?: InputMaybe<PurposeEnum>;
  input?: InputMaybe<PurposeEnumInput>;
};

export type QueryPoolQuestionTotalArgs = {
  purpose: PurposeEnum;
};

export type QueryQuestionArgs = {
  input: QuestionInput;
};

export type QueryQuestionPoolArgs = {
  input: QuestionPoolInput;
};

export type QueryQuestionPoolLeaderArgs = {
  input: QuestionPoolInput;
};

export type QueryQuestionsArgs = {
  input: QuestionsInput;
};

export type QueryContentArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type QueryContentGroupArgs = {
  id: Scalars['ID'];
};

export type QueryContentGroupsArgs = {
  filter?: InputMaybe<ContentGroupFilterInput>;
  order?: InputMaybe<ContentGroupOrderInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryContentsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryKeywordArgs = {
  id: Scalars['ID'];
};

export type QueryKeywordsArgs = {
  filter?: InputMaybe<KeywordFilterInput>;
  itemsPerPage: Scalars['Int'];
  order?: InputMaybe<KeywordOrderInput>;
  page: Scalars['Int'];
};

export type QueryTagArgs = {
  id: Scalars['ID'];
};

export type QueryTagValueArgs = {
  id: Scalars['ID'];
};

export type QueryTagValuesArgs = {
  filter: TagValueFilterInput;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryTagsArgs = {
  filter?: InputMaybe<TagFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryAssignmentArgs = {
  id?: InputMaybe<Scalars['String']>;
  lti?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryAssignmentsArgs = {
  archiveStatus?: InputMaybe<ArchiveStatuses>;
  archived?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryBaseDistrictAssignmentsArgs = {
  paginationInfo?: InputMaybe<DistrictAssignmentPageInfoInput>;
};

export type QueryDistrictAssignmentsArgs = {
  filter?: InputMaybe<DistrictAssignmentsFilter>;
};

export type QueryDistrictAssignmentsForLeaderArgs = {
  filter: LeaderDistrictAssignmentsFilter;
};

export type QueryDistrictStudentAssignmentsArgs = {
  classId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
  shareResults?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<StudentAssignmentStatus>;
  studentId?: InputMaybe<Scalars['String']>;
};

export type QueryEdgexlAssignmentArgs = {
  id: Scalars['String'];
};

export type QueryEdgexlAssignmentsArgs = {
  assessmentId?: InputMaybe<Scalars['String']>;
  classId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryEdgexlAssignmentsProgressArgs = {
  params: EdgexlAssignmentsProgressInfoInput;
};

export type QueryEdgexlStudentAssignmentsArgs = {
  studentId: Scalars['String'];
};

export type QueryMnPersonalAssignmentAttemptsArgs = {
  assignmentSource: AssignmentSourceInput;
  assignmentType: MnPersonalAssignmentType;
  studentId?: InputMaybe<Scalars['String']>;
};

export type QueryOwnerAssignmentsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  assignmentDateFilter?: InputMaybe<AssignmentDateFilterInput>;
  classId?: InputMaybe<Scalars['String']>;
  classIds?: InputMaybe<Array<Scalars['String']>>;
  endDateFilter?: InputMaybe<AssignmentDateFilterInput>;
  paginationInfo?: InputMaybe<StemscopesAssignmentPageInfoInput>;
  schoolId?: InputMaybe<Scalars['String']>;
  studentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryStudentNotebookArgs = {
  classId: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryStudentNotebooksArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
};

export type QueryStudentResourcesArgs = {
  filter?: InputMaybe<StudentResourcesFilter>;
  schoolId: Scalars['String'];
};

export type QueryStudentStudentAssignmentsArgs = {
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryTeacherFeedbackArgs = {
  sessionId: Scalars['String'];
};

export type QueryTeacherStudentAssignmentsArgs = {
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryTccPackageDownloadLinkArgs = {
  id: Scalars['String'];
};

export type QueryTccPackagesArgs = {
  districtId: Scalars['String'];
  ltiConsumer: TccConsumerEnum;
};

export type QueryTccPackagesCountArgs = {
  districtId: Scalars['String'];
};

export type QueryBaseScopeArgs = {
  id: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryCurriculumAreasArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']>;
};

export type QueryDigitalNotebookCoversArgs = {
  filter?: InputMaybe<DigitalNotebookCoverFilter>;
};

export type QueryElementsArgs = {
  filter?: InputMaybe<ElementFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryGradesArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']>;
};

export type QueryHelpPageArgs = {
  input: HelpPageInput;
};

export type QueryMnGradesArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryMnScopeArgs = {
  id: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryMnScopesArgs = {
  filter?: InputMaybe<MathNationScopeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryRecentlyViewedArgs = {
  schoolId: Scalars['String'];
};

export type QueryScopeArgs = {
  id: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
};

export type QueryScopesArgs = {
  filter?: InputMaybe<ScopeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']>;
};

export type QueryStreamingArgs = {
  schoolId: Scalars['String'];
  id: Scalars['String'];
};

export type QueryStreamingContentTypesArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingCurriculumAreasArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingGradesArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingTagsArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingTopicsArgs = {
  schoolId: Scalars['String'];
};

export type QueryStreamingsArgs = {
  schoolId: Scalars['String'];
  filter?: InputMaybe<StreamingFilter>;
  search?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryTeacherResourceArgs = {
  id: Scalars['String'];
  schoolId: Scalars['String'];
};

export type QueryTeacherResourcesArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryTopicsArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']>;
};

export type QueryWidgetArgs = {
  id: Scalars['String'];
};

export type QueryDashboardEmbedUrlArgs = {
  dashboardID: Scalars['String'];
  sheetID?: InputMaybe<Scalars['String']>;
  visualID?: InputMaybe<Scalars['String']>;
};

export type QueryDashboardEmbedUrlForAnonymousUserArgs = {
  dashboardID: Scalars['String'];
  sheetID?: InputMaybe<Scalars['String']>;
  visualID?: InputMaybe<Scalars['String']>;
};

export type QueryAdoptionAccountArgs = {
  id: Scalars['ID'];
};

export type QueryAdoptionAccountsArgs = {
  filter?: InputMaybe<AdoptionAccountFilterInput>;
  order?: InputMaybe<AdoptionAccountOrderInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryAdoptionEventArgs = {
  id: Scalars['ID'];
};

export type QueryAdoptionEventsArgs = {
  filter?: InputMaybe<AdoptionEventFilterInput>;
  order?: InputMaybe<AdoptionEventOrderInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryAdoptionReviewerArgs = {
  id: Scalars['ID'];
};

export type QueryAdoptionReviewersArgs = {
  filter?: InputMaybe<AdoptionReviewerFilterInput>;
  itemsPerPage: Scalars['Int'];
  order?: InputMaybe<AdoptionReviewerOrderInput>;
  page: Scalars['Int'];
};

export type QueryLatestPreviewAccountImportLogArgs = {
  type: PreviewAccountImportLogTypeEnum;
};

export type QueryPreviewAccountArgs = {
  id: Scalars['ID'];
};

export type QueryPreviewAccountImportLogArgs = {
  id: Scalars['ID'];
};

export type QueryPreviewAccountImportLogsArgs = {
  type: PreviewAccountImportLogTypeEnum;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryPreviewAccountTeacherArgs = {
  id: Scalars['ID'];
};

export type QueryPreviewAccountTeachersArgs = {
  filter?: InputMaybe<PreviewAccountTeacherFilterInput>;
  itemsPerPage: Scalars['Int'];
  order?: InputMaybe<PreviewAccountTeacherOrderInput>;
  page: Scalars['Int'];
};

export type QueryPreviewAccountsArgs = {
  filter?: InputMaybe<PreviewAccountFilterInput>;
  order?: InputMaybe<PreviewAccountOrderInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryProductGroupArgs = {
  id: Scalars['ID'];
};

export type QueryProductGroupsArgs = {
  filter?: InputMaybe<ProductGroupFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryAccessPoliciesArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryAccessPolicyArgs = {
  id: Scalars['ID'];
};

export type QueryLookupRolesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryRoleArgs = {
  id: Scalars['ID'];
};

export type QueryRolesArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryIconArgs = {
  id: Scalars['ID'];
};

export type QueryIconsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<FilterIconInput>;
};

export type QueryLookupIconsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryImageArgs = {
  id: Scalars['ID'];
};

export type QueryImageCategoriesArgs = {
  filter?: InputMaybe<ImageCategoryFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryImageCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryImageFileUploadFormArgs = {
  input: ReadImageFileUploadFormInput;
};

export type QueryImageKeywordArgs = {
  id: Scalars['ID'];
};

export type QueryImageKeywordsArgs = {
  filter?: InputMaybe<ImageKeywordFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryImageTagArgs = {
  id: Scalars['ID'];
};

export type QueryImageTagValueArgs = {
  id: Scalars['ID'];
};

export type QueryImageTagValuesArgs = {
  filter: ImageTagValueFilterInput;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryImageTagsArgs = {
  filter?: InputMaybe<ImageTagFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryImagesArgs = {
  filter?: InputMaybe<ImageFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryCleverSchoolsArgs = {
  districtId: Scalars['String'];
};

export type QueryEdfiGradeLevelsArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryEdfiJobRolesArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryEdfiSchoolsArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryImportResultArgs = {
  input: ImportResultInput;
};

export type QueryImportResultErrorsArgs = {
  input: ImportResultErrorInput;
};

export type QueryImportResultErrorsLinkArgs = {
  input: ImportResultErrorsLinkInput;
};

export type QueryImportResultsArgs = {
  integrationInput?: InputMaybe<IntegrationKeyInput>;
};

export type QueryIntegrationArgs = {
  integrationInput: IntegrationKeyInput;
};

export type QueryIntegrationsArgs = {
  input?: InputMaybe<IntegrationsInput>;
};

export type QueryOneRosterRestOauth2SchoolsArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryOneRosterRestSchoolsArgs = {
  configInput: IntegrationConfigInput;
};

export type QueryGetReportArgs = {
  containerId: Scalars['String'];
  activityId?: InputMaybe<Scalars['String']>;
  activities?: InputMaybe<Scalars['Iterable']>;
  users?: InputMaybe<Scalars['Iterable']>;
  userId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  displayUser?: InputMaybe<Scalars['Boolean']>;
  type: Scalars['String'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type QueryGetSignedRequestsArgs = {
  parameter?: InputMaybe<Array<InputMaybe<InputRequest>>>;
  view?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryCalendarArgs = {
  id: Scalars['ID'];
};

export type QueryCalendarTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryCalendarTemplatesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalendarFilter_Order>>>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<CalendarTemplateFilter>>>;
};

export type QueryCalendarsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalendarFilter_Order>>>;
  calendarId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  teamId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<CalendarFilter>>>;
};

export type QueryEntriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  entryType?: InputMaybe<EntryTypeEnum>;
  filter?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
};

export type QueryEntryArgs = {
  id: Scalars['ID'];
};

export type QueryPacingGuideArgs = {
  id: Scalars['ID'];
};

export type QueryPacingGuideScopeArgs = {
  id: Scalars['ID'];
};

export type QueryPacingGuideScopesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<PacingGuideScopeFilter>>>;
};

export type QueryPacingGuideTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryPacingGuideTemplatesArgs = {
  name?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  grades?: InputMaybe<Scalars['Iterable']>;
  sharedType?: InputMaybe<Scalars['String']>;
  pacingGuideType?: InputMaybe<PacingGuideTypeEnum>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

export type QueryPacingGuidesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PacingGuideFilter_Order>>>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  pacingGuideType?: InputMaybe<PacingGuideTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<PacingGuideFilter>>>;
};

export type QueryPlannerTeamsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryScopePlanArgs = {
  id: Scalars['ID'];
};

export type QueryScopePlanScopeArgs = {
  id: Scalars['ID'];
};

export type QueryScopePlanScopesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<ScopePlanScopeFilter>>>;
};

export type QueryScopePlanTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryScopePlanTemplatesArgs = {
  name?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  grades?: InputMaybe<Scalars['Iterable']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

export type QueryScopePlansArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ScopePlanFilter_Order>>>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<ScopePlanFilter>>>;
};

export type QuerySearchCalendarArgs = {
  calendarType?: InputMaybe<CalendarTypeEnum>;
  calendarName?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
};

export type QueryUserCalendarArgs = {
  id: Scalars['ID'];
};

export type QueryUserCalendarsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  accessType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<UserCalendarFilter_Order>>>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<UserCalendarFilter>>>;
};

export type QueryUserSelectionArgs = {
  id: Scalars['ID'];
};

export type QueryUserSelectionsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryStudentAssignmentsMockArgs = {
  classId: Scalars['String'];
};

export type QueryDistrictArgs = {
  id: Scalars['ID'];
};

export type QueryDistrictsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<DistrictFilter_Order>>>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
  filter?: InputMaybe<Array<InputMaybe<DistrictFilter>>>;
};

export type QueryLookupDistrictsArgs = {
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryLookupSchoolsArgs = {
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySchoolArgs = {
  id: Scalars['ID'];
};

export type QuerySchoolsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  districtName?: InputMaybe<Scalars['String']>;
  districtState?: InputMaybe<StateEnum>;
  districtId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  rolesRelated?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Array<InputMaybe<SchoolFilter_Order>>>;
  filter?: InputMaybe<Array<InputMaybe<SchoolFilter>>>;
};

export type QueryPublicDistrictArgs = {
  id: Scalars['ID'];
};

export type QueryNewDistrictsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
};

export type QueryPasswordRecoveryTokenArgs = {
  id: Scalars['ID'];
};

export type QueryPreviewAccountDistrictsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryPublicAdoptionEventArgs = {
  urlCode: Scalars['String'];
};

export type QuerySystemAdminsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  districtUuid: Scalars['String'];
};

export type QueryClassArgs = {
  id: Scalars['ID'];
};

export type QueryClassStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type QueryClassTeachersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
};

export type QueryClassesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Array<InputMaybe<ClassFilter_Order>>>;
  hasLmsId?: InputMaybe<Scalars['Boolean']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusEnum>;
  studentsId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<ClassFilter>>>;
};

export type QueryLookupRosterStudentsArgs = {
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryStudentArgs = {
  id: Scalars['ID'];
};

export type QueryStudentSubgroupArgs = {
  id: Scalars['ID'];
};

export type QueryStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryTeacherArgs = {
  id: Scalars['ID'];
};

export type QueryTeacherStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  schoolId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
  classIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  studentHasArchivedClasses?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Array<InputMaybe<StudentFilter_Order>>>;
  fullName?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<TeacherStudentFilter>>>;
};

export type QueryTeachersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<TeacherFilter>>>;
};

export type QueryActiveContentGroupsArgs = {
  filter?: InputMaybe<ContentGroupFilterInput>;
};

export type QueryLookupProductsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersArgs = {
  filter?: InputMaybe<OrderFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QuerySubscriptionArgs = {
  id: Scalars['ID'];
};

export type QuerySubscriptionPackageArgs = {
  id: Scalars['ID'];
};

export type QuerySubscriptionPackagesArgs = {
  filter?: InputMaybe<SubscriptionPackageFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QuerySubscriptionsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryTeamArgs = {
  id: Scalars['ID'];
};

export type QueryTeamsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<FilterTeamInput>;
};

export type QueryIdentifyUsersArgs = {
  districtId?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  sisId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<IdentifyUserFilter>>>;
};

export type QueryLookupStudentsArgs = {
  id_list: Array<InputMaybe<Scalars['String']>>;
};

export type QueryLookupTeachersArgs = {
  id_list: Array<InputMaybe<Scalars['String']>>;
};

export type QueryLookupUsersArgs = {
  id_list: Array<InputMaybe<Scalars['String']>>;
};

export type QueryParentsArgs = {
  studentId: Scalars['String'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUserExportRequestArgs = {
  id: Scalars['ID'];
};

export type QueryUserRolesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryUsersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
  classesArchived?: InputMaybe<Scalars['Boolean']>;
  classesCurriculumArea?: InputMaybe<Scalars['String']>;
  classesGradeOrCourse?: InputMaybe<Scalars['String']>;
  classesId?: InputMaybe<Scalars['String']>;
  classesLanguage?: InputMaybe<Scalars['String']>;
  classesName?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<CurriculumAreaEnum>;
  districtId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  excludeCurrent?: InputMaybe<Scalars['Boolean']>;
  exclude?: InputMaybe<Array<InputMaybe<UserFilter_Exclude>>>;
  externalSystemName?: InputMaybe<ExternalSystemNameEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  fullNameOrUsername?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<GradeLevelFilterEnum>;
  highestRoleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lmsId?: InputMaybe<Scalars['String']>;
  rolesId?: InputMaybe<Scalars['String']>;
  schoolsId?: InputMaybe<Scalars['String']>;
  schoolsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sisId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subgroupsId?: InputMaybe<Scalars['String']>;
  subgroupsName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<UserFilter_Order>>>;
  type?: InputMaybe<UserTypeEnum>;
  purpose?: InputMaybe<UserPurposeEnum>;
  username?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<UserFilter>>>;
};

export type QueryReaderLibrariesArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<ReaderLibraryFilterInput>;
};

export type QueryReaderLibraryArgs = {
  id: Scalars['ID'];
};

export type QueryStemscopesMediaArgs = {
  id: Scalars['ID'];
};

export type QueryStemscopesMediaPackArgs = {
  id: Scalars['ID'];
};

export type QueryStemscopesMediaPacksArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<StemscopesMediaPackFilterInput>;
};

export type QueryStemscopesMediasArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<StemscopesMediaFilterInput>;
};

export type QueryStreamingVideoArgs = {
  id: Scalars['ID'];
};

export type QueryStreamingVideosArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<StreamingVideoFilterInput>;
};

export type QueryTutorArgs = {
  id: Scalars['ID'];
};

export type QueryTutorsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter?: InputMaybe<TutorFilterInput>;
};

export type QueryVideoArgs = {
  id: Scalars['ID'];
};

export type QueryVideosArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter: VideoFilterInput;
  sort?: InputMaybe<VideoSortInput>;
};

export type QueryVideosByGroupArgs = {
  input: VideoByGroupInput;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryScienceBotsArgs = {
  id: Scalars['String'];
};

export type QueryNoteArgs = {
  id: Scalars['ID'];
};

export type QueryAcademicStandardsArgs = {
  input: AcademicStandardInput;
};

export type QueryAdminKnowledgeTreeArgs = {
  input: AdminKnowledgeTreeInput;
};

export type QueryBenchmarkListArgs = {
  input: BenchmarkInput;
};

export type QueryCreateTeacherAssignmentArgs = {
  input: TeacherAssignmentInput;
};

export type QueryKnowledgeTreeDataArgs = {
  input: KnowledgeTreeInput;
};

export type QueryOnrampContinueStudentAssignmentArgs = {
  input: OnrampStudentAssignmentInput;
};

export type QueryOnrampStartUpCompletedArgs = {
  currentSchoolId: Scalars['String'];
};

export type QueryOnrampStartUpTestArgs = {
  input: OnrampStartupTestInput;
};

export type QueryOnrampStudentAssignmentArgs = {
  input: OnrampStudentAssignmentInput;
};

export type QueryOnrampStudentDashboardArgs = {
  currentSchoolId: Scalars['String'];
};

export type QueryOnrampStudentStatusArgs = {
  input: OnrampStudentStatusInput;
};

export type QueryOnrampTeacherAssignmentsListArgs = {
  input: TeacherAssignmentListInput;
};

export type QueryStandardListArgs = {
  input: StandardListInput;
};

export type QueryStandardsByNameArgs = {
  standardNames: Array<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type QueryStudentCertificateArgs = {
  input: StudentCertificateInput;
};

export type QueryTeacherStandardListArgs = {
  input: StandardListInput;
};

export type QueryKarmaAwardsArgs = {
  districtId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  timeRange?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
  currentSchoolId: Scalars['String'];
};

export type QueryPostsArgs = {
  parameter?: InputMaybe<InputPostRequest>;
  page: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
};

export type QueryS3UploadSignedUrlArgs = {
  wallId: Scalars['String'];
  currentSchoolId: Scalars['String'];
  fileName: Scalars['String'];
};

export type QueryUnfilteredPostsArgs = {
  parameter?: InputMaybe<UnfilteredInputPostRequest>;
  page: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
};

export type QueryWallAgeControlsArgs = {
  classId: Scalars['String'];
  currentSchoolId: Scalars['String'];
};

export type QueryWallControlsArgs = {
  currentSchoolId: Scalars['String'];
};

export type QueryWallPostLikesArgs = {
  currentSchoolId: Scalars['String'];
};

export type QueryStandardsArgs = {
  state: Scalars['String'];
  subject: Scalars['String'];
  ids?: InputMaybe<Array<Scalars['String']>>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  onlyRoot?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEmailTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryEmailTemplateAttachmentUploadFormArgs = {
  input: ReadEmailTemplateAttachmentUploadFormInput;
};

export type QueryEmailTemplateImageUploadFormArgs = {
  input: ReadEmailTemplateImageUploadFormInput;
};

export type QueryEmailTemplatePlaceholdersArgs = {
  type: EmailTemplateTypeEnum;
};

export type QueryEmailTemplatesArgs = {
  filter?: InputMaybe<EmailTemplateFilterInput>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryFromEmailsArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type QueryCanvasConfigArgs = {
  districtInput: DistrictInput;
};

export type QueryCanvasSsoConfigArgs = {
  districtInput: DistrictInput;
};

export type QueryListLtiArgs = {
  listInput?: InputMaybe<LtiListInput>;
};

export type QueryListSsoArgs = {
  listInput?: InputMaybe<SsoListInput>;
};

export type QueryReadLtiArgs = {
  ltiKeyInput: LtiKeyInput;
};

export type QueryReadSsoArgs = {
  ssoKeyInput: SsoKeyInput;
};

export type QueryAskChatbotArgs = {
  userId: Scalars['String'];
  query: Scalars['String'];
  threadId?: InputMaybe<Scalars['String']>;
};

export type QueryGetQuestionHintArgs = {
  question: Scalars['String'];
  options?: InputMaybe<Scalars['String']>;
  correctOption?: InputMaybe<Scalars['String']>;
};

export type QueryGenerateQuestionArgs = {
  question: Scalars['String'];
  options?: InputMaybe<Scalars['String']>;
};

export type QueryAthenaDistrictsArgs = {
  filter?: InputMaybe<DistrictFilter>;
  order?: InputMaybe<DistrictSort>;
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type QueryMnCourseworkArgs = {
  category?: InputMaybe<Scalars['String']>;
  classId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<MnCourseworkOrderType>;
};

export type QueryMnCourseworkDownloadReportArgs = {
  category?: InputMaybe<Scalars['String']>;
  classId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<MnCourseworkOrderType>;
};

export type QueryMnOnRampArgs = {
  classId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  reportType?: InputMaybe<OnRampReportTypeEnum>;
  state?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<MnOnRampOrderType>;
};

export type QueryMnOnRampDownloadReportArgs = {
  classId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  reportType?: InputMaybe<OnRampReportTypeEnum>;
  state?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<MnOnRampOrderType>;
};

export type QuerySsStandardsArgs = {
  assignmentId?: InputMaybe<Scalars['String']>;
  classId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<LevelEnumType>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<SsStandardsSort>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  standards?: InputMaybe<Scalars['String']>;
  teacherId?: InputMaybe<Scalars['String']>;
};

export type QuerySsStandards2Args = {
  districtAssessmentId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<LevelEnumType>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<SsStandardsSort>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  standards?: InputMaybe<Scalars['String']>;
};

export type QuerySsStandardsItemsArgs = {
  filter?: InputMaybe<SsStandardsItemFilter>;
};

export enum QuestionBankEnum {
  Teacher = 'TEACHER',
  District = 'DISTRICT',
  Extra = 'EXTRA',
}

export type QuestionContainerPayload = {
  __typename?: 'QuestionContainerPayload';
  statusPayload?: Maybe<StatusPayload>;
};

export type QuestionInput = {
  platformAdminInput?: InputMaybe<PlatformAdminQuestionInput>;
};

export type QuestionPayload = {
  __typename?: 'QuestionPayload';
  platformAdminPayload?: Maybe<PlatformAdminQuestionPayload>;
};

export type QuestionPool = {
  __typename?: 'QuestionPool';
  categories: Array<Scalars['String']>;
  curriculumAreas: Array<Scalars['String']>;
  grades: Array<Scalars['String']>;
  grading?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lessons: Array<Scalars['String']>;
  gradeLessons: Array<Scalars['String']>;
  editions: Array<Scalars['String']>;
  questionTypes: Array<Scalars['String']>;
  scopes: Array<Scalars['String']>;
  standards: Array<Scalars['String']>;
  units: Array<Scalars['String']>;
};

export type QuestionPoolInput = {
  edgexlInput?: InputMaybe<EdgexlQuestionPoolInput>;
  platformAdminInput?: InputMaybe<PlatformAdminQuestionPoolInput>;
  stemscopesInput?: InputMaybe<StemscopesQuestionPoolInput>;
};

export type QuestionPoolPageConnection = {
  __typename?: 'QuestionPoolPageConnection';
  collection: Array<QuestionPool>;
  paginationInfo?: Maybe<QuestionPoolPaginationInfo>;
};

export type QuestionPoolPaginationInfo = {
  __typename?: 'QuestionPoolPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type QuestionPoolPayload = {
  __typename?: 'QuestionPoolPayload';
  impEdgexlPayload?: Maybe<QuestionPoolPageConnection>;
  impStemscopesPayload?: Maybe<QuestionPoolPageConnection>;
  platformAdminPayload?: Maybe<PlatformAdminQuestionPoolPayload>;
};

export type QuestionsInput = {
  platformAdminInput?: InputMaybe<PlatformAdminQuestionsInput>;
};

export type QuestionsPayload = {
  __typename?: 'QuestionsPayload';
  platformAdminPayload?: Maybe<PlatformAdminQuestionsPayload>;
};

export type ReadEmailTemplateAttachmentUploadFormInput = {
  fileNames: Array<Scalars['String']>;
};

export type ReadEmailTemplateImageUploadFormInput = {
  fileName: Scalars['String'];
};

export type ReaderLibrary = {
  __typename?: 'ReaderLibrary';
  description: Scalars['String'];
  enPdfFile: Scalars['String'];
  esPdfFile: Scalars['String'];
  id: Scalars['String'];
  pdfFile: Scalars['String'];
  title: Scalars['String'];
};

export type ReaderLibraryFilterInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type ReaderLibraryPageConnection = {
  __typename?: 'ReaderLibraryPageConnection';
  collection: Array<ReaderLibrary>;
  paginationInfo?: Maybe<ReaderLibraryPaginationInfo>;
};

export type ReaderLibraryPaginationInfo = {
  __typename?: 'ReaderLibraryPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ReaderLibraryPayload = {
  __typename?: 'ReaderLibraryPayload';
  readerLibrary: ReaderLibrary;
};

export type ReadImageFileUploadFormInput = {
  fileName: Scalars['String'];
};

export type ReadStemscopesMediaPackPayload = {
  __typename?: 'ReadStemscopesMediaPackPayload';
  pack: StemscopesMediaPack;
  items: Array<StemscopesMedia>;
};

export type RecentlyViewed = {
  __typename?: 'RecentlyViewed';
  displayedNames?: Maybe<Array<Scalars['String']>>;
  objectIds: Array<Scalars['String']>;
  projectIds: Array<Scalars['String']>;
};

export type RegisterAdoptionReviewerInput = {
  plainPassword: Scalars['String'];
  registrationToken: Scalars['String'];
  username: Scalars['String'];
};

export type RegisterAdoptionReviewerPayload = {
  __typename?: 'RegisterAdoptionReviewerPayload';
  districtName: Scalars['String'];
  eventUrlCode: Scalars['String'];
};

export type RegisterParentInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username: Scalars['String'];
  plainPassword: Scalars['String'];
  registrationToken: Scalars['String'];
};

export type RegisterPreviewAccountTeacherInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  plainPassword: Scalars['String'];
  registrationToken: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

/** Removes a Calendar. */
export type RemoveCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Removes a Calendar. */
export type RemoveCalendarPayload = {
  __typename?: 'removeCalendarPayload';
  calendar?: Maybe<Calendar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Removes a ClassTeacher. */
export type RemoveClassTeacherInput = {
  classId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  teacherId: Scalars['String'];
};

/** Removes a ClassTeacher. */
export type RemoveClassTeacherPayload = {
  __typename?: 'removeClassTeacherPayload';
  classTeacher?: Maybe<ClassTeacher>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Removes a Entry. */
export type RemoveEntryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Removes a Entry. */
export type RemoveEntryPayload = {
  __typename?: 'removeEntryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
};

/** Removes a PacingGuide. */
export type RemovePacingGuideInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Removes a PacingGuide. */
export type RemovePacingGuidePayload = {
  __typename?: 'removePacingGuidePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuide?: Maybe<PacingGuide>;
};

export type RemovePermissionInput = {
  id: Scalars['ID'];
  scopedPermission: ScopedPermissionInput;
};

/** Removes a Post. */
export type RemovePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  id: Scalars['String'];
};

/** Removes a Post. */
export type RemovePostPayload = {
  __typename?: 'removePostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
};

/** Removes a ScopePlan. */
export type RemoveScopePlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
};

/** Removes a ScopePlan. */
export type RemoveScopePlanPayload = {
  __typename?: 'removeScopePlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlan?: Maybe<ScopePlan>;
};

/** RemoveStudentFroms a Classes. */
export type RemoveStudentFromClassesInput = {
  classesIds: Scalars['Iterable'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentId: Scalars['String'];
};

/** RemoveStudentFroms a Classes. */
export type RemoveStudentFromClassesPayload = {
  __typename?: 'removeStudentFromClassesPayload';
  classes?: Maybe<Classes>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** RemoveStudentsFroms a Class. */
export type RemoveStudentsFromClassInput = {
  classId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentIds: Scalars['Iterable'];
};

/** RemoveStudentsFroms a Class. */
export type RemoveStudentsFromClassPayload = {
  __typename?: 'removeStudentsFromClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** RemoveStudentsFroms a StudentSubgroup. */
export type RemoveStudentsFromStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  studentIds: Scalars['Iterable'];
  subgroupId: Scalars['String'];
};

/** RemoveStudentsFroms a StudentSubgroup. */
export type RemoveStudentsFromStudentSubgroupPayload = {
  __typename?: 'removeStudentsFromStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<StudentSubgroup>;
};

/** RemoveTeachersFroms a StudentSubgroup. */
export type RemoveTeachersFromStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  subgroupId: Scalars['String'];
  teacherIds: Scalars['Iterable'];
};

/** RemoveTeachersFroms a StudentSubgroup. */
export type RemoveTeachersFromStudentSubgroupPayload = {
  __typename?: 'removeTeachersFromStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<StudentSubgroup>;
};

/** Reopen assignment input */
export type ReopenAssignmentInput = {
  assignmentId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  notes: Scalars['String'];
  studentIds: Array<Scalars['String']>;
};

/** Reopen District Assignment input */
export type ReopenDistrictAssignmentInput = {
  assignmentId: Scalars['String'];
  reopenDate: Scalars['ISO8601Date'];
  studentIds: Array<Scalars['String']>;
};

export type Report = Node & {
  __typename?: 'Report';
  _id: Scalars['String'];
  id: Scalars['ID'];
  signedRequest: Scalars['String'];
};

/** Report Signed Request type */
export type ReportSignedRequest = SignedRequestInterface & {
  __typename?: 'ReportSignedRequest';
  items?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  renderingType?: Maybe<Scalars['String']>;
  signedRequest: Scalars['JSON'];
  state?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** Report Sign Request Input */
export type ReportSignRequestInput = {
  domain?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<Scalars['String']>>;
  referenceId?: InputMaybe<Scalars['String']>;
  renderingType?: InputMaybe<AssignmentLearnosityRenderingType>;
  /** Data for field "reports" in sign */
  reports: Scalars['JSON'];
  schoolId?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<SignAssignmentServices>;
  sessionId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<AssignmentLearnosityState>;
  studentId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssignmentLearnosityType>;
};

/** Reset assignment input */
export type ResetAssignmentInput = {
  assignmentId: Scalars['String'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  notes?: InputMaybe<Scalars['String']>;
  studentIds: Array<Scalars['String']>;
};

export type ResetOnrampStartupTestInput = {
  studentIds: Array<Scalars['String']>;
  studentGrade: Scalars['String'];
  currentSchoolId: Scalars['String'];
};

/** ResetPasswordSetTimes a User. */
export type ResetPasswordSetTimeUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  passwordSetTime?: InputMaybe<Scalars['DateTime']>;
  userIds: Scalars['Iterable'];
};

/** ResetPasswordSetTimes a User. */
export type ResetPasswordSetTimeUserPayload = {
  __typename?: 'resetPasswordSetTimeUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ResetPreviewAccountActivityPeriodEndInput = {
  activityPeriodEnd?: InputMaybe<Scalars['DateTime']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ResetStudentStartupPayload = {
  __typename?: 'ResetStudentStartupPayload';
  status: Scalars['String'];
};

/** Resource Object Type */
export type Resource = {
  __typename?: 'Resource';
  classId: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  originLearnosityId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  resourceId: Scalars['String'];
  resourceSource: ResourceSource;
  schoolId?: Maybe<Scalars['String']>;
  status: AssignmentStatus;
  studentIds?: Maybe<Array<Scalars['String']>>;
  subgroupIds?: Maybe<Array<Scalars['String']>>;
};

/** ResourceSource Object Type */
export type ResourceSource = {
  __typename?: 'ResourceSource';
  element?: Maybe<Element>;
};

/** ResourceSourceInput */
export type ResourceSourceInput = {
  element?: InputMaybe<ElementInput>;
};

export type ResponseFeedback = {
  __typename?: 'ResponseFeedback';
  feedback: Scalars['String'];
  responseId: Scalars['String'];
};

/** Response feedback input */
export type ResponseFeedbackInput = {
  feedback: Scalars['String'];
  responseId: Scalars['String'];
};

/** RestrictInvitations a Parent. */
export type RestrictInvitationParentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
  studentId: Scalars['String'];
};

/** RestrictInvitations a Parent. */
export type RestrictInvitationParentPayload = {
  __typename?: 'restrictInvitationParentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parent?: Maybe<RestrictInvitationParentPayloadData>;
};

/** RestrictInvitations a Parent. */
export type RestrictInvitationParentPayloadData = Node & {
  __typename?: 'restrictInvitationParentPayloadData';
  id: Scalars['ID'];
};

/** Return assignment input */
export type ReturnAssignmentInput = {
  assignmentId: Scalars['String'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  notes: Scalars['String'];
  studentIds: Array<Scalars['String']>;
};

/** Use only for merging data! */
export type Role = Node & {
  __typename?: 'Role';
  code?: Maybe<RoleCodeEnum>;
  id: Scalars['ID'];
  name: Scalars['String'];
  policies: Array<AccessPolicy>;
  _id: Scalars['String'];
};

export enum RoleCodeEnum {
  Adoptions = 'Adoptions',
  AdoptionContentReviewer = 'AdoptionContentReviewer',
  AdoptionReviewer = 'AdoptionReviewer',
  CampusCurriculumLeader = 'CampusCurriculumLeader',
  CampusLeader = 'CampusLeader',
  ContentAuthor = 'ContentAuthor',
  CustomerSupport = 'CustomerSupport',
  DistrictAdmin = 'DistrictAdmin',
  DistrictCurriculumLeader = 'DistrictCurriculumLeader',
  DistrictLeader = 'DistrictLeader',
  Finance = 'Finance',
  Parent = 'Parent',
  PlatformAdmin = 'PlatformAdmin',
  Student = 'Student',
  SchoolAdmin = 'SchoolAdmin',
  SystemAdmin = 'SystemAdmin',
  Teacher = 'Teacher',
  WallWatcher = 'WallWatcher',
}

export type RolePageConnection = {
  __typename?: 'RolePageConnection';
  collection: Array<Role>;
  paginationInfo?: Maybe<RolePaginationInfo>;
};

export type RolePaginationInfo = {
  __typename?: 'RolePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RolePayload = {
  __typename?: 'RolePayload';
  role: Role;
};

export type S3UploadSignedUrl = Node & {
  __typename?: 's3UploadSignedUrl';
  _id: Scalars['String'];
  formAttributes: Scalars['Iterable'];
  formInputs: Scalars['Iterable'];
  id: Scalars['ID'];
};

/** Save grading session input */
export type SaveGradingSessionInput = {
  responseFeedbacks?: InputMaybe<Array<ResponseFeedbackInput>>;
  responses?: InputMaybe<Array<Scalars['JSON']>>;
  sessionId: Scalars['String'];
  userId: Scalars['String'];
};

export type School = Node & {
  __typename?: 'School';
  id: Scalars['ID'];
  /** Use only for merging data! */
  _id: Scalars['ID'];
  city?: Maybe<Scalars['String']>;
  district: District;
  /** @deprecated Use from District */
  districtId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  externalSystem?: Maybe<Scalars['String']>;
  globalName: Scalars['String'];
  localName: Scalars['String'];
  mathNationId?: Maybe<Scalars['String']>;
  /** @deprecated The field has been renamed to globalName */
  name: Scalars['String'];
  ncesId?: Maybe<Scalars['String']>;
  sisId?: Maybe<Scalars['String']>;
};

export type SchoolFilter = {
  city?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  districtName?: InputMaybe<Scalars['String']>;
  districtState?: InputMaybe<StateEnum>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SchoolFilter_Order>>>;
  rolesRelated?: InputMaybe<Scalars['Boolean']>;
};

export type SchoolFilter_Order = {
  city?: InputMaybe<SortEnum>;
  district_globalName?: InputMaybe<SortEnum>;
  district_state?: InputMaybe<SortEnum>;
  globalName?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
};

export type SchoolGradeLevels = {
  __typename?: 'SchoolGradeLevels';
  gradeLevels?: Maybe<Array<Scalars['String']>>;
  schoolId?: Maybe<Scalars['String']>;
};

export type SchoolGradeLevelsInput = {
  gradeLevels: Array<Scalars['String']>;
  schoolId: Scalars['String'];
};

/** Page connection for School. */
export type SchoolPageConnection = {
  __typename?: 'SchoolPageConnection';
  collection?: Maybe<Array<Maybe<School>>>;
  paginationInfo: SchoolPaginationInfo;
};

/** Information about the pagination. */
export type SchoolPaginationInfo = {
  __typename?: 'SchoolPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ScienceBotPayload = {
  __typename?: 'ScienceBotPayload';
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type Scope = {
  __typename?: 'Scope';
  active?: Maybe<Scalars['Boolean']>;
  banner: Scalars['String'];
  contentGroups: Array<Scalars['String']>;
  contentLocation: Scalars['String'];
  created: Scalars['String'];
  displayName: Scalars['String'];
  editionName: Scalars['String'];
  elementTitles: Array<Scalars['String']>;
  home: Scalars['JSON'];
  id: Scalars['String'];
  image: Scalars['String'];
  internalName: Scalars['String'];
  lastUpdated: Scalars['String'];
  launchUrl: Scalars['String'];
  metadata: Scalars['JSON'];
  platform: Scalars['String'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sections: Array<Scalars['JSON']>;
  state: Scalars['String'];
  tags: Array<Scalars['String']>;
  taxonomy: Array<Scalars['JSON']>;
  version: Scalars['Int'];
};

export type ScopedPermission = {
  __typename?: 'ScopedPermission';
  permission: Scalars['String'];
  scope: PermissionScope;
};

export type ScopedPermissionInput = {
  permission: Scalars['String'];
  scope: PermissionScope;
};

export type ScopeFilter = {
  curriculumArea?: InputMaybe<Array<Scalars['String']>>;
  editionName?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Array<Scalars['String']>>;
  instructionalDays?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Array<Scalars['String']>>;
};

export type ScopeGroup = {
  __typename?: 'ScopeGroup';
  id: Scalars['ID'];
  /** Use only for merging data! */
  _id: Scalars['ID'];
};

export type ScopePlan = Node & {
  __typename?: 'ScopePlan';
  _id: Scalars['String'];
  approachType?: Maybe<ScopePlanApproachEnum>;
  assignedTo?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  classSize?: Maybe<Scalars['Int']>;
  classTime?: Maybe<Scalars['Int']>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  curriculumArea?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  grade?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSelected?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pacingGuideId?: Maybe<Scalars['String']>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopePlanId?: Maybe<Scalars['String']>;
  scopePlanType?: Maybe<ScopePlanTypeEnum>;
  scopes?: Maybe<ScopePlanScopePageConnection>;
  searchName?: Maybe<Scalars['String']>;
  sharedBy?: Maybe<Scalars['String']>;
  sharedData?: Maybe<Scalars['Iterable']>;
  sharedOption?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  teacherExp?: Maybe<Scalars['Int']>;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type ScopePlanScopesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<ScopePlanScopeFilter>>>;
};

/** Scope Plan type: Adaptive / Traditional */
export enum ScopePlanApproachEnum {
  /** Adaptive Type */
  Adaptive = 'Adaptive',
  /** Traditional Type */
  Traditional = 'Traditional',
}

export type ScopePlanFilter = {
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  teamId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ScopePlanFilter_Order = {
  name?: InputMaybe<SortEnum>;
};

/** Page connection for ScopePlan. */
export type ScopePlanPageConnection = {
  __typename?: 'ScopePlanPageConnection';
  collection?: Maybe<Array<Maybe<ScopePlan>>>;
  paginationInfo: ScopePlanPaginationInfo;
};

/** Information about the pagination. */
export type ScopePlanPaginationInfo = {
  __typename?: 'ScopePlanPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ScopePlanScope = Node & {
  __typename?: 'ScopePlanScope';
  _id: Scalars['String'];
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  elements?: Maybe<Scalars['Iterable']>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionalDays?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopeId?: Maybe<Scalars['String']>;
  scopePlanId?: Maybe<Scalars['String']>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  type: EntryTypeEnum;
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

/** Actions: Add / Remove */
export enum ScopePlanScopeElementActionTypeEnum {
  /** Action Type Add */
  Add = 'Add',
  /** Action Type Remove */
  Remove = 'Remove',
}

export type ScopePlanScopeFilter = {
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scopeId?: InputMaybe<Scalars['String']>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Page connection for ScopePlanScope. */
export type ScopePlanScopePageConnection = {
  __typename?: 'ScopePlanScopePageConnection';
  collection?: Maybe<Array<Maybe<ScopePlanScope>>>;
  paginationInfo: ScopePlanScopePaginationInfo;
};

/** Information about the pagination. */
export type ScopePlanScopePaginationInfo = {
  __typename?: 'ScopePlanScopePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** Scope Plan type: Individual / Team / Template */
export enum ScopePlanTypeEnum {
  /** Individual Type */
  Individual = 'Individual',
  /** Team Type */
  Team = 'Team',
  /** Template Type */
  Template = 'Template',
}

export type ScopePrintDownloadInput = {
  /** Id of the scope which user tries to print/download */
  scopeId?: Scalars['String'];
  /** Type of the request */
  eventType?: InputMaybe<ScopePrintDownloadTypeEnum>;
};

export type ScopePrintDownloadPayload = {
  __typename?: 'ScopePrintDownloadPayload';
  scopeId: Scalars['String'];
};

export enum ScopePrintDownloadTypeEnum {
  Print = 'Print',
  Download = 'Download',
}

export type Scopes = {
  __typename?: 'Scopes';
  collection: Array<SearchableScope>;
  pageInfo: PageInfo;
};

export type SearchableElement = {
  __typename?: 'SearchableElement';
  contentGroups: Array<Scalars['String']>;
  curriculumArea?: Maybe<Scalars['String']>;
  editionName: Scalars['String'];
  elementFiles?: Maybe<Array<Scalars['JSON']>>;
  gradeLevel?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  metadata: Scalars['JSON'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  resourceDefault: Scalars['Boolean'];
  scopeId: Scalars['String'];
  scopeName: Scalars['String'];
  sectionId: Scalars['String'];
  sectionName: Scalars['String'];
  sectionType: Scalars['String'];
  studentView?: Maybe<Array<Scalars['JSON']>>;
  teacherView?: Maybe<Array<Scalars['JSON']>>;
};

export type SearchableMathNationScope = {
  __typename?: 'SearchableMathNationScope';
  category: Scalars['String'];
  contentAudience: AudienceEnum;
  contentGroups?: Maybe<Array<Scalars['String']>>;
  displayName: Scalars['String'];
  grade: Scalars['String'];
  id: Scalars['String'];
  projectIdentifiers?: Maybe<Scalars['JSON']>;
  projectTitles?: Maybe<Scalars['JSON']>;
};

export type SearchableScope = {
  __typename?: 'SearchableScope';
  contentGroups: Array<Scalars['String']>;
  displayName: Scalars['String'];
  editionName: Scalars['String'];
  elementTaxonomy?: Maybe<Array<Scalars['JSON']>>;
  elementTitles: Array<Scalars['String']>;
  id: Scalars['String'];
  image: Scalars['String'];
  internalName: Scalars['String'];
  metadata: Scalars['JSON'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sections: Array<Scalars['JSON']>;
  taxonomy: Array<Scalars['JSON']>;
};

export type SearchableTeacherResource = {
  __typename?: 'SearchableTeacherResource';
  contentGroups: Array<Scalars['String']>;
  displayName: Scalars['String'];
  elementTitles: Array<Scalars['String']>;
  id: Scalars['String'];
  internalName: Scalars['String'];
  metadata: Scalars['JSON'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sectionTitles: Array<Scalars['JSON']>;
};

export enum SetupStatusEnum {
  Activated = 'activated',
  Completed = 'completed',
  NotStarted = 'notStarted',
}

export type ShareAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlShareAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesShareAssessmentInput>;
};

/** Shares a Calendar. */
export type ShareCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  shareType: Scalars['String'];
  userIds: Scalars['Iterable'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Shares a Calendar. */
export type ShareCalendarPayload = {
  __typename?: 'shareCalendarPayload';
  calendar?: Maybe<Calendar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum ShareResults {
  Disabled = 'DISABLED',
  WithoutCorrectAnswers = 'WITHOUT_CORRECT_ANSWERS',
  WithCorrectAnswers = 'WITH_CORRECT_ANSWERS',
}

/** ShareTemplates a PacingGuide. */
export type ShareTemplatePacingGuideInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  sharedData: Scalars['Iterable'];
  sharedOption: Scalars['String'];
  sharedType: Scalars['String'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** ShareTemplates a PacingGuide. */
export type ShareTemplatePacingGuidePayload = {
  __typename?: 'shareTemplatePacingGuidePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuide?: Maybe<PacingGuide>;
};

/** ShareTemplates a ScopePlan. */
export type ShareTemplateScopePlanInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  sharedData: Scalars['Iterable'];
  sharedOption: Scalars['String'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** ShareTemplates a ScopePlan. */
export type ShareTemplateScopePlanPayload = {
  __typename?: 'shareTemplateScopePlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlan?: Maybe<ScopePlan>;
};

export enum SignAssignmentServices {
  Assess = 'assess',
  Author = 'author',
  Data = 'data',
  Events = 'events',
  Items = 'items',
  Questions = 'questions',
  Reports = 'reports',
}

export type SignedRequest = Node & {
  __typename?: 'SignedRequest';
  _id: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  mode: Scalars['String'];
  referenceId: Scalars['String'];
  signedRequest: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

/** Signed Request Interface */
export type SignedRequestInterface = {
  items?: Maybe<Array<Scalars['String']>>;
  referenceId?: Maybe<Scalars['String']>;
  renderingType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Signed Request Union */
export type SignedRequestUnion = ItemSignedRequest | ReportSignedRequest;

/** Action for sign */
export type SignRequestActionInput = {
  startNewAttempt: Scalars['Boolean'];
};

/** Sign Request Input */
export type SignRequestInput = {
  itemSignedRequestInput?: InputMaybe<ItemSignRequestInput>;
  reportSignedRequestInput?: InputMaybe<ReportSignRequestInput>;
};

export type SignRequestPayload = {
  __typename?: 'SignRequestPayload';
  signedRequest: Scalars['String'];
};

export type SignRequestStartUpPayload = {
  __typename?: 'SignRequestStartUpPayload';
  subject: Scalars['String'];
  startupCompleted: Scalars['Boolean'];
  signedRequest: Scalars['String'];
};

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort type: ASC / DESC */
export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortEnumMissingLast {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortFieldEnum {
  Created = 'CREATED',
  Updated = 'UPDATED',
}

export type SortInput = {
  direction: SortDirectionEnum;
  field: SortFieldEnum;
};

export type Sso = {
  __typename?: 'Sso';
  districtId: Scalars['String'];
  oauth?: Maybe<SsoOauth>;
  saml?: Maybe<SsoSaml>;
  type: SsoTypeEnum;
  policies: Array<SsoPolicy>;
};

export type SsoInput = {
  oauth?: InputMaybe<SsoOauthInput>;
  saml?: InputMaybe<SsoSamlInput>;
  ssoKey: SsoKeyInput;
};

export type SsoKeyInput = {
  districtId: Scalars['String'];
  type: SsoTypeEnum;
};

export type SsoListInput = {
  districtId?: InputMaybe<Scalars['String']>;
};

export type SsoOauth = {
  __typename?: 'SsoOauth';
  baseUrl?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  clientSecret?: Maybe<Scalars['String']>;
};

export type SsoOauthInput = {
  clientId: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  baseUrl?: InputMaybe<Scalars['String']>;
};

export type SsoPolicy = {
  __typename?: 'SsoPolicy';
  externalField?: Maybe<ExternalFieldEnum>;
  internalFields: Array<SsoPolicyInternalField>;
  name: PolicyNameEnum;
  sequence: Scalars['Int'];
  userType: SsoUserTypeEnum;
};

export type SsoPolicyInternalField = {
  __typename?: 'SsoPolicyInternalField';
  name?: Maybe<InternalFieldEnum>;
  value: InternalFieldEnum;
};

export type SsoSaml = {
  __typename?: 'SsoSaml';
  idpEntityId: Scalars['String'];
  idpSingleSingOnUrl: Scalars['String'];
  idpSingleLogoutUrl: Scalars['String'];
  idpX509Cert: Scalars['String'];
};

export type SsoSamlInput = {
  idpEntityId: Scalars['String'];
  idpSingleSingOnUrl: Scalars['String'];
  idpSingleLogoutUrl: Scalars['String'];
  idpX509Cert: Scalars['String'];
};

export enum SsoTypeEnum {
  Oauth2Canvas = 'OAUTH2_CANVAS',
  Oauth2Classlink = 'OAUTH2_CLASSLINK',
  Oauth2Clever = 'OAUTH2_CLEVER',
  OauthCanvas = 'OAUTH_CANVAS',
  SamlClasslink = 'SAML_CLASSLINK',
  SamlGoogle = 'SAML_GOOGLE',
  SamlMicrosoft = 'SAML_MICROSOFT',
}

export enum SsoUserTypeEnum {
  Staff = 'STAFF',
  Student = 'STUDENT',
}

export type SsoWithPoliciesInput = {
  policies?: InputMaybe<Array<PolicyInput>>;
  oauth?: InputMaybe<SsoOauthInput>;
  saml?: InputMaybe<SsoSamlInput>;
  ssoKey: SsoKeyInput;
};

export type SsQuestionContainerInput = {
  category: Scalars['String'];
  grade: Scalars['String'];
  lesson: Scalars['String'];
  unit: Scalars['String'];
};

export type SsStandards = Node & {
  __typename?: 'ssStandards';
  _id: Scalars['String'];
  id: Scalars['ID'];
  /** A token used for paginating through results. Pass this token in subsequent requests to retrieve the next page of results. */
  nextToken: Scalars['String'];
  resultSetJson: Scalars['String'];
};

export type SsStandards2 = Node & {
  __typename?: 'ssStandards2';
  _id: Scalars['String'];
  id: Scalars['ID'];
  /** A token used for paginating through results. Pass this token in subsequent requests to retrieve the next page of results. */
  nextToken: Scalars['String'];
  resultSetJson: Scalars['String'];
};

export type SsStandardsItem = Node & {
  __typename?: 'ssStandardsItem';
  id: Scalars['ID'];
  standard: Scalars['String'];
};

/** Contains types to be used for filtering. */
export type SsStandardsItemFilter = {
  /** Filter by assignment id */
  assignmentId?: InputMaybe<Scalars['String']>;
  /** Filter by class id(s) with an array */
  classIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter by teacher id(s) with an array */
  teacherIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** SsStandardsSort type: studentName by ASC or DESC */
export type SsStandardsSort = {
  /** sort by studentName asc or desc */
  studentName?: InputMaybe<SortEnum>;
};

export type Standard = {
  __typename?: 'Standard';
  id: Scalars['String'];
  state: Scalars['String'];
  grade: Scalars['String'];
  standard: Scalars['String'];
  sourceDocument: Scalars['String'];
  subject: Scalars['String'];
  parent: Scalars['String'];
  description: Scalars['String'];
  itemsBack?: Maybe<Array<Scalars['String']>>;
  itemsForward?: Maybe<Array<Scalars['String']>>;
  hasNoKids?: Maybe<Scalars['Boolean']>;
};

export type StandardListInput = {
  domainInput?: InputMaybe<Scalars['String']>;
  gradeInput?: InputMaybe<Scalars['String']>;
  standardInput?: InputMaybe<Scalars['String']>;
  currentSchoolId?: InputMaybe<Scalars['String']>;
  isPlatformAdmin?: Scalars['Boolean'];
};

export type StandardListPayload = {
  __typename?: 'StandardListPayload';
  standards: Scalars['String'];
};

export type StandardPayload = {
  __typename?: 'StandardPayload';
  color: Scalars['String'];
  desc: Scalars['String'];
  id: Scalars['Int'];
  xCoordinate: Scalars['Int'];
  yCoordinate: Scalars['Int'];
  standard: Scalars['String'];
};

export type Standards = {
  __typename?: 'Standards';
  description?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
  standardId: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** Params for CYU/SS/TY Assignments */
export type StartMnPersonalAssignmentInput = {
  assignmentSource: AssignmentSourceInput;
  assignmentType: MnPersonalAssignmentType;
  devDomain?: InputMaybe<Scalars['String']>;
  signItem: ItemSignRequestInput;
};

export enum StateEnum {
  Al = 'AL',
  Ak = 'AK',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
}

export enum StatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type StatusPayload = {
  __typename?: 'StatusPayload';
  success: Scalars['Boolean'];
};

export type StemscopesAssessmentInput = {
  field: FieldEnum;
  value: Scalars['String'];
  currentSchoolId: Scalars['String'];
};

export type StemscopesAssessmentItemPayload = {
  __typename?: 'StemscopesAssessmentItemPayload';
  isArchived?: Maybe<Scalars['Boolean']>;
  isAssigned?: Maybe<Scalars['Boolean']>;
  isEdited?: Maybe<Scalars['Boolean']>;
  isPrintable?: Maybe<Scalars['Boolean']>;
  isShared?: Maybe<Scalars['Boolean']>;
  activityType: ActivityTypeEnum;
  createdAt: Scalars['String'];
  deliveries: Array<DeliveryEnum>;
  hasManuallyGradedQuestions?: Maybe<Scalars['Boolean']>;
  hiddenForSchool: Scalars['Boolean'];
  library?: Maybe<StemscopesLibraryEnum>;
  status: Scalars['String'];
  subPurpose?: Maybe<SubPurposeEnum>;
  teams: Array<Scalars['String']>;
  updatedAt: Scalars['String'];
  usersViewOnlyIds: Array<Scalars['String']>;
  id: Scalars['String'];
  createdBy?: Maybe<UserPayload>;
  title?: Maybe<Scalars['String']>;
  standards: Array<Scalars['String']>;
  items: Array<Scalars['String']>;
};

export type StemscopesAssessmentPackageBoxItemPayload = {
  __typename?: 'StemscopesAssessmentPackageBoxItemPayload';
  assessments: Array<Scalars['String']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type StemscopesAssessmentPackageBoxPayload = {
  __typename?: 'StemscopesAssessmentPackageBoxPayload';
  item?: Maybe<StemscopesAssessmentPackageBoxItemPayload>;
};

export type StemscopesAssessmentPayload = {
  __typename?: 'StemscopesAssessmentPayload';
  item?: Maybe<StemscopesAssessmentItemPayload>;
};

export type StemscopesAssessmentPoolInput = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  libraries: Array<StemscopesLibraryEnum>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  currentSchoolId: Scalars['String'];
};

export type StemscopesAssessmentPoolPayload = {
  __typename?: 'StemscopesAssessmentPoolPayload';
  list: Array<StemscopesAssessmentItemPayload>;
  paginationInfo: PaginationInfoPayload;
};

/** StemscopesAssignment Object Type */
export type StemscopesAssignment = {
  __typename?: 'StemscopesAssignment';
  /** @deprecated Has been deprecated, use Types::AssignmentSourceType instead */
  assessmentId?: Maybe<Scalars['String']>;
  assignmentId: Scalars['String'];
  assignmentListId: Scalars['String'];
  assignmentSource: AssignmentSource;
  calculator?: Maybe<Calculator>;
  canBePaused?: Maybe<Scalars['Boolean']>;
  classId: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  immediatelyShareResults?: Maybe<ImmediatelyShareResults>;
  labels?: Maybe<Array<Scalars['String']>>;
  lateTurnIn?: Maybe<Scalars['Boolean']>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  originLearnosityId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  releaseFeedback?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
  startDate: Scalars['ISO8601Date'];
  status: AssignmentStatus;
  studentAssignments?: Maybe<Array<StudentAssignment>>;
  studentIds?: Maybe<Array<Scalars['String']>>;
  subgroupIds?: Maybe<Array<Scalars['String']>>;
};

/** Assignment page info input */
export type StemscopesAssignmentPageInfoInput = {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type StemscopesCopyAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

export type StemscopesCreateAssessmentInput = {
  deliveries: Array<DeliveryEnum>;
  isArchived?: Scalars['Boolean'];
  isAssigned?: Scalars['Boolean'];
  isVisible?: Scalars['Boolean'];
  items?: InputMaybe<Array<Scalars['String']>>;
  library: StemscopesLibraryEnum;
  teams?: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type StemscopesCreateAssessmentPackageBoxInput = {
  assessments?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

/** Attributes for creating stemscopes assignment */
export type StemscopesCreateInput = {
  assignmentSource: AssignmentSourceInput;
  calculator?: InputMaybe<CalculatorInput>;
  canBePaused?: InputMaybe<Scalars['Boolean']>;
  classOtherAssignments?: InputMaybe<Array<ClassOtherAssignment>>;
  classes: Array<AssignmentClassInput>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  immediatelyShareResults?: InputMaybe<ImmediatelyShareResults>;
  labels: Array<Scalars['String']>;
  lateTurnIn: Scalars['Boolean'];
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  notes: Scalars['String'];
  releaseFeedback: Scalars['Boolean'];
  schoolId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export type StemscopesDeleteAssessmentInput = {
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

/** Attributes for editing stemscopes assignment */
export type StemscopesEditInput = {
  assignmentId: Scalars['String'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export enum StemscopesLibraryEnum {
  Individual = 'INDIVIDUAL',
  Team = 'TEAM',
  District = 'DISTRICT',
  Campus = 'CAMPUS',
  Shared = 'SHARED',
  Benchmark = 'BENCHMARK',
}

export type StemscopesMedia = {
  __typename?: 'StemscopesMedia';
  area: StemscopesVideoAreaEnum;
  id: Scalars['String'];
  language: LanguageEnum;
  quality?: Maybe<VideoQualityEnum>;
  title: Scalars['String'];
  type: MediaTypeEnum;
  uniquePackTitle?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  subtitlesSrtFormat: Scalars['Boolean'];
};

export type StemscopesMediaFilterInput = {
  area?: InputMaybe<StemscopesVideoAreaEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type StemscopesMediaPack = {
  __typename?: 'StemscopesMediaPack';
  area: StemscopesVideoAreaEnum;
  id: Scalars['String'];
  title: Scalars['String'];
  uniqueItemTitles?: Maybe<Array<Scalars['String']>>;
};

export type StemscopesMediaPackFilterInput = {
  area?: InputMaybe<StemscopesVideoAreaEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type StemscopesMediaPackPageConnection = {
  __typename?: 'StemscopesMediaPackPageConnection';
  collection: Array<StemscopesMediaPack>;
  paginationInfo?: Maybe<StemscopesMediaPackPaginationInfo>;
};

export type StemscopesMediaPackPaginationInfo = {
  __typename?: 'StemscopesMediaPackPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StemscopesMediaPageConnection = {
  __typename?: 'StemscopesMediaPageConnection';
  collection: Array<StemscopesMedia>;
  paginationInfo?: Maybe<StemscopesMediaPaginationInfo>;
};

export type StemscopesMediaPaginationInfo = {
  __typename?: 'StemscopesMediaPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StemscopesMediaPayload = {
  __typename?: 'StemscopesMediaPayload';
  media: StemscopesMedia;
};

export type StemscopesMediaPostObjectPayload = {
  __typename?: 'StemscopesMediaPostObjectPayload';
  subtitlesPostObject?: Maybe<MediaPostObject>;
  videoPostObject: MediaPostObject;
};

export type StemscopesQuestionPoolInput = {
  assessmentId?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Array<Scalars['String']>>;
  deliveries?: InputMaybe<Array<DeliveryEnum>>;
  editions?: InputMaybe<Array<Scalars['String']>>;
  excludeIds?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  gradingTypes?: InputMaybe<Array<GradingTypeEnum>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  questionBanks?: InputMaybe<Array<QuestionBankEnum>>;
  questionTypes?: InputMaybe<Array<Scalars['String']>>;
  scopes?: InputMaybe<Array<Scalars['String']>>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  standards?: InputMaybe<Array<Scalars['String']>>;
};

export type StemscopesShareAssessmentInput = {
  isViewOnly: Scalars['Boolean'];
  userIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
};

export type StemscopesUpdateAssessmentInput = {
  id: Scalars['ID'];
  deliveries: Array<DeliveryEnum>;
  isArchived?: Scalars['Boolean'];
  isAssigned?: Scalars['Boolean'];
  isVisible?: Scalars['Boolean'];
  items?: InputMaybe<Array<Scalars['String']>>;
  library: StemscopesLibraryEnum;
  teams?: Array<Scalars['String']>;
  title: Scalars['String'];
};

export enum StemscopesVideoAreaEnum {
  Science = 'Science',
  Math = 'Math',
}

export type Streaming = {
  __typename?: 'Streaming';
  contentGroups?: Maybe<Array<Scalars['String']>>;
  contentType: Scalars['String'];
  coverImage: Scalars['String'];
  curriculumArea?: Maybe<Scalars['String']>;
  grades?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  metadata: Scalars['JSON'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  studentView?: Maybe<Scalars['JSON']>;
  tags?: Maybe<Array<Scalars['String']>>;
  teacherView?: Maybe<Scalars['JSON']>;
  topic?: Maybe<Scalars['String']>;
};

export type StreamingContentTypes = {
  __typename?: 'StreamingContentTypes';
  contentTypes?: Maybe<Array<Scalars['String']>>;
};

export type StreamingCurriculumAreas = {
  __typename?: 'StreamingCurriculumAreas';
  curriculumAreas?: Maybe<Array<Scalars['String']>>;
};

export type StreamingFilter = {
  contentTypes?: InputMaybe<Array<Scalars['String']>>;
  topics?: InputMaybe<Array<Scalars['String']>>;
  curriculumAreas?: InputMaybe<Array<Scalars['String']>>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type StreamingGrades = {
  __typename?: 'StreamingGrades';
  grades?: Maybe<Array<Scalars['String']>>;
};

export type Streamings = {
  __typename?: 'Streamings';
  collection?: Maybe<Array<Streaming>>;
  pageInfo?: Maybe<PageInfo>;
};

export type StreamingTags = {
  __typename?: 'StreamingTags';
  tags?: Maybe<Array<Scalars['String']>>;
};

export type StreamingTopics = {
  __typename?: 'StreamingTopics';
  topics?: Maybe<Array<Scalars['String']>>;
};

export type StreamingVideo = {
  __typename?: 'StreamingVideo';
  date?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: LanguageEnum;
  quality?: Maybe<VideoQualityEnum>;
  title: Scalars['String'];
  type: MediaTypeEnum;
  url: Scalars['String'];
  active: Scalars['Boolean'];
};

export type StreamingVideoFilterInput = {
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type StreamingVideoPageConnection = {
  __typename?: 'StreamingVideoPageConnection';
  collection: Array<StreamingVideo>;
  paginationInfo?: Maybe<StreamingVideoPaginationInfo>;
};

export type StreamingVideoPaginationInfo = {
  __typename?: 'StreamingVideoPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StreamingVideoPayload = {
  __typename?: 'StreamingVideoPayload';
  video: StreamingVideo;
};

export type StringPageConnection = {
  __typename?: 'StringPageConnection';
  collection: Array<Scalars['String']>;
  paginationInfo?: Maybe<StringPaginationInfo>;
};

export type StringPaginationInfo = {
  __typename?: 'StringPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Student = Node & {
  __typename?: 'Student';
  _id: Scalars['String'];
  classes?: Maybe<ClassPageConnection>;
  subgroups?: Maybe<StudentSubgroupPageConnection>;
  active: Scalars['Boolean'];
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdByUsername?: Maybe<Scalars['String']>;
  curriculumAreas?: Maybe<Scalars['Iterable']>;
  district?: Maybe<District>;
  /** @deprecated Used from Session User */
  districtUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gradeLevel?: Maybe<GradeLevelEnum>;
  id: Scalars['ID'];
  importId?: Maybe<Scalars['String']>;
  inactivityReason?: Maybe<UserInactivityReasonEnum>;
  language: LanguageEnum;
  lastName?: Maybe<Scalars['String']>;
  lmsId?: Maybe<Scalars['String']>;
  manualMaintenance?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  plainPassword?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  purpose?: Maybe<UserPurposeEnum>;
  sisId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type: UserTypeEnum;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByUsername?: Maybe<Scalars['String']>;
  userRoles?: Maybe<UserRolePageConnection>;
  username: Scalars['String'];
};

export type StudentClassesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Array<InputMaybe<ClassFilter_Order>>>;
  hasLmsId?: InputMaybe<Scalars['Boolean']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusEnum>;
  studentsId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InputMaybe<ClassFilter>>>;
};

export type StudentSubgroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type StudentUserRolesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

/** Student Assignment Object Type */
export type StudentAssignment = {
  __typename?: 'StudentAssignment';
  answers?: Maybe<Scalars['JSON']>;
  archived?: Maybe<Scalars['Boolean']>;
  assessmentId?: Maybe<Scalars['String']>;
  assignmentId: Scalars['String'];
  assignmentSource?: Maybe<AssignmentSource>;
  classId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lateTurnIn: Scalars['Boolean'];
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originLearnosityId?: Maybe<Scalars['String']>;
  otherAssignmentScope?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  randomizeQuestionsOrder?: Maybe<Scalars['Boolean']>;
  releaseFeedback: Scalars['Boolean'];
  reopenedAt?: Maybe<Scalars['ISO8601DateTime']>;
  resetted?: Maybe<Scalars['Boolean']>;
  returnedAt?: Maybe<Scalars['ISO8601DateTime']>;
  shareResults?: Maybe<ShareResults>;
  startDate?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status: StudentAssignmentStatus;
  studentId: Scalars['String'];
  studentNotes?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type StudentAssignmentIdUpdatePayload = {
  __typename?: 'StudentAssignmentIdUpdatePayload';
  status: Scalars['String'];
};

export type StudentAssignmentMock = {
  __typename?: 'StudentAssignmentMock';
  assignmentId: Scalars['String'];
  name: Scalars['String'];
  completedAt: Scalars['ISO8601DateTime'];
  grade: Scalars['Int'];
  startedAt: Scalars['ISO8601DateTime'];
  status: StudentAssignmentStatusEnumMock;
  submittedAt: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  startDate: Scalars['ISO8601DateTime'];
  saId: Scalars['String'];
};

export type StudentAssignmentMockPageConnection = {
  __typename?: 'StudentAssignmentMockPageConnection';
  collection: Array<StudentAssignmentMock>;
  paginationInfo?: Maybe<StudentAssignmentMockPaginationInfo>;
};

export type StudentAssignmentMockPaginationInfo = {
  __typename?: 'StudentAssignmentMockPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StudentAssignmentPayload = {
  __typename?: 'StudentAssignmentPayload';
  assignment_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  gdst?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Student Assignments collection object Type */
export type StudentAssignments = {
  __typename?: 'StudentAssignments';
  collection?: Maybe<Array<StudentAssignment>>;
  pageInfo?: Maybe<PageInfo>;
};

export type StudentAssignmentsFilter = {
  archived?: InputMaybe<Scalars['Boolean']>;
  assignmentId?: InputMaybe<Scalars['String']>;
  classId?: InputMaybe<Scalars['String']>;
  otherAssignment?: InputMaybe<Scalars['Boolean']>;
  otherAssignmentScope?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StudentAssignmentStatus>;
  studentId?: InputMaybe<Scalars['String']>;
  studentShareResults?: InputMaybe<Scalars['Boolean']>;
};

export enum StudentAssignmentStatus {
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Started = 'STARTED',
  Submitted = 'SUBMITTED',
}

export enum StudentAssignmentStatusEnumMock {
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Started = 'STARTED',
}

export type StudentAssignmentStatusPayload = {
  __typename?: 'StudentAssignmentStatusPayload';
  status: Scalars['String'];
};

export type StudentCertificateInput = {
  studentId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type StudentCertificatePayload = {
  __typename?: 'StudentCertificatePayload';
  path?: Maybe<Scalars['String']>;
};

export type StudentCompletedTopicPayload = {
  __typename?: 'StudentCompletedTopicPayload';
  assignmentId: Scalars['String'];
  domainName: Scalars['String'];
  domainNo: Scalars['String'];
  gdst: Scalars['String'];
  status: Scalars['String'];
  topic: Scalars['String'];
};

export type StudentFilter_Order = {
  className?: InputMaybe<SortEnum>;
  fullName?: InputMaybe<SortEnum>;
};

/** Student Notebook Object Type */
export type StudentNotebook = {
  __typename?: 'StudentNotebook';
  classId: Scalars['String'];
  scopeIds?: Maybe<Array<Scalars['String']>>;
};

/** Student Notebooks collection Object Type */
export type StudentNotebooks = {
  __typename?: 'StudentNotebooks';
  collection?: Maybe<Array<StudentNotebook>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Page connection for Student. */
export type StudentPageConnection = {
  __typename?: 'StudentPageConnection';
  collection?: Maybe<Array<Maybe<Student>>>;
  paginationInfo: StudentPaginationInfo;
};

/** Information about the pagination. */
export type StudentPaginationInfo = {
  __typename?: 'StudentPaginationInfo';
  hasNextPage: Scalars['Boolean'];
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type StudentPayload = {
  __typename?: 'StudentPayload';
  itemReferenceIds: Array<Scalars['String']>;
  learnosityActivityId: Scalars['String'];
  learnosityAssignmentId: Scalars['String'];
  sessionId: Scalars['String'];
  studentCompletedTopics: Array<StudentCompletedTopicPayload>;
  studentProgress: Array<StudentProgressPayload>;
  continueNextGrade: Scalars['Boolean'];
  isRedirect: Scalars['Boolean'];
  assessmentCount?: Maybe<Scalars['Int']>;
  startupCompleteDate?: Maybe<Scalars['String']>;
  startupIsReview: Scalars['Boolean'];
  startupStatus?: Maybe<Scalars['String']>;
  studentPathwayCount: Scalars['Int'];
};

export type StudentProgressPayload = {
  __typename?: 'StudentProgressPayload';
  code: Scalars['String'];
  domain: Scalars['String'];
  topic: Scalars['String'];
};

/** StudentResource Object Type */
export type StudentResource = {
  __typename?: 'StudentResource';
  classId?: Maybe<Scalars['String']>;
  learnosityReferenceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originLearnosityId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  resourceId: Scalars['String'];
  resourceSource?: Maybe<ResourceSource>;
  schoolId?: Maybe<Scalars['String']>;
  srGrade?: Maybe<Scalars['String']>;
  srId: Scalars['String'];
  srStatus: StudentResourceStatus;
  studentId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

/** StudentResources Object Type */
export type StudentResources = {
  __typename?: 'StudentResources';
  collection?: Maybe<Array<StudentResource>>;
  defaultElements?: Maybe<Array<DefaultElement>>;
  pageInfo?: Maybe<PageInfo>;
};

export type StudentResourcesFilter = {
  classId?: InputMaybe<Scalars['String']>;
  scopeId?: InputMaybe<Scalars['String']>;
};

/** Params to save student resources filter */
export type StudentResourcesFilterInput = {
  gradeLevelsByGrade?: InputMaybe<GradeLevelsByGradeInput>;
  individual?: InputMaybe<Scalars['Boolean']>;
};

/** Student resources filter object */
export type StudentResourcesSettings = {
  __typename?: 'StudentResourcesSettings';
  gradeLevelsByGrade?: Maybe<GradeLevelsByGrade>;
  individual?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
};

export enum StudentResourceStatus {
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Started = 'STARTED',
}

export type StudentResponsePayload = {
  __typename?: 'StudentResponsePayload';
  response: Scalars['String'];
  status: Scalars['String'];
};

/** Student resources filters collection */
export type StudentsResourcesSettings = {
  __typename?: 'StudentsResourcesSettings';
  collection?: Maybe<Array<StudentResourcesSettings>>;
};

export type StudentSubgroup = Node & {
  __typename?: 'StudentSubgroup';
  _id: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  students?: Maybe<StudentSubgroupPageConnection>;
  teachers?: Maybe<StudentSubgroupPageConnection>;
};

export type StudentSubgroupStudentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type StudentSubgroupTeachersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

/** Page connection for StudentSubgroup. */
export type StudentSubgroupPageConnection = {
  __typename?: 'StudentSubgroupPageConnection';
  collection?: Maybe<Array<Maybe<StudentSubgroup>>>;
  paginationInfo: StudentSubgroupPaginationInfo;
};

/** Information about the pagination. */
export type StudentSubgroupPaginationInfo = {
  __typename?: 'StudentSubgroupPaginationInfo';
  hasNextPage: Scalars['Boolean'];
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type SubjectPayload = {
  __typename?: 'SubjectPayload';
  id: Scalars['String'];
};

export enum SubPurposeEnum {
  StemscopesMath = 'STEMSCOPES_MATH',
  StemscopesScience = 'STEMSCOPES_SCIENCE',
}

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['ID'];
  products: Array<Product>;
  dateStart: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
  quantity: Scalars['Int'];
};

export type SubscriptionPackage = {
  __typename?: 'SubscriptionPackage';
  id: Scalars['ID'];
  /** Use only for merging data! */
  _id: Scalars['ID'];
  schools: Array<School>;
  scope: SubscriptionScopeEnum;
  subscriptions: Array<Subscription>;
};

export type SubscriptionPackageFilterInput = {
  districtId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  schoolIds?: InputMaybe<Array<Scalars['ID']>>;
  state?: InputMaybe<Scalars['String']>;
};

export type SubscriptionPackagePageConnection = {
  __typename?: 'SubscriptionPackagePageConnection';
  collection: Array<SubscriptionPackage>;
  paginationInfo?: Maybe<SubscriptionPackagePaginationInfo>;
};

export type SubscriptionPackagePaginationInfo = {
  __typename?: 'SubscriptionPackagePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubscriptionPackagePayload = {
  __typename?: 'SubscriptionPackagePayload';
  subscriptionPackage: SubscriptionPackage;
};

export type SubscriptionPageConnection = {
  __typename?: 'SubscriptionPageConnection';
  collection: Array<Subscription>;
  paginationInfo?: Maybe<SubscriptionPaginationInfo>;
};

export type SubscriptionPaginationInfo = {
  __typename?: 'SubscriptionPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload';
  subscription: Subscription;
};

export enum SubscriptionScopeEnum {
  School = 'School',
  District = 'District',
}

/** Success Object Type */
export type Success = {
  __typename?: 'Success';
  success?: Maybe<Scalars['Boolean']>;
};

export type SuccessPayload = {
  __typename?: 'SuccessPayload';
  isSuccess: Scalars['Boolean'];
};

export type SystemAdmin = {
  __typename?: 'SystemAdmin';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SystemAdminPageConnection = {
  __typename?: 'SystemAdminPageConnection';
  collection: Array<SystemAdmin>;
  paginationInfo?: Maybe<SystemAdminPaginationInfo>;
};

export type SystemAdminPaginationInfo = {
  __typename?: 'SystemAdminPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TagFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type TagPageConnection = {
  __typename?: 'TagPageConnection';
  collection: Array<Tag>;
  paginationInfo?: Maybe<TagPaginationInfo>;
};

export type TagPaginationInfo = {
  __typename?: 'TagPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TagPayload = {
  __typename?: 'TagPayload';
  name: Scalars['String'];
  value: Scalars['String'];
  tag: Tag;
};

export type TagValue = {
  __typename?: 'TagValue';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TagValueFilterInput = {
  tagId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type TagValuePageConnection = {
  __typename?: 'TagValuePageConnection';
  collection: Array<TagValue>;
  paginationInfo?: Maybe<TagValuePaginationInfo>;
};

export type TagValuePaginationInfo = {
  __typename?: 'TagValuePaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TagValuePayload = {
  __typename?: 'TagValuePayload';
  tagValue: TagValue;
};

export enum TccConsumerEnum {
  Canvas = 'CANVAS',
  Infinitecampus = 'INFINITECAMPUS',
  Itslearning = 'ITSLEARNING',
  Safarimontage = 'SAFARIMONTAGE',
  Schoology = 'SCHOOLOGY',
}

export type TccPackage = {
  __typename?: 'TccPackage';
  bucketName?: Maybe<Scalars['String']>;
  consumer?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  contentItems?: Maybe<Array<Scalars['String']>>;
  districtId?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type TccPackages = {
  __typename?: 'TccPackages';
  collection: Array<TccPackage>;
  packageInfo: Scalars['String'];
};

export type TccPackagesCount = {
  __typename?: 'TccPackagesCount';
  collection: Array<Scalars['JSON']>;
};

export enum TccVersionEnum {
  Version_11 = 'VERSION_11',
  Version_12 = 'VERSION_12',
  Version_13 = 'VERSION_13',
}

export type Teacher = Node & {
  __typename?: 'Teacher';
  _id: Scalars['String'];
  id: Scalars['ID'];
  orderListStudents: Scalars['String'];
  active: Scalars['Boolean'];
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdByUsername?: Maybe<Scalars['String']>;
  curriculumAreas?: Maybe<Scalars['Iterable']>;
  district?: Maybe<District>;
  /** @deprecated Used from Session User */
  districtUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gradeLevel?: Maybe<GradeLevelEnum>;
  importId?: Maybe<Scalars['String']>;
  inactivityReason?: Maybe<UserInactivityReasonEnum>;
  language: LanguageEnum;
  lastName?: Maybe<Scalars['String']>;
  lmsId?: Maybe<Scalars['String']>;
  manualMaintenance?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  plainPassword?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  purpose?: Maybe<UserPurposeEnum>;
  sisId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type: UserTypeEnum;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByUsername?: Maybe<Scalars['String']>;
  userRoles?: Maybe<UserRolePageConnection>;
  username: Scalars['String'];
};

export type TeacherUserRolesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type TeacherAssignmentInput = {
  class: Scalars['String'];
  students: Array<Scalars['String']>;
  topicIds: Array<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type TeacherAssignmentListInput = {
  /** The list of ordering criteria. */
  orderBy: Array<OrderByInput>;
  /** The number of items per page. */
  pageCount: Scalars['Int'];
  /** The page number. */
  pageNo: Scalars['Int'];
  /** The list of student IDs. */
  students: Array<Scalars['String']>;
  currentSchoolId: Scalars['String'];
};

export type TeacherAssignmentListPayload = {
  __typename?: 'TeacherAssignmentListPayload';
  data: Array<AssignmentDataPayload>;
  total: Scalars['Int'];
};

export type TeacherAssignmentPayload = {
  __typename?: 'TeacherAssignmentPayload';
  response: Scalars['String'];
};

/** Attributes for creating teacher assignments */
export type TeacherAssignmentsCreateInput = {
  assignmentSource: AssignmentSourceInput;
  calculator?: InputMaybe<CalculatorInput>;
  canBePaused?: InputMaybe<Scalars['Boolean']>;
  classes: Array<AssignmentClassInput>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  immediatelyShareResults?: InputMaybe<ImmediatelyShareResults>;
  labels: Array<Scalars['String']>;
  lateTurnIn: Scalars['Boolean'];
  learnosityReferenceId: Scalars['String'];
  name: Scalars['String'];
  notes: Scalars['String'];
  randomizeQuestionsOrder?: InputMaybe<Scalars['Boolean']>;
  releaseFeedback: Scalars['Boolean'];
  schoolId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export type TeacherDistrictAssignmentsFilter = {
  classId?: InputMaybe<Scalars['String']>;
  daId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
};

export type TeacherFeedback = {
  __typename?: 'TeacherFeedback';
  responseFeedbacks?: Maybe<Array<ResponseFeedback>>;
  sessionId: Scalars['String'];
  teacherId: Scalars['String'];
};

export type TeacherFilter = {
  schoolId?: InputMaybe<Scalars['String']>;
};

/** Page connection for Teacher. */
export type TeacherPageConnection = {
  __typename?: 'TeacherPageConnection';
  collection?: Maybe<Array<Maybe<Teacher>>>;
  paginationInfo: TeacherPaginationInfo;
};

/** Information about the pagination. */
export type TeacherPaginationInfo = {
  __typename?: 'TeacherPaginationInfo';
  hasNextPage: Scalars['Boolean'];
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type TeacherResource = {
  __typename?: 'TeacherResource';
  active: Scalars['Boolean'];
  contentGroups: Array<Scalars['String']>;
  contentLocation: Scalars['String'];
  created: Scalars['String'];
  displayName: Scalars['String'];
  editionName: Scalars['String'];
  elementTitles: Array<Scalars['String']>;
  id: Scalars['String'];
  internalName: Scalars['String'];
  lastUpdated: Scalars['String'];
  metadata: Scalars['JSON'];
  positionNumber: Scalars['Int'];
  projectId: Scalars['String'];
  projectType: Scalars['String'];
  sectionTitles: Array<Scalars['JSON']>;
  sections: Array<Scalars['JSON']>;
  state: Scalars['String'];
  version: Scalars['Int'];
};

export type TeacherResources = {
  __typename?: 'TeacherResources';
  collection?: Maybe<Array<SearchableTeacherResource>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TeacherStudentFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  classIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<StudentFilter_Order>>>;
  schoolId?: InputMaybe<Scalars['String']>;
  studentHasArchivedClasses?: InputMaybe<Scalars['Boolean']>;
  teachersId?: InputMaybe<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  archived: Scalars['Boolean'];
  context: TeamContextEnum;
  district?: Maybe<District>;
  id: Scalars['ID'];
  members: Array<User>;
  name: Scalars['String'];
  /** @deprecated Use creator */
  owner: User;
  creator: User;
  state?: Maybe<StateEnum>;
};

export enum TeamContextEnum {
  Calendar = 'calendar',
  StemscopesAssessment = 'stemscopesAssessment',
}

export type TeamPageConnection = {
  __typename?: 'TeamPageConnection';
  collection: Array<Team>;
  paginationInfo?: Maybe<TeamPaginationInfo>;
};

export type TeamPaginationInfo = {
  __typename?: 'TeamPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TeamPayload = {
  __typename?: 'TeamPayload';
  team: Team;
};

/** ToggleVerifySchoolAges a WallAgeControl. */
export type ToggleVerifySchoolAgeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  isVerified: Scalars['Boolean'];
  schoolId: Scalars['String'];
};

/** ToggleVerifySchoolAges a WallAgeControl. */
export type ToggleVerifySchoolAgePayload = {
  __typename?: 'toggleVerifySchoolAgePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  wallAgeControl?: Maybe<WallAgeControl>;
};

/** Toggles a WallPostLike. */
export type ToggleWallPostLikeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  wallPostId: Scalars['String'];
};

/** Toggles a WallPostLike. */
export type ToggleWallPostLikePayload = {
  __typename?: 'toggleWallPostLikePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  wallPostLike?: Maybe<WallPostLike>;
};

export type ToMoveVideoInput = {
  groupIdentifier: Scalars['String'];
  tutorId?: InputMaybe<Scalars['String']>;
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type Topics = {
  __typename?: 'Topics';
  topics: Array<Scalars['String']>;
};

export type Tutor = {
  __typename?: 'Tutor';
  bioText: Scalars['String'];
  bioVideo: Scalars['String'];
  depthScale: Scalars['Int'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  initialTag: LanguageTagEnum;
  languageTag: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  mainProfilePhoto: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  profilePhoto: Scalars['String'];
  videos: VideoPageConnection;
};

export type TutorVideosArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type TutorFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type TutorPageConnection = {
  __typename?: 'TutorPageConnection';
  collection: Array<Tutor>;
  paginationInfo?: Maybe<TutorPaginationInfo>;
};

export type TutorPaginationInfo = {
  __typename?: 'TutorPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TutorPayload = {
  __typename?: 'TutorPayload';
  tutor: Tutor;
};

export enum TypeEnum {
  /** Item Type */
  Items = 'items',
  /** Activity Type */
  Activities = 'activities',
}

export type TyQuestionContainerInput = {
  category: Scalars['String'];
  grade: Scalars['String'];
  unit: Scalars['String'];
};

/** Object of Post Input Request Type */
export type UnfilteredInputPostRequest = {
  /** Text to search posts by content, firstname or lastname */
  comment?: InputMaybe<Scalars['String']>;
  /** ID of selected school */
  currentSchoolId: Scalars['String'];
  /** Id of post */
  id?: InputMaybe<Scalars['String']>;
  /** Get hidden / unhidden posts */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Offset */
  offset?: InputMaybe<Scalars['Int']>;
  /** Size */
  size?: InputMaybe<Scalars['Int']>;
  /** User Id of post author */
  userId?: InputMaybe<Scalars['String']>;
  /** Array of Wall Ids */
  wallIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UnionVideoPdf = Video | Pdf;

export type UnionVideoPdfPageConnection = {
  __typename?: 'UnionVideoPdfPageConnection';
  collection: Array<UnionVideoPdf>;
  paginationInfo?: Maybe<UnionVideoPdfPaginationInfo>;
};

export type UnionVideoPdfPaginationInfo = {
  __typename?: 'UnionVideoPdfPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UpdateAccessPolicyInput = {
  applications?: InputMaybe<Array<ApplicationInput>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<ScopedPermissionInput>>;
};

export type UpdateAdoptionAccountInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
  beginDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateAdoptionEventInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<AdoptionEventCategoryEnum>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  uiCustomization?: InputMaybe<Scalars['String']>;
  urlCode?: InputMaybe<Scalars['String']>;
  productGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  beginDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateAdoptionReviewerInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  plainPassword?: InputMaybe<Scalars['String']>;
  beginDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateAssessmentInput = {
  edgexlInput?: InputMaybe<EdgexlUpdateAssessmentInput>;
  platformAdminInput?: InputMaybe<PlatformAdminUpdateAssessmentInput>;
  stemscopesInput?: InputMaybe<StemscopesUpdateAssessmentInput>;
};

/** Updates a Calendar. */
export type UpdateCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  firstDayOfSchool?: InputMaybe<Scalars['Iterable']>;
  gradingPeriods?: InputMaybe<Scalars['Iterable']>;
  holidays?: InputMaybe<Scalars['Iterable']>;
  id: Scalars['String'];
  lastDayOfSchool?: InputMaybe<Scalars['Iterable']>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a Calendar. */
export type UpdateCalendarPayload = {
  __typename?: 'updateCalendarPayload';
  calendar?: Maybe<Calendar>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a Class. */
export type UpdateClassInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  classTeachers?: InputMaybe<Array<InputMaybe<UpdateClassTeacherNestedInput>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<CurriculumAreaEnum>;
  gradeOrCourse?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusEnum>;
  subgroups?: InputMaybe<Array<InputMaybe<UpdateStudentSubgroupNestedInput>>>;
};

/** Updates a Class. */
export type UpdateClassPayload = {
  __typename?: 'updateClassPayload';
  class?: Maybe<Class>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a ClassTeacher. */
export type UpdateClassTeacherInput = {
  _id?: InputMaybe<Scalars['String']>;
  accesses?: InputMaybe<Array<InputMaybe<AccessesEnum>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mainTeacher?: InputMaybe<Scalars['Boolean']>;
  orderListStudents?: InputMaybe<Scalars['String']>;
  subgroups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Updates a ClassTeacher. */
export type UpdateClassTeacherNestedInput = {
  _id?: InputMaybe<Scalars['String']>;
  accesses?: InputMaybe<Array<InputMaybe<AccessesEnum>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mainTeacher?: InputMaybe<Scalars['Boolean']>;
  orderListStudents?: InputMaybe<Scalars['String']>;
  subgroups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Updates a ClassTeacher. */
export type UpdateClassTeacherPayload = {
  __typename?: 'updateClassTeacherPayload';
  classTeacher?: Maybe<ClassTeacher>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContentGroupInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  contents?: InputMaybe<Array<ContentIdInput>>;
  id: Scalars['ID'];
};

export type UpdateContentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

/** Updates a District. */
export type UpdateDistrictInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystem?: InputMaybe<Scalars['String']>;
  globalName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  localName?: InputMaybe<Scalars['String']>;
  mathNationId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
};

/** Updates a District. */
export type UpdateDistrictPayload = {
  __typename?: 'updateDistrictPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
};

/** Updates a DistrictSettingsLessonPlanner. */
export type UpdateDistrictSettingsLessonPlannerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  teacherCanUseAiAssistedLessonPlan?: InputMaybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsLessonPlanner. */
export type UpdateDistrictSettingsLessonPlannerPayload = {
  __typename?: 'updateDistrictSettingsLessonPlannerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  districtSettingsLessonPlanner?: Maybe<UpdateDistrictSettingsLessonPlannerPayloadData>;
};

/** Updates a DistrictSettingsLessonPlanner. */
export type UpdateDistrictSettingsLessonPlannerPayloadData = Node & {
  __typename?: 'updateDistrictSettingsLessonPlannerPayloadData';
  id: Scalars['ID'];
  teacherCanUseAiAssistedLessonPlan?: Maybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsRoster. */
export type UpdateDistrictSettingsRosterInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  teacherCanAddAdditionalTeacher?: InputMaybe<Scalars['Boolean']>;
  teacherCanCreateClasses?: InputMaybe<Scalars['Boolean']>;
  teacherCanEditClassRoster?: InputMaybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsRoster. */
export type UpdateDistrictSettingsRosterPayload = {
  __typename?: 'updateDistrictSettingsRosterPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  districtSettingsRoster?: Maybe<DistrictSettingsRoster>;
};

/** Updates a DistrictSettingsUserManagement. */
export type UpdateDistrictSettingsUserManagementInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  teacherCanCreateStudents?: InputMaybe<Scalars['Boolean']>;
  teacherCanEditStudentPassword?: InputMaybe<Scalars['Boolean']>;
  teacherCanImpersonateStudents?: InputMaybe<Scalars['Boolean']>;
};

/** Updates a DistrictSettingsUserManagement. */
export type UpdateDistrictSettingsUserManagementPayload = {
  __typename?: 'updateDistrictSettingsUserManagementPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  districtSettingsUserManagement?: Maybe<UpdateDistrictSettingsUserManagementPayloadData>;
};

/** Updates a DistrictSettingsUserManagement. */
export type UpdateDistrictSettingsUserManagementPayloadData = Node & {
  __typename?: 'updateDistrictSettingsUserManagementPayloadData';
  id: Scalars['ID'];
  teacherCanCreateStudents?: Maybe<Scalars['Boolean']>;
  teacherCanEditStudentPassword?: Maybe<Scalars['Boolean']>;
  teacherCanImpersonateStudents?: Maybe<Scalars['Boolean']>;
};

export type UpdateEmailTemplateInput = {
  attachments?: InputMaybe<Array<InputMaybe<EmailTemplateAttachmentInput>>>;
  body?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fromEmail?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  source?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTemplateTypeEnum>;
};

/** Updates a Entry. */
export type UpdateEntryInput = {
  calendarId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EntryTypeEnum>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a Entry. */
export type UpdateEntryPayload = {
  __typename?: 'updateEntryPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
};

export type UpdateImageCategoryInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
};

export type UpdateImageFileInput = {
  id?: InputMaybe<Scalars['ID']>;
  originalFileName: Scalars['String'];
  path: Scalars['String'];
};

export type UpdateImageInput = {
  altText?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  files: Array<UpdateImageFileInput>;
  id: Scalars['ID'];
  keywordIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  tagAssociations?: InputMaybe<Array<ImageTagAssociationInput>>;
};

export type UpdateImageKeywordInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateImageTagInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateImageTagValueInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateKeywordInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Updates a PacingGuide. */
export type UpdatePacingGuideInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  selectedCalendar?: InputMaybe<Scalars['Iterable']>;
  startDate?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a PacingGuide. */
export type UpdatePacingGuidePayload = {
  __typename?: 'updatePacingGuidePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuide?: Maybe<PacingGuide>;
};

/** Updates a PacingGuideScope. */
export type UpdatePacingGuideScopeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  pacingGuideId: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopes: Scalars['Iterable'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a PacingGuideScope. */
export type UpdatePacingGuideScopePayload = {
  __typename?: 'updatePacingGuideScopePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  pacingGuideScope?: Maybe<PacingGuideScope>;
};

/** Updates a Post. */
export type UpdatePostInput = {
  answer?: InputMaybe<Scalars['Int']>;
  blocked?: InputMaybe<Scalars['Int']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  currentSchoolId: Scalars['String'];
  documentKey?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isSticky?: InputMaybe<Scalars['Int']>;
  stared?: InputMaybe<Scalars['Int']>;
  welcome?: InputMaybe<Scalars['Int']>;
};

/** Updates a Post. */
export type UpdatePostPayload = {
  __typename?: 'updatePostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
};

export type UpdatePreviewAccountInput = {
  activityPeriodEnd?: InputMaybe<Scalars['DateTime']>;
  activityPeriodStart?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  state?: InputMaybe<StateEnum>;
  productGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdatePreviewAccountTeacherInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateProductGroupInput = {
  id: Scalars['ID'];
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateProductInput = {
  contentGroups?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  productType?: InputMaybe<ProductTypeEnum>;
  scopeGroups?: InputMaybe<Array<Scalars['ID']>>;
  sku?: InputMaybe<Scalars['String']>;
};

export type UpdateReaderLibraryInput = {
  description?: InputMaybe<Scalars['String']>;
  enPdfFile?: InputMaybe<Scalars['Upload']>;
  esPdfFile?: InputMaybe<Scalars['Upload']>;
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRoleInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  policies?: InputMaybe<Array<Scalars['String']>>;
};

/** Updates a School. */
export type UpdateSchoolInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  districtId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystem?: InputMaybe<Scalars['String']>;
  globalName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  localName?: InputMaybe<Scalars['String']>;
  mathNationId?: InputMaybe<Scalars['String']>;
  sisId?: InputMaybe<Scalars['String']>;
};

/** Updates a School. */
export type UpdateSchoolPayload = {
  __typename?: 'updateSchoolPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
};

/** Updates a ScopePlan. */
export type UpdateScopePlanInput = {
  approachType?: InputMaybe<ScopePlanApproachEnum>;
  classSize?: InputMaybe<Scalars['Int']>;
  classTime?: InputMaybe<Scalars['Int']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopes?: InputMaybe<Scalars['Iterable']>;
  scopesText?: InputMaybe<Scalars['String']>;
  teacherExp?: InputMaybe<Scalars['Int']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a ScopePlan. */
export type UpdateScopePlanPayload = {
  __typename?: 'updateScopePlanPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlan?: Maybe<ScopePlan>;
};

/** Updates a ScopePlanScope. */
export type UpdateScopePlanScopeInput = {
  action: ScopePlanScopeElementActionTypeEnum;
  clientMutationId?: InputMaybe<Scalars['String']>;
  elements: Scalars['Iterable'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopeId: Scalars['String'];
  scopePlanId: Scalars['String'];
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
};

/** Updates a ScopePlanScope. */
export type UpdateScopePlanScopePayload = {
  __typename?: 'updateScopePlanScopePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  scopePlanScope?: Maybe<ScopePlanScope>;
};

export type UpdateStemscopesMediaInput = {
  active?: Scalars['Boolean'];
  id: Scalars['ID'];
  subtitlesSrtFormat?: Scalars['Boolean'];
};

export type UpdateStemscopesMediaPackInput = {
  id: Scalars['ID'];
  items: Array<UpdateStemscopesMediaInput>;
};

export type UpdateStemscopesMediaPackPayload = {
  __typename?: 'UpdateStemscopesMediaPackPayload';
  pack: StemscopesMediaPack;
  items: Array<StemscopesMediaPostObjectPayload>;
};

export type UpdateStreamingVideoInput = {
  active?: Scalars['Boolean'];
  date?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a StudentSubgroup. */
export type UpdateStudentSubgroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  teacherIds?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a StudentSubgroup. */
export type UpdateStudentSubgroupNestedInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  teacherIds?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a StudentSubgroup. */
export type UpdateStudentSubgroupPayload = {
  __typename?: 'updateStudentSubgroupPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  studentSubgroup?: Maybe<StudentSubgroup>;
};

export type UpdateSubscriptionInput = {
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type UpdateSubscriptionPackageInput = {
  id?: InputMaybe<Scalars['ID']>;
  districtId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<Scalars['ID']>>;
  scope?: InputMaybe<SubscriptionScopeEnum>;
  subscriptionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateTagInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateTagValueInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateTeamInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** User IDs */
  members?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTutorInput = {
  bioText?: InputMaybe<Scalars['String']>;
  bioVideo?: InputMaybe<Scalars['Upload']>;
  depthScale?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  initialTag?: InputMaybe<LanguageTagEnum>;
  lastName?: InputMaybe<Scalars['String']>;
  mainProfilePhoto?: InputMaybe<Scalars['Upload']>;
  order?: InputMaybe<Scalars['Int']>;
  profilePhoto?: InputMaybe<Scalars['Upload']>;
};

/** UpdateUserActivitys a User. */
export type UpdateUserActivityInput = {
  active: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  userIds: Scalars['Iterable'];
};

/** UpdateUserActivitys a User. */
export type UpdateUserActivityPayload = {
  __typename?: 'updateUserActivityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Updates a UserCalendar. */
export type UpdateUserCalendarInput = {
  assignedTo?: InputMaybe<Scalars['String']>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarTemplateId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  createTime?: InputMaybe<Scalars['String']>;
  creator?: InputMaybe<Scalars['String']>;
  editable?: InputMaybe<Scalars['Boolean']>;
  eventId?: InputMaybe<Scalars['String']>;
  eventTemplateId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isSelected?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pacingGuideId?: InputMaybe<Scalars['String']>;
  pacingGuideTemplateId?: InputMaybe<Scalars['String']>;
  pacingGuideType?: InputMaybe<PacingGuideTypeEnum>;
  pk?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  scopePlanId?: InputMaybe<Scalars['String']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  sharedBy?: InputMaybe<Scalars['String']>;
  sk?: InputMaybe<Scalars['String']>;
  updateTime?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<UsersTypeEnum>;
  version?: InputMaybe<Scalars['Int']>;
};

/** Updates a UserCalendar. */
export type UpdateUserCalendarPayload = {
  __typename?: 'updateUserCalendarPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userCalendar?: Maybe<UserCalendar>;
};

/** Updates a User. */
export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  districtUuid?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalSystemName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<GradeLevelEnum>;
  id: Scalars['ID'];
  language?: InputMaybe<LanguageEnum>;
  lastName?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  manualMaintenance?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['String']>;
  plainPassword?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  sisId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  userRoles?: InputMaybe<Array<InputMaybe<UpdateUserRoleNestedInput>>>;
  userRolesExplicitDelete?: InputMaybe<
    Array<InputMaybe<UpdateUserRoleNestedInput>>
  >;
  username?: InputMaybe<Scalars['String']>;
};

/** Updates a User. */
export type UpdateUserPayload = {
  __typename?: 'updateUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Updates a UserRole. */
export type UpdateUserRoleInput = {
  allSchoolsAccess?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  roleId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a UserRole. */
export type UpdateUserRoleNestedInput = {
  _delete?: InputMaybe<Scalars['Boolean']>;
  allSchoolsAccess?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a UserRole. */
export type UpdateUserRolePayload = {
  __typename?: 'updateUserRolePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
};

/** Updates a UserSelection. */
export type UpdateUserSelectionInput = {
  calendarSettingsPopup?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  notificationCalendar?: InputMaybe<Scalars['Iterable']>;
  notificationPacingGuide?: InputMaybe<Scalars['Iterable']>;
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  selectedCalendar?: InputMaybe<Scalars['Iterable']>;
  selectedPacingGuide?: InputMaybe<Scalars['Iterable']>;
  selectedScopePlan?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
  version?: InputMaybe<Scalars['Int']>;
  viewMode?: InputMaybe<ViewModeEnum>;
};

/** Updates a UserSelection. */
export type UpdateUserSelectionPayload = {
  __typename?: 'updateUserSelectionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  userSelection?: Maybe<UserSelection>;
};

export type UpdateWidgetInput = {
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
  h?: InputMaybe<Scalars['Int']>;
  i: Scalars['String'];
  id: Scalars['String'];
  collapsed?: InputMaybe<Scalars['Boolean']>;
  expandedH?: InputMaybe<Scalars['Int']>;
  expandedW?: InputMaybe<Scalars['Int']>;
  minH?: InputMaybe<Scalars['Int']>;
  minW?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<WidgetType>;
  config?: InputMaybe<Scalars['JSONObject']>;
};

export type User = Node & {
  __typename?: 'User';
  id: Scalars['ID'];
  _id: Scalars['String'];
  active: Scalars['Boolean'];
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdByUsername?: Maybe<Scalars['String']>;
  curriculumAreas?: Maybe<Scalars['Iterable']>;
  district?: Maybe<District>;
  /** @deprecated Used from Session User */
  districtUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalSystemName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gradeLevel?: Maybe<GradeLevelEnum>;
  importId?: Maybe<Scalars['String']>;
  inactivityReason?: Maybe<UserInactivityReasonEnum>;
  language: LanguageEnum;
  lastName?: Maybe<Scalars['String']>;
  lmsId?: Maybe<Scalars['String']>;
  manualMaintenance?: Maybe<Scalars['Boolean']>;
  middleName?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  plainPassword?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['String']>;
  purpose?: Maybe<UserPurposeEnum>;
  sisId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type: UserTypeEnum;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByUsername?: Maybe<Scalars['String']>;
  userRoles?: Maybe<UserRolePageConnection>;
  username: Scalars['String'];
};

export type UserUserRolesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
};

export type UserCalendar = Node & {
  __typename?: 'UserCalendar';
  _id: Scalars['String'];
  assignedTo?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  calendarTemplateId?: Maybe<Scalars['String']>;
  calendarType?: Maybe<CalendarTypeEnum>;
  calendars?: Maybe<CalendarPageConnection>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  editable: Scalars['Boolean'];
  eventId?: Maybe<Scalars['String']>;
  eventTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  isSelected?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pacingGuideId?: Maybe<Scalars['String']>;
  pacingGuideTemplateId?: Maybe<Scalars['String']>;
  pacingGuideType?: Maybe<PacingGuideTypeEnum>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  scopePlanId?: Maybe<Scalars['String']>;
  scopePlanType?: Maybe<ScopePlanTypeEnum>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  updateTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userSelection: UserSelection;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
};

export type UserCalendarCalendarsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CalendarFilter_Order>>>;
  calendarId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  teamId?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<CalendarFilter>>>;
};

export type UserCalendarFilter = {
  accessType?: InputMaybe<Scalars['String']>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userId?: InputMaybe<Scalars['String']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserCalendarFilter_Order = {
  name?: InputMaybe<SortEnum>;
};

/** Page connection for UserCalendar. */
export type UserCalendarPageConnection = {
  __typename?: 'UserCalendarPageConnection';
  collection?: Maybe<Array<Maybe<UserCalendar>>>;
  paginationInfo: UserCalendarPaginationInfo;
};

/** Information about the pagination. */
export type UserCalendarPaginationInfo = {
  __typename?: 'UserCalendarPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type UserExportRequest = Node & {
  __typename?: 'UserExportRequest';
  _id: Scalars['String'];
  downloadLink?: Maybe<Scalars['String']>;
  filters: Scalars['Iterable'];
  id: Scalars['ID'];
  status: UserExportRequestStatusEnum;
};

export enum UserExportRequestStatusEnum {
  InQueue = 'in_queue',
  InProcess = 'in_process',
  Completed = 'completed',
}

export type UserFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  classesArchived?: InputMaybe<Scalars['Boolean']>;
  classesCurriculumArea?: InputMaybe<Scalars['String']>;
  classesGradeOrCourse?: InputMaybe<Scalars['String']>;
  classesId?: InputMaybe<Scalars['String']>;
  classesLanguage?: InputMaybe<Scalars['String']>;
  classesName?: InputMaybe<Scalars['String']>;
  curriculumArea?: InputMaybe<CurriculumAreaEnum>;
  districtId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<UserFilter_Exclude>>>;
  excludeCurrent?: InputMaybe<Scalars['Boolean']>;
  externalSystemName?: InputMaybe<ExternalSystemNameEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  fullNameOrUsername?: InputMaybe<Scalars['String']>;
  gradeLevel?: InputMaybe<GradeLevelFilterEnum>;
  highestRoleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  lmsId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<UserFilter_Order>>>;
  purpose?: InputMaybe<UserPurposeEnum>;
  rolesId?: InputMaybe<Scalars['String']>;
  schoolsId?: InputMaybe<Scalars['String']>;
  schoolsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sisId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subgroupsId?: InputMaybe<Scalars['String']>;
  subgroupsName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserTypeEnum>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserFilter_Exclude = {
  id?: InputMaybe<Scalars['Iterable']>;
};

export type UserFilter_Order = {
  active?: InputMaybe<SortEnum>;
  email?: InputMaybe<SortEnum>;
  firstName?: InputMaybe<SortEnum>;
  gradeLevel?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  lastName?: InputMaybe<SortEnum>;
  username?: InputMaybe<SortEnum>;
};

export enum UserInactivityReasonEnum {
  AdoptionAccountDeactivatedManually = 'ADOPTION_ACCOUNT_DEACTIVATED_MANUALLY',
  AdoptionAccountHasExpired = 'ADOPTION_ACCOUNT_HAS_EXPIRED',
  AdoptionAccountHasNoProducts = 'ADOPTION_ACCOUNT_HAS_NO_PRODUCTS',
  AdoptionAccountPeriodHasNotStarted = 'ADOPTION_ACCOUNT_PERIOD_HAS_NOT_STARTED',
  PreviewAccountHasExpired = 'PREVIEW_ACCOUNT_HAS_EXPIRED',
  PreviewAccountHasNoProducts = 'PREVIEW_ACCOUNT_HAS_NO_PRODUCTS',
  PreviewAccountPeriodHasNotStarted = 'PREVIEW_ACCOUNT_PERIOD_HAS_NOT_STARTED',
  PreviewAccountSelfRegistrationRequired = 'PREVIEW_ACCOUNT_SELF_REGISTRATION_REQUIRED',
}

/** Page connection for User. */
export type UserPageConnection = {
  __typename?: 'UserPageConnection';
  collection?: Maybe<Array<Maybe<User>>>;
  paginationInfo: UserPaginationInfo;
};

/** Information about the pagination. */
export type UserPaginationInfo = {
  __typename?: 'UserPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type UserPayload = {
  __typename?: 'UserPayload';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  id: Scalars['String'];
};

export enum UserPurposeEnum {
  AdoptionReviewer = 'adoption_reviewer',
  PreviewAccount = 'preview_account',
  Regular = 'regular',
}

export type UserRole = Node & {
  __typename?: 'UserRole';
  allSchoolsAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  role: Role;
  /** @deprecated Use the Role property instead */
  roleId: Scalars['String'];
  schoolIds: Scalars['Iterable'];
};

/** Page connection for UserRole. */
export type UserRolePageConnection = {
  __typename?: 'UserRolePageConnection';
  collection?: Maybe<Array<Maybe<UserRole>>>;
  paginationInfo: UserRolePaginationInfo;
};

/** Information about the pagination. */
export type UserRolePaginationInfo = {
  __typename?: 'UserRolePaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type UserSelection = Node & {
  __typename?: 'UserSelection';
  _id: Scalars['String'];
  calendarSettingsPopup?: Maybe<Scalars['Boolean']>;
  createTime?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  notificationCalendar?: Maybe<Scalars['Iterable']>;
  notificationPacingGuide?: Maybe<Scalars['Iterable']>;
  pk: Scalars['String'];
  schoolIds?: Maybe<Scalars['Iterable']>;
  selectedCalendar?: Maybe<Scalars['Iterable']>;
  selectedPacingGuide?: Maybe<Scalars['Iterable']>;
  selectedScopePlan?: Maybe<Scalars['Iterable']>;
  sharedBy?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  updateTime?: Maybe<Scalars['String']>;
  userCalendars?: Maybe<UserCalendarPageConnection>;
  userId?: Maybe<Scalars['String']>;
  userRoleCode?: Maybe<Scalars['String']>;
  userType?: Maybe<UsersTypeEnum>;
  version?: Maybe<Scalars['Int']>;
  viewMode?: Maybe<ViewModeEnum>;
};

export type UserSelectionUserCalendarsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  accessType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<UserCalendarFilter_Order>>>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarType?: InputMaybe<CalendarTypeEnum>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<Array<InputMaybe<UserCalendarFilter>>>;
};

/** Page connection for UserSelection. */
export type UserSelectionPageConnection = {
  __typename?: 'UserSelectionPageConnection';
  collection?: Maybe<Array<Maybe<UserSelection>>>;
  paginationInfo: UserSelectionPaginationInfo;
};

/** Information about the pagination. */
export type UserSelectionPaginationInfo = {
  __typename?: 'UserSelectionPaginationInfo';
  itemsPerPage: Scalars['Int'];
  lastPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** User type: Individual / Team */
export enum UsersTypeEnum {
  /** Individual Type */
  Individual = 'Individual',
  /** Team Type */
  Team = 'Team',
}

export enum UserTypeEnum {
  Admin = 'admin',
  Parent = 'parent',
  Staff = 'staff',
  Student = 'student',
  Other = 'other',
}

/** VerifyEmailss a Parent. */
export type VerifyEmailsParentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  districtId: Scalars['String'];
  emails: Array<Scalars['String']>;
};

/** VerifyEmailss a Parent. */
export type VerifyEmailsParentPayload = {
  __typename?: 'verifyEmailsParentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parent?: Maybe<VerifyEmailsParentPayloadData>;
};

/** VerifyEmailss a Parent. */
export type VerifyEmailsParentPayloadData = Node & {
  __typename?: 'verifyEmailsParentPayloadData';
  id: Scalars['ID'];
  results: Scalars['Iterable'];
};

export type Video = {
  __typename?: 'Video';
  description?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  glossaryTerm?: Maybe<Scalars['String']>;
  groupIdentifier: Scalars['String'];
  id: Scalars['String'];
  linkedVideos: UnionVideoPdfPageConnection;
  mnIdentifier?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  processStatus: VideoProcessStatusEnum;
  questionList: Array<Scalars['String']>;
  spriteUrl?: Maybe<Scalars['String']>;
  streamUrl: Scalars['String'];
  subtitlesUrl?: Maybe<Scalars['String']>;
  thumbnailSize?: Maybe<Scalars['String']>;
  tutor?: Maybe<Tutor>;
  videoIndex?: Maybe<Scalars['String']>;
  videoUrl: Scalars['String'];
  active: Scalars['Boolean'];
  glossary: Scalars['Boolean'];
  k5: Scalars['Boolean'];
};

export type VideoLinkedVideosArgs = {
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
};

export type VideoByGroupInput = {
  groupIdentifier: Scalars['String'];
  groupType: VideoGroupEnum;
  mediaType?: MediaTypeEnum;
  videoIndex?: InputMaybe<Scalars['String']>;
};

export type VideoFilterInput = {
  /** For all videos */
  active?: InputMaybe<Scalars['Boolean']>;
  /** For Coursework videos */
  category?: InputMaybe<Scalars['String']>;
  /** For OnRamp videos */
  course?: InputMaybe<Scalars['String']>;
  /** For OnRamp videos */
  domain?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  excludeLesson?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  glossary?: InputMaybe<Scalars['Boolean']>;
  /** For Coursework videos */
  glossaryCondition?: GlossaryFilterConditionEnum;
  /** For Coursework videos */
  glossaryTerm?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  grade?: InputMaybe<Scalars['String']>;
  /** For all videos */
  id?: InputMaybe<Scalars['String']>;
  /** For Glossary videos */
  k5?: InputMaybe<Scalars['Boolean']>;
  /** For Coursework videos */
  lesson?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  mnComponent?: InputMaybe<Scalars['String']>;
  /** For all videos */
  processStatus?: InputMaybe<VideoProcessStatusEnum>;
  /** For all videos */
  projectType?: InputMaybe<Scalars['String']>;
  /** For all videos */
  purpose: VideoGroupEnum;
  /** For OnRamp videos */
  standard?: InputMaybe<Scalars['String']>;
  /** For OnRamp videos */
  topic?: InputMaybe<Scalars['String']>;
  /** For all videos */
  tutorIds?: InputMaybe<Array<Scalars['String']>>;
  /** For OnRamp videos */
  type?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  unit?: InputMaybe<Scalars['String']>;
  /** For Coursework videos */
  videoIndex?: InputMaybe<Scalars['String']>;
};

export enum VideoGroupEnum {
  ContentGroup = 'ContentGroup',
  OnRampGroup = 'OnRampGroup',
  QuestionGroup = 'QuestionGroup',
  WithoutGroup = 'WithoutGroup',
  StemscopesGroup = 'StemscopesGroup',
  StreamingGroup = 'StreamingGroup',
  GlossaryGroup = 'GlossaryGroup',
}

export type VideoPageConnection = {
  __typename?: 'VideoPageConnection';
  collection: Array<Video>;
  paginationInfo?: Maybe<VideoPaginationInfo>;
};

export type VideoPaginationInfo = {
  __typename?: 'VideoPaginationInfo';
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VideoPayload = {
  __typename?: 'VideoPayload';
  video: UnionVideoPdf;
};

export type VideoPostObjectPayload = {
  __typename?: 'VideoPostObjectPayload';
  fileKey: Scalars['String'];
  formAttributes: Scalars['String'];
  formInputs: Scalars['String'];
  id: Scalars['String'];
};

export enum VideoProcessStatusEnum {
  Uploading = 'Uploading',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum VideoQualityEnum {
  Hd = 'HD',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
  ExtraLow = 'EXTRA_LOW',
}

export type VideoSortInput = {
  glossaryTerm?: InputMaybe<SortEnum>;
};

/** ViewMode type: Day, Week or Month */
export enum ViewModeEnum {
  /** Day View Mode */
  Day = 'Day',
  /** Week View Mode */
  Week = 'Week',
  /** Month View Mode */
  Month = 'Month',
}

export type VisibilityPayload = {
  __typename?: 'VisibilityPayload';
  isVisible: Scalars['Boolean'];
};

export type WallAgeControl = Node & {
  __typename?: 'WallAgeControl';
  _id: Scalars['Int'];
  created: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  modified: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  verifiedByTeacherId?: Maybe<Scalars['String']>;
};

export type WallControl = Node & {
  __typename?: 'WallControl';
  _id: Scalars['Int'];
  created: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modified: Scalars['String'];
  name: Scalars['String'];
  stateCode: Scalars['String'];
  subjectCode: Scalars['String'];
  wallId: Scalars['String'];
};

export type WallList = {
  __typename?: 'wallList';
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type WallListConnect = {
  __typename?: 'wallListConnect';
  collection: Array<WallList>;
};

export type WallPostConnect = {
  __typename?: 'wallPostConnect';
  collection: Array<WallPosts>;
};

export type WallPostLike = Node & {
  __typename?: 'WallPostLike';
  _id: Scalars['Int'];
  created: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modified: Scalars['String'];
  userId: Scalars['String'];
  wallPost: Post;
};

export type WallPostPin = Node & {
  __typename?: 'WallPostPin';
  _id: Scalars['Int'];
  created: Scalars['String'];
  endTime: Scalars['String'];
  id: Scalars['ID'];
  modified: Scalars['String'];
  userId: Scalars['String'];
  wallPost?: Maybe<Post>;
};

export type WallPosts = Node & {
  __typename?: 'wallPosts';
  _id: Scalars['String'];
  id: Scalars['ID'];
  postId: Scalars['String'];
  wallId: Scalars['Int'];
  content: Scalars['String'];
  timeStamp: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isStickPost: Scalars['Boolean'];
  isWelcomePost: Scalars['Boolean'];
  answered: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  mediaKey: Scalars['String'];
  documentKey: Scalars['String'];
  equationEditorKey: Scalars['String'];
  gBoardKey: Scalars['String'];
  user: WallUser;
  likes?: Maybe<Array<Scalars['String']>>;
  replies?: Maybe<Array<WallReply>>;
  retired?: Maybe<Scalars['Int']>;
};

export type WallReply = {
  __typename?: 'wallReply';
  postId?: Maybe<Scalars['String']>;
  user?: Maybe<WallUser>;
  content?: Maybe<Scalars['String']>;
  documentKey?: Maybe<Scalars['String']>;
  mediaKey?: Maybe<Scalars['String']>;
  gBoardKey?: Maybe<Scalars['String']>;
  equationEditorKey?: Maybe<Scalars['String']>;
  likesCount?: Maybe<Scalars['Int']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeStamp?: Maybe<Scalars['Int']>;
};

export type WallUser = {
  __typename?: 'wallUser';
  username?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Widget = {
  __typename?: 'Widget';
  x: Scalars['Int'];
  y: Scalars['Int'];
  w: Scalars['Int'];
  h: Scalars['Int'];
  i: Scalars['String'];
  id: Scalars['String'];
  collapsed: Scalars['Boolean'];
  expandedH: Scalars['Int'];
  expandedW: Scalars['Int'];
  minH: Scalars['Int'];
  minW: Scalars['Int'];
  type: WidgetType;
  config?: Maybe<Scalars['JSONObject']>;
};

export enum WidgetType {
  /** Calendar */
  Calendar = 'Calendar',
  /** Week at a Glance */
  WeekAtAGlance = 'WeekAtAGlance',
  /** Assignment */
  Assignment = 'Assignment',
  /** Report */
  Report = 'Report',
  /** Live View */
  LiveView = 'LiveView',
  /** To Do List */
  ToDoList = 'ToDoList',
  /** Countdown */
  Countdown = 'Countdown',
  /** Shortcut */
  Shortcut = 'Shortcut',
}

export type ApplicationSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type ApplicationSettingsQuery = {
  __typename?: 'Query';
  applicationSettings?: {
    __typename?: 'ApplicationSettings';
    value: any;
    id: string;
  } | null;
};

export type GetSchoolsQueryVariables = Exact<{
  id_list?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  districtId?: InputMaybe<Scalars['String']>;
}>;

export type GetSchoolsQuery = {
  __typename?: 'Query';
  schools?: {
    __typename?: 'SchoolPageConnection';
    collection?: Array<{
      __typename?: 'School';
      globalName: string;
      id: string;
      _id: string;
      name: string;
    } | null> | null;
  } | null;
};

export type GetSystemSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSystemSettingsQuery = {
  __typename?: 'Query';
  applicationSettings?: {
    __typename?: 'ApplicationSettings';
    value: any;
    id: string;
  } | null;
  aclApplications: Array<{ __typename?: 'AclApplication'; name: string }>;
};

export type ControlContainerMutationVariables = Exact<{
  input: ControlContainerInput;
}>;

export type ControlContainerMutation = {
  __typename?: 'Mutation';
  controlContainer: {
    __typename?: 'AssessmentPayload';
    statusPayload?: { __typename?: 'StatusPayload'; success: boolean } | null;
  };
};

export type GetAssessmentPoolForAccessSettingsQueryVariables = Exact<{
  input: AssessmentPoolInput;
}>;

export type GetAssessmentPoolForAccessSettingsQuery = {
  __typename?: 'Query';
  assessmentPoolLeader: {
    __typename?: 'AssessmentPoolPayload';
    stemscopesPayload?: {
      __typename?: 'StemscopesAssessmentPoolPayload';
      list: Array<{
        __typename?: 'StemscopesAssessmentItemPayload';
        id: string;
        title?: string | null;
        hiddenForSchool: boolean;
      }>;
    } | null;
  };
};

export type GetExtraQuestionSettingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetExtraQuestionSettingQuery = {
  __typename?: 'Query';
  readContainer: {
    __typename?: 'ContainersVisibilityPayload';
    visibilityPayload?: {
      __typename?: 'VisibilityPayload';
      isVisible: boolean;
    } | null;
  };
};

export type UpdateControlAssessmentMutationVariables = Exact<{
  input: ControlAssessmentInput;
}>;

export type UpdateControlAssessmentMutation = {
  __typename?: 'Mutation';
  controlAssessment: {
    __typename?: 'AssessmentPayload';
    statusPayload?: { __typename?: 'StatusPayload'; success: boolean } | null;
  };
};

export type GetSchoolQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSchoolQuery = {
  __typename?: 'Query';
  school?: { __typename?: 'School'; globalName: string } | null;
};

export type CopyAssessmentMutationVariables = Exact<{
  id: Scalars['ID'];
  currentSchoolId: Scalars['String'];
}>;

export type CopyAssessmentMutation = {
  __typename?: 'Mutation';
  copyAssessment: {
    __typename?: 'AssessmentPayload';
    statusPayload?: { __typename?: 'StatusPayload'; success: boolean } | null;
  };
};

export type GetLookUpRolesQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetLookUpRolesQuery = {
  __typename?: 'Query';
  lookupRoles: Array<{
    __typename?: 'Role';
    id: string;
    code?: RoleCodeEnum | null;
    name: string;
  }>;
};

export type GetAssessmentQueryVariables = Exact<{
  input: AssessmentInput;
}>;

export type GetAssessmentQuery = {
  __typename?: 'Query';
  assessment: {
    __typename?: 'AssessmentPayload';
    stemscopesPayload?: {
      __typename?: 'StemscopesAssessmentPayload';
      item?: {
        __typename?: 'StemscopesAssessmentItemPayload';
        id: string;
        title?: string | null;
        items: Array<string>;
        isArchived?: boolean | null;
        isShared?: boolean | null;
        usersViewOnlyIds: Array<string>;
        library?: StemscopesLibraryEnum | null;
        deliveries: Array<DeliveryEnum>;
        standards: Array<string>;
        subPurpose?: SubPurposeEnum | null;
        activityType: ActivityTypeEnum;
        teams: Array<string>;
      } | null;
    } | null;
  };
};

export type GetAssessmentLeaderQueryVariables = Exact<{
  input: AssessmentInput;
}>;

export type GetAssessmentLeaderQuery = {
  __typename?: 'Query';
  assessmentLeader: {
    __typename?: 'AssessmentPayload';
    stemscopesPayload?: {
      __typename?: 'StemscopesAssessmentPayload';
      item?: {
        __typename?: 'StemscopesAssessmentItemPayload';
        id: string;
        title?: string | null;
        items: Array<string>;
        isArchived?: boolean | null;
        isShared?: boolean | null;
        usersViewOnlyIds: Array<string>;
        library?: StemscopesLibraryEnum | null;
        deliveries: Array<DeliveryEnum>;
        standards: Array<string>;
        subPurpose?: SubPurposeEnum | null;
        activityType: ActivityTypeEnum;
        teams: Array<string>;
      } | null;
    } | null;
  };
};

export type GetAssessmentEnumsQueryVariables = Exact<{
  input?: InputMaybe<PurposeEnumInput>;
}>;

export type GetAssessmentEnumsQuery = {
  __typename?: 'Query';
  poolEnums: {
    __typename?: 'PoolEnumsPayload';
    standards: Array<string>;
    scopes: Array<string>;
    questionTypes: Array<string>;
    keywords: Array<string>;
    grades: Array<string>;
    editions: Array<string>;
    curriculumAreas: Array<string>;
  };
};

export type ShareAssessmentMutationVariables = Exact<{
  input: StemscopesShareAssessmentInput;
}>;

export type ShareAssessmentMutation = {
  __typename?: 'Mutation';
  shareAssessment: {
    __typename?: 'AssessmentPayload';
    statusPayload?: { __typename?: 'StatusPayload'; success: boolean } | null;
  };
};

export type GetAllAssessmentsQueryVariables = Exact<{
  input: AssessmentPoolInput;
}>;

export type GetAllAssessmentsQuery = {
  __typename?: 'Query';
  assessmentPool: {
    __typename?: 'AssessmentPoolPayload';
    stemscopesPayload?: {
      __typename?: 'StemscopesAssessmentPoolPayload';
      list: Array<{
        __typename?: 'StemscopesAssessmentItemPayload';
        id: string;
        title?: string | null;
        items: Array<string>;
        createdAt: string;
        isArchived?: boolean | null;
        isShared?: boolean | null;
        usersViewOnlyIds: Array<string>;
        library?: StemscopesLibraryEnum | null;
        deliveries: Array<DeliveryEnum>;
        standards: Array<string>;
        teams: Array<string>;
        activityType: ActivityTypeEnum;
        createdBy?: {
          __typename?: 'UserPayload';
          lastName: string;
          firstName: string;
        } | null;
      }>;
      paginationInfo: {
        __typename?: 'PaginationInfoPayload';
        itemsPerPage: number;
        lastPage: number;
        totalCount: number;
      };
    } | null;
  };
};

export type GetAllAssessmentsLeaderQueryVariables = Exact<{
  input: AssessmentPoolInput;
}>;

export type GetAllAssessmentsLeaderQuery = {
  __typename?: 'Query';
  assessmentPoolLeader: {
    __typename?: 'AssessmentPoolPayload';
    stemscopesPayload?: {
      __typename?: 'StemscopesAssessmentPoolPayload';
      list: Array<{
        __typename?: 'StemscopesAssessmentItemPayload';
        id: string;
        title?: string | null;
        items: Array<string>;
        createdAt: string;
        isArchived?: boolean | null;
        isShared?: boolean | null;
        usersViewOnlyIds: Array<string>;
        library?: StemscopesLibraryEnum | null;
        deliveries: Array<DeliveryEnum>;
        hasManuallyGradedQuestions?: boolean | null;
        standards: Array<string>;
        teams: Array<string>;
        activityType: ActivityTypeEnum;
        createdBy?: {
          __typename?: 'UserPayload';
          lastName: string;
          firstName: string;
        } | null;
      }>;
      paginationInfo: {
        __typename?: 'PaginationInfoPayload';
        itemsPerPage: number;
        lastPage: number;
        totalCount: number;
      };
    } | null;
  };
};

export type GetUsersToShareQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserTypeEnum>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolsIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
  >;
  id_list?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetUsersToShareQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UserPageConnection';
    collection?: Array<{
      __typename?: 'User';
      _id: string;
      firstName?: string | null;
      lastName?: string | null;
      userRoles?: {
        __typename?: 'UserRolePageConnection';
        collection?: Array<{
          __typename?: 'UserRole';
          role: { __typename?: 'Role'; code?: RoleCodeEnum | null };
        } | null> | null;
      } | null;
    } | null> | null;
    paginationInfo: {
      __typename?: 'UserPaginationInfo';
      itemsPerPage: number;
      totalCount: number;
    };
  } | null;
};

export type UpdateAssessmentMutationVariables = Exact<{
  input: StemscopesUpdateAssessmentInput;
}>;

export type UpdateAssessmentMutation = {
  __typename?: 'Mutation';
  updateAssessment: {
    __typename?: 'AssessmentPayload';
    stemscopesPayload?: {
      __typename?: 'StemscopesAssessmentPayload';
      item?: {
        __typename?: 'StemscopesAssessmentItemPayload';
        id: string;
        title?: string | null;
        items: Array<string>;
        isArchived?: boolean | null;
        isShared?: boolean | null;
        usersViewOnlyIds: Array<string>;
        library?: StemscopesLibraryEnum | null;
        deliveries: Array<DeliveryEnum>;
        activityType: ActivityTypeEnum;
        teams: Array<string>;
      } | null;
    } | null;
  };
};

export type ArchiveAssignmentMutationVariables = Exact<{
  action: ArchiveAssignmentAction;
  assignmentIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type ArchiveAssignmentMutation = {
  __typename?: 'Mutation';
  archiveAssignments?: {
    __typename?: 'Assignments';
    collection?: Array<{
      __typename?: 'Assignment';
      assignmentId: string;
    }> | null;
  } | null;
};

export type EditStemscopesAssignmentMutationVariables = Exact<{
  assignmentParams: StemscopesEditInput;
}>;

export type EditStemscopesAssignmentMutation = {
  __typename?: 'Mutation';
  editStemscopesAssignment?: {
    __typename?: 'Assignment';
    assignmentId: string;
  } | null;
};

export type GetAssessmentQuestionsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  deliveries?: InputMaybe<Array<DeliveryEnum> | DeliveryEnum>;
  gradingTypes?: InputMaybe<Array<GradingTypeEnum> | GradingTypeEnum>;
  questionBanks?: InputMaybe<Array<QuestionBankEnum> | QuestionBankEnum>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  curriculumAreas?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  editions?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  grades?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  standards?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  scopes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  questionTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  excludeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  assessmentId?: InputMaybe<Scalars['String']>;
}>;

export type GetAssessmentQuestionsQuery = {
  __typename?: 'Query';
  questionPool: {
    __typename?: 'QuestionPoolPayload';
    impStemscopesPayload?: {
      __typename?: 'QuestionPoolPageConnection';
      collection: Array<{
        __typename?: 'QuestionPool';
        id: string;
        grading?: string | null;
        standards: Array<string>;
        questionTypes: Array<string>;
        grades: Array<string>;
        curriculumAreas: Array<string>;
        scopes: Array<string>;
        editions: Array<string>;
      }>;
      paginationInfo?: {
        __typename?: 'QuestionPoolPaginationInfo';
        itemsPerPage?: number | null;
        lastPage?: number | null;
        totalCount?: number | null;
      } | null;
    } | null;
  };
};

export type GetLeaderAssessmentQuestionsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  deliveries?: InputMaybe<Array<DeliveryEnum> | DeliveryEnum>;
  gradingTypes?: InputMaybe<Array<GradingTypeEnum> | GradingTypeEnum>;
  questionBanks?: InputMaybe<Array<QuestionBankEnum> | QuestionBankEnum>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  curriculumAreas?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  editions?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  grades?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  standards?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  scopes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  questionTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  keywords?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  excludeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  assessmentId?: InputMaybe<Scalars['String']>;
}>;

export type GetLeaderAssessmentQuestionsQuery = {
  __typename?: 'Query';
  questionPoolLeader: {
    __typename?: 'QuestionPoolPayload';
    impStemscopesPayload?: {
      __typename?: 'QuestionPoolPageConnection';
      collection: Array<{
        __typename?: 'QuestionPool';
        id: string;
        grading?: string | null;
        standards: Array<string>;
        questionTypes: Array<string>;
        grades: Array<string>;
        curriculumAreas: Array<string>;
        scopes: Array<string>;
        editions: Array<string>;
      }>;
      paginationInfo?: {
        __typename?: 'QuestionPoolPaginationInfo';
        itemsPerPage?: number | null;
        lastPage?: number | null;
        totalCount?: number | null;
      } | null;
    } | null;
  };
};

export type GetAllOwnerAssignmentsQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type GetAllOwnerAssignmentsQuery = {
  __typename?: 'Query';
  ownerAssignments?: {
    __typename?: 'OwnerAssignments';
    assignments: Array<{
      __typename?: 'Assignment';
      assignmentSource?: {
        __typename?: 'AssignmentSource';
        element?: {
          __typename?: 'Element';
          elementId: string;
          viewId?: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetAssignmentListQueryVariables = Exact<{
  id: Scalars['String'];
  schoolId: Scalars['String'];
  teachersId: Scalars['String'];
  archived?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetAssignmentListQuery = {
  __typename?: 'Query';
  assignments?: {
    __typename?: 'Assignments';
    collection?: Array<{
      __typename?: 'Assignment';
      name: string;
      classId: string;
      startDate: any;
      endDate: any;
      immediatelyShareResults?: ImmediatelyShareResults | null;
      lateTurnIn?: boolean | null;
      assignmentId: string;
      subgroupIds?: Array<string> | null;
      archived?: boolean | null;
      studentAssignments?: Array<{
        __typename?: 'StudentAssignment';
        studentId: string;
        assessmentId?: string | null;
        archived?: boolean | null;
        assignmentId: string;
        status: StudentAssignmentStatus;
        grade?: string | null;
        completedAt?: any | null;
        submittedAt?: any | null;
        endDate?: string | null;
        startedAt?: any | null;
        classId?: string | null;
        answers?: any | null;
        id: string;
        studentNotes?: string | null;
        learnosityReferenceId?: string | null;
        shareResults?: ShareResults | null;
      }> | null;
      assignmentSource?: {
        __typename?: 'AssignmentSource';
        element?: {
          __typename?: 'Element';
          elementId: string;
          scopeId: string;
          projectId: string;
          sectionId: string;
          elementName: string;
          scopeName: string;
          viewId?: string | null;
        } | null;
      } | null;
    }> | null;
  } | null;
  teacherStudents?: {
    __typename?: 'StudentPageConnection';
    collection?: Array<{
      __typename?: 'Student';
      _id: string;
      firstName?: string | null;
      lastName?: string | null;
      classes?: {
        __typename?: 'ClassPageConnection';
        collection?: Array<{ __typename?: 'Class'; _id: string } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      name: string;
    } | null> | null;
  } | null;
};

export type GetAssignmentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  lti?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetAssignmentQuery = {
  __typename?: 'Query';
  assignment?: {
    __typename?: 'Assignment';
    assignmentId: string;
    endDate: any;
    startDate: any;
    name: string;
    classId: string;
    archived?: boolean | null;
    immediatelyShareResults?: ImmediatelyShareResults | null;
    lateTurnIn?: boolean | null;
    notes?: string | null;
    subgroupIds?: Array<string> | null;
    studentAssignments?: Array<{
      __typename?: 'StudentAssignment';
      id: string;
      learnosityReferenceId?: string | null;
      studentId: string;
      assignmentId: string;
      status: StudentAssignmentStatus;
      grade?: string | null;
      completedAt?: any | null;
      submittedAt?: any | null;
      endDate?: string | null;
      startedAt?: any | null;
      answers?: any | null;
      lateTurnIn: boolean;
      shareResults?: ShareResults | null;
      returnedAt?: any | null;
      resetted?: boolean | null;
      studentNotes?: string | null;
      archived?: boolean | null;
      assessmentId?: string | null;
    }> | null;
    assignmentSource?: {
      __typename?: 'AssignmentSource';
      element?: {
        __typename?: 'Element';
        elementId: string;
        scopeId: string;
        projectId: string;
        sectionId: string;
        elementName: string;
        scopeName: string;
        sectionName: string;
        viewId?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetAssignmentClassQueryVariables = Exact<{
  classId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetAssignmentClassQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    _id: string;
    id: string;
    name: string;
    classStudents?: {
      __typename?: 'ClassStudentPageConnection';
      collection?: Array<{
        __typename?: 'ClassStudent';
        _id: string;
        student: {
          __typename?: 'Student';
          _id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type GetAssignmentClassesQueryVariables = Exact<{
  classIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  schoolId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetAssignmentClassesQuery = {
  __typename?: 'Query';
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      id: string;
      name: string;
      classStudents?: {
        __typename?: 'ClassStudentPageConnection';
        collection?: Array<{
          __typename?: 'ClassStudent';
          _id: string;
          student: {
            __typename?: 'Student';
            _id: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetStudentsBySubgroupsQueryVariables = Exact<{
  classId: Scalars['ID'];
}>;

export type GetStudentsBySubgroupsQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    _id: string;
    id: string;
    name: string;
    subgroups?: {
      __typename?: 'StudentSubgroupPageConnection';
      collection?: Array<{
        __typename?: 'StudentSubgroup';
        name: string;
        id: string;
        _id: string;
        students?: {
          __typename?: 'StudentSubgroupPageConnection';
          collection?: Array<{
            __typename?: 'StudentSubgroup';
            _id: string;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetDistrictStudentAssignmentsQueryVariables = Exact<{
  studentId?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['String'];
  shareResults?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<StudentAssignmentStatus>;
}>;

export type GetDistrictStudentAssignmentsQuery = {
  __typename?: 'Query';
  districtStudentAssignments?: {
    __typename?: 'DistrictStudentAssignmentsType';
    collection: Array<{
      __typename?: 'DistrictStudentAssignmentType';
      answers?: any | null;
      assignmentId: string;
      endDate: any;
      learnosityReferenceId: string;
      name: string;
      status?: StudentAssignmentStatus | null;
      studentNotes?: string | null;
      completedAt?: any | null;
      id: string;
      submittedAt?: any | null;
      shareResults?: ShareResults | null;
    }>;
  } | null;
};

export type GetDistrictAssignmentsQueryVariables = Exact<{
  filter?: InputMaybe<DistrictAssignmentsFilter>;
}>;

export type GetDistrictAssignmentsQuery = {
  __typename?: 'Query';
  districtAssignments?: {
    __typename?: 'DistrictClassAssignmentsType';
    collection: Array<{
      __typename?: 'DistrictClassAssignmentType';
      assignmentId: string;
      startDate: any;
      endDate: any;
      name: string;
      availableDate?: any | null;
      gradeLevel: string;
      classId: string;
      assignmentSource?: {
        __typename?: 'AssignmentSource';
        element?: {
          __typename?: 'Element';
          scopeName: string;
          elementName: string;
          scopeId: string;
          sectionId: string;
          elementId: string;
          projectId: string;
          viewId?: string | null;
        } | null;
      } | null;
      studentAssignments?: Array<{
        __typename?: 'DistrictStudentAssignmentType';
        assignmentId: string;
        id: string;
        studentId: string;
        answers?: any | null;
        status?: StudentAssignmentStatus | null;
        startedAt?: any | null;
        submittedAt?: any | null;
        completedAt?: any | null;
        endDate: any;
        classId: string;
        grade?: string | null;
        shareResults?: ShareResults | null;
        learnosityReferenceId: string;
      }> | null;
    }>;
  } | null;
};

export type GetClassAssignmentsQueryVariables = Exact<{
  classId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type GetClassAssignmentsQuery = {
  __typename?: 'Query';
  ownerAssignments?: {
    __typename?: 'OwnerAssignments';
    assignments: Array<{
      __typename?: 'Assignment';
      classId: string;
      assignmentListId: string;
      assignmentId: string;
      learnosityReferenceId?: string | null;
      name: string;
      releaseFeedback?: boolean | null;
      startDate: any;
      endDate: any;
      status: AssignmentStatus;
      subgroupIds?: Array<string> | null;
      studentIds?: Array<string> | null;
      labels?: Array<string> | null;
      notes?: string | null;
    }>;
    studentAssignments: Array<{
      __typename?: 'StudentAssignment';
      classId?: string | null;
      assignmentId: string;
      studentId: string;
      name: string;
      id: string;
      grade?: string | null;
      startedAt?: any | null;
      submittedAt?: any | null;
      completedAt?: any | null;
      status: StudentAssignmentStatus;
      releaseFeedback: boolean;
      lateTurnIn: boolean;
      answers?: any | null;
      returnedAt?: any | null;
    }>;
    pageInfo?: {
      __typename?: 'PageInfo';
      itemsPerPage?: number | null;
      totalCount?: number | null;
    } | null;
  } | null;
  class?: {
    __typename?: 'Class';
    _id: string;
    name: string;
    classStudents?: {
      __typename?: 'ClassStudentPageConnection';
      collection?: Array<{
        __typename?: 'ClassStudent';
        _id: string;
        id: string;
        student: {
          __typename?: 'Student';
          _id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type GetClassAssignmentsForAssignmentsWidgetQueryVariables = Exact<{
  classIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  assignmentDateFilter?: InputMaybe<AssignmentDateFilterInput>;
  endDateFilter?: InputMaybe<AssignmentDateFilterInput>;
}>;

export type GetClassAssignmentsForAssignmentsWidgetQuery = {
  __typename?: 'Query';
  ownerAssignments?: {
    __typename?: 'OwnerAssignments';
    assignments: Array<{
      __typename?: 'Assignment';
      assignmentId: string;
      name: string;
      startDate: any;
      endDate: any;
      assignmentSource?: {
        __typename?: 'AssignmentSource';
        element?: { __typename?: 'Element'; scopeName: string } | null;
      } | null;
    }>;
    studentAssignments: Array<{
      __typename?: 'StudentAssignment';
      assignmentId: string;
      studentId: string;
      status: StudentAssignmentStatus;
      grade?: string | null;
      answers?: any | null;
    }>;
  } | null;
};

export type GetClassAssignmentsWithPaginationQueryVariables = Exact<{
  classId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
  paginationInfo?: InputMaybe<StemscopesAssignmentPageInfoInput>;
}>;

export type GetClassAssignmentsWithPaginationQuery = {
  __typename?: 'Query';
  ownerAssignments?: {
    __typename?: 'OwnerAssignments';
    assignments: Array<{
      __typename?: 'Assignment';
      classId: string;
      assignmentListId: string;
      assignmentId: string;
      learnosityReferenceId?: string | null;
      name: string;
      releaseFeedback?: boolean | null;
      startDate: any;
      endDate: any;
      status: AssignmentStatus;
      subgroupIds?: Array<string> | null;
      studentIds?: Array<string> | null;
      labels?: Array<string> | null;
      notes?: string | null;
    }>;
    studentAssignments: Array<{
      __typename?: 'StudentAssignment';
      classId?: string | null;
      assignmentId: string;
      studentId: string;
      name: string;
      id: string;
      grade?: string | null;
      startedAt?: any | null;
      submittedAt?: any | null;
      completedAt?: any | null;
      status: StudentAssignmentStatus;
      releaseFeedback: boolean;
      lateTurnIn: boolean;
      answers?: any | null;
    }>;
    pageInfo?: {
      __typename?: 'PageInfo';
      itemsPerPage?: number | null;
      totalCount?: number | null;
    } | null;
  } | null;
  class?: {
    __typename?: 'Class';
    _id: string;
    name: string;
    classStudents?: {
      __typename?: 'ClassStudentPageConnection';
      collection?: Array<{
        __typename?: 'ClassStudent';
        _id: string;
        id: string;
        student: {
          __typename?: 'Student';
          _id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type GetFeedbacksQueryVariables = Exact<{
  sessionId: Scalars['String'];
}>;

export type GetFeedbacksQuery = {
  __typename?: 'Query';
  teacherFeedback?: {
    __typename?: 'TeacherFeedback';
    responseFeedbacks?: Array<{
      __typename?: 'ResponseFeedback';
      feedback: string;
      responseId: string;
    }> | null;
  } | null;
};

export type GetFeedbacksAndScoresQueryVariables = Exact<{
  sessionId: Scalars['String'];
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type GetFeedbacksAndScoresQuery = {
  __typename?: 'Query';
  teacherFeedback?: {
    __typename?: 'TeacherFeedback';
    responseFeedbacks?: Array<{
      __typename?: 'ResponseFeedback';
      feedback: string;
      responseId: string;
    }> | null;
  } | null;
  assignments?: {
    __typename?: 'StudentAssignments';
    collection?: Array<{
      __typename?: 'StudentAssignment';
      studentId: string;
      assignmentId: string;
      id: string;
      answers?: any | null;
    }> | null;
  } | null;
};

export type GetFeedbacksAndScoresForDaQueryVariables = Exact<{
  sessionId: Scalars['String'];
  schoolId: Scalars['String'];
  studentId?: InputMaybe<Scalars['String']>;
}>;

export type GetFeedbacksAndScoresForDaQuery = {
  __typename?: 'Query';
  teacherFeedback?: {
    __typename?: 'TeacherFeedback';
    responseFeedbacks?: Array<{
      __typename?: 'ResponseFeedback';
      feedback: string;
      responseId: string;
    }> | null;
  } | null;
  assignments?: {
    __typename?: 'DistrictStudentAssignmentsType';
    collection: Array<{
      __typename?: 'DistrictStudentAssignmentType';
      studentId: string;
      assignmentId: string;
      id: string;
      answers?: any | null;
    }>;
  } | null;
};

export type SignRequestForAssessmentQuestionsMutationVariables = Exact<{
  params: SignRequestInput;
}>;

export type SignRequestForAssessmentQuestionsMutation = {
  __typename?: 'Mutation';
  signRequest?:
    | { __typename?: 'ItemSignedRequest'; signedRequest: any }
    | { __typename?: 'ReportSignedRequest' }
    | null;
};

export type CreateSignRequestForAssessmentQuestionsMutationVariables = Exact<{
  input?: InputMaybe<CreateSignRequestInput>;
}>;

export type CreateSignRequestForAssessmentQuestionsMutation = {
  __typename?: 'Mutation';
  createSignRequest: {
    __typename?: 'SignRequestPayload';
    signedRequest: string;
  };
};

export type GetTeacherStudentAssignmentsQueryVariables = Exact<{
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  teachersId?: InputMaybe<Scalars['String']>;
}>;

export type GetTeacherStudentAssignmentsQuery = {
  __typename?: 'Query';
  teacherStudentAssignments?: {
    __typename?: 'StudentAssignments';
    collection?: Array<{
      __typename?: 'StudentAssignment';
      id: string;
      answers?: any | null;
      assignmentId: string;
      studentId: string;
      name: string;
      completedAt?: any | null;
      grade?: string | null;
      startedAt?: any | null;
      status: StudentAssignmentStatus;
      submittedAt?: any | null;
      endDate?: string | null;
      startDate?: string | null;
      lateTurnIn: boolean;
      shareResults?: ShareResults | null;
      returnedAt?: any | null;
      resetted?: boolean | null;
      studentNotes?: string | null;
      archived?: boolean | null;
      classId?: string | null;
    }> | null;
  } | null;
  user?: {
    __typename?: 'User';
    _id: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{ __typename?: 'Class'; _id: string } | null> | null;
  } | null;
};

export type ResetAssignmentMutationVariables = Exact<{
  sessionsParams: ResetAssignmentInput;
}>;

export type ResetAssignmentMutation = {
  __typename?: 'Mutation';
  resetAssignment?: { __typename?: 'Success'; success?: boolean | null } | null;
};

export type ReopenAssignmentMutationVariables = Exact<{
  sessionsParams: ReopenAssignmentInput;
}>;

export type ReopenAssignmentMutation = {
  __typename?: 'Mutation';
  reopenAssignment?: {
    __typename?: 'Success';
    success?: boolean | null;
  } | null;
};

export type ReturnAssignmentMutationVariables = Exact<{
  sessionsParams: ReturnAssignmentInput;
}>;

export type ReturnAssignmentMutation = {
  __typename?: 'Mutation';
  returnAssignment?: {
    __typename?: 'Success';
    success?: boolean | null;
  } | null;
};

export type ShareAssignmentResultsMutationVariables = Exact<{
  params: PutActivitiesShareResultsInput;
}>;

export type ShareAssignmentResultsMutation = {
  __typename?: 'Mutation';
  putActivitiesShareResults?: {
    __typename?: 'Success';
    success?: boolean | null;
  } | null;
};

export type ShareDistrictAssignmentResultsMutationVariables = Exact<{
  params: DistrictAssignmentsShareResultsInput;
}>;

export type ShareDistrictAssignmentResultsMutation = {
  __typename?: 'Mutation';
  putDistrictAssignmentsShareResults?: {
    __typename?: 'DistrictStudentAssignmentsType';
    collection: Array<{
      __typename?: 'DistrictStudentAssignmentType';
      shareResults?: ShareResults | null;
    }>;
  } | null;
};

export type CreateStemscopesAssessmentMutationVariables = Exact<{
  input: StemscopesCreateAssessmentInput;
}>;

export type CreateStemscopesAssessmentMutation = {
  __typename?: 'Mutation';
  createAssessment: {
    __typename?: 'AssessmentPayload';
    stemscopesPayload?: {
      __typename?: 'StemscopesAssessmentPayload';
      item?: {
        __typename?: 'StemscopesAssessmentItemPayload';
        id: string;
        title?: string | null;
        items: Array<string>;
        isArchived?: boolean | null;
        isShared?: boolean | null;
        usersViewOnlyIds: Array<string>;
        library?: StemscopesLibraryEnum | null;
        deliveries: Array<DeliveryEnum>;
      } | null;
    } | null;
  };
};

export type SaveGradingSessionMutationVariables = Exact<{
  sessionsParams: Array<SaveGradingSessionInput> | SaveGradingSessionInput;
}>;

export type SaveGradingSessionMutation = {
  __typename?: 'Mutation';
  saveGradingSession?: {
    __typename?: 'GradingSession';
    success?: boolean | null;
  } | null;
};

export type SignRequestForGradingMutationVariables = Exact<{
  params: SignRequestInput;
}>;

export type SignRequestForGradingMutation = {
  __typename?: 'Mutation';
  signRequest?:
    | { __typename?: 'ItemSignedRequest'; signedRequest: any }
    | { __typename?: 'ReportSignedRequest'; signedRequest: any }
    | null;
};

export type ResetAssignmentStatusMutationVariables = Exact<{
  activityParams: ActivityParamsInput;
}>;

export type ResetAssignmentStatusMutation = {
  __typename?: 'Mutation';
  startStemscopesAssignment?: {
    __typename?: 'Success';
    success?: boolean | null;
  } | null;
};

export type SignRequestForStudentAssignmentMutationVariables = Exact<{
  params: SignRequestInput;
}>;

export type SignRequestForStudentAssignmentMutation = {
  __typename?: 'Mutation';
  signRequest?:
    | { __typename?: 'ItemSignedRequest'; signedRequest: any }
    | { __typename?: 'ReportSignedRequest'; signedRequest: any }
    | null;
};

export type SignRequestForDistrictAssignmentMutationVariables = Exact<{
  params: DistrictAssignmentSignRequestInput;
}>;

export type SignRequestForDistrictAssignmentMutation = {
  __typename?: 'Mutation';
  daSignRequest?: {
    __typename?: 'DistrictAssignmentSignRequestType';
    sign?: string | null;
  } | null;
};

export type StartDistrictAssignmentMutationVariables = Exact<{
  params: MakeAvailableDistrictAssignmentInput;
}>;

export type StartDistrictAssignmentMutation = {
  __typename?: 'Mutation';
  makeAvailableDistrictAssignment?: {
    __typename?: 'DistrictClassAssignmentType';
    daId: string;
  } | null;
};

export type GetCurriculumAreasQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetCurriculumAreasQuery = {
  __typename?: 'Query';
  curriculumAreas?: {
    __typename?: 'CurriculumAreas';
    curriculumAreas?: Array<string> | null;
  } | null;
};

export type GetActiveContentGroupsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveContentGroupsQuery = {
  __typename?: 'Query';
  activeContentGroups: Array<{ __typename?: 'ContentGroup'; id: string }>;
};

export type GetActiveContentGroupsWithSchoolsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveContentGroupsWithSchoolsQuery = {
  __typename?: 'Query';
  activeContentGroupsWithSchools: Array<{
    __typename?: 'ContentGroupWithSchoolPayload';
    contentGroups?: Array<string> | null;
    schoolId: string;
  }>;
};

export type AddStudentToClassesMutationVariables = Exact<{
  input: AddStudentToClassesInput;
}>;

export type AddStudentToClassesMutation = {
  __typename?: 'Mutation';
  addStudentToClasses?: {
    __typename?: 'addStudentToClassesPayload';
    classes?: { __typename?: 'Classes'; id: string } | null;
  } | null;
};

export type AddStudentsToClassMutationVariables = Exact<{
  input: AddStudentsToClassInput;
}>;

export type AddStudentsToClassMutation = {
  __typename?: 'Mutation';
  addStudentsToClass?: {
    __typename?: 'addStudentsToClassPayload';
    class?: { __typename?: 'Class'; id: string } | null;
  } | null;
};

export type CreateClassMutationVariables = Exact<{
  input: CreateClassInput;
}>;

export type CreateClassMutation = {
  __typename?: 'Mutation';
  createClass?: {
    __typename?: 'createClassPayload';
    class?: { __typename?: 'Class'; _id: string } | null;
  } | null;
};

export type RemoveStudentFromClassesMutationVariables = Exact<{
  input: RemoveStudentFromClassesInput;
}>;

export type RemoveStudentFromClassesMutation = {
  __typename?: 'Mutation';
  removeStudentFromClasses?: {
    __typename?: 'removeStudentFromClassesPayload';
    classes?: { __typename?: 'Classes'; id: string } | null;
  } | null;
};

export type RemoveStudentsFromClassMutationVariables = Exact<{
  input: RemoveStudentsFromClassInput;
}>;

export type RemoveStudentsFromClassMutation = {
  __typename?: 'Mutation';
  removeStudentsFromClass?: {
    __typename?: 'removeStudentsFromClassPayload';
    class?: { __typename?: 'Class'; id: string } | null;
  } | null;
};

export type GetDashboardEmbedUrlQueryVariables = Exact<{
  dashboardID: Scalars['String'];
  sheetID?: InputMaybe<Scalars['String']>;
  visualID?: InputMaybe<Scalars['String']>;
}>;

export type GetDashboardEmbedUrlQuery = {
  __typename?: 'Query';
  dashboardEmbedUrl: { __typename?: 'EmbedUrlResponse'; EmbedUrl: string };
};

export type GetClassesForSelectInputQueryVariables = Exact<{
  teachersId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetClassesForSelectInputQuery = {
  __typename?: 'Query';
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      id: string;
      name: string;
      schoolId: string;
      classTeachers?: {
        __typename?: 'ClassTeacherPageConnection';
        collection?: Array<{
          __typename?: 'ClassTeacher';
          teacherId: string;
          mainTeacher: boolean;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetClassesNamesQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  studentsId?: InputMaybe<Scalars['String']>;
}>;

export type GetClassesNamesQuery = {
  __typename?: 'Query';
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      id: string;
      name: string;
      schoolId: string;
      curriculumArea?: CurriculumAreaEnum | null;
      gradeOrCourse?: string | null;
    } | null> | null;
  } | null;
};

export type GetClassesForDaDetailsQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
  teachersId?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  id_list?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetClassesForDaDetailsQuery = {
  __typename?: 'Query';
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      name: string;
    } | null> | null;
  } | null;
};

export type GetStudentsInClassesQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
  teachersId: Scalars['String'];
  archived?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetStudentsInClassesQuery = {
  __typename?: 'Query';
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      id: string;
      gradeOrCourse?: string | null;
      classStudents?: {
        __typename?: 'ClassStudentPageConnection';
        collection?: Array<{
          __typename?: 'ClassStudent';
          student: {
            __typename?: 'Student';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetStudentTeachersQueryVariables = Exact<{
  filter?: InputMaybe<Array<InputMaybe<ClassFilter>> | InputMaybe<ClassFilter>>;
}>;

export type GetStudentTeachersQuery = {
  __typename?: 'Query';
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      classTeachers?: {
        __typename?: 'ClassTeacherPageConnection';
        collection?: Array<{
          __typename?: 'ClassTeacher';
          teacher: {
            __typename?: 'Teacher';
            firstName?: string | null;
            lastName?: string | null;
            id: string;
          };
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetClassesForReportFilterQueryVariables = Exact<{
  teachersId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  archived?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetClassesForReportFilterQuery = {
  __typename?: 'Query';
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      id: string;
      name: string;
      schoolId: string;
    } | null> | null;
  } | null;
};

export type PutAssignmentMutationVariables = Exact<{
  assignmentParams: PutAssignmentInput;
}>;

export type PutAssignmentMutation = {
  __typename?: 'Mutation';
  putAssignment?: Array<
    | { __typename?: 'AssignmentId'; assignmentId?: string | null }
    | { __typename?: 'DistrictAssignmentType'; daId: string }
    | { __typename?: 'EdgexlAssignmentType' }
    | { __typename?: 'LtiEdgexlAssignmentType' }
    | {
        __typename?: 'StemscopesAssignment';
        learnosityReferenceId?: string | null;
      }
  > | null;
};

export type DistrictQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DistrictQuery = {
  __typename?: 'Query';
  district?: {
    __typename?: 'District';
    externalId?: string | null;
    state: StateEnum;
    globalName: string;
    localName: string;
  } | null;
};

export type GetAllAssignmentsQueryVariables = Exact<{
  teachersId: Scalars['String'];
  schoolId: Scalars['String'];
  archivedClasses?: InputMaybe<Scalars['Boolean']>;
  archivedAssignments?: InputMaybe<Scalars['Boolean']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  assignmentDateFilter?: InputMaybe<AssignmentDateFilterInput>;
  paginationInfo?: InputMaybe<StemscopesAssignmentPageInfoInput>;
}>;

export type GetAllAssignmentsQuery = {
  __typename?: 'Query';
  ownerAssignments?: {
    __typename?: 'OwnerAssignments';
    assignments: Array<{
      __typename?: 'Assignment';
      ownerId: string;
      classId: string;
      assignmentListId: string;
      assignmentId: string;
      learnosityReferenceId?: string | null;
      name: string;
      releaseFeedback?: boolean | null;
      startDate: any;
      endDate: any;
      status: AssignmentStatus;
      subgroupIds?: Array<string> | null;
      studentIds?: Array<string> | null;
      labels?: Array<string> | null;
      notes?: string | null;
    }>;
    studentAssignments: Array<{
      __typename?: 'StudentAssignment';
      classId?: string | null;
      assignmentId: string;
      studentId: string;
      name: string;
      id: string;
      grade?: string | null;
      startedAt?: any | null;
      submittedAt?: any | null;
      completedAt?: any | null;
      status: StudentAssignmentStatus;
      releaseFeedback: boolean;
      lateTurnIn: boolean;
      answers?: any | null;
      learnosityReferenceId?: string | null;
    }>;
    pageInfo?: {
      __typename?: 'PageInfo';
      itemsPerPage?: number | null;
      totalCount?: number | null;
    } | null;
  } | null;
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      name: string;
      schoolId: string;
      classStudents?: {
        __typename?: 'ClassStudentPageConnection';
        collection?: Array<{
          __typename?: 'ClassStudent';
          student: { __typename?: 'Student'; _id: string };
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  teacherStudents?: {
    __typename?: 'StudentPageConnection';
    collection?: Array<{
      __typename?: 'Student';
      _id: string;
      firstName?: string | null;
      lastName?: string | null;
      username: string;
    } | null> | null;
  } | null;
};

export type GetClassesAndStudentsQueryVariables = Exact<{
  teachersId: Scalars['String'];
  schoolId: Scalars['String'];
  archivedClasses?: InputMaybe<Scalars['Boolean']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetClassesAndStudentsQuery = {
  __typename?: 'Query';
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      name: string;
      schoolId: string;
      classStudents?: {
        __typename?: 'ClassStudentPageConnection';
        collection?: Array<{
          __typename?: 'ClassStudent';
          student: { __typename?: 'Student'; _id: string };
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  teacherStudents?: {
    __typename?: 'StudentPageConnection';
    collection?: Array<{
      __typename?: 'Student';
      _id: string;
      firstName?: string | null;
      lastName?: string | null;
      username: string;
    } | null> | null;
  } | null;
};

export type GetAllAssignmentsNewQueryVariables = Exact<{
  schoolId: Scalars['String'];
  archivedAssignments?: InputMaybe<Scalars['Boolean']>;
  assignmentDateFilter?: InputMaybe<AssignmentDateFilterInput>;
  paginationInfo?: InputMaybe<StemscopesAssignmentPageInfoInput>;
  classIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAllAssignmentsNewQuery = {
  __typename?: 'Query';
  ownerAssignments?: {
    __typename?: 'OwnerAssignments';
    assignments: Array<{
      __typename?: 'Assignment';
      ownerId: string;
      classId: string;
      assignmentListId: string;
      assignmentId: string;
      learnosityReferenceId?: string | null;
      name: string;
      releaseFeedback?: boolean | null;
      startDate: any;
      endDate: any;
      status: AssignmentStatus;
      subgroupIds?: Array<string> | null;
      studentIds?: Array<string> | null;
      labels?: Array<string> | null;
      notes?: string | null;
    }>;
    studentAssignments: Array<{
      __typename?: 'StudentAssignment';
      classId?: string | null;
      assignmentId: string;
      studentId: string;
      name: string;
      id: string;
      grade?: string | null;
      startedAt?: any | null;
      submittedAt?: any | null;
      completedAt?: any | null;
      status: StudentAssignmentStatus;
      releaseFeedback: boolean;
      lateTurnIn: boolean;
      answers?: any | null;
      learnosityReferenceId?: string | null;
    }>;
    pageInfo?: {
      __typename?: 'PageInfo';
      itemsPerPage?: number | null;
      totalCount?: number | null;
    } | null;
  } | null;
};

export type PutDistrictStudentAssignmentsMutationVariables = Exact<{
  input: PutDistrictStudentAssignmentsInput;
}>;

export type PutDistrictStudentAssignmentsMutation = {
  __typename?: 'Mutation';
  putDistrictStudentAssignments?: {
    __typename?: 'DistrictStudentAssignmentsType';
    collection: Array<{
      __typename?: 'DistrictStudentAssignmentType';
      assessmentId: string;
    }>;
  } | null;
};

export type GetClassQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClassQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    _id: string;
    classTeachers?: {
      __typename?: 'ClassTeacherPageConnection';
      collection?: Array<{
        __typename?: 'ClassTeacher';
        _id: string;
        teacherId: string;
        accesses?: Array<AccessesEnum | null> | null;
        mainTeacher: boolean;
        subgroups?: {
          __typename?: 'StudentSubgroupPageConnection';
          collection?: Array<{
            __typename?: 'StudentSubgroup';
            id: string;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetClassStudentsQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetClassStudentsQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    _id: string;
    id: string;
    name: string;
    classStudents?: {
      __typename?: 'ClassStudentPageConnection';
      collection?: Array<{
        __typename?: 'ClassStudent';
        student: {
          __typename?: 'Student';
          id: string;
          _id: string;
          sisId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          username: string;
          gradeLevel?: GradeLevelEnum | null;
          plainPassword?: string | null;
          authenticationToken?: string | null;
        };
        subgroups?: {
          __typename?: 'StudentSubgroupPageConnection';
          collection?: Array<{
            __typename?: 'StudentSubgroup';
            _id: string;
            name: string;
          } | null> | null;
        } | null;
      } | null> | null;
      paginationInfo: {
        __typename?: 'ClassStudentPaginationInfo';
        itemsPerPage: number;
        totalCount: number;
        lastPage: number;
      };
    } | null;
  } | null;
};

export type GetClassStudentsForManageQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetClassStudentsForManageQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    id: string;
    name: string;
    classStudents?: {
      __typename?: 'ClassStudentPageConnection';
      collection?: Array<{
        __typename?: 'ClassStudent';
        student: {
          __typename?: 'Student';
          _id: string;
          firstName?: string | null;
          lastName?: string | null;
          gradeLevel?: GradeLevelEnum | null;
          username: string;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type GetClassNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClassNameQuery = {
  __typename?: 'Query';
  class?: { __typename?: 'Class'; name: string } | null;
};

export type GetClassTeachersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClassTeachersQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    id: string;
    name: string;
    classTeachers?: {
      __typename?: 'ClassTeacherPageConnection';
      collection?: Array<{
        __typename?: 'ClassTeacher';
        teacherId: string;
        mainTeacher: boolean;
      } | null> | null;
    } | null;
  } | null;
};

export type GetClassForStudentNotebookQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClassForStudentNotebookQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    name: string;
    gradeOrCourse?: string | null;
    classTeachers?: {
      __typename?: 'ClassTeacherPageConnection';
      collection?: Array<{
        __typename?: 'ClassTeacher';
        mainTeacher: boolean;
        teacher: {
          __typename?: 'Teacher';
          lastName?: string | null;
          firstName?: string | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type GetClassForClassFormQueryVariables = Exact<{
  id: Scalars['ID'];
  activeTeachers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetClassForClassFormQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    _id: string;
    id: string;
    name: string;
    language: string;
    curriculumArea?: CurriculumAreaEnum | null;
    gradeOrCourse?: string | null;
    archived: boolean;
    externalId?: string | null;
    subgroups?: {
      __typename?: 'StudentSubgroupPageConnection';
      collection?: Array<{
        __typename?: 'StudentSubgroup';
        id: string;
        name: string;
      } | null> | null;
    } | null;
    classTeachers?: {
      __typename?: 'ClassTeacherPageConnection';
      collection?: Array<{
        __typename?: 'ClassTeacher';
        _id: string;
        teacherId: string;
        accesses?: Array<AccessesEnum | null> | null;
        mainTeacher: boolean;
        orderListStudents: string;
        subgroups?: {
          __typename?: 'StudentSubgroupPageConnection';
          collection?: Array<{
            __typename?: 'StudentSubgroup';
            id: string;
            name: string;
          } | null> | null;
        } | null;
        teacher: {
          __typename?: 'Teacher';
          _id: string;
          lastName?: string | null;
          firstName?: string | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type GetClassForAssignmentFormQueryVariables = Exact<{
  classId: Scalars['ID'];
}>;

export type GetClassForAssignmentFormQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    curriculumArea?: CurriculumAreaEnum | null;
    gradeOrCourse?: string | null;
    name: string;
  } | null;
};

export type GetClassStudentsForAssignmentFormQueryVariables = Exact<{
  classId: Scalars['ID'];
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetClassStudentsForAssignmentFormQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    _id: string;
    id: string;
    name: string;
    curriculumArea?: CurriculumAreaEnum | null;
    gradeOrCourse?: string | null;
    classStudents?: {
      __typename?: 'ClassStudentPageConnection';
      collection?: Array<{
        __typename?: 'ClassStudent';
        _id: string;
        student: {
          __typename?: 'Student';
          _id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type GetClassSubgroupsForAssignmentFormQueryVariables = Exact<{
  classId: Scalars['ID'];
}>;

export type GetClassSubgroupsForAssignmentFormQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    curriculumArea?: CurriculumAreaEnum | null;
    gradeOrCourse?: string | null;
    name: string;
    subgroups?: {
      __typename?: 'StudentSubgroupPageConnection';
      collection?: Array<{
        __typename?: 'StudentSubgroup';
        _id: string;
        name: string;
      } | null> | null;
    } | null;
  } | null;
};

export type GetDistrictsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<
    Array<InputMaybe<DistrictFilter_Order>> | InputMaybe<DistrictFilter_Order>
  >;
  filter?: InputMaybe<
    Array<InputMaybe<DistrictFilter>> | InputMaybe<DistrictFilter>
  >;
}>;

export type GetDistrictsQuery = {
  __typename?: 'Query';
  districts?: {
    __typename?: 'DistrictPageConnection';
    collection?: Array<{
      __typename?: 'District';
      _id: string;
      globalName: string;
      state: StateEnum;
      mathNationId?: string | null;
    } | null> | null;
    paginationInfo: {
      __typename?: 'DistrictPaginationInfo';
      totalCount: number;
      lastPage: number;
      itemsPerPage: number;
    };
  } | null;
};

export type AllDistrictAssignmentQueryVariables = Exact<{
  archivedClasses?: InputMaybe<Scalars['Boolean']>;
  teachersId: Scalars['String'];
  schoolId: Scalars['String'];
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<DistrictAssignmentsFilter>;
}>;

export type AllDistrictAssignmentQuery = {
  __typename?: 'Query';
  districtAssignments?: {
    __typename?: 'DistrictClassAssignmentsType';
    collection: Array<{
      __typename?: 'DistrictClassAssignmentType';
      assessmentId: string;
      assignmentId: string;
      availableDate?: any | null;
      classId: string;
      daId: string;
      districtId: string;
      endDate: any;
      gradeLevel: string;
      name: string;
      schoolId: string;
      startDate: any;
      teacherId: string;
      assignmentSource?: {
        __typename?: 'AssignmentSource';
        assessment?: {
          __typename?: 'AssignmentSourceAssessment';
          assessmentId: string;
        } | null;
      } | null;
      studentAssignments?: Array<{
        __typename?: 'DistrictStudentAssignmentType';
        name: string;
        studentId: string;
        answers?: any | null;
        grade?: string | null;
        classId: string;
        status?: StudentAssignmentStatus | null;
        completedAt?: any | null;
        submittedAt?: any | null;
      }> | null;
    }>;
  } | null;
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      name: string;
      schoolId: string;
      classStudents?: {
        __typename?: 'ClassStudentPageConnection';
        collection?: Array<{
          __typename?: 'ClassStudent';
          student: {
            __typename?: 'Student';
            _id: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  teacherStudents?: {
    __typename?: 'StudentPageConnection';
    collection?: Array<{
      __typename?: 'Student';
      _id: string;
      firstName?: string | null;
      lastName?: string | null;
      username: string;
    } | null> | null;
  } | null;
};

export type GetPublicDistrictQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPublicDistrictQuery = {
  __typename?: 'Query';
  publicDistrict: {
    __typename?: 'NewDistrict';
    id: string;
    globalName: string;
    state: string;
  };
};

export type GetGradesQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetGradesQuery = {
  __typename?: 'Query';
  grades?: { __typename?: 'Grades'; grades: Array<string> } | null;
};

export type GetPublicDistrictsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateEnum>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
}>;

export type GetPublicDistrictsQuery = {
  __typename?: 'Query';
  newDistricts: {
    __typename?: 'NewDistrictPageConnection';
    collection: Array<{
      __typename?: 'NewDistrict';
      globalName: string;
      id: string;
      city?: string | null;
    }>;
  };
};

export type GetPreviewDistrictsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetPreviewDistrictsQuery = {
  __typename?: 'Query';
  previewAccountDistricts: {
    __typename?: 'NewDistrictPageConnection';
    collection: Array<{
      __typename?: 'NewDistrict';
      id: string;
      globalName: string;
      state: string;
      urlCode?: string | null;
    }>;
  };
};

export type OpenLtiAssignmentLinkMutationVariables = Exact<{
  assignmentParams: PutAssignmentInput;
  assignmentId: Scalars['String'];
  ownerId: Scalars['String'];
  curriculumArea: Scalars['String'];
  gradeLevel: Scalars['String'];
}>;

export type OpenLtiAssignmentLinkMutation = {
  __typename?: 'Mutation';
  openLtiAssignmentLink?:
    | { __typename?: 'AssignmentId' }
    | { __typename?: 'DistrictAssignmentType' }
    | { __typename?: 'EdgexlAssignmentType' }
    | { __typename?: 'LtiEdgexlAssignmentType' }
    | {
        __typename?: 'StemscopesAssignment';
        assignmentId: string;
        classId: string;
      }
    | null;
};

export type GetAthenaDistrictsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  ordering?: InputMaybe<DistrictSort>;
  filter?: InputMaybe<DistrictFilter>;
}>;

export type GetAthenaDistrictsQuery = {
  __typename?: 'Query';
  athenaDistricts?: {
    __typename?: 'athenaDistrictPageConnection';
    collection?: Array<{
      __typename?: 'athenaDistrict';
      _id: string;
      globalName: string;
      state: string;
      source: DistrictSourceEnum;
      mathnationId?: string | null;
    } | null> | null;
    paginationInfo: {
      __typename?: 'athenaDistrictPaginationInfo';
      totalCount: number;
      lastPage: number;
      itemsPerPage: number;
    };
  } | null;
};

export type GetHelpPageQueryVariables = Exact<{
  input: HelpPageInput;
}>;

export type GetHelpPageQuery = {
  __typename?: 'Query';
  helpPage: { __typename?: 'HelpPageStructure'; link: string };
};

export type SubmitContentItemLinksMutationVariables = Exact<{
  contentItems: Array<ContentItem> | ContentItem;
  schoolId: Scalars['String'];
}>;

export type SubmitContentItemLinksMutation = {
  __typename?: 'Mutation';
  submitContentItemLinks: {
    __typename?: 'ContentItemLinkData';
    ltiVersion: string;
    url: string;
    fields: { __typename?: 'GpbJwt'; JWT: string };
  };
};

export type PublishEventMutationVariables = Exact<{
  input: EventInput;
}>;

export type PublishEventMutation = {
  __typename?: 'Mutation';
  publishEvent: { __typename?: 'EventStatusPayload'; success: boolean };
};

export type GetNotebooksQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
}>;

export type GetNotebooksQuery = {
  __typename?: 'Query';
  studentNotebooks?: {
    __typename?: 'StudentNotebooks';
    collection?: Array<{
      __typename?: 'StudentNotebook';
      classId: string;
      scopeIds?: Array<string> | null;
    }> | null;
  } | null;
};

export type GetStudentParentsQueryVariables = Exact<{
  studentId: Scalars['String'];
}>;

export type GetStudentParentsQuery = {
  __typename?: 'Query';
  parents?: Array<{
    __typename?: 'Parent';
    _id?: string | null;
    id: string;
    email: string;
    inviteCount: number;
    inviteStatus: ParentInviteStatusEnum;
    invitedAt: string;
  } | null> | null;
};

export type GetIconQueryVariables = Exact<{
  filter?: InputMaybe<FilterIconInput>;
}>;

export type GetIconQuery = {
  __typename?: 'Query';
  icons: {
    __typename?: 'IconPageConnection';
    collection: Array<{
      __typename?: 'Icon';
      filePath: string;
      languageFilePath: Array<{
        __typename?: 'LanguageFilePath';
        languageCode: string;
        filePath: string;
      }>;
    }>;
  };
};

export type VerifyEmailsParentMutationVariables = Exact<{
  input: VerifyEmailsParentInput;
}>;

export type VerifyEmailsParentMutation = {
  __typename?: 'Mutation';
  verifyEmailsParent?: {
    __typename?: 'verifyEmailsParentPayload';
    parent?: {
      __typename?: 'verifyEmailsParentPayloadData';
      results: any;
    } | null;
  } | null;
};

export type InviteParentMutationVariables = Exact<{
  input: InviteParentInput;
}>;

export type InviteParentMutation = {
  __typename?: 'Mutation';
  inviteParent?: {
    __typename?: 'inviteParentPayload';
    parent?: { __typename?: 'inviteParentPayloadData'; id: string } | null;
  } | null;
};

export type GetAssignmentsQueryVariables = Exact<{
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type GetAssignmentsQuery = {
  __typename?: 'Query';
  studentStudentAssignments?: {
    __typename?: 'StudentAssignments';
    collection?: Array<{
      __typename?: 'StudentAssignment';
      answers?: any | null;
      assignmentId: string;
      assessmentId?: string | null;
      randomizeQuestionsOrder?: boolean | null;
      startDate?: string | null;
      endDate?: string | null;
      lateTurnIn: boolean;
      id: string;
      learnosityReferenceId?: string | null;
      otherAssignmentScope?: string | null;
      name: string;
      shareResults?: ShareResults | null;
      ownerId?: string | null;
      returnedAt?: any | null;
      submittedAt?: any | null;
      resetted?: boolean | null;
      status: StudentAssignmentStatus;
      studentNotes?: string | null;
      archived?: boolean | null;
      classId?: string | null;
      assignmentSource?: {
        __typename?: 'AssignmentSource';
        element?: {
          __typename?: 'Element';
          elementId: string;
          scopeId: string;
          sectionId: string;
          viewId?: string | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type CheckAssignmentsOnExistenceQueryVariables = Exact<{
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId: Scalars['String'];
  studentId?: InputMaybe<Scalars['String']>;
}>;

export type CheckAssignmentsOnExistenceQuery = {
  __typename?: 'Query';
  studentStudentAssignments?: {
    __typename?: 'StudentAssignments';
    collection?: Array<{
      __typename?: 'StudentAssignment';
      id: string;
      archived?: boolean | null;
    }> | null;
  } | null;
  districtStudentAssignments?: {
    __typename?: 'DistrictStudentAssignmentsType';
    collection: Array<{
      __typename?: 'DistrictStudentAssignmentType';
      assignmentId: string;
    }>;
  } | null;
};

export type RestrictInvitationMutationVariables = Exact<{
  input: RestrictInvitationParentInput;
}>;

export type RestrictInvitationMutation = {
  __typename?: 'Mutation';
  restrictInvitationParent?: {
    __typename?: 'restrictInvitationParentPayload';
    parent?: {
      __typename?: 'restrictInvitationParentPayloadData';
      id: string;
    } | null;
  } | null;
};

export type CalendarTemplatesQueryVariables = Exact<{
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type CalendarTemplatesQuery = {
  __typename?: 'Query';
  calendarTemplates?: {
    __typename?: 'CalendarPageConnection';
    collection?: Array<{
      __typename?: 'Calendar';
      _id: string;
      id: string;
      name?: string | null;
      creator?: string | null;
      calendarEntries?: {
        __typename?: 'EntryPageConnection';
        collection?: Array<{
          __typename?: 'Entry';
          id: string;
          _id: string;
          eventId?: string | null;
          name: string;
          type: EntryTypeEnum;
          startDate: string;
          endDate?: string | null;
          editable?: boolean | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetParentStudentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetParentStudentsQuery = {
  __typename?: 'Query';
  parentStudents?: Array<{
    __typename?: 'ParentStudent';
    student: {
      __typename?: 'Student';
      _id: string;
      firstName?: string | null;
      lastName?: string | null;
      gradeLevel?: GradeLevelEnum | null;
      classes?: {
        __typename?: 'ClassPageConnection';
        collection?: Array<{
          __typename?: 'Class';
          _id: string;
          gradeOrCourse?: string | null;
          schoolId: string;
          school: { __typename?: 'School'; id: string; localName: string };
        } | null> | null;
      } | null;
    };
  } | null> | null;
};

export type CalendarUsersQueryVariables = Exact<{
  calendarId?: InputMaybe<Scalars['String']>;
  accessType?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
}>;

export type CalendarUsersQuery = {
  __typename?: 'Query';
  userCalendars?: {
    __typename?: 'UserCalendarPageConnection';
    collection?: Array<{
      __typename?: 'UserCalendar';
      userId?: string | null;
    } | null> | null;
  } | null;
};

export type CalendarsQueryVariables = Exact<{
  calendarId?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type CalendarsQuery = {
  __typename?: 'Query';
  calendars?: {
    __typename?: 'CalendarPageConnection';
    collection?: Array<{
      __typename?: 'Calendar';
      _id: string;
      pk: string;
      name?: string | null;
      isDefault?: boolean | null;
      calendarTemplateId?: string | null;
      version?: number | null;
      editable?: boolean | null;
      calendarType?: CalendarTypeEnum | null;
      userId?: string | null;
      calendarEntries?: {
        __typename?: 'EntryPageConnection';
        collection?: Array<{
          __typename?: 'Entry';
          id: string;
          _id: string;
          eventId?: string | null;
          eventTemplateId?: string | null;
          name: string;
          type: EntryTypeEnum;
          startDate: string;
          endDate?: string | null;
          notes?: string | null;
          editable?: boolean | null;
          version?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreateEntryMutationVariables = Exact<{
  input: CreateEntryInput;
}>;

export type CreateEntryMutation = {
  __typename?: 'Mutation';
  createEntry?: {
    __typename?: 'createEntryPayload';
    entry?: { __typename?: 'Entry'; _id: string } | null;
  } | null;
};

export type CreateCalendarMutationVariables = Exact<{
  input: CreateCalendarInput;
}>;

export type CreateCalendarMutation = {
  __typename?: 'Mutation';
  createCalendar?: {
    __typename?: 'createCalendarPayload';
    calendar?: {
      __typename?: 'Calendar';
      _id: string;
      name?: string | null;
      pk: string;
    } | null;
  } | null;
};

export type CreatePacingGuideMutationVariables = Exact<{
  input: CreatePacingGuideInput;
}>;

export type CreatePacingGuideMutation = {
  __typename?: 'Mutation';
  createPacingGuide?: {
    __typename?: 'createPacingGuidePayload';
    pacingGuide?: {
      __typename?: 'PacingGuide';
      _id: string;
      id: string;
      name?: string | null;
      version?: number | null;
      pacingGuideId?: string | null;
      selectedCalendar?: any | null;
      curriculumArea?: string | null;
      grade?: string | null;
      scopeEditable?: boolean | null;
      pacingGuideType?: PacingGuideTypeEnum | null;
    } | null;
  } | null;
};

export type CalendarTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CalendarTemplateQuery = {
  __typename?: 'Query';
  calendarTemplate?: {
    __typename?: 'Calendar';
    _id: string;
    id: string;
    name?: string | null;
    version?: number | null;
    creator?: string | null;
    calendarEntries?: {
      __typename?: 'EntryPageConnection';
      collection?: Array<{
        __typename?: 'Entry';
        id: string;
        _id: string;
        eventId?: string | null;
        name: string;
        type: EntryTypeEnum;
        startDate: string;
        endDate?: string | null;
        editable?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCalendarQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCalendarQuery = {
  __typename?: 'Query';
  calendar?: {
    __typename?: 'Calendar';
    id: string;
    _id: string;
    pk: string;
    sk: string;
    name?: string | null;
    isDefault?: boolean | null;
    version?: number | null;
    editable?: boolean | null;
    calendarType?: CalendarTypeEnum | null;
    userId?: string | null;
    creator?: string | null;
    calendarEntries?: {
      __typename?: 'EntryPageConnection';
      collection?: Array<{
        __typename?: 'Entry';
        id: string;
        _id: string;
        eventId?: string | null;
        name: string;
        type: EntryTypeEnum;
        startDate: string;
        endDate?: string | null;
        notes?: string | null;
        version?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ClearTemplateUpdatedNotificationMutationVariables = Exact<{
  notificationCalendar?: InputMaybe<Scalars['Iterable']>;
  notificationPacingGuide?: InputMaybe<Scalars['Iterable']>;
  version: Scalars['Int'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userRoleCode?: InputMaybe<Scalars['String']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
}>;

export type ClearTemplateUpdatedNotificationMutation = {
  __typename?: 'Mutation';
  updateUserSelection?: {
    __typename?: 'updateUserSelectionPayload';
    userSelection?: { __typename?: 'UserSelection'; id: string } | null;
  } | null;
};

export type GetElementsFromPlanQueryVariables = Exact<{
  scopeId?: InputMaybe<Scalars['String']>;
  scopePlanId: Scalars['String'];
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetElementsFromPlanQuery = {
  __typename?: 'Query';
  scopePlanScopes?: {
    __typename?: 'ScopePlanScopePageConnection';
    collection?: Array<{
      __typename?: 'ScopePlanScope';
      elements?: any | null;
      startDate?: string | null;
      endDate?: string | null;
      version?: number | null;
    } | null> | null;
  } | null;
};

export type GetPacingGuideQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPacingGuideQuery = {
  __typename?: 'Query';
  pacingGuide?: {
    __typename?: 'PacingGuide';
    _id: string;
    id: string;
    name?: string | null;
    pacingGuideId?: string | null;
    pacingGuideType?: PacingGuideTypeEnum | null;
    grade?: string | null;
    userId?: string | null;
    curriculumArea?: string | null;
    selectedCalendar?: any | null;
    startDate?: string | null;
    version?: number | null;
    sharedType?: string | null;
    sharedOption?: string | null;
    sharedData?: any | null;
  } | null;
};

export type GetPlanFromFieldsQueryVariables = Exact<{
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetPlanFromFieldsQuery = {
  __typename?: 'Query';
  scopePlans?: {
    __typename?: 'ScopePlanPageConnection';
    collection?: Array<{
      __typename?: 'ScopePlan';
      name?: string | null;
      scopePlanId?: string | null;
      scopePlanType?: ScopePlanTypeEnum | null;
    } | null> | null;
  } | null;
};

export type GetPlanTemplatesListQueryVariables = Exact<{
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  grades?: InputMaybe<Scalars['Iterable']>;
  userRoleCode: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
}>;

export type GetPlanTemplatesListQuery = {
  __typename?: 'Query';
  scopePlanTemplates?: {
    __typename?: 'ScopePlanPageConnection';
    collection?: Array<{
      __typename?: 'ScopePlan';
      _id: string;
      id: string;
      curriculumArea?: string | null;
      name?: string | null;
      grade?: string | null;
    } | null> | null;
  } | null;
};

export type GetGradesFromSchoolIdQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetGradesFromSchoolIdQuery = {
  __typename?: 'Query';
  grades?: { __typename?: 'Grades'; grades: Array<string> } | null;
};

export type GetScopePlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetScopePlanQuery = {
  __typename?: 'Query';
  scopePlan?: {
    __typename?: 'ScopePlan';
    _id: string;
    id: string;
    scopePlanId?: string | null;
    name?: string | null;
    scopePlanType?: ScopePlanTypeEnum | null;
    curriculumArea?: string | null;
    grade?: string | null;
    userId?: string | null;
    pacingGuideId?: string | null;
    approachType?: ScopePlanApproachEnum | null;
    classSize?: number | null;
    classTime?: number | null;
    teacherExp?: number | null;
    sharedData?: any | null;
    sharedOption?: string | null;
    version?: number | null;
    scopes?: {
      __typename?: 'ScopePlanScopePageConnection';
      collection?: Array<{
        __typename?: 'ScopePlanScope';
        id: string;
        _id: string;
        elements?: any | null;
        startDate?: string | null;
        endDate?: string | null;
        instructionalDays?: number | null;
        order?: number | null;
        version?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateScopePlanMutationVariables = Exact<{
  input: CreateScopePlanInput;
}>;

export type CreateScopePlanMutation = {
  __typename?: 'Mutation';
  createScopePlan?: {
    __typename?: 'createScopePlanPayload';
    scopePlan?: {
      __typename?: 'ScopePlan';
      id: string;
      _id: string;
      version?: number | null;
    } | null;
  } | null;
};

export type GetScope2ForPlanQueryVariables = Exact<{
  id: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type GetScope2ForPlanQuery = {
  __typename?: 'Query';
  baseScope?: {
    __typename?: 'BaseScope';
    id: string;
    subscriptionContentGroups?: Array<string> | null;
    jsonUrl: string;
    projectId: string;
  } | null;
};

export type GetPlannerCurriculumAreasQueryVariables = Exact<{
  schoolId?: InputMaybe<Scalars['String']>;
  schoolIds?: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetPlannerCurriculumAreasQuery = {
  __typename?: 'Query';
  curriculumAreas?: {
    __typename?: 'CurriculumAreas';
    curriculumAreas?: Array<string> | null;
  } | null;
};

export type GetScopesForPlanQueryVariables = Exact<{
  filter?: InputMaybe<ScopeFilter>;
  schoolId: Scalars['String'];
}>;

export type GetScopesForPlanQuery = {
  __typename?: 'Query';
  scopes: {
    __typename?: 'Scopes';
    collection: Array<{
      __typename?: 'SearchableScope';
      id: string;
      displayName: string;
    }>;
  };
};

export type GetUniqueTemplateByNameQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
}>;

export type GetUniqueTemplateByNameQuery = {
  __typename?: 'Query';
  userCalendars?: {
    __typename?: 'UserCalendarPageConnection';
    collection?: Array<{
      __typename?: 'UserCalendar';
      _id: string;
    } | null> | null;
  } | null;
};

export type GetTemplateUpdatedNotificationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTemplateUpdatedNotificationsQuery = {
  __typename?: 'Query';
  userSelection?: {
    __typename?: 'UserSelection';
    id: string;
    _id: string;
    notificationCalendar?: any | null;
    notificationPacingGuide?: any | null;
    version?: number | null;
  } | null;
};

export type GetSuggestedRequiredPacingGuidesQueryVariables = Exact<{
  curriculumAreas?: InputMaybe<Scalars['Iterable']>;
  grades?: InputMaybe<Scalars['Iterable']>;
  userRoleCode: Scalars['String'];
  schoolIds?: InputMaybe<Scalars['Iterable']>;
  userTeamIds?: InputMaybe<Scalars['Iterable']>;
}>;

export type GetSuggestedRequiredPacingGuidesQuery = {
  __typename?: 'Query';
  pacingGuideTemplates?: {
    __typename?: 'PacingGuidePageConnection';
    collection?: Array<{
      __typename?: 'PacingGuide';
      id: string;
      _id: string;
      pacingGuideId?: string | null;
      pacingGuideType?: PacingGuideTypeEnum | null;
      name?: string | null;
      curriculumArea?: string | null;
      grade?: string | null;
      selectedCalendar?: any | null;
      startDate?: string | null;
      userId?: string | null;
      editable?: boolean | null;
      scopeEditable?: boolean | null;
      userRoleCode?: string | null;
      sharedType?: string | null;
      sharedData?: any | null;
      sharedOption?: string | null;
      schoolIds?: any | null;
      creator?: string | null;
      version?: number | null;
      pacingGuideTemplateId?: string | null;
      scopes?: {
        __typename?: 'PacingGuideScopePageConnection';
        collection?: Array<{
          __typename?: 'PacingGuideScope';
          id: string;
          _id: string;
          pacingGuideId?: string | null;
          pacingGuideType?: PacingGuideTypeEnum | null;
          scopeId?: string | null;
          name?: string | null;
          instructionalDays?: number | null;
          order?: number | null;
          type: EntryTypeEnum;
          version?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PlannerTeamsQueryVariables = Exact<{
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
}>;

export type PlannerTeamsQuery = {
  __typename?: 'Query';
  teams: {
    __typename?: 'TeamPageConnection';
    collection: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      archived: boolean;
    }>;
  };
};

export type PacingGuidesQueryVariables = Exact<{
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type PacingGuidesQuery = {
  __typename?: 'Query';
  pacingGuides?: {
    __typename?: 'PacingGuidePageConnection';
    collection?: Array<{
      __typename?: 'PacingGuide';
      id: string;
      _id: string;
      name?: string | null;
      pacingGuideId?: string | null;
      pacingGuideType?: PacingGuideTypeEnum | null;
      curriculumArea?: string | null;
      grade?: string | null;
      editable?: boolean | null;
      isSelected?: boolean | null;
      creator?: string | null;
      schoolIds?: any | null;
      userId?: string | null;
      scopeEditable?: boolean | null;
      version?: number | null;
      scopes?: {
        __typename?: 'PacingGuideScopePageConnection';
        collection?: Array<{
          __typename?: 'PacingGuideScope';
          _id: string;
          id: string;
          scopeId?: string | null;
          name?: string | null;
          instructionalDays?: number | null;
          version?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type RemoveCalendarMutationVariables = Exact<{
  input: RemoveCalendarInput;
}>;

export type RemoveCalendarMutation = {
  __typename?: 'Mutation';
  removeCalendar?: {
    __typename?: 'removeCalendarPayload';
    calendar?: { __typename?: 'Calendar'; id: string } | null;
  } | null;
};

export type RemoveEntryMutationVariables = Exact<{
  input: RemoveEntryInput;
}>;

export type RemoveEntryMutation = {
  __typename?: 'Mutation';
  removeEntry?: {
    __typename?: 'removeEntryPayload';
    entry?: { __typename?: 'Entry'; _id: string } | null;
  } | null;
};

export type InitiateUserSelectionMutationVariables = Exact<{
  input: InitiateUserSelectionInput;
}>;

export type InitiateUserSelectionMutation = {
  __typename?: 'Mutation';
  initiateUserSelection?: {
    __typename?: 'initiateUserSelectionPayload';
    userSelection?: {
      __typename?: 'UserSelection';
      calendarSettingsPopup?: boolean | null;
    } | null;
  } | null;
};

export type RemovePacingGuideMutationVariables = Exact<{
  input: RemovePacingGuideInput;
}>;

export type RemovePacingGuideMutation = {
  __typename?: 'Mutation';
  removePacingGuide?: {
    __typename?: 'removePacingGuidePayload';
    pacingGuide?: { __typename?: 'PacingGuide'; name?: string | null } | null;
  } | null;
};

export type RemoveScopePlanMutationVariables = Exact<{
  input: RemoveScopePlanInput;
}>;

export type RemoveScopePlanMutation = {
  __typename?: 'Mutation';
  removeScopePlan?: {
    __typename?: 'removeScopePlanPayload';
    scopePlan?: { __typename?: 'ScopePlan'; name?: string | null } | null;
  } | null;
};

export type GetSchoolsToShareQueryVariables = Exact<{
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type GetSchoolsToShareQuery = {
  __typename?: 'Query';
  schools?: {
    __typename?: 'SchoolPageConnection';
    collection?: Array<{
      __typename?: 'School';
      _id: string;
      globalName: string;
    } | null> | null;
  } | null;
};

export type ScopePlansQueryVariables = Exact<{
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type ScopePlansQuery = {
  __typename?: 'Query';
  scopePlans?: {
    __typename?: 'ScopePlanPageConnection';
    collection?: Array<{
      __typename?: 'ScopePlan';
      _id: string;
      id: string;
      name?: string | null;
      grade?: string | null;
      curriculumArea?: string | null;
      editable?: boolean | null;
      isSelected?: boolean | null;
      version?: number | null;
      scopePlanType?: ScopePlanTypeEnum | null;
      approachType?: ScopePlanApproachEnum | null;
      pacingGuideId?: string | null;
    } | null> | null;
  } | null;
};

export type ScopesPacingJoinedQueryVariables = Exact<{
  filter?: InputMaybe<ScopeFilter>;
  perPage?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  schoolId: Scalars['String'];
}>;

export type ScopesPacingJoinedQuery = {
  __typename?: 'Query';
  scopes: {
    __typename?: 'Scopes';
    collection: Array<{
      __typename?: 'SearchableScope';
      id: string;
      displayName: string;
      editionName: string;
      image: string;
      metadata: any;
    }>;
  };
  pacingGuide?: {
    __typename?: 'PacingGuide';
    version?: number | null;
    scopes?: {
      __typename?: 'PacingGuideScopePageConnection';
      collection?: Array<{
        __typename?: 'PacingGuideScope';
        _id: string;
        id: string;
        scopeId?: string | null;
        name?: string | null;
        instructionalDays?: number | null;
        order?: number | null;
        version?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PacingGuideTemplatesQueryVariables = Exact<{
  name: Scalars['String'];
  schoolIds: Scalars['Iterable'];
  userRoleCode: Scalars['String'];
}>;

export type PacingGuideTemplatesQuery = {
  __typename?: 'Query';
  pacingGuideTemplates?: {
    __typename?: 'PacingGuidePageConnection';
    collection?: Array<{
      __typename?: 'PacingGuide';
      _id: string;
    } | null> | null;
  } | null;
};

export type ShareCalendarMutationVariables = Exact<{
  input: ShareCalendarInput;
}>;

export type ShareCalendarMutation = {
  __typename?: 'Mutation';
  shareCalendar?: {
    __typename?: 'shareCalendarPayload';
    calendar?: { __typename?: 'Calendar'; _id: string } | null;
  } | null;
};

export type TeamsQueryVariables = Exact<{
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  filter: FilterTeamInput;
}>;

export type TeamsQuery = {
  __typename?: 'Query';
  teams: {
    __typename?: 'TeamPageConnection';
    collection: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      archived: boolean;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      }>;
    }>;
  };
};

export type ShareTemplatePacingGuideMutationVariables = Exact<{
  input: ShareTemplatePacingGuideInput;
}>;

export type ShareTemplatePacingGuideMutation = {
  __typename?: 'Mutation';
  shareTemplatePacingGuide?: {
    __typename?: 'shareTemplatePacingGuidePayload';
    pacingGuide?: { __typename?: 'PacingGuide'; _id: string } | null;
  } | null;
};

export type ShareTemplateScopePlanMutationVariables = Exact<{
  input: ShareTemplateScopePlanInput;
}>;

export type ShareTemplateScopePlanMutation = {
  __typename?: 'Mutation';
  shareTemplateScopePlan?: {
    __typename?: 'shareTemplateScopePlanPayload';
    scopePlan?: { __typename?: 'ScopePlan'; _id: string; id: string } | null;
  } | null;
};

export type UpdateCalendarMutationVariables = Exact<{
  input: UpdateCalendarInput;
}>;

export type UpdateCalendarMutation = {
  __typename?: 'Mutation';
  updateCalendar?: {
    __typename?: 'updateCalendarPayload';
    calendar?: {
      __typename?: 'Calendar';
      _id: string;
      name?: string | null;
    } | null;
  } | null;
};

export type UpdateEntryMutationVariables = Exact<{
  input: UpdateEntryInput;
}>;

export type UpdateEntryMutation = {
  __typename?: 'Mutation';
  updateEntry?: {
    __typename?: 'updateEntryPayload';
    entry?: { __typename?: 'Entry'; _id: string } | null;
  } | null;
};

export type UpdatePacingGuideScopeMutationVariables = Exact<{
  input: UpdatePacingGuideScopeInput;
}>;

export type UpdatePacingGuideScopeMutation = {
  __typename?: 'Mutation';
  updatePacingGuideScope?: {
    __typename?: 'updatePacingGuideScopePayload';
    pacingGuideScope?: { __typename?: 'PacingGuideScope'; id: string } | null;
  } | null;
};

export type SearchPlanTemplatesQueryVariables = Exact<{
  name: Scalars['String'];
  schoolIds: Scalars['Iterable'];
  userRoleCode: Scalars['String'];
  scopePlanType: ScopePlanTypeEnum;
}>;

export type SearchPlanTemplatesQuery = {
  __typename?: 'Query';
  scopePlanTemplates?: {
    __typename?: 'ScopePlanPageConnection';
    collection?: Array<{ __typename?: 'ScopePlan'; _id: string } | null> | null;
  } | null;
};

export type UpdateScopePlanScopeMutationVariables = Exact<{
  input: UpdateScopePlanScopeInput;
}>;

export type UpdateScopePlanScopeMutation = {
  __typename?: 'Mutation';
  updateScopePlanScope?: {
    __typename?: 'updateScopePlanScopePayload';
    scopePlanScope?: { __typename?: 'ScopePlanScope'; _id: string } | null;
  } | null;
};

export type UpdateUserSelectionMutationVariables = Exact<{
  input: UpdateUserSelectionInput;
}>;

export type UpdateUserSelectionMutation = {
  __typename?: 'Mutation';
  updateUserSelection?: {
    __typename?: 'updateUserSelectionPayload';
    userSelection?: {
      __typename?: 'UserSelection';
      viewMode?: ViewModeEnum | null;
      selectedCalendar?: any | null;
      selectedPacingGuide?: any | null;
      selectedScopePlan?: any | null;
    } | null;
  } | null;
};

export type UpdateScopePlanMutationVariables = Exact<{
  input: UpdateScopePlanInput;
}>;

export type UpdateScopePlanMutation = {
  __typename?: 'Mutation';
  updateScopePlan?: {
    __typename?: 'updateScopePlanPayload';
    scopePlan?: {
      __typename?: 'ScopePlan';
      _id: string;
      id: string;
      name?: string | null;
      scopePlanId?: string | null;
      version?: number | null;
      scopes?: {
        __typename?: 'ScopePlanScopePageConnection';
        collection?: Array<{
          __typename?: 'ScopePlanScope';
          id: string;
          _id: string;
          elements?: any | null;
          scopePlanId?: string | null;
          startDate?: string | null;
          endDate?: string | null;
          instructionalDays?: number | null;
          order?: number | null;
          version?: number | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdatePacingGuideMutationVariables = Exact<{
  input: UpdatePacingGuideInput;
}>;

export type UpdatePacingGuideMutation = {
  __typename?: 'Mutation';
  updatePacingGuide?: {
    __typename?: 'updatePacingGuidePayload';
    pacingGuide?: {
      __typename?: 'PacingGuide';
      _id: string;
      id: string;
      name?: string | null;
      pacingGuideId?: string | null;
      grade?: string | null;
      curriculumArea?: string | null;
      pacingGuideType?: PacingGuideTypeEnum | null;
      version?: number | null;
    } | null;
  } | null;
};

export type UserCalendarsQueryVariables = Exact<{
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type UserCalendarsQuery = {
  __typename?: 'Query';
  userCalendars?: {
    __typename?: 'UserCalendarPageConnection';
    collection?: Array<{
      __typename?: 'UserCalendar';
      _id: string;
      name: string;
      calendarId?: string | null;
      calendarType?: CalendarTypeEnum | null;
      isDefault: boolean;
      isSelected?: boolean | null;
      editable: boolean;
      userId?: string | null;
      creator?: string | null;
      userSelection: {
        __typename?: 'UserSelection';
        calendarSettingsPopup?: boolean | null;
        selectedCalendar?: any | null;
        viewMode?: ViewModeEnum | null;
      };
    } | null> | null;
  } | null;
};

export type GetDistrictSettingsQueryVariables = Exact<{
  withContentGroups: Scalars['Boolean'];
  withWizardStatus: Scalars['Boolean'];
  withUMSettings: Scalars['Boolean'];
  withRosterSettings: Scalars['Boolean'];
  withAISettings: Scalars['Boolean'];
  withKnowledgeSettings: Scalars['Boolean'];
}>;

export type GetDistrictSettingsQuery = {
  __typename?: 'Query';
  activeContentGroups?: Array<{ __typename?: 'ContentGroup'; id: string }>;
  districtSetupStatus?: {
    __typename?: 'DistrictSetupStatus';
    status: SetupStatusEnum;
  } | null;
  districtSettingsRoster?: {
    __typename?: 'DistrictSettingsRoster';
    teacherCanAddAdditionalTeacher: boolean;
    teacherCanCreateClasses: boolean;
    teacherCanEditClassRoster: boolean;
  } | null;
  districtSettingsUserManagement?: {
    __typename?: 'DistrictSettingsUserManagement';
    teacherCanCreateStudents: boolean;
    teacherCanEditStudentPassword: boolean;
    teacherCanImpersonateStudents: boolean;
  } | null;
  districtSettingsLessonPlanner?: {
    __typename?: 'DistrictSettingsLessonPlanner';
    teacherCanUseAiAssistedLessonPlan: boolean;
  } | null;
  districtSettingsKnowledgeBase?: {
    __typename?: 'DistrictSettingsKnowledgeBase';
    teacherCanUsePerryBot: boolean;
    studentCanUsePerryBot: boolean;
  };
};

export type GetRecentlyViewedQueryVariables = Exact<{
  schoolId: Scalars['String'];
}>;

export type GetRecentlyViewedQuery = {
  __typename?: 'Query';
  recentlyViewed?: {
    __typename?: 'RecentlyViewed';
    displayedNames?: Array<string> | null;
    objectIds: Array<string>;
    projectIds: Array<string>;
  } | null;
};

export type GetBaseDistrictAssignmentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBaseDistrictAssignmentsQuery = {
  __typename?: 'Query';
  baseDistrictAssignments?: {
    __typename?: 'DistrictBaseAssignmentsType';
    collection?: Array<{
      __typename?: 'DistrictBaseAssignmentType';
      daId?: string | null;
      assignType?: DaAssignType | null;
      districtId?: string | null;
      endDate?: any | null;
      name?: string | null;
      startDate?: any | null;
      schoolGradeLevels?: Array<{
        __typename?: 'SchoolGradeLevels';
        gradeLevels?: Array<string> | null;
        schoolId?: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetLeaderDistrictAssignmentsQueryVariables = Exact<{
  filter: LeaderDistrictAssignmentsFilter;
}>;

export type GetLeaderDistrictAssignmentsQuery = {
  __typename?: 'Query';
  districtAssignmentsForLeader?: {
    __typename?: 'DistrictClassAssignmentsType';
    collection: Array<{
      __typename?: 'DistrictClassAssignmentType';
      assignmentId: string;
      startDate: any;
      endDate: any;
      schoolId: string;
      gradeLevel: string;
      daId: string;
      districtId: string;
      name: string;
      availableDate?: any | null;
      studentAssignments?: Array<{
        __typename?: 'DistrictStudentAssignmentType';
        assignmentId: string;
        startDate: any;
        endDate: any;
        schoolId: string;
        grade?: string | null;
        completedAt?: any | null;
        submittedAt?: any | null;
        name: string;
        status?: StudentAssignmentStatus | null;
        studentId: string;
        studentNotes?: string | null;
        reopenDate?: any | null;
        availableDate?: any | null;
        shareResults?: ShareResults | null;
        learnosityReferenceId: string;
        classId: string;
        answers?: any | null;
        startedAt?: any | null;
        id: string;
      }> | null;
    }>;
  } | null;
};

export type ModuleFragment = {
  __typename?: 'ApplicationModule';
  name: string;
  operations: Array<{
    __typename?: 'ApplicationModuleOperation';
    name: string;
    scopes: Array<string>;
  }>;
  modules: Array<{
    __typename?: 'ApplicationModule';
    name: string;
    operations: Array<{
      __typename?: 'ApplicationModuleOperation';
      name: string;
      scopes: Array<string>;
    }>;
    modules: Array<{
      __typename?: 'ApplicationModule';
      name: string;
      operations: Array<{
        __typename?: 'ApplicationModuleOperation';
        name: string;
        scopes: Array<string>;
      }>;
      modules: Array<{
        __typename?: 'ApplicationModule';
        name: string;
        operations: Array<{
          __typename?: 'ApplicationModuleOperation';
          name: string;
          scopes: Array<string>;
        }>;
      }>;
    }>;
  }>;
};

export type GetAclApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAclApplicationsQuery = {
  __typename?: 'Query';
  applicationSettings?: {
    __typename?: 'ApplicationSettings';
    value: any;
    id: string;
  } | null;
  aclApplications: Array<{
    __typename?: 'AclApplication';
    name: string;
    modules: Array<{
      __typename?: 'ApplicationModule';
      name: string;
      operations: Array<{
        __typename?: 'ApplicationModuleOperation';
        name: string;
        scopes: Array<string>;
      }>;
      modules: Array<{
        __typename?: 'ApplicationModule';
        name: string;
        operations: Array<{
          __typename?: 'ApplicationModuleOperation';
          name: string;
          scopes: Array<string>;
        }>;
        modules: Array<{
          __typename?: 'ApplicationModule';
          name: string;
          operations: Array<{
            __typename?: 'ApplicationModuleOperation';
            name: string;
            scopes: Array<string>;
          }>;
          modules: Array<{
            __typename?: 'ApplicationModule';
            name: string;
            operations: Array<{
              __typename?: 'ApplicationModuleOperation';
              name: string;
              scopes: Array<string>;
            }>;
          }>;
        }>;
      }>;
    }>;
    schools: Array<{
      __typename?: 'ApplicationSchool';
      schoolIds: Array<string>;
      modules: Array<{
        __typename?: 'ApplicationModule';
        name: string;
        operations: Array<{
          __typename?: 'ApplicationModuleOperation';
          name: string;
          scopes: Array<string>;
        }>;
        modules: Array<{
          __typename?: 'ApplicationModule';
          name: string;
          operations: Array<{
            __typename?: 'ApplicationModuleOperation';
            name: string;
            scopes: Array<string>;
          }>;
          modules: Array<{
            __typename?: 'ApplicationModule';
            name: string;
            operations: Array<{
              __typename?: 'ApplicationModuleOperation';
              name: string;
              scopes: Array<string>;
            }>;
            modules: Array<{
              __typename?: 'ApplicationModule';
              name: string;
              operations: Array<{
                __typename?: 'ApplicationModuleOperation';
                name: string;
                scopes: Array<string>;
              }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type CheckPasswordRecoveryTokenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CheckPasswordRecoveryTokenQuery = {
  __typename?: 'Query';
  passwordRecoveryToken?: {
    __typename?: 'PasswordRecoveryToken';
    expired: boolean;
  } | null;
};

export type CreatePasswordChangeMutationVariables = Exact<{
  input: CreatePasswordChangeInput;
}>;

export type CreatePasswordChangeMutation = {
  __typename?: 'Mutation';
  createPasswordChange?: {
    __typename?: 'createPasswordChangePayload';
    passwordChange?: {
      __typename?: 'createPasswordChangePayloadData';
      id: string;
    } | null;
  } | null;
};

export type CreatePasswordRecoveryRequestMutationVariables = Exact<{
  input: PasswordRecoveryRequestInput;
}>;

export type CreatePasswordRecoveryRequestMutation = {
  __typename?: 'Mutation';
  createPasswordRecoveryRequest: boolean;
};

export type CreatePasswordRecoveryMutationVariables = Exact<{
  input: PasswordRecoveryInput;
}>;

export type CreatePasswordRecoveryMutation = {
  __typename?: 'Mutation';
  createPasswordRecovery: boolean;
};

export type RegisterPreviewAccountTeacherMutationVariables = Exact<{
  input: RegisterPreviewAccountTeacherInput;
}>;

export type RegisterPreviewAccountTeacherMutation = {
  __typename?: 'Mutation';
  registerPreviewAccountTeacher: {
    __typename?: 'PublicPreviewAccountTeacher';
    previewAccount: {
      __typename?: 'PublicPreviewAccount';
      district: { __typename?: 'NewDistrict'; urlCode?: string | null };
    };
  };
};

export type GetReportQueryVariables = Exact<{
  containerId: Scalars['String'];
  activityId?: InputMaybe<Scalars['String']>;
  activities?: InputMaybe<Scalars['Iterable']>;
  limit?: InputMaybe<Scalars['Int']>;
  displayUser?: InputMaybe<Scalars['Boolean']>;
  users?: InputMaybe<Scalars['Iterable']>;
  userId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  sessionId?: InputMaybe<Scalars['String']>;
}>;

export type GetReportQuery = {
  __typename?: 'Query';
  getReport?: {
    __typename?: 'Report';
    id: string;
    signedRequest: string;
  } | null;
};

export type SignRequestReportMutationVariables = Exact<{
  params: SignRequestInput;
}>;

export type SignRequestReportMutation = {
  __typename?: 'Mutation';
  signRequest?:
    | { __typename?: 'ItemSignedRequest' }
    | { __typename?: 'ReportSignedRequest'; signedRequest: any }
    | null;
};

export type RegisterParentMutationVariables = Exact<{
  input: RegisterParentInput;
}>;

export type RegisterParentMutation = {
  __typename?: 'Mutation';
  registerParent?: {
    __typename?: 'PublicParent';
    districtId: string;
    id: string;
  } | null;
};

export type GetScope2QueryVariables = Exact<{
  id: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type GetScope2Query = {
  __typename?: 'Query';
  baseScope?: {
    __typename?: 'BaseScope';
    id: string;
    subscriptionContentGroups?: Array<string> | null;
    jsonUrl: string;
    projectId: string;
  } | null;
};

export type RegisterAdoptionReviewerMutationVariables = Exact<{
  input: RegisterAdoptionReviewerInput;
}>;

export type RegisterAdoptionReviewerMutation = {
  __typename?: 'Mutation';
  registerAdoptionReviewer: {
    __typename?: 'RegisterAdoptionReviewerPayload';
    eventUrlCode: string;
    districtName: string;
  };
};

export type AskChatbotQueryVariables = Exact<{
  userId: Scalars['String'];
  query: Scalars['String'];
  threadId?: InputMaybe<Scalars['String']>;
}>;

export type AskChatbotQuery = {
  __typename?: 'Query';
  askChatbot?: {
    __typename?: 'ChatbotResponse';
    answer?: string | null;
    threadId?: string | null;
  } | null;
};

export type CreateAiChatMutationVariables = Exact<{
  input: AiChatInput;
}>;

export type CreateAiChatMutation = {
  __typename?: 'Mutation';
  createAiChat: { __typename?: 'AiChatPayload'; answer: string };
};

export type GetStandardsItemsQueryVariables = Exact<{
  filter?: InputMaybe<SsStandardsItemFilter>;
}>;

export type GetStandardsItemsQuery = {
  __typename?: 'Query';
  ssStandardsItems?: Array<{
    __typename?: 'ssStandardsItem';
    standard: string;
  } | null> | null;
};

export type CreateResourceMutationVariables = Exact<{
  params: CreateResourceInput;
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type CreateResourceMutation = {
  __typename?: 'Mutation';
  createResource?: Array<{
    __typename?: 'Resource';
    learnosityReferenceId?: string | null;
  }> | null;
};

export type GetStandardsByNameQueryVariables = Exact<{
  standardNames: Array<Scalars['String']> | Scalars['String'];
  currentSchoolId: Scalars['String'];
}>;

export type GetStandardsByNameQuery = {
  __typename?: 'Query';
  standardsByName: Array<{
    __typename?: 'Standards';
    description?: string | null;
    standard?: string | null;
  }>;
};

export type GetScopesQueryVariables = Exact<{
  filter?: InputMaybe<ScopeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetScopesQuery = {
  __typename?: 'Query';
  scopes: {
    __typename?: 'Scopes';
    collection: Array<{
      __typename?: 'SearchableScope';
      displayName: string;
      editionName: string;
      id: string;
      image: string;
      metadata: any;
      projectId: string;
    }>;
    pageInfo: { __typename?: 'PageInfo'; totalCount?: number | null };
  };
};

export type GetScopesForAssignmentFormQueryVariables = Exact<{
  filter?: InputMaybe<ScopeFilter>;
  schoolId: Scalars['String'];
}>;

export type GetScopesForAssignmentFormQuery = {
  __typename?: 'Query';
  scopes: {
    __typename?: 'Scopes';
    collection: Array<{
      __typename?: 'SearchableScope';
      displayName: string;
      id: string;
    }>;
  };
};

export type GetTeachersStandardsListQueryVariables = Exact<{
  input: StandardListInput;
}>;

export type GetTeachersStandardsListQuery = {
  __typename?: 'Query';
  teacherStandardList: {
    __typename?: 'StandardListPayload';
    standards: string;
  };
};

export type AddStudentToSchoolMutationVariables = Exact<{
  input: AddSchoolToStudentInput;
}>;

export type AddStudentToSchoolMutation = {
  __typename?: 'Mutation';
  addSchoolToStudent?: {
    __typename?: 'addSchoolToStudentPayload';
    clientMutationId?: string | null;
  } | null;
};

export type FindStudentAssignmentsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  filter?: InputMaybe<StudentAssignmentsFilter>;
}>;

export type FindStudentAssignmentsQuery = {
  __typename?: 'Query';
  studentStudentAssignments?: {
    __typename?: 'StudentAssignments';
    collection?: Array<{ __typename?: 'StudentAssignment'; id: string }> | null;
  } | null;
};

export type GetStudentDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetStudentDetailsQuery = {
  __typename?: 'Query';
  student?: {
    __typename?: 'Student';
    _id: string;
    id: string;
    classes?: {
      __typename?: 'ClassPageConnection';
      collection?: Array<{
        __typename?: 'Class';
        id: string;
        _id: string;
        name: string;
        gradeOrCourse?: string | null;
        archived: boolean;
      } | null> | null;
    } | null;
  } | null;
};

export type GetStudentResourcesQueryVariables = Exact<{
  filter?: InputMaybe<StudentResourcesFilter>;
  schoolId: Scalars['String'];
}>;

export type GetStudentResourcesQuery = {
  __typename?: 'Query';
  studentResources?: {
    __typename?: 'StudentResources';
    collection?: Array<{
      __typename?: 'StudentResource';
      learnosityReferenceId?: string | null;
      originLearnosityId?: string | null;
      name: string;
      resourceId: string;
      grade?: string | null;
      id: string;
      status: StudentResourceStatus;
      resourceSource?: {
        __typename?: 'ResourceSource';
        element?: {
          __typename?: 'Element';
          elementId: string;
          elementName: string;
          projectId: string;
          scopeId: string;
          scopeName: string;
          sectionId: string;
          sectionName: string;
          viewId?: string | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetElementsQueryVariables = Exact<{
  filter?: InputMaybe<ElementFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type GetElementsQuery = {
  __typename?: 'Query';
  elements?: {
    __typename?: 'Elements';
    collection?: Array<{
      __typename?: 'SearchableElement';
      id: string;
      scopeId: string;
      name: string;
      metadata: any;
      studentView?: Array<any> | null;
    }> | null;
  } | null;
};

export type GetTeacherStudentsQueryVariables = Exact<{
  schoolId: Scalars['String'];
  teachersId: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetTeacherStudentsQuery = {
  __typename?: 'Query';
  teacherStudents?: {
    __typename?: 'StudentPageConnection';
    collection?: Array<{
      __typename?: 'Student';
      id: string;
      _id: string;
      sisId?: string | null;
      lastName?: string | null;
      firstName?: string | null;
      username: string;
      gradeLevel?: GradeLevelEnum | null;
      plainPassword?: string | null;
      authenticationToken?: string | null;
      classes?: {
        __typename?: 'ClassPageConnection';
        collection?: Array<{
          __typename?: 'Class';
          name: string;
          schoolId: string;
          archived: boolean;
          classTeachers?: {
            __typename?: 'ClassTeacherPageConnection';
            collection?: Array<{
              __typename?: 'ClassTeacher';
              teacherId: string;
              mainTeacher: boolean;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
    paginationInfo: {
      __typename?: 'StudentPaginationInfo';
      lastPage: number;
      totalCount: number;
    };
  } | null;
};

export type GetTeacherStudentsForAddingToDaQueryVariables = Exact<{
  schoolId: Scalars['String'];
  teachersId: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetTeacherStudentsForAddingToDaQuery = {
  __typename?: 'Query';
  teacherStudents?: {
    __typename?: 'StudentPageConnection';
    collection?: Array<{
      __typename?: 'Student';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      gradeLevel?: GradeLevelEnum | null;
    } | null> | null;
  } | null;
};

export type GetStudentClassAssignmentsQueryVariables = Exact<{
  filter?: InputMaybe<StudentAssignmentsFilter>;
  schoolId?: InputMaybe<Scalars['String']>;
}>;

export type GetStudentClassAssignmentsQuery = {
  __typename?: 'Query';
  studentStudentAssignments?: {
    __typename?: 'StudentAssignments';
    collection?: Array<{
      __typename?: 'StudentAssignment';
      answers?: any | null;
      classId?: string | null;
      grade?: string | null;
      status: StudentAssignmentStatus;
      endDate?: string | null;
      completedAt?: any | null;
      startDate?: string | null;
      releaseFeedback: boolean;
      studentId: string;
      name: string;
      returnedAt?: any | null;
      assignmentId: string;
      id: string;
      lateTurnIn: boolean;
      shareResults?: ShareResults | null;
      otherAssignmentScope?: string | null;
      archived?: boolean | null;
      assignmentSource?: {
        __typename?: 'AssignmentSource';
        element?: {
          __typename?: 'Element';
          elementId: string;
          elementName: string;
          projectId: string;
          scopeId: string;
          scopeName: string;
          sectionId: string;
          sectionName: string;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type SystemAdminsQueryVariables = Exact<{
  districtUuid: Scalars['String'];
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type SystemAdminsQuery = {
  __typename?: 'Query';
  systemAdmins: {
    __typename?: 'SystemAdminPageConnection';
    collection: Array<{
      __typename?: 'SystemAdmin';
      firstName: string;
      lastName: string;
    }>;
  };
};

export type AddStudentsToStudentSubgroupMutationVariables = Exact<{
  input: AddStudentsToStudentSubgroupInput;
}>;

export type AddStudentsToStudentSubgroupMutation = {
  __typename?: 'Mutation';
  addStudentsToStudentSubgroup?: {
    __typename?: 'addStudentsToStudentSubgroupPayload';
    studentSubgroup?: { __typename?: 'StudentSubgroup'; id: string } | null;
  } | null;
};

export type RemoveStudentsFromStudentSubgroupMutationVariables = Exact<{
  input: RemoveStudentsFromStudentSubgroupInput;
}>;

export type RemoveStudentsFromStudentSubgroupMutation = {
  __typename?: 'Mutation';
  removeStudentsFromStudentSubgroup?: {
    __typename?: 'removeStudentsFromStudentSubgroupPayload';
    studentSubgroup?: { __typename?: 'StudentSubgroup'; id: string } | null;
  } | null;
};

export type GetTeacherQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTeacherQuery = {
  __typename?: 'Query';
  teacher?: {
    __typename?: 'Teacher';
    lastName?: string | null;
    firstName?: string | null;
  } | null;
};

export type GetClassSubgroupsQueryVariables = Exact<{
  classId: Scalars['ID'];
  activeTeachers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetClassSubgroupsQuery = {
  __typename?: 'Query';
  class?: {
    __typename?: 'Class';
    name: string;
    _id: string;
    subgroups?: {
      __typename?: 'StudentSubgroupPageConnection';
      collection?: Array<{
        __typename?: 'StudentSubgroup';
        name: string;
        id: string;
        _id: string;
        students?: {
          __typename?: 'StudentSubgroupPageConnection';
          collection?: Array<{
            __typename?: 'StudentSubgroup';
            id: string;
          } | null> | null;
        } | null;
        teachers?: {
          __typename?: 'StudentSubgroupPageConnection';
          collection?: Array<{
            __typename?: 'StudentSubgroup';
            _id: string;
            id: string;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    classTeachers?: {
      __typename?: 'ClassTeacherPageConnection';
      collection?: Array<{
        __typename?: 'ClassTeacher';
        teacherId: string;
        mainTeacher: boolean;
        subgroups?: {
          __typename?: 'StudentSubgroupPageConnection';
          collection?: Array<{
            __typename?: 'StudentSubgroup';
            _id: string;
            id: string;
            name: string;
          } | null> | null;
        } | null;
        teacher: {
          __typename?: 'Teacher';
          _id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type GetClassAndSubgroupQueryVariables = Exact<{
  subgroupId: Scalars['ID'];
  classId: Scalars['ID'];
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetClassAndSubgroupQuery = {
  __typename?: 'Query';
  studentSubgroup?: {
    __typename?: 'StudentSubgroup';
    _id: string;
    id: string;
    name: string;
    students?: {
      __typename?: 'StudentSubgroupPageConnection';
      collection?: Array<{
        __typename?: 'StudentSubgroup';
        _id: string;
      } | null> | null;
    } | null;
  } | null;
  class?: {
    __typename?: 'Class';
    name: string;
    _id: string;
    subgroups?: {
      __typename?: 'StudentSubgroupPageConnection';
      collection?: Array<{
        __typename?: 'StudentSubgroup';
        id: string;
        name: string;
      } | null> | null;
    } | null;
    classStudents?: {
      __typename?: 'ClassStudentPageConnection';
      collection?: Array<{
        __typename?: 'ClassStudent';
        _id: string;
        studentId: string;
        student: {
          __typename?: 'Student';
          _id: string;
          firstName?: string | null;
          lastName?: string | null;
          username: string;
          gradeLevel?: GradeLevelEnum | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type CreateQrcodeTemplateMutationVariables = Exact<{
  createQrcodeTemplateInput: CreateQrcodeTemplateInput;
}>;

export type CreateQrcodeTemplateMutation = {
  __typename?: 'Mutation';
  createQrcodeTemplate: { __typename?: 'QrcodeTemplate'; content: string };
};

export type GetTeacherResourceQueryVariables = Exact<{
  id: Scalars['String'];
  schoolId: Scalars['String'];
}>;

export type GetTeacherResourceQuery = {
  __typename?: 'Query';
  teacherResource?: {
    __typename?: 'TeacherResource';
    displayName: string;
    id: string;
    positionNumber: number;
    projectType: string;
    projectId: string;
    sectionTitles: Array<any>;
    metadata: any;
    sections: Array<any>;
  } | null;
};

export type GetTeacherResourcesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetTeacherResourcesQuery = {
  __typename?: 'Query';
  teacherResources?: {
    __typename?: 'TeacherResources';
    collection?: Array<{
      __typename?: 'SearchableTeacherResource';
      displayName: string;
      id: string;
      positionNumber: number;
      projectId: string;
      sectionTitles: Array<any>;
      metadata: any;
    }> | null;
  } | null;
};

export type GetTopicsQueryVariables = Exact<{
  schoolIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetTopicsQuery = {
  __typename?: 'Query';
  topics?: { __typename?: 'Topics'; topics: Array<string> } | null;
};

export type GetTeamsQueryVariables = Exact<{
  perPage: Scalars['Int'];
  page: Scalars['Int'];
}>;

export type GetTeamsQuery = {
  __typename?: 'Query';
  teams: {
    __typename?: 'TeamPageConnection';
    collection: Array<{ __typename?: 'Team'; id: string; name: string }>;
  };
};

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput;
}>;

export type UpdateTeamMutation = {
  __typename?: 'Mutation';
  updateTeam: {
    __typename?: 'TeamPayload';
    team: { __typename?: 'Team'; name: string };
  };
};

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createTeam: {
    __typename?: 'TeamPayload';
    team: { __typename?: 'Team'; name: string };
  };
};

export type CancelImpersonationMutationVariables = Exact<{
  input: CancelImpersonationInput;
}>;

export type CancelImpersonationMutation = {
  __typename?: 'Mutation';
  cancelImpersonation?: {
    __typename?: 'cancelImpersonationPayload';
    impersonation?: {
      __typename?: 'cancelImpersonationPayloadData';
      user?: { __typename?: 'User'; id: string } | null;
    } | null;
  } | null;
};

export type CheckStudentOnExistenceQueryVariables = Exact<{
  sisId?: InputMaybe<Scalars['String']>;
  withSisId: Scalars['Boolean'];
  withStateId: Scalars['Boolean'];
  stateId?: InputMaybe<Scalars['String']>;
}>;

export type CheckStudentOnExistenceQuery = {
  __typename?: 'Query';
  usersBySisId?: Array<{
    __typename?: 'User';
    id: string;
    _id: string;
    firstName?: string | null;
    lastName?: string | null;
    userRoles?: {
      __typename?: 'UserRolePageConnection';
      collection?: Array<{
        __typename?: 'UserRole';
        schoolIds: any;
      } | null> | null;
    } | null;
  } | null> | null;
  usersByStateId?: Array<{
    __typename?: 'User';
    id: string;
    _id: string;
    firstName?: string | null;
    lastName?: string | null;
    userRoles?: {
      __typename?: 'UserRolePageConnection';
      collection?: Array<{
        __typename?: 'UserRole';
        schoolIds: any;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type CreateImpersonationMutationVariables = Exact<{
  input: CreateImpersonationInput;
}>;

export type CreateImpersonationMutation = {
  __typename?: 'Mutation';
  createImpersonation?: {
    __typename?: 'createImpersonationPayload';
    impersonation?: {
      __typename?: 'createImpersonationPayloadData';
      user?: {
        __typename?: 'createUserNestedPayload';
        gradeLevel?: GradeLevelEnum | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        _id: string;
      } | null;
    } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'createUserPayload';
    user?: {
      __typename?: 'User';
      id: string;
      lastName?: string | null;
      firstName?: string | null;
    } | null;
  } | null;
};

export type CreateStudentMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateStudentMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'createUserPayload';
    user?: {
      __typename?: 'User';
      lastName?: string | null;
      _id: string;
      firstName?: string | null;
    } | null;
  } | null;
};

export type GetPasswordQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPasswordQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'User'; plainPassword?: string | null } | null;
};

export type GetUserRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserRolesQuery = {
  __typename?: 'Query';
  roles: {
    __typename?: 'RolePageConnection';
    collection: Array<{
      __typename?: 'Role';
      id: string;
      name: string;
      code?: RoleCodeEnum | null;
    }>;
  };
};

export type GetUserForUpdateQueryVariables = Exact<{
  id: Scalars['ID'];
  schoolId: Scalars['String'];
  teachersId: Scalars['String'];
  archived?: InputMaybe<Scalars['Boolean']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetUserForUpdateQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    _id: string;
    sisId?: string | null;
    stateId?: string | null;
    username: string;
    lastName?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    plainPassword?: string | null;
    email?: string | null;
    gradeLevel?: GradeLevelEnum | null;
    language: LanguageEnum;
  } | null;
  teacherStudents?: {
    __typename?: 'StudentPageConnection';
    collection?: Array<{
      __typename?: 'Student';
      _id: string;
      classes?: {
        __typename?: 'ClassPageConnection';
        collection?: Array<{ __typename?: 'Class'; _id: string } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  classes?: {
    __typename?: 'ClassPageConnection';
    collection?: Array<{
      __typename?: 'Class';
      _id: string;
      name: string;
      schoolId: string;
      classTeachers?: {
        __typename?: 'ClassTeacherPageConnection';
        collection?: Array<{
          __typename?: 'ClassTeacher';
          teacherId: string;
          mainTeacher: boolean;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    lastName?: string | null;
    firstName?: string | null;
    gradeLevel?: GradeLevelEnum | null;
  } | null;
};

export type GetUserForCampusLeaderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserForCampusLeaderQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    plainPassword?: string | null;
    username: string;
    staffId?: string | null;
    email?: string | null;
    language: LanguageEnum;
    stateId?: string | null;
    gradeLevel?: GradeLevelEnum | null;
    userRoles?: {
      __typename?: 'UserRolePageConnection';
      collection?: Array<{
        __typename?: 'UserRole';
        schoolIds: any;
        role: {
          __typename?: 'Role';
          id: string;
          _id: string;
          code?: RoleCodeEnum | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateStudentMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateStudentMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'updateUserPayload';
    user?: {
      __typename?: 'User';
      _id: string;
      active: boolean;
      email?: string | null;
      firstName?: string | null;
      gradeLevel?: GradeLevelEnum | null;
      language: LanguageEnum;
      lastName?: string | null;
      middleName?: string | null;
      plainPassword?: string | null;
      preferences?: string | null;
      sisId?: string | null;
      stateId?: string | null;
      username: string;
      createdByUsername?: string | null;
      updatedByUsername?: string | null;
      createdAt: string;
      updatedAt?: string | null;
      userRoles?: {
        __typename?: 'UserRolePageConnection';
        collection?: Array<{
          __typename?: 'UserRole';
          roleId: string;
          schoolIds: any;
          id: string;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'updateUserPayload';
    user?: { __typename?: 'User'; id: string } | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserTypeEnum>;
  schoolId?: InputMaybe<Scalars['String']>;
  id_list?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  active?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UserPageConnection';
    collection?: Array<{
      __typename?: 'User';
      _id: string;
      firstName?: string | null;
      lastName?: string | null;
      username: string;
    } | null> | null;
    paginationInfo: {
      __typename?: 'UserPaginationInfo';
      itemsPerPage: number;
      totalCount: number;
    };
  } | null;
};

export type GetManageStudentsUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UserTypeEnum>;
  schoolId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<
    Array<InputMaybe<UserFilter_Order>> | InputMaybe<UserFilter_Order>
  >;
  gradeLevel?: InputMaybe<GradeLevelFilterEnum>;
  exclude?: InputMaybe<
    Array<InputMaybe<UserFilter_Exclude>> | InputMaybe<UserFilter_Exclude>
  >;
  active?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetManageStudentsUsersQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UserPageConnection';
    collection?: Array<{
      __typename?: 'User';
      _id: string;
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      username: string;
      gradeLevel?: GradeLevelEnum | null;
    } | null> | null;
    paginationInfo: {
      __typename?: 'UserPaginationInfo';
      itemsPerPage: number;
      totalCount: number;
      lastPage: number;
    };
  } | null;
};

export type GetUsersForClassesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UserTypeEnum>;
  schoolId?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUsersForClassesQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UserPageConnection';
    collection?: Array<{
      __typename?: 'User';
      _id: string;
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null> | null;
    paginationInfo: {
      __typename?: 'UserPaginationInfo';
      itemsPerPage: number;
      totalCount: number;
    };
  } | null;
};

export type GetUsersForCampusLeaderQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  schoolsIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
  >;
  type?: InputMaybe<UserTypeEnum>;
  filter?: InputMaybe<Array<InputMaybe<UserFilter>> | InputMaybe<UserFilter>>;
  id?: InputMaybe<Scalars['String']>;
}>;

export type GetUsersForCampusLeaderQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UserPageConnection';
    collection?: Array<{
      __typename?: 'User';
      _id: string;
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      plainPassword?: string | null;
      username: string;
      staffId?: string | null;
      curriculumAreas?: any | null;
      gradeLevel?: GradeLevelEnum | null;
      userRoles?: {
        __typename?: 'UserRolePageConnection';
        collection?: Array<{
          __typename?: 'UserRole';
          schoolIds: any;
          role: {
            __typename?: 'Role';
            id: string;
            _id: string;
            code?: RoleCodeEnum | null;
          };
        } | null> | null;
      } | null;
    } | null> | null;
    paginationInfo: {
      __typename?: 'UserPaginationInfo';
      itemsPerPage: number;
      totalCount: number;
      lastPage: number;
    };
  } | null;
};

export type PacingGuideListQueryVariables = Exact<{
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type PacingGuideListQuery = {
  __typename?: 'Query';
  pacingGuides?: {
    __typename?: 'PacingGuidePageConnection';
    collection?: Array<{
      __typename?: 'PacingGuide';
      id: string;
      _id: string;
      name?: string | null;
      curriculumArea?: string | null;
      grade?: string | null;
      startDate?: string | null;
      selectedCalendar?: any | null;
    } | null> | null;
  } | null;
};

export type PacingGuideWithScopesQueryVariables = Exact<{
  pacingGuideId: Scalars['String'];
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type PacingGuideWithScopesQuery = {
  __typename?: 'Query';
  pacingGuides?: {
    __typename?: 'PacingGuidePageConnection';
    collection?: Array<{
      __typename?: 'PacingGuide';
      id: string;
      _id: string;
      name?: string | null;
      curriculumArea?: string | null;
      grade?: string | null;
      pacingGuideType?: PacingGuideTypeEnum | null;
      selectedCalendar?: any | null;
      scopes?: {
        __typename?: 'PacingGuideScopePageConnection';
        collection?: Array<{
          __typename?: 'PacingGuideScope';
          _id: string;
          id: string;
          scopeId?: string | null;
          name?: string | null;
          instructionalDays?: number | null;
          order?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCalendarScopeEventsQueryVariables = Exact<{
  calendarId?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetCalendarScopeEventsQuery = {
  __typename?: 'Query';
  calendars?: {
    __typename?: 'CalendarPageConnection';
    collection?: Array<{
      __typename?: 'Calendar';
      _id: string;
      calendarEntries?: {
        __typename?: 'EntryPageConnection';
        collection?: Array<{
          __typename?: 'Entry';
          id: string;
          pacingGuideId?: string | null;
          startDate: string;
          endDate?: string | null;
          scopeId?: string | null;
          name: string;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreateWidgetMutationVariables = Exact<{
  x: Scalars['Int'];
  y: Scalars['Int'];
  w: Scalars['Int'];
  h: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  expandedH: Scalars['Int'];
  expandedW: Scalars['Int'];
  minH: Scalars['Int'];
  minW: Scalars['Int'];
  type: WidgetType;
  config?: InputMaybe<Scalars['JSONObject']>;
}>;

export type CreateWidgetMutation = {
  __typename?: 'Mutation';
  createWidget: {
    __typename?: 'Widget';
    h: number;
    i: string;
    id: string;
    minH: number;
    minW: number;
    w: number;
    x: number;
    y: number;
    expandedW: number;
    expandedH: number;
    collapsed: boolean;
    type: WidgetType;
    config?: any | null;
  };
};

export type PlansByCurriculumAndGradeQueryVariables = Exact<{
  curriculumArea?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  scopePlanType?: InputMaybe<ScopePlanTypeEnum>;
  schoolIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userRoleCode: Scalars['String'];
  userTeamIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type PlansByCurriculumAndGradeQuery = {
  __typename?: 'Query';
  scopePlans?: {
    __typename?: 'ScopePlanPageConnection';
    collection?: Array<{
      __typename?: 'ScopePlan';
      id: string;
      _id: string;
      pacingGuideId?: string | null;
      name?: string | null;
      scopes?: {
        __typename?: 'ScopePlanScopePageConnection';
        collection?: Array<{
          __typename?: 'ScopePlanScope';
          scopeId?: string | null;
          startDate?: string | null;
          endDate?: string | null;
          elements?: any | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetWidgetQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetWidgetQuery = {
  __typename?: 'Query';
  widget: {
    __typename?: 'Widget';
    h: number;
    i: string;
    id: string;
    minH: number;
    minW: number;
    w: number;
    x: number;
    y: number;
    expandedW: number;
    expandedH: number;
    collapsed: boolean;
    type: WidgetType;
    config?: any | null;
  };
};

export type GetDashboardEmbedUrlForAnonymousUserQueryVariables = Exact<{
  dashboardID: Scalars['String'];
  sheetID?: InputMaybe<Scalars['String']>;
  visualID?: InputMaybe<Scalars['String']>;
}>;

export type GetDashboardEmbedUrlForAnonymousUserQuery = {
  __typename?: 'Query';
  dashboardEmbedUrlForAnonymousUser: {
    __typename?: 'EmbedUrlResponse';
    EmbedUrl: string;
  };
};

export type GetWidgetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWidgetsQuery = {
  __typename?: 'Query';
  widgets: Array<{
    __typename?: 'Widget';
    h: number;
    i: string;
    id: string;
    minH: number;
    minW: number;
    w: number;
    x: number;
    y: number;
    expandedW: number;
    expandedH: number;
    collapsed: boolean;
    type: WidgetType;
    config?: any | null;
  } | null>;
};

export type UpdateWidgetsMutationVariables = Exact<{
  input: Array<InputMaybe<UpdateWidgetInput>> | InputMaybe<UpdateWidgetInput>;
}>;

export type UpdateWidgetsMutation = {
  __typename?: 'Mutation';
  updateWidgets: Array<{
    __typename?: 'Widget';
    h: number;
    i: string;
    id: string;
    minH: number;
    minW: number;
    w: number;
    x: number;
    y: number;
    expandedW: number;
    expandedH: number;
    collapsed: boolean;
    type: WidgetType;
  } | null>;
};

export type UpdateWidgetMutationVariables = Exact<{
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
  w?: InputMaybe<Scalars['Int']>;
  h?: InputMaybe<Scalars['Int']>;
  i: Scalars['String'];
  id: Scalars['String'];
  collapsed?: InputMaybe<Scalars['Boolean']>;
  expandedH?: InputMaybe<Scalars['Int']>;
  expandedW?: InputMaybe<Scalars['Int']>;
  minH?: InputMaybe<Scalars['Int']>;
  minW?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<WidgetType>;
  config?: InputMaybe<Scalars['JSONObject']>;
}>;

export type UpdateWidgetMutation = {
  __typename?: 'Mutation';
  updateWidget: {
    __typename?: 'Widget';
    h: number;
    i: string;
    id: string;
    minH: number;
    minW: number;
    w: number;
    x: number;
    y: number;
    expandedW: number;
    expandedH: number;
    collapsed: boolean;
    type: WidgetType;
    config?: any | null;
  };
};

export type DeleteWidgetMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteWidgetMutation = {
  __typename?: 'Mutation';
  deleteWidget: { __typename?: 'Widget'; type: WidgetType };
};

export const ModuleFragmentDoc = gql`
  fragment Module on ApplicationModule {
    name
    operations {
      name
      scopes
    }
    modules {
      name
      operations {
        name
        scopes
      }
      modules {
        name
        operations {
          name
          scopes
        }
        modules {
          name
          operations {
            name
            scopes
          }
        }
      }
    }
  }
`;
export const ApplicationSettingsDocument = gql`
  query applicationSettings {
    applicationSettings {
      value
      id
    }
  }
`;

/**
 * __useApplicationSettingsQuery__
 *
 * To run a query within a React component, call `useApplicationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApplicationSettingsQuery,
    ApplicationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationSettingsQuery,
    ApplicationSettingsQueryVariables
  >(ApplicationSettingsDocument, options);
}
export function useApplicationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationSettingsQuery,
    ApplicationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationSettingsQuery,
    ApplicationSettingsQueryVariables
  >(ApplicationSettingsDocument, options);
}
export type ApplicationSettingsQueryHookResult = ReturnType<
  typeof useApplicationSettingsQuery
>;
export type ApplicationSettingsLazyQueryHookResult = ReturnType<
  typeof useApplicationSettingsLazyQuery
>;
export type ApplicationSettingsQueryResult = Apollo.QueryResult<
  ApplicationSettingsQuery,
  ApplicationSettingsQueryVariables
>;
export const GetSchoolsDocument = gql`
  query getSchools(
    $id_list: [String]
    $itemsPerPage: Int = 10000
    $districtId: String
  ) {
    schools(
      id_list: $id_list
      itemsPerPage: $itemsPerPage
      districtId: $districtId
    ) {
      collection {
        globalName
        id
        _id
        name
      }
    }
  }
`;

/**
 * __useGetSchoolsQuery__
 *
 * To run a query within a React component, call `useGetSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsQuery({
 *   variables: {
 *      id_list: // value for 'id_list'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      districtId: // value for 'districtId'
 *   },
 * });
 */
export function useGetSchoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolsQuery,
    GetSchoolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(
    GetSchoolsDocument,
    options
  );
}
export function useGetSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsQuery,
    GetSchoolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(
    GetSchoolsDocument,
    options
  );
}
export type GetSchoolsQueryHookResult = ReturnType<typeof useGetSchoolsQuery>;
export type GetSchoolsLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsLazyQuery
>;
export type GetSchoolsQueryResult = Apollo.QueryResult<
  GetSchoolsQuery,
  GetSchoolsQueryVariables
>;
export const GetSystemSettingsDocument = gql`
  query getSystemSettings {
    applicationSettings {
      value
      id
    }
    aclApplications {
      name
    }
  }
`;

/**
 * __useGetSystemSettingsQuery__
 *
 * To run a query within a React component, call `useGetSystemSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSystemSettingsQuery,
    GetSystemSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSystemSettingsQuery,
    GetSystemSettingsQueryVariables
  >(GetSystemSettingsDocument, options);
}
export function useGetSystemSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSystemSettingsQuery,
    GetSystemSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSystemSettingsQuery,
    GetSystemSettingsQueryVariables
  >(GetSystemSettingsDocument, options);
}
export type GetSystemSettingsQueryHookResult = ReturnType<
  typeof useGetSystemSettingsQuery
>;
export type GetSystemSettingsLazyQueryHookResult = ReturnType<
  typeof useGetSystemSettingsLazyQuery
>;
export type GetSystemSettingsQueryResult = Apollo.QueryResult<
  GetSystemSettingsQuery,
  GetSystemSettingsQueryVariables
>;
export const ControlContainerDocument = gql`
  mutation controlContainer($input: ControlContainerInput!) {
    controlContainer(input: $input) {
      statusPayload {
        success
      }
    }
  }
`;
export type ControlContainerMutationFn = Apollo.MutationFunction<
  ControlContainerMutation,
  ControlContainerMutationVariables
>;

/**
 * __useControlContainerMutation__
 *
 * To run a mutation, you first call `useControlContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useControlContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [controlContainerMutation, { data, loading, error }] = useControlContainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useControlContainerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ControlContainerMutation,
    ControlContainerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ControlContainerMutation,
    ControlContainerMutationVariables
  >(ControlContainerDocument, options);
}
export type ControlContainerMutationHookResult = ReturnType<
  typeof useControlContainerMutation
>;
export type ControlContainerMutationResult =
  Apollo.MutationResult<ControlContainerMutation>;
export type ControlContainerMutationOptions = Apollo.BaseMutationOptions<
  ControlContainerMutation,
  ControlContainerMutationVariables
>;
export const GetAssessmentPoolForAccessSettingsDocument = gql`
  query getAssessmentPoolForAccessSettings($input: AssessmentPoolInput!) {
    assessmentPoolLeader(input: $input) {
      stemscopesPayload {
        list {
          id
          title
          hiddenForSchool
        }
      }
    }
  }
`;

/**
 * __useGetAssessmentPoolForAccessSettingsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentPoolForAccessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentPoolForAccessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentPoolForAccessSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssessmentPoolForAccessSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessmentPoolForAccessSettingsQuery,
    GetAssessmentPoolForAccessSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssessmentPoolForAccessSettingsQuery,
    GetAssessmentPoolForAccessSettingsQueryVariables
  >(GetAssessmentPoolForAccessSettingsDocument, options);
}
export function useGetAssessmentPoolForAccessSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentPoolForAccessSettingsQuery,
    GetAssessmentPoolForAccessSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssessmentPoolForAccessSettingsQuery,
    GetAssessmentPoolForAccessSettingsQueryVariables
  >(GetAssessmentPoolForAccessSettingsDocument, options);
}
export type GetAssessmentPoolForAccessSettingsQueryHookResult = ReturnType<
  typeof useGetAssessmentPoolForAccessSettingsQuery
>;
export type GetAssessmentPoolForAccessSettingsLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentPoolForAccessSettingsLazyQuery
>;
export type GetAssessmentPoolForAccessSettingsQueryResult = Apollo.QueryResult<
  GetAssessmentPoolForAccessSettingsQuery,
  GetAssessmentPoolForAccessSettingsQueryVariables
>;
export const GetExtraQuestionSettingDocument = gql`
  query getExtraQuestionSetting {
    readContainer {
      visibilityPayload {
        isVisible
      }
    }
  }
`;

/**
 * __useGetExtraQuestionSettingQuery__
 *
 * To run a query within a React component, call `useGetExtraQuestionSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtraQuestionSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtraQuestionSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtraQuestionSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExtraQuestionSettingQuery,
    GetExtraQuestionSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExtraQuestionSettingQuery,
    GetExtraQuestionSettingQueryVariables
  >(GetExtraQuestionSettingDocument, options);
}
export function useGetExtraQuestionSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExtraQuestionSettingQuery,
    GetExtraQuestionSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExtraQuestionSettingQuery,
    GetExtraQuestionSettingQueryVariables
  >(GetExtraQuestionSettingDocument, options);
}
export type GetExtraQuestionSettingQueryHookResult = ReturnType<
  typeof useGetExtraQuestionSettingQuery
>;
export type GetExtraQuestionSettingLazyQueryHookResult = ReturnType<
  typeof useGetExtraQuestionSettingLazyQuery
>;
export type GetExtraQuestionSettingQueryResult = Apollo.QueryResult<
  GetExtraQuestionSettingQuery,
  GetExtraQuestionSettingQueryVariables
>;
export const UpdateControlAssessmentDocument = gql`
  mutation updateControlAssessment($input: ControlAssessmentInput!) {
    controlAssessment(input: $input) {
      statusPayload {
        success
      }
    }
  }
`;
export type UpdateControlAssessmentMutationFn = Apollo.MutationFunction<
  UpdateControlAssessmentMutation,
  UpdateControlAssessmentMutationVariables
>;

/**
 * __useUpdateControlAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateControlAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateControlAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateControlAssessmentMutation, { data, loading, error }] = useUpdateControlAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateControlAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateControlAssessmentMutation,
    UpdateControlAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateControlAssessmentMutation,
    UpdateControlAssessmentMutationVariables
  >(UpdateControlAssessmentDocument, options);
}
export type UpdateControlAssessmentMutationHookResult = ReturnType<
  typeof useUpdateControlAssessmentMutation
>;
export type UpdateControlAssessmentMutationResult =
  Apollo.MutationResult<UpdateControlAssessmentMutation>;
export type UpdateControlAssessmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateControlAssessmentMutation,
  UpdateControlAssessmentMutationVariables
>;
export const GetSchoolDocument = gql`
  query getSchool($id: ID!) {
    school(id: $id) {
      globalName
    }
  }
`;

/**
 * __useGetSchoolQuery__
 *
 * To run a query within a React component, call `useGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(
    GetSchoolDocument,
    options
  );
}
export function useGetSchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolQuery,
    GetSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(
    GetSchoolDocument,
    options
  );
}
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolLazyQueryHookResult = ReturnType<
  typeof useGetSchoolLazyQuery
>;
export type GetSchoolQueryResult = Apollo.QueryResult<
  GetSchoolQuery,
  GetSchoolQueryVariables
>;
export const CopyAssessmentDocument = gql`
  mutation copyAssessment($id: ID!, $currentSchoolId: String!) {
    copyAssessment(
      input: { stemscopesInput: { id: $id, currentSchoolId: $currentSchoolId } }
    ) {
      statusPayload {
        success
      }
    }
  }
`;
export type CopyAssessmentMutationFn = Apollo.MutationFunction<
  CopyAssessmentMutation,
  CopyAssessmentMutationVariables
>;

/**
 * __useCopyAssessmentMutation__
 *
 * To run a mutation, you first call `useCopyAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyAssessmentMutation, { data, loading, error }] = useCopyAssessmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentSchoolId: // value for 'currentSchoolId'
 *   },
 * });
 */
export function useCopyAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyAssessmentMutation,
    CopyAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CopyAssessmentMutation,
    CopyAssessmentMutationVariables
  >(CopyAssessmentDocument, options);
}
export type CopyAssessmentMutationHookResult = ReturnType<
  typeof useCopyAssessmentMutation
>;
export type CopyAssessmentMutationResult =
  Apollo.MutationResult<CopyAssessmentMutation>;
export type CopyAssessmentMutationOptions = Apollo.BaseMutationOptions<
  CopyAssessmentMutation,
  CopyAssessmentMutationVariables
>;
export const GetLookUpRolesDocument = gql`
  query getLookUpRoles($ids: [ID!]!) {
    lookupRoles(ids: $ids) {
      id
      code
      name
    }
  }
`;

/**
 * __useGetLookUpRolesQuery__
 *
 * To run a query within a React component, call `useGetLookUpRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLookUpRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLookUpRolesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetLookUpRolesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLookUpRolesQuery,
    GetLookUpRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLookUpRolesQuery, GetLookUpRolesQueryVariables>(
    GetLookUpRolesDocument,
    options
  );
}
export function useGetLookUpRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLookUpRolesQuery,
    GetLookUpRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLookUpRolesQuery, GetLookUpRolesQueryVariables>(
    GetLookUpRolesDocument,
    options
  );
}
export type GetLookUpRolesQueryHookResult = ReturnType<
  typeof useGetLookUpRolesQuery
>;
export type GetLookUpRolesLazyQueryHookResult = ReturnType<
  typeof useGetLookUpRolesLazyQuery
>;
export type GetLookUpRolesQueryResult = Apollo.QueryResult<
  GetLookUpRolesQuery,
  GetLookUpRolesQueryVariables
>;
export const GetAssessmentDocument = gql`
  query getAssessment($input: AssessmentInput!) {
    assessment(input: $input) {
      stemscopesPayload {
        item {
          id
          title
          items
          isArchived
          isShared
          usersViewOnlyIds
          library
          deliveries
          standards
          subPurpose
          activityType
          teams
        }
      }
    }
  }
`;

/**
 * __useGetAssessmentQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(
    GetAssessmentDocument,
    options
  );
}
export function useGetAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(
    GetAssessmentDocument,
    options
  );
}
export type GetAssessmentQueryHookResult = ReturnType<
  typeof useGetAssessmentQuery
>;
export type GetAssessmentLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentLazyQuery
>;
export type GetAssessmentQueryResult = Apollo.QueryResult<
  GetAssessmentQuery,
  GetAssessmentQueryVariables
>;
export const GetAssessmentLeaderDocument = gql`
  query getAssessmentLeader($input: AssessmentInput!) {
    assessmentLeader(input: $input) {
      stemscopesPayload {
        item {
          id
          title
          items
          isArchived
          isShared
          usersViewOnlyIds
          library
          deliveries
          standards
          subPurpose
          activityType
          teams
        }
      }
    }
  }
`;

/**
 * __useGetAssessmentLeaderQuery__
 *
 * To run a query within a React component, call `useGetAssessmentLeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentLeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentLeaderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssessmentLeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessmentLeaderQuery,
    GetAssessmentLeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssessmentLeaderQuery,
    GetAssessmentLeaderQueryVariables
  >(GetAssessmentLeaderDocument, options);
}
export function useGetAssessmentLeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentLeaderQuery,
    GetAssessmentLeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssessmentLeaderQuery,
    GetAssessmentLeaderQueryVariables
  >(GetAssessmentLeaderDocument, options);
}
export type GetAssessmentLeaderQueryHookResult = ReturnType<
  typeof useGetAssessmentLeaderQuery
>;
export type GetAssessmentLeaderLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentLeaderLazyQuery
>;
export type GetAssessmentLeaderQueryResult = Apollo.QueryResult<
  GetAssessmentLeaderQuery,
  GetAssessmentLeaderQueryVariables
>;
export const GetAssessmentEnumsDocument = gql`
  query getAssessmentEnums($input: PurposeEnumInput) {
    poolEnums(input: $input) {
      standards
      scopes
      questionTypes
      keywords
      grades
      editions
      curriculumAreas
    }
  }
`;

/**
 * __useGetAssessmentEnumsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentEnumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentEnumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentEnumsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssessmentEnumsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssessmentEnumsQuery,
    GetAssessmentEnumsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssessmentEnumsQuery,
    GetAssessmentEnumsQueryVariables
  >(GetAssessmentEnumsDocument, options);
}
export function useGetAssessmentEnumsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentEnumsQuery,
    GetAssessmentEnumsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssessmentEnumsQuery,
    GetAssessmentEnumsQueryVariables
  >(GetAssessmentEnumsDocument, options);
}
export type GetAssessmentEnumsQueryHookResult = ReturnType<
  typeof useGetAssessmentEnumsQuery
>;
export type GetAssessmentEnumsLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentEnumsLazyQuery
>;
export type GetAssessmentEnumsQueryResult = Apollo.QueryResult<
  GetAssessmentEnumsQuery,
  GetAssessmentEnumsQueryVariables
>;
export const ShareAssessmentDocument = gql`
  mutation shareAssessment($input: StemscopesShareAssessmentInput!) {
    shareAssessment(input: { stemscopesInput: $input }) {
      statusPayload {
        success
      }
    }
  }
`;
export type ShareAssessmentMutationFn = Apollo.MutationFunction<
  ShareAssessmentMutation,
  ShareAssessmentMutationVariables
>;

/**
 * __useShareAssessmentMutation__
 *
 * To run a mutation, you first call `useShareAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareAssessmentMutation, { data, loading, error }] = useShareAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareAssessmentMutation,
    ShareAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareAssessmentMutation,
    ShareAssessmentMutationVariables
  >(ShareAssessmentDocument, options);
}
export type ShareAssessmentMutationHookResult = ReturnType<
  typeof useShareAssessmentMutation
>;
export type ShareAssessmentMutationResult =
  Apollo.MutationResult<ShareAssessmentMutation>;
export type ShareAssessmentMutationOptions = Apollo.BaseMutationOptions<
  ShareAssessmentMutation,
  ShareAssessmentMutationVariables
>;
export const GetAllAssessmentsDocument = gql`
  query getAllAssessments($input: AssessmentPoolInput!) {
    assessmentPool(input: $input) {
      stemscopesPayload {
        list {
          id
          title
          items
          createdAt
          isArchived
          isShared
          usersViewOnlyIds
          library
          deliveries
          standards
          teams
          createdBy {
            lastName
            firstName
          }
          items
          activityType
        }
        paginationInfo {
          itemsPerPage
          lastPage
          totalCount
        }
      }
    }
  }
`;

/**
 * __useGetAllAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetAllAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssessmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllAssessmentsQuery,
    GetAllAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllAssessmentsQuery,
    GetAllAssessmentsQueryVariables
  >(GetAllAssessmentsDocument, options);
}
export function useGetAllAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssessmentsQuery,
    GetAllAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllAssessmentsQuery,
    GetAllAssessmentsQueryVariables
  >(GetAllAssessmentsDocument, options);
}
export type GetAllAssessmentsQueryHookResult = ReturnType<
  typeof useGetAllAssessmentsQuery
>;
export type GetAllAssessmentsLazyQueryHookResult = ReturnType<
  typeof useGetAllAssessmentsLazyQuery
>;
export type GetAllAssessmentsQueryResult = Apollo.QueryResult<
  GetAllAssessmentsQuery,
  GetAllAssessmentsQueryVariables
>;
export const GetAllAssessmentsLeaderDocument = gql`
  query getAllAssessmentsLeader($input: AssessmentPoolInput!) {
    assessmentPoolLeader(input: $input) {
      stemscopesPayload {
        list {
          id
          title
          items
          createdAt
          isArchived
          isShared
          usersViewOnlyIds
          library
          deliveries
          hasManuallyGradedQuestions
          standards
          teams
          createdBy {
            lastName
            firstName
          }
          items
          activityType
        }
        paginationInfo {
          itemsPerPage
          lastPage
          totalCount
        }
      }
    }
  }
`;

/**
 * __useGetAllAssessmentsLeaderQuery__
 *
 * To run a query within a React component, call `useGetAllAssessmentsLeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssessmentsLeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssessmentsLeaderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllAssessmentsLeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllAssessmentsLeaderQuery,
    GetAllAssessmentsLeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllAssessmentsLeaderQuery,
    GetAllAssessmentsLeaderQueryVariables
  >(GetAllAssessmentsLeaderDocument, options);
}
export function useGetAllAssessmentsLeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssessmentsLeaderQuery,
    GetAllAssessmentsLeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllAssessmentsLeaderQuery,
    GetAllAssessmentsLeaderQueryVariables
  >(GetAllAssessmentsLeaderDocument, options);
}
export type GetAllAssessmentsLeaderQueryHookResult = ReturnType<
  typeof useGetAllAssessmentsLeaderQuery
>;
export type GetAllAssessmentsLeaderLazyQueryHookResult = ReturnType<
  typeof useGetAllAssessmentsLeaderLazyQuery
>;
export type GetAllAssessmentsLeaderQueryResult = Apollo.QueryResult<
  GetAllAssessmentsLeaderQuery,
  GetAllAssessmentsLeaderQueryVariables
>;
export const GetUsersToShareDocument = gql`
  query getUsersToShare(
    $page: Int
    $itemsPerPage: Int
    $fullName: String
    $type: UserTypeEnum
    $schoolId: String
    $schoolsIds: [ID]
    $id_list: [String]
  ) {
    users(
      page: $page
      itemsPerPage: $itemsPerPage
      type: $type
      schoolsId: $schoolId
      fullName: $fullName
      id_list: $id_list
      schoolsIds: $schoolsIds
    ) {
      collection {
        _id
        firstName
        lastName
        userRoles {
          collection {
            role {
              code
            }
          }
        }
      }
      paginationInfo {
        itemsPerPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetUsersToShareQuery__
 *
 * To run a query within a React component, call `useGetUsersToShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersToShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersToShareQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      fullName: // value for 'fullName'
 *      type: // value for 'type'
 *      schoolId: // value for 'schoolId'
 *      schoolsIds: // value for 'schoolsIds'
 *      id_list: // value for 'id_list'
 *   },
 * });
 */
export function useGetUsersToShareQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersToShareQuery,
    GetUsersToShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersToShareQuery, GetUsersToShareQueryVariables>(
    GetUsersToShareDocument,
    options
  );
}
export function useGetUsersToShareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersToShareQuery,
    GetUsersToShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersToShareQuery,
    GetUsersToShareQueryVariables
  >(GetUsersToShareDocument, options);
}
export type GetUsersToShareQueryHookResult = ReturnType<
  typeof useGetUsersToShareQuery
>;
export type GetUsersToShareLazyQueryHookResult = ReturnType<
  typeof useGetUsersToShareLazyQuery
>;
export type GetUsersToShareQueryResult = Apollo.QueryResult<
  GetUsersToShareQuery,
  GetUsersToShareQueryVariables
>;
export const UpdateAssessmentDocument = gql`
  mutation updateAssessment($input: StemscopesUpdateAssessmentInput!) {
    updateAssessment(input: { stemscopesInput: $input }) {
      stemscopesPayload {
        item {
          id
          title
          items
          isArchived
          isShared
          usersViewOnlyIds
          library
          deliveries
          activityType
          teams
        }
      }
    }
  }
`;
export type UpdateAssessmentMutationFn = Apollo.MutationFunction<
  UpdateAssessmentMutation,
  UpdateAssessmentMutationVariables
>;

/**
 * __useUpdateAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentMutation, { data, loading, error }] = useUpdateAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssessmentMutation,
    UpdateAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssessmentMutation,
    UpdateAssessmentMutationVariables
  >(UpdateAssessmentDocument, options);
}
export type UpdateAssessmentMutationHookResult = ReturnType<
  typeof useUpdateAssessmentMutation
>;
export type UpdateAssessmentMutationResult =
  Apollo.MutationResult<UpdateAssessmentMutation>;
export type UpdateAssessmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssessmentMutation,
  UpdateAssessmentMutationVariables
>;
export const ArchiveAssignmentDocument = gql`
  mutation archiveAssignment(
    $action: ArchiveAssignmentAction!
    $assignmentIds: [String!]!
  ) {
    archiveAssignments(action: $action, assignmentIds: $assignmentIds) {
      collection {
        assignmentId
      }
    }
  }
`;
export type ArchiveAssignmentMutationFn = Apollo.MutationFunction<
  ArchiveAssignmentMutation,
  ArchiveAssignmentMutationVariables
>;

/**
 * __useArchiveAssignmentMutation__
 *
 * To run a mutation, you first call `useArchiveAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAssignmentMutation, { data, loading, error }] = useArchiveAssignmentMutation({
 *   variables: {
 *      action: // value for 'action'
 *      assignmentIds: // value for 'assignmentIds'
 *   },
 * });
 */
export function useArchiveAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveAssignmentMutation,
    ArchiveAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveAssignmentMutation,
    ArchiveAssignmentMutationVariables
  >(ArchiveAssignmentDocument, options);
}
export type ArchiveAssignmentMutationHookResult = ReturnType<
  typeof useArchiveAssignmentMutation
>;
export type ArchiveAssignmentMutationResult =
  Apollo.MutationResult<ArchiveAssignmentMutation>;
export type ArchiveAssignmentMutationOptions = Apollo.BaseMutationOptions<
  ArchiveAssignmentMutation,
  ArchiveAssignmentMutationVariables
>;
export const EditStemscopesAssignmentDocument = gql`
  mutation editStemscopesAssignment($assignmentParams: StemscopesEditInput!) {
    editStemscopesAssignment(assignmentParams: $assignmentParams) {
      assignmentId
    }
  }
`;
export type EditStemscopesAssignmentMutationFn = Apollo.MutationFunction<
  EditStemscopesAssignmentMutation,
  EditStemscopesAssignmentMutationVariables
>;

/**
 * __useEditStemscopesAssignmentMutation__
 *
 * To run a mutation, you first call `useEditStemscopesAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStemscopesAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStemscopesAssignmentMutation, { data, loading, error }] = useEditStemscopesAssignmentMutation({
 *   variables: {
 *      assignmentParams: // value for 'assignmentParams'
 *   },
 * });
 */
export function useEditStemscopesAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditStemscopesAssignmentMutation,
    EditStemscopesAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditStemscopesAssignmentMutation,
    EditStemscopesAssignmentMutationVariables
  >(EditStemscopesAssignmentDocument, options);
}
export type EditStemscopesAssignmentMutationHookResult = ReturnType<
  typeof useEditStemscopesAssignmentMutation
>;
export type EditStemscopesAssignmentMutationResult =
  Apollo.MutationResult<EditStemscopesAssignmentMutation>;
export type EditStemscopesAssignmentMutationOptions =
  Apollo.BaseMutationOptions<
    EditStemscopesAssignmentMutation,
    EditStemscopesAssignmentMutationVariables
  >;
export const GetAssessmentQuestionsDocument = gql`
  query getAssessmentQuestions(
    $ids: [String!]
    $deliveries: [DeliveryEnum!]
    $gradingTypes: [GradingTypeEnum!]
    $questionBanks: [QuestionBankEnum!]
    $itemsPerPage: Int
    $page: Int
    $curriculumAreas: [String!]
    $editions: [String!]
    $grades: [String!]
    $standards: [String!]
    $scopes: [String!]
    $questionTypes: [String!]
    $keywords: [String!]
    $excludeIds: [String!]
    $assessmentId: String = null
  ) {
    questionPool(
      input: {
        stemscopesInput: {
          ids: $ids
          deliveries: $deliveries
          excludeIds: $excludeIds
          gradingTypes: $gradingTypes
          questionBanks: $questionBanks
          curriculumAreas: $curriculumAreas
          grades: $grades
          questionTypes: $questionTypes
          scopes: $scopes
          standards: $standards
          keywords: $keywords
          editions: $editions
          itemsPerPage: $itemsPerPage
          page: $page
          assessmentId: $assessmentId
        }
      }
    ) {
      impStemscopesPayload {
        collection {
          id
          grading
          standards
          questionTypes
          grades
          curriculumAreas
          scopes
          editions
        }
        paginationInfo {
          itemsPerPage
          lastPage
          totalCount
        }
      }
    }
  }
`;

/**
 * __useGetAssessmentQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuestionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      deliveries: // value for 'deliveries'
 *      gradingTypes: // value for 'gradingTypes'
 *      questionBanks: // value for 'questionBanks'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *      curriculumAreas: // value for 'curriculumAreas'
 *      editions: // value for 'editions'
 *      grades: // value for 'grades'
 *      standards: // value for 'standards'
 *      scopes: // value for 'scopes'
 *      questionTypes: // value for 'questionTypes'
 *      keywords: // value for 'keywords'
 *      excludeIds: // value for 'excludeIds'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssessmentQuestionsQuery,
    GetAssessmentQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssessmentQuestionsQuery,
    GetAssessmentQuestionsQueryVariables
  >(GetAssessmentQuestionsDocument, options);
}
export function useGetAssessmentQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentQuestionsQuery,
    GetAssessmentQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssessmentQuestionsQuery,
    GetAssessmentQuestionsQueryVariables
  >(GetAssessmentQuestionsDocument, options);
}
export type GetAssessmentQuestionsQueryHookResult = ReturnType<
  typeof useGetAssessmentQuestionsQuery
>;
export type GetAssessmentQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentQuestionsLazyQuery
>;
export type GetAssessmentQuestionsQueryResult = Apollo.QueryResult<
  GetAssessmentQuestionsQuery,
  GetAssessmentQuestionsQueryVariables
>;
export const GetLeaderAssessmentQuestionsDocument = gql`
  query getLeaderAssessmentQuestions(
    $ids: [String!]
    $deliveries: [DeliveryEnum!]
    $gradingTypes: [GradingTypeEnum!]
    $questionBanks: [QuestionBankEnum!]
    $itemsPerPage: Int
    $page: Int
    $curriculumAreas: [String!]
    $editions: [String!]
    $grades: [String!]
    $standards: [String!]
    $scopes: [String!]
    $questionTypes: [String!]
    $keywords: [String!]
    $excludeIds: [String!]
    $assessmentId: String = null
  ) {
    questionPoolLeader(
      input: {
        stemscopesInput: {
          ids: $ids
          deliveries: $deliveries
          excludeIds: $excludeIds
          gradingTypes: $gradingTypes
          questionBanks: $questionBanks
          curriculumAreas: $curriculumAreas
          grades: $grades
          questionTypes: $questionTypes
          scopes: $scopes
          standards: $standards
          keywords: $keywords
          editions: $editions
          itemsPerPage: $itemsPerPage
          page: $page
          assessmentId: $assessmentId
        }
      }
    ) {
      impStemscopesPayload {
        collection {
          id
          grading
          standards
          questionTypes
          grades
          curriculumAreas
          scopes
          editions
        }
        paginationInfo {
          itemsPerPage
          lastPage
          totalCount
        }
      }
    }
  }
`;

/**
 * __useGetLeaderAssessmentQuestionsQuery__
 *
 * To run a query within a React component, call `useGetLeaderAssessmentQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaderAssessmentQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaderAssessmentQuestionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      deliveries: // value for 'deliveries'
 *      gradingTypes: // value for 'gradingTypes'
 *      questionBanks: // value for 'questionBanks'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *      curriculumAreas: // value for 'curriculumAreas'
 *      editions: // value for 'editions'
 *      grades: // value for 'grades'
 *      standards: // value for 'standards'
 *      scopes: // value for 'scopes'
 *      questionTypes: // value for 'questionTypes'
 *      keywords: // value for 'keywords'
 *      excludeIds: // value for 'excludeIds'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetLeaderAssessmentQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLeaderAssessmentQuestionsQuery,
    GetLeaderAssessmentQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaderAssessmentQuestionsQuery,
    GetLeaderAssessmentQuestionsQueryVariables
  >(GetLeaderAssessmentQuestionsDocument, options);
}
export function useGetLeaderAssessmentQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaderAssessmentQuestionsQuery,
    GetLeaderAssessmentQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaderAssessmentQuestionsQuery,
    GetLeaderAssessmentQuestionsQueryVariables
  >(GetLeaderAssessmentQuestionsDocument, options);
}
export type GetLeaderAssessmentQuestionsQueryHookResult = ReturnType<
  typeof useGetLeaderAssessmentQuestionsQuery
>;
export type GetLeaderAssessmentQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetLeaderAssessmentQuestionsLazyQuery
>;
export type GetLeaderAssessmentQuestionsQueryResult = Apollo.QueryResult<
  GetLeaderAssessmentQuestionsQuery,
  GetLeaderAssessmentQuestionsQueryVariables
>;
export const GetAllOwnerAssignmentsDocument = gql`
  query getAllOwnerAssignments($schoolId: String) {
    ownerAssignments(schoolId: $schoolId) {
      assignments {
        assignmentSource {
          element {
            elementId
            viewId
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllOwnerAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetAllOwnerAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOwnerAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOwnerAssignmentsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetAllOwnerAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllOwnerAssignmentsQuery,
    GetAllOwnerAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllOwnerAssignmentsQuery,
    GetAllOwnerAssignmentsQueryVariables
  >(GetAllOwnerAssignmentsDocument, options);
}
export function useGetAllOwnerAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllOwnerAssignmentsQuery,
    GetAllOwnerAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllOwnerAssignmentsQuery,
    GetAllOwnerAssignmentsQueryVariables
  >(GetAllOwnerAssignmentsDocument, options);
}
export type GetAllOwnerAssignmentsQueryHookResult = ReturnType<
  typeof useGetAllOwnerAssignmentsQuery
>;
export type GetAllOwnerAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetAllOwnerAssignmentsLazyQuery
>;
export type GetAllOwnerAssignmentsQueryResult = Apollo.QueryResult<
  GetAllOwnerAssignmentsQuery,
  GetAllOwnerAssignmentsQueryVariables
>;
export const GetAssignmentListDocument = gql`
  query getAssignmentList(
    $id: String!
    $schoolId: String!
    $teachersId: String!
    $archived: Boolean = false
  ) {
    assignments(filter: { assignmentListId: $id }, schoolId: $schoolId) {
      collection {
        name
        classId
        startDate
        endDate
        immediatelyShareResults
        lateTurnIn
        assignmentId
        subgroupIds
        archived
        studentAssignments {
          studentId
          assessmentId
          archived
          assignmentId
          status
          grade
          completedAt
          submittedAt
          endDate
          startedAt
          classId
          answers
          id
          studentNotes
          learnosityReferenceId
          shareResults
        }
        assignmentSource {
          element {
            elementId
            scopeId
            projectId
            sectionId
            elementName
            scopeName
            viewId
          }
        }
      }
    }
    teacherStudents(
      schoolId: $schoolId
      teachersId: $teachersId
      itemsPerPage: 300
    ) {
      collection {
        _id
        firstName
        lastName
        classes(teachersId: $teachersId, archived: $archived, status: Active) {
          collection {
            _id
          }
        }
      }
    }
    classes(teachersId: $teachersId, archived: $archived, status: Active) {
      collection {
        _id
        name
      }
    }
  }
`;

/**
 * __useGetAssignmentListQuery__
 *
 * To run a query within a React component, call `useGetAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      schoolId: // value for 'schoolId'
 *      teachersId: // value for 'teachersId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetAssignmentListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssignmentListQuery,
    GetAssignmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssignmentListQuery,
    GetAssignmentListQueryVariables
  >(GetAssignmentListDocument, options);
}
export function useGetAssignmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignmentListQuery,
    GetAssignmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignmentListQuery,
    GetAssignmentListQueryVariables
  >(GetAssignmentListDocument, options);
}
export type GetAssignmentListQueryHookResult = ReturnType<
  typeof useGetAssignmentListQuery
>;
export type GetAssignmentListLazyQueryHookResult = ReturnType<
  typeof useGetAssignmentListLazyQuery
>;
export type GetAssignmentListQueryResult = Apollo.QueryResult<
  GetAssignmentListQuery,
  GetAssignmentListQueryVariables
>;
export const GetAssignmentDocument = gql`
  query getAssignment($id: String, $schoolId: String, $lti: Boolean) {
    assignment(id: $id, schoolId: $schoolId, lti: $lti) {
      assignmentId
      endDate
      startDate
      name
      classId
      archived
      immediatelyShareResults
      lateTurnIn
      notes
      subgroupIds
      studentAssignments {
        id
        learnosityReferenceId
        studentId
        assignmentId
        status
        grade
        completedAt
        submittedAt
        endDate
        startedAt
        answers
        lateTurnIn
        shareResults
        returnedAt
        resetted
        studentNotes
        archived
        assessmentId
      }
      assignmentSource {
        element {
          elementId
          scopeId
          projectId
          sectionId
          elementName
          scopeName
          sectionName
          viewId
        }
      }
    }
  }
`;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      schoolId: // value for 'schoolId'
 *      lti: // value for 'lti'
 *   },
 * });
 */
export function useGetAssignmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssignmentQuery,
    GetAssignmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(
    GetAssignmentDocument,
    options
  );
}
export function useGetAssignmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignmentQuery,
    GetAssignmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(
    GetAssignmentDocument,
    options
  );
}
export type GetAssignmentQueryHookResult = ReturnType<
  typeof useGetAssignmentQuery
>;
export type GetAssignmentLazyQueryHookResult = ReturnType<
  typeof useGetAssignmentLazyQuery
>;
export type GetAssignmentQueryResult = Apollo.QueryResult<
  GetAssignmentQuery,
  GetAssignmentQueryVariables
>;
export const GetAssignmentClassDocument = gql`
  query getAssignmentClass(
    $classId: ID!
    $page: Int = 1
    $itemsPerPage: Int = 999
  ) {
    class(id: $classId) {
      _id
      id
      name
      classStudents(page: $page, itemsPerPage: $itemsPerPage) {
        collection {
          _id
          student {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetAssignmentClassQuery__
 *
 * To run a query within a React component, call `useGetAssignmentClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetAssignmentClassQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssignmentClassQuery,
    GetAssignmentClassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssignmentClassQuery,
    GetAssignmentClassQueryVariables
  >(GetAssignmentClassDocument, options);
}
export function useGetAssignmentClassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignmentClassQuery,
    GetAssignmentClassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignmentClassQuery,
    GetAssignmentClassQueryVariables
  >(GetAssignmentClassDocument, options);
}
export type GetAssignmentClassQueryHookResult = ReturnType<
  typeof useGetAssignmentClassQuery
>;
export type GetAssignmentClassLazyQueryHookResult = ReturnType<
  typeof useGetAssignmentClassLazyQuery
>;
export type GetAssignmentClassQueryResult = Apollo.QueryResult<
  GetAssignmentClassQuery,
  GetAssignmentClassQueryVariables
>;
export const GetAssignmentClassesDocument = gql`
  query getAssignmentClasses(
    $classIds: [String]
    $schoolId: String
    $page: Int = 1
    $itemsPerPage: Int = 999
  ) {
    classes(id_list: $classIds, schoolId: $schoolId, status: Active) {
      collection {
        _id
        id
        name
        classStudents(page: $page, itemsPerPage: $itemsPerPage) {
          collection {
            _id
            student {
              _id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAssignmentClassesQuery__
 *
 * To run a query within a React component, call `useGetAssignmentClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentClassesQuery({
 *   variables: {
 *      classIds: // value for 'classIds'
 *      schoolId: // value for 'schoolId'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetAssignmentClassesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssignmentClassesQuery,
    GetAssignmentClassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssignmentClassesQuery,
    GetAssignmentClassesQueryVariables
  >(GetAssignmentClassesDocument, options);
}
export function useGetAssignmentClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignmentClassesQuery,
    GetAssignmentClassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignmentClassesQuery,
    GetAssignmentClassesQueryVariables
  >(GetAssignmentClassesDocument, options);
}
export type GetAssignmentClassesQueryHookResult = ReturnType<
  typeof useGetAssignmentClassesQuery
>;
export type GetAssignmentClassesLazyQueryHookResult = ReturnType<
  typeof useGetAssignmentClassesLazyQuery
>;
export type GetAssignmentClassesQueryResult = Apollo.QueryResult<
  GetAssignmentClassesQuery,
  GetAssignmentClassesQueryVariables
>;
export const GetStudentsBySubgroupsDocument = gql`
  query getStudentsBySubgroups($classId: ID!) {
    class(id: $classId) {
      _id
      id
      name
      subgroups {
        collection {
          name
          id
          _id
          students {
            collection {
              _id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentsBySubgroupsQuery__
 *
 * To run a query within a React component, call `useGetStudentsBySubgroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsBySubgroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsBySubgroupsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetStudentsBySubgroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentsBySubgroupsQuery,
    GetStudentsBySubgroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentsBySubgroupsQuery,
    GetStudentsBySubgroupsQueryVariables
  >(GetStudentsBySubgroupsDocument, options);
}
export function useGetStudentsBySubgroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentsBySubgroupsQuery,
    GetStudentsBySubgroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentsBySubgroupsQuery,
    GetStudentsBySubgroupsQueryVariables
  >(GetStudentsBySubgroupsDocument, options);
}
export type GetStudentsBySubgroupsQueryHookResult = ReturnType<
  typeof useGetStudentsBySubgroupsQuery
>;
export type GetStudentsBySubgroupsLazyQueryHookResult = ReturnType<
  typeof useGetStudentsBySubgroupsLazyQuery
>;
export type GetStudentsBySubgroupsQueryResult = Apollo.QueryResult<
  GetStudentsBySubgroupsQuery,
  GetStudentsBySubgroupsQueryVariables
>;
export const GetDistrictStudentAssignmentsDocument = gql`
  query getDistrictStudentAssignments(
    $studentId: String
    $schoolId: String!
    $shareResults: Boolean
    $status: StudentAssignmentStatus
  ) {
    districtStudentAssignments(
      studentId: $studentId
      schoolId: $schoolId
      shareResults: $shareResults
      status: $status
    ) {
      collection {
        answers
        assignmentId
        endDate
        learnosityReferenceId
        name
        status
        studentNotes
        completedAt
        id
        submittedAt
        shareResults
      }
    }
  }
`;

/**
 * __useGetDistrictStudentAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetDistrictStudentAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictStudentAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictStudentAssignmentsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      schoolId: // value for 'schoolId'
 *      shareResults: // value for 'shareResults'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetDistrictStudentAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDistrictStudentAssignmentsQuery,
    GetDistrictStudentAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDistrictStudentAssignmentsQuery,
    GetDistrictStudentAssignmentsQueryVariables
  >(GetDistrictStudentAssignmentsDocument, options);
}
export function useGetDistrictStudentAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDistrictStudentAssignmentsQuery,
    GetDistrictStudentAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDistrictStudentAssignmentsQuery,
    GetDistrictStudentAssignmentsQueryVariables
  >(GetDistrictStudentAssignmentsDocument, options);
}
export type GetDistrictStudentAssignmentsQueryHookResult = ReturnType<
  typeof useGetDistrictStudentAssignmentsQuery
>;
export type GetDistrictStudentAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetDistrictStudentAssignmentsLazyQuery
>;
export type GetDistrictStudentAssignmentsQueryResult = Apollo.QueryResult<
  GetDistrictStudentAssignmentsQuery,
  GetDistrictStudentAssignmentsQueryVariables
>;
export const GetDistrictAssignmentsDocument = gql`
  query getDistrictAssignments($filter: DistrictAssignmentsFilter) {
    districtAssignments(filter: $filter) {
      collection {
        assignmentId
        startDate
        endDate
        name
        availableDate
        gradeLevel
        classId
        assignmentSource {
          element {
            scopeName
            elementName
            scopeId
            sectionId
            elementId
            projectId
            viewId
          }
        }
        studentAssignments {
          assignmentId
          id
          studentId
          answers
          status
          startedAt
          submittedAt
          completedAt
          endDate
          classId
          grade
          shareResults
          learnosityReferenceId
        }
      }
    }
  }
`;

/**
 * __useGetDistrictAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetDistrictAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictAssignmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDistrictAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDistrictAssignmentsQuery,
    GetDistrictAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDistrictAssignmentsQuery,
    GetDistrictAssignmentsQueryVariables
  >(GetDistrictAssignmentsDocument, options);
}
export function useGetDistrictAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDistrictAssignmentsQuery,
    GetDistrictAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDistrictAssignmentsQuery,
    GetDistrictAssignmentsQueryVariables
  >(GetDistrictAssignmentsDocument, options);
}
export type GetDistrictAssignmentsQueryHookResult = ReturnType<
  typeof useGetDistrictAssignmentsQuery
>;
export type GetDistrictAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetDistrictAssignmentsLazyQuery
>;
export type GetDistrictAssignmentsQueryResult = Apollo.QueryResult<
  GetDistrictAssignmentsQuery,
  GetDistrictAssignmentsQueryVariables
>;
export const GetClassAssignmentsDocument = gql`
  query getClassAssignments(
    $classId: String
    $id: ID!
    $page: Int = 1
    $itemsPerPage: Int = 999
    $schoolId: String
  ) {
    ownerAssignments(classId: $classId, schoolId: $schoolId) {
      assignments {
        classId
        assignmentListId
        assignmentId
        learnosityReferenceId
        name
        releaseFeedback
        startDate
        endDate
        status
        subgroupIds
        studentIds
        labels
        notes
      }
      studentAssignments {
        classId
        assignmentId
        studentId
        name
        id
        grade
        startedAt
        submittedAt
        completedAt
        status
        releaseFeedback
        lateTurnIn
        answers
        returnedAt
      }
      pageInfo {
        itemsPerPage
        totalCount
      }
    }
    class(id: $id) {
      _id
      name
      classStudents(page: $page, itemsPerPage: $itemsPerPage) {
        collection {
          _id
          id
          student {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetClassAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassAssignmentsQuery,
    GetClassAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassAssignmentsQuery,
    GetClassAssignmentsQueryVariables
  >(GetClassAssignmentsDocument, options);
}
export function useGetClassAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassAssignmentsQuery,
    GetClassAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassAssignmentsQuery,
    GetClassAssignmentsQueryVariables
  >(GetClassAssignmentsDocument, options);
}
export type GetClassAssignmentsQueryHookResult = ReturnType<
  typeof useGetClassAssignmentsQuery
>;
export type GetClassAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetClassAssignmentsLazyQuery
>;
export type GetClassAssignmentsQueryResult = Apollo.QueryResult<
  GetClassAssignmentsQuery,
  GetClassAssignmentsQueryVariables
>;
export const GetClassAssignmentsForAssignmentsWidgetDocument = gql`
  query getClassAssignmentsForAssignmentsWidget(
    $classIds: [String!]
    $schoolId: String
    $assignmentDateFilter: AssignmentDateFilterInput
    $endDateFilter: AssignmentDateFilterInput
  ) {
    ownerAssignments(
      classIds: $classIds
      schoolId: $schoolId
      assignmentDateFilter: $assignmentDateFilter
      endDateFilter: $endDateFilter
    ) {
      assignments {
        assignmentId
        name
        startDate
        endDate
        assignmentSource {
          element {
            scopeName
          }
        }
      }
      studentAssignments {
        assignmentId
        studentId
        status
        grade
        answers
      }
    }
  }
`;

/**
 * __useGetClassAssignmentsForAssignmentsWidgetQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentsForAssignmentsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentsForAssignmentsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentsForAssignmentsWidgetQuery({
 *   variables: {
 *      classIds: // value for 'classIds'
 *      schoolId: // value for 'schoolId'
 *      assignmentDateFilter: // value for 'assignmentDateFilter'
 *      endDateFilter: // value for 'endDateFilter'
 *   },
 * });
 */
export function useGetClassAssignmentsForAssignmentsWidgetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassAssignmentsForAssignmentsWidgetQuery,
    GetClassAssignmentsForAssignmentsWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassAssignmentsForAssignmentsWidgetQuery,
    GetClassAssignmentsForAssignmentsWidgetQueryVariables
  >(GetClassAssignmentsForAssignmentsWidgetDocument, options);
}
export function useGetClassAssignmentsForAssignmentsWidgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassAssignmentsForAssignmentsWidgetQuery,
    GetClassAssignmentsForAssignmentsWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassAssignmentsForAssignmentsWidgetQuery,
    GetClassAssignmentsForAssignmentsWidgetQueryVariables
  >(GetClassAssignmentsForAssignmentsWidgetDocument, options);
}
export type GetClassAssignmentsForAssignmentsWidgetQueryHookResult = ReturnType<
  typeof useGetClassAssignmentsForAssignmentsWidgetQuery
>;
export type GetClassAssignmentsForAssignmentsWidgetLazyQueryHookResult =
  ReturnType<typeof useGetClassAssignmentsForAssignmentsWidgetLazyQuery>;
export type GetClassAssignmentsForAssignmentsWidgetQueryResult =
  Apollo.QueryResult<
    GetClassAssignmentsForAssignmentsWidgetQuery,
    GetClassAssignmentsForAssignmentsWidgetQueryVariables
  >;
export const GetClassAssignmentsWithPaginationDocument = gql`
  query getClassAssignmentsWithPagination(
    $classId: String
    $id: ID!
    $page: Int = 1
    $itemsPerPage: Int = 999
    $schoolId: String
    $paginationInfo: StemscopesAssignmentPageInfoInput
  ) {
    ownerAssignments(
      classId: $classId
      schoolId: $schoolId
      paginationInfo: $paginationInfo
    ) {
      assignments {
        classId
        assignmentListId
        assignmentId
        learnosityReferenceId
        name
        releaseFeedback
        startDate
        endDate
        status
        subgroupIds
        studentIds
        labels
        notes
      }
      studentAssignments {
        classId
        assignmentId
        studentId
        name
        id
        grade
        startedAt
        submittedAt
        completedAt
        status
        releaseFeedback
        lateTurnIn
        answers
      }
      pageInfo {
        itemsPerPage
        totalCount
      }
    }
    class(id: $id) {
      _id
      name
      classStudents(page: $page, itemsPerPage: $itemsPerPage) {
        collection {
          _id
          id
          student {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassAssignmentsWithPaginationQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentsWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentsWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentsWithPaginationQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      schoolId: // value for 'schoolId'
 *      paginationInfo: // value for 'paginationInfo'
 *   },
 * });
 */
export function useGetClassAssignmentsWithPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassAssignmentsWithPaginationQuery,
    GetClassAssignmentsWithPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassAssignmentsWithPaginationQuery,
    GetClassAssignmentsWithPaginationQueryVariables
  >(GetClassAssignmentsWithPaginationDocument, options);
}
export function useGetClassAssignmentsWithPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassAssignmentsWithPaginationQuery,
    GetClassAssignmentsWithPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassAssignmentsWithPaginationQuery,
    GetClassAssignmentsWithPaginationQueryVariables
  >(GetClassAssignmentsWithPaginationDocument, options);
}
export type GetClassAssignmentsWithPaginationQueryHookResult = ReturnType<
  typeof useGetClassAssignmentsWithPaginationQuery
>;
export type GetClassAssignmentsWithPaginationLazyQueryHookResult = ReturnType<
  typeof useGetClassAssignmentsWithPaginationLazyQuery
>;
export type GetClassAssignmentsWithPaginationQueryResult = Apollo.QueryResult<
  GetClassAssignmentsWithPaginationQuery,
  GetClassAssignmentsWithPaginationQueryVariables
>;
export const GetFeedbacksDocument = gql`
  query getFeedbacks($sessionId: String!) {
    teacherFeedback(sessionId: $sessionId) {
      responseFeedbacks {
        feedback
        responseId
      }
    }
  }
`;

/**
 * __useGetFeedbacksQuery__
 *
 * To run a query within a React component, call `useGetFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbacksQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetFeedbacksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeedbacksQuery,
    GetFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeedbacksQuery, GetFeedbacksQueryVariables>(
    GetFeedbacksDocument,
    options
  );
}
export function useGetFeedbacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedbacksQuery,
    GetFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeedbacksQuery, GetFeedbacksQueryVariables>(
    GetFeedbacksDocument,
    options
  );
}
export type GetFeedbacksQueryHookResult = ReturnType<
  typeof useGetFeedbacksQuery
>;
export type GetFeedbacksLazyQueryHookResult = ReturnType<
  typeof useGetFeedbacksLazyQuery
>;
export type GetFeedbacksQueryResult = Apollo.QueryResult<
  GetFeedbacksQuery,
  GetFeedbacksQueryVariables
>;
export const GetFeedbacksAndScoresDocument = gql`
  query getFeedbacksAndScores(
    $sessionId: String!
    $filter: StudentAssignmentsFilter
    $schoolId: String
  ) {
    teacherFeedback(sessionId: $sessionId) {
      responseFeedbacks {
        feedback
        responseId
      }
    }
    assignments: teacherStudentAssignments(
      filter: $filter
      schoolId: $schoolId
    ) {
      collection {
        studentId
        assignmentId
        id
        answers
      }
    }
  }
`;

/**
 * __useGetFeedbacksAndScoresQuery__
 *
 * To run a query within a React component, call `useGetFeedbacksAndScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbacksAndScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbacksAndScoresQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      filter: // value for 'filter'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetFeedbacksAndScoresQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeedbacksAndScoresQuery,
    GetFeedbacksAndScoresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeedbacksAndScoresQuery,
    GetFeedbacksAndScoresQueryVariables
  >(GetFeedbacksAndScoresDocument, options);
}
export function useGetFeedbacksAndScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedbacksAndScoresQuery,
    GetFeedbacksAndScoresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeedbacksAndScoresQuery,
    GetFeedbacksAndScoresQueryVariables
  >(GetFeedbacksAndScoresDocument, options);
}
export type GetFeedbacksAndScoresQueryHookResult = ReturnType<
  typeof useGetFeedbacksAndScoresQuery
>;
export type GetFeedbacksAndScoresLazyQueryHookResult = ReturnType<
  typeof useGetFeedbacksAndScoresLazyQuery
>;
export type GetFeedbacksAndScoresQueryResult = Apollo.QueryResult<
  GetFeedbacksAndScoresQuery,
  GetFeedbacksAndScoresQueryVariables
>;
export const GetFeedbacksAndScoresForDaDocument = gql`
  query getFeedbacksAndScoresForDA(
    $sessionId: String!
    $schoolId: String!
    $studentId: String
  ) {
    teacherFeedback(sessionId: $sessionId) {
      responseFeedbacks {
        feedback
        responseId
      }
    }
    assignments: districtStudentAssignments(
      studentId: $studentId
      schoolId: $schoolId
    ) {
      collection {
        studentId
        assignmentId
        id
        answers
      }
    }
  }
`;

/**
 * __useGetFeedbacksAndScoresForDaQuery__
 *
 * To run a query within a React component, call `useGetFeedbacksAndScoresForDaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbacksAndScoresForDaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbacksAndScoresForDaQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      schoolId: // value for 'schoolId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetFeedbacksAndScoresForDaQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeedbacksAndScoresForDaQuery,
    GetFeedbacksAndScoresForDaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeedbacksAndScoresForDaQuery,
    GetFeedbacksAndScoresForDaQueryVariables
  >(GetFeedbacksAndScoresForDaDocument, options);
}
export function useGetFeedbacksAndScoresForDaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedbacksAndScoresForDaQuery,
    GetFeedbacksAndScoresForDaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeedbacksAndScoresForDaQuery,
    GetFeedbacksAndScoresForDaQueryVariables
  >(GetFeedbacksAndScoresForDaDocument, options);
}
export type GetFeedbacksAndScoresForDaQueryHookResult = ReturnType<
  typeof useGetFeedbacksAndScoresForDaQuery
>;
export type GetFeedbacksAndScoresForDaLazyQueryHookResult = ReturnType<
  typeof useGetFeedbacksAndScoresForDaLazyQuery
>;
export type GetFeedbacksAndScoresForDaQueryResult = Apollo.QueryResult<
  GetFeedbacksAndScoresForDaQuery,
  GetFeedbacksAndScoresForDaQueryVariables
>;
export const SignRequestForAssessmentQuestionsDocument = gql`
  mutation signRequestForAssessmentQuestions($params: SignRequestInput!) {
    signRequest(params: $params) {
      ... on ItemSignedRequest {
        signedRequest
      }
    }
  }
`;
export type SignRequestForAssessmentQuestionsMutationFn =
  Apollo.MutationFunction<
    SignRequestForAssessmentQuestionsMutation,
    SignRequestForAssessmentQuestionsMutationVariables
  >;

/**
 * __useSignRequestForAssessmentQuestionsMutation__
 *
 * To run a mutation, you first call `useSignRequestForAssessmentQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignRequestForAssessmentQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signRequestForAssessmentQuestionsMutation, { data, loading, error }] = useSignRequestForAssessmentQuestionsMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignRequestForAssessmentQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignRequestForAssessmentQuestionsMutation,
    SignRequestForAssessmentQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignRequestForAssessmentQuestionsMutation,
    SignRequestForAssessmentQuestionsMutationVariables
  >(SignRequestForAssessmentQuestionsDocument, options);
}
export type SignRequestForAssessmentQuestionsMutationHookResult = ReturnType<
  typeof useSignRequestForAssessmentQuestionsMutation
>;
export type SignRequestForAssessmentQuestionsMutationResult =
  Apollo.MutationResult<SignRequestForAssessmentQuestionsMutation>;
export type SignRequestForAssessmentQuestionsMutationOptions =
  Apollo.BaseMutationOptions<
    SignRequestForAssessmentQuestionsMutation,
    SignRequestForAssessmentQuestionsMutationVariables
  >;
export const CreateSignRequestForAssessmentQuestionsDocument = gql`
  mutation createSignRequestForAssessmentQuestions(
    $input: CreateSignRequestInput
  ) {
    createSignRequest(input: $input) {
      signedRequest
    }
  }
`;
export type CreateSignRequestForAssessmentQuestionsMutationFn =
  Apollo.MutationFunction<
    CreateSignRequestForAssessmentQuestionsMutation,
    CreateSignRequestForAssessmentQuestionsMutationVariables
  >;

/**
 * __useCreateSignRequestForAssessmentQuestionsMutation__
 *
 * To run a mutation, you first call `useCreateSignRequestForAssessmentQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignRequestForAssessmentQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignRequestForAssessmentQuestionsMutation, { data, loading, error }] = useCreateSignRequestForAssessmentQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSignRequestForAssessmentQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSignRequestForAssessmentQuestionsMutation,
    CreateSignRequestForAssessmentQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSignRequestForAssessmentQuestionsMutation,
    CreateSignRequestForAssessmentQuestionsMutationVariables
  >(CreateSignRequestForAssessmentQuestionsDocument, options);
}
export type CreateSignRequestForAssessmentQuestionsMutationHookResult =
  ReturnType<typeof useCreateSignRequestForAssessmentQuestionsMutation>;
export type CreateSignRequestForAssessmentQuestionsMutationResult =
  Apollo.MutationResult<CreateSignRequestForAssessmentQuestionsMutation>;
export type CreateSignRequestForAssessmentQuestionsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSignRequestForAssessmentQuestionsMutation,
    CreateSignRequestForAssessmentQuestionsMutationVariables
  >;
export const GetTeacherStudentAssignmentsDocument = gql`
  query getTeacherStudentAssignments(
    $filter: StudentAssignmentsFilter
    $schoolId: String
    $userId: ID!
    $teachersId: String
  ) {
    teacherStudentAssignments(filter: $filter, schoolId: $schoolId) {
      collection {
        id
        answers
        assignmentId
        studentId
        name
        completedAt
        grade
        startedAt
        status
        submittedAt
        endDate
        startDate
        lateTurnIn
        shareResults
        returnedAt
        resetted
        studentNotes
        archived
        classId
      }
    }
    user(id: $userId) {
      _id
      firstName
      lastName
    }
    classes(schoolId: $schoolId, teachersId: $teachersId, status: Active) {
      collection {
        _id
      }
    }
  }
`;

/**
 * __useGetTeacherStudentAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetTeacherStudentAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherStudentAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherStudentAssignmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      schoolId: // value for 'schoolId'
 *      userId: // value for 'userId'
 *      teachersId: // value for 'teachersId'
 *   },
 * });
 */
export function useGetTeacherStudentAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeacherStudentAssignmentsQuery,
    GetTeacherStudentAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeacherStudentAssignmentsQuery,
    GetTeacherStudentAssignmentsQueryVariables
  >(GetTeacherStudentAssignmentsDocument, options);
}
export function useGetTeacherStudentAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeacherStudentAssignmentsQuery,
    GetTeacherStudentAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeacherStudentAssignmentsQuery,
    GetTeacherStudentAssignmentsQueryVariables
  >(GetTeacherStudentAssignmentsDocument, options);
}
export type GetTeacherStudentAssignmentsQueryHookResult = ReturnType<
  typeof useGetTeacherStudentAssignmentsQuery
>;
export type GetTeacherStudentAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetTeacherStudentAssignmentsLazyQuery
>;
export type GetTeacherStudentAssignmentsQueryResult = Apollo.QueryResult<
  GetTeacherStudentAssignmentsQuery,
  GetTeacherStudentAssignmentsQueryVariables
>;
export const ResetAssignmentDocument = gql`
  mutation resetAssignment($sessionsParams: ResetAssignmentInput!) {
    resetAssignment(sessionsParams: $sessionsParams) {
      success
    }
  }
`;
export type ResetAssignmentMutationFn = Apollo.MutationFunction<
  ResetAssignmentMutation,
  ResetAssignmentMutationVariables
>;

/**
 * __useResetAssignmentMutation__
 *
 * To run a mutation, you first call `useResetAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAssignmentMutation, { data, loading, error }] = useResetAssignmentMutation({
 *   variables: {
 *      sessionsParams: // value for 'sessionsParams'
 *   },
 * });
 */
export function useResetAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetAssignmentMutation,
    ResetAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetAssignmentMutation,
    ResetAssignmentMutationVariables
  >(ResetAssignmentDocument, options);
}
export type ResetAssignmentMutationHookResult = ReturnType<
  typeof useResetAssignmentMutation
>;
export type ResetAssignmentMutationResult =
  Apollo.MutationResult<ResetAssignmentMutation>;
export type ResetAssignmentMutationOptions = Apollo.BaseMutationOptions<
  ResetAssignmentMutation,
  ResetAssignmentMutationVariables
>;
export const ReopenAssignmentDocument = gql`
  mutation reopenAssignment($sessionsParams: ReopenAssignmentInput!) {
    reopenAssignment(sessionsParams: $sessionsParams) {
      success
    }
  }
`;
export type ReopenAssignmentMutationFn = Apollo.MutationFunction<
  ReopenAssignmentMutation,
  ReopenAssignmentMutationVariables
>;

/**
 * __useReopenAssignmentMutation__
 *
 * To run a mutation, you first call `useReopenAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenAssignmentMutation, { data, loading, error }] = useReopenAssignmentMutation({
 *   variables: {
 *      sessionsParams: // value for 'sessionsParams'
 *   },
 * });
 */
export function useReopenAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReopenAssignmentMutation,
    ReopenAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReopenAssignmentMutation,
    ReopenAssignmentMutationVariables
  >(ReopenAssignmentDocument, options);
}
export type ReopenAssignmentMutationHookResult = ReturnType<
  typeof useReopenAssignmentMutation
>;
export type ReopenAssignmentMutationResult =
  Apollo.MutationResult<ReopenAssignmentMutation>;
export type ReopenAssignmentMutationOptions = Apollo.BaseMutationOptions<
  ReopenAssignmentMutation,
  ReopenAssignmentMutationVariables
>;
export const ReturnAssignmentDocument = gql`
  mutation ReturnAssignment($sessionsParams: ReturnAssignmentInput!) {
    returnAssignment(sessionsParams: $sessionsParams) {
      success
    }
  }
`;
export type ReturnAssignmentMutationFn = Apollo.MutationFunction<
  ReturnAssignmentMutation,
  ReturnAssignmentMutationVariables
>;

/**
 * __useReturnAssignmentMutation__
 *
 * To run a mutation, you first call `useReturnAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnAssignmentMutation, { data, loading, error }] = useReturnAssignmentMutation({
 *   variables: {
 *      sessionsParams: // value for 'sessionsParams'
 *   },
 * });
 */
export function useReturnAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReturnAssignmentMutation,
    ReturnAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReturnAssignmentMutation,
    ReturnAssignmentMutationVariables
  >(ReturnAssignmentDocument, options);
}
export type ReturnAssignmentMutationHookResult = ReturnType<
  typeof useReturnAssignmentMutation
>;
export type ReturnAssignmentMutationResult =
  Apollo.MutationResult<ReturnAssignmentMutation>;
export type ReturnAssignmentMutationOptions = Apollo.BaseMutationOptions<
  ReturnAssignmentMutation,
  ReturnAssignmentMutationVariables
>;
export const ShareAssignmentResultsDocument = gql`
  mutation ShareAssignmentResults($params: PutActivitiesShareResultsInput!) {
    putActivitiesShareResults(params: $params) {
      success
    }
  }
`;
export type ShareAssignmentResultsMutationFn = Apollo.MutationFunction<
  ShareAssignmentResultsMutation,
  ShareAssignmentResultsMutationVariables
>;

/**
 * __useShareAssignmentResultsMutation__
 *
 * To run a mutation, you first call `useShareAssignmentResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareAssignmentResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareAssignmentResultsMutation, { data, loading, error }] = useShareAssignmentResultsMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useShareAssignmentResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareAssignmentResultsMutation,
    ShareAssignmentResultsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareAssignmentResultsMutation,
    ShareAssignmentResultsMutationVariables
  >(ShareAssignmentResultsDocument, options);
}
export type ShareAssignmentResultsMutationHookResult = ReturnType<
  typeof useShareAssignmentResultsMutation
>;
export type ShareAssignmentResultsMutationResult =
  Apollo.MutationResult<ShareAssignmentResultsMutation>;
export type ShareAssignmentResultsMutationOptions = Apollo.BaseMutationOptions<
  ShareAssignmentResultsMutation,
  ShareAssignmentResultsMutationVariables
>;
export const ShareDistrictAssignmentResultsDocument = gql`
  mutation ShareDistrictAssignmentResults(
    $params: DistrictAssignmentsShareResultsInput!
  ) {
    putDistrictAssignmentsShareResults(params: $params) {
      collection {
        shareResults
      }
    }
  }
`;
export type ShareDistrictAssignmentResultsMutationFn = Apollo.MutationFunction<
  ShareDistrictAssignmentResultsMutation,
  ShareDistrictAssignmentResultsMutationVariables
>;

/**
 * __useShareDistrictAssignmentResultsMutation__
 *
 * To run a mutation, you first call `useShareDistrictAssignmentResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareDistrictAssignmentResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareDistrictAssignmentResultsMutation, { data, loading, error }] = useShareDistrictAssignmentResultsMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useShareDistrictAssignmentResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareDistrictAssignmentResultsMutation,
    ShareDistrictAssignmentResultsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareDistrictAssignmentResultsMutation,
    ShareDistrictAssignmentResultsMutationVariables
  >(ShareDistrictAssignmentResultsDocument, options);
}
export type ShareDistrictAssignmentResultsMutationHookResult = ReturnType<
  typeof useShareDistrictAssignmentResultsMutation
>;
export type ShareDistrictAssignmentResultsMutationResult =
  Apollo.MutationResult<ShareDistrictAssignmentResultsMutation>;
export type ShareDistrictAssignmentResultsMutationOptions =
  Apollo.BaseMutationOptions<
    ShareDistrictAssignmentResultsMutation,
    ShareDistrictAssignmentResultsMutationVariables
  >;
export const CreateStemscopesAssessmentDocument = gql`
  mutation createStemscopesAssessment(
    $input: StemscopesCreateAssessmentInput!
  ) {
    createAssessment(input: { stemscopesInput: $input }) {
      stemscopesPayload {
        item {
          id
          title
          items
          isArchived
          isShared
          usersViewOnlyIds
          library
          deliveries
        }
      }
    }
  }
`;
export type CreateStemscopesAssessmentMutationFn = Apollo.MutationFunction<
  CreateStemscopesAssessmentMutation,
  CreateStemscopesAssessmentMutationVariables
>;

/**
 * __useCreateStemscopesAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateStemscopesAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStemscopesAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStemscopesAssessmentMutation, { data, loading, error }] = useCreateStemscopesAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStemscopesAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStemscopesAssessmentMutation,
    CreateStemscopesAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStemscopesAssessmentMutation,
    CreateStemscopesAssessmentMutationVariables
  >(CreateStemscopesAssessmentDocument, options);
}
export type CreateStemscopesAssessmentMutationHookResult = ReturnType<
  typeof useCreateStemscopesAssessmentMutation
>;
export type CreateStemscopesAssessmentMutationResult =
  Apollo.MutationResult<CreateStemscopesAssessmentMutation>;
export type CreateStemscopesAssessmentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateStemscopesAssessmentMutation,
    CreateStemscopesAssessmentMutationVariables
  >;
export const SaveGradingSessionDocument = gql`
  mutation saveGradingSession($sessionsParams: [SaveGradingSessionInput!]!) {
    saveGradingSession(sessionsParams: $sessionsParams) {
      success
    }
  }
`;
export type SaveGradingSessionMutationFn = Apollo.MutationFunction<
  SaveGradingSessionMutation,
  SaveGradingSessionMutationVariables
>;

/**
 * __useSaveGradingSessionMutation__
 *
 * To run a mutation, you first call `useSaveGradingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGradingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGradingSessionMutation, { data, loading, error }] = useSaveGradingSessionMutation({
 *   variables: {
 *      sessionsParams: // value for 'sessionsParams'
 *   },
 * });
 */
export function useSaveGradingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveGradingSessionMutation,
    SaveGradingSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveGradingSessionMutation,
    SaveGradingSessionMutationVariables
  >(SaveGradingSessionDocument, options);
}
export type SaveGradingSessionMutationHookResult = ReturnType<
  typeof useSaveGradingSessionMutation
>;
export type SaveGradingSessionMutationResult =
  Apollo.MutationResult<SaveGradingSessionMutation>;
export type SaveGradingSessionMutationOptions = Apollo.BaseMutationOptions<
  SaveGradingSessionMutation,
  SaveGradingSessionMutationVariables
>;
export const SignRequestForGradingDocument = gql`
  mutation signRequestForGrading($params: SignRequestInput!) {
    signRequest(params: $params) {
      ... on ReportSignedRequest {
        signedRequest
      }
      ... on ItemSignedRequest {
        signedRequest
      }
    }
  }
`;
export type SignRequestForGradingMutationFn = Apollo.MutationFunction<
  SignRequestForGradingMutation,
  SignRequestForGradingMutationVariables
>;

/**
 * __useSignRequestForGradingMutation__
 *
 * To run a mutation, you first call `useSignRequestForGradingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignRequestForGradingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signRequestForGradingMutation, { data, loading, error }] = useSignRequestForGradingMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignRequestForGradingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignRequestForGradingMutation,
    SignRequestForGradingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignRequestForGradingMutation,
    SignRequestForGradingMutationVariables
  >(SignRequestForGradingDocument, options);
}
export type SignRequestForGradingMutationHookResult = ReturnType<
  typeof useSignRequestForGradingMutation
>;
export type SignRequestForGradingMutationResult =
  Apollo.MutationResult<SignRequestForGradingMutation>;
export type SignRequestForGradingMutationOptions = Apollo.BaseMutationOptions<
  SignRequestForGradingMutation,
  SignRequestForGradingMutationVariables
>;
export const ResetAssignmentStatusDocument = gql`
  mutation resetAssignmentStatus($activityParams: ActivityParamsInput!) {
    startStemscopesAssignment(activityParams: $activityParams) {
      success
    }
  }
`;
export type ResetAssignmentStatusMutationFn = Apollo.MutationFunction<
  ResetAssignmentStatusMutation,
  ResetAssignmentStatusMutationVariables
>;

/**
 * __useResetAssignmentStatusMutation__
 *
 * To run a mutation, you first call `useResetAssignmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAssignmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAssignmentStatusMutation, { data, loading, error }] = useResetAssignmentStatusMutation({
 *   variables: {
 *      activityParams: // value for 'activityParams'
 *   },
 * });
 */
export function useResetAssignmentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetAssignmentStatusMutation,
    ResetAssignmentStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetAssignmentStatusMutation,
    ResetAssignmentStatusMutationVariables
  >(ResetAssignmentStatusDocument, options);
}
export type ResetAssignmentStatusMutationHookResult = ReturnType<
  typeof useResetAssignmentStatusMutation
>;
export type ResetAssignmentStatusMutationResult =
  Apollo.MutationResult<ResetAssignmentStatusMutation>;
export type ResetAssignmentStatusMutationOptions = Apollo.BaseMutationOptions<
  ResetAssignmentStatusMutation,
  ResetAssignmentStatusMutationVariables
>;
export const SignRequestForStudentAssignmentDocument = gql`
  mutation signRequestForStudentAssignment($params: SignRequestInput!) {
    signRequest(params: $params) {
      ... on ReportSignedRequest {
        signedRequest
      }
      ... on ItemSignedRequest {
        signedRequest
      }
    }
  }
`;
export type SignRequestForStudentAssignmentMutationFn = Apollo.MutationFunction<
  SignRequestForStudentAssignmentMutation,
  SignRequestForStudentAssignmentMutationVariables
>;

/**
 * __useSignRequestForStudentAssignmentMutation__
 *
 * To run a mutation, you first call `useSignRequestForStudentAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignRequestForStudentAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signRequestForStudentAssignmentMutation, { data, loading, error }] = useSignRequestForStudentAssignmentMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignRequestForStudentAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignRequestForStudentAssignmentMutation,
    SignRequestForStudentAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignRequestForStudentAssignmentMutation,
    SignRequestForStudentAssignmentMutationVariables
  >(SignRequestForStudentAssignmentDocument, options);
}
export type SignRequestForStudentAssignmentMutationHookResult = ReturnType<
  typeof useSignRequestForStudentAssignmentMutation
>;
export type SignRequestForStudentAssignmentMutationResult =
  Apollo.MutationResult<SignRequestForStudentAssignmentMutation>;
export type SignRequestForStudentAssignmentMutationOptions =
  Apollo.BaseMutationOptions<
    SignRequestForStudentAssignmentMutation,
    SignRequestForStudentAssignmentMutationVariables
  >;
export const SignRequestForDistrictAssignmentDocument = gql`
  mutation signRequestForDistrictAssignment(
    $params: DistrictAssignmentSignRequestInput!
  ) {
    daSignRequest(params: $params) {
      sign
    }
  }
`;
export type SignRequestForDistrictAssignmentMutationFn =
  Apollo.MutationFunction<
    SignRequestForDistrictAssignmentMutation,
    SignRequestForDistrictAssignmentMutationVariables
  >;

/**
 * __useSignRequestForDistrictAssignmentMutation__
 *
 * To run a mutation, you first call `useSignRequestForDistrictAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignRequestForDistrictAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signRequestForDistrictAssignmentMutation, { data, loading, error }] = useSignRequestForDistrictAssignmentMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignRequestForDistrictAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignRequestForDistrictAssignmentMutation,
    SignRequestForDistrictAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignRequestForDistrictAssignmentMutation,
    SignRequestForDistrictAssignmentMutationVariables
  >(SignRequestForDistrictAssignmentDocument, options);
}
export type SignRequestForDistrictAssignmentMutationHookResult = ReturnType<
  typeof useSignRequestForDistrictAssignmentMutation
>;
export type SignRequestForDistrictAssignmentMutationResult =
  Apollo.MutationResult<SignRequestForDistrictAssignmentMutation>;
export type SignRequestForDistrictAssignmentMutationOptions =
  Apollo.BaseMutationOptions<
    SignRequestForDistrictAssignmentMutation,
    SignRequestForDistrictAssignmentMutationVariables
  >;
export const StartDistrictAssignmentDocument = gql`
  mutation startDistrictAssignment(
    $params: MakeAvailableDistrictAssignmentInput!
  ) {
    makeAvailableDistrictAssignment(params: $params) {
      daId
    }
  }
`;
export type StartDistrictAssignmentMutationFn = Apollo.MutationFunction<
  StartDistrictAssignmentMutation,
  StartDistrictAssignmentMutationVariables
>;

/**
 * __useStartDistrictAssignmentMutation__
 *
 * To run a mutation, you first call `useStartDistrictAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDistrictAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDistrictAssignmentMutation, { data, loading, error }] = useStartDistrictAssignmentMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useStartDistrictAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartDistrictAssignmentMutation,
    StartDistrictAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartDistrictAssignmentMutation,
    StartDistrictAssignmentMutationVariables
  >(StartDistrictAssignmentDocument, options);
}
export type StartDistrictAssignmentMutationHookResult = ReturnType<
  typeof useStartDistrictAssignmentMutation
>;
export type StartDistrictAssignmentMutationResult =
  Apollo.MutationResult<StartDistrictAssignmentMutation>;
export type StartDistrictAssignmentMutationOptions = Apollo.BaseMutationOptions<
  StartDistrictAssignmentMutation,
  StartDistrictAssignmentMutationVariables
>;
export const GetCurriculumAreasDocument = gql`
  query getCurriculumAreas($schoolId: String, $schoolIds: [String!]! = []) {
    curriculumAreas(schoolId: $schoolId, schoolIds: $schoolIds) {
      curriculumAreas
    }
  }
`;

/**
 * __useGetCurriculumAreasQuery__
 *
 * To run a query within a React component, call `useGetCurriculumAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurriculumAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurriculumAreasQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetCurriculumAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurriculumAreasQuery,
    GetCurriculumAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurriculumAreasQuery,
    GetCurriculumAreasQueryVariables
  >(GetCurriculumAreasDocument, options);
}
export function useGetCurriculumAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurriculumAreasQuery,
    GetCurriculumAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurriculumAreasQuery,
    GetCurriculumAreasQueryVariables
  >(GetCurriculumAreasDocument, options);
}
export type GetCurriculumAreasQueryHookResult = ReturnType<
  typeof useGetCurriculumAreasQuery
>;
export type GetCurriculumAreasLazyQueryHookResult = ReturnType<
  typeof useGetCurriculumAreasLazyQuery
>;
export type GetCurriculumAreasQueryResult = Apollo.QueryResult<
  GetCurriculumAreasQuery,
  GetCurriculumAreasQueryVariables
>;
export const GetActiveContentGroupsDocument = gql`
  query getActiveContentGroups {
    activeContentGroups {
      id
    }
  }
`;

/**
 * __useGetActiveContentGroupsQuery__
 *
 * To run a query within a React component, call `useGetActiveContentGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveContentGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveContentGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveContentGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveContentGroupsQuery,
    GetActiveContentGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveContentGroupsQuery,
    GetActiveContentGroupsQueryVariables
  >(GetActiveContentGroupsDocument, options);
}
export function useGetActiveContentGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveContentGroupsQuery,
    GetActiveContentGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveContentGroupsQuery,
    GetActiveContentGroupsQueryVariables
  >(GetActiveContentGroupsDocument, options);
}
export type GetActiveContentGroupsQueryHookResult = ReturnType<
  typeof useGetActiveContentGroupsQuery
>;
export type GetActiveContentGroupsLazyQueryHookResult = ReturnType<
  typeof useGetActiveContentGroupsLazyQuery
>;
export type GetActiveContentGroupsQueryResult = Apollo.QueryResult<
  GetActiveContentGroupsQuery,
  GetActiveContentGroupsQueryVariables
>;
export const GetActiveContentGroupsWithSchoolsDocument = gql`
  query getActiveContentGroupsWithSchools {
    activeContentGroupsWithSchools {
      contentGroups
      schoolId
    }
  }
`;

/**
 * __useGetActiveContentGroupsWithSchoolsQuery__
 *
 * To run a query within a React component, call `useGetActiveContentGroupsWithSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveContentGroupsWithSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveContentGroupsWithSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveContentGroupsWithSchoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveContentGroupsWithSchoolsQuery,
    GetActiveContentGroupsWithSchoolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveContentGroupsWithSchoolsQuery,
    GetActiveContentGroupsWithSchoolsQueryVariables
  >(GetActiveContentGroupsWithSchoolsDocument, options);
}
export function useGetActiveContentGroupsWithSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveContentGroupsWithSchoolsQuery,
    GetActiveContentGroupsWithSchoolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveContentGroupsWithSchoolsQuery,
    GetActiveContentGroupsWithSchoolsQueryVariables
  >(GetActiveContentGroupsWithSchoolsDocument, options);
}
export type GetActiveContentGroupsWithSchoolsQueryHookResult = ReturnType<
  typeof useGetActiveContentGroupsWithSchoolsQuery
>;
export type GetActiveContentGroupsWithSchoolsLazyQueryHookResult = ReturnType<
  typeof useGetActiveContentGroupsWithSchoolsLazyQuery
>;
export type GetActiveContentGroupsWithSchoolsQueryResult = Apollo.QueryResult<
  GetActiveContentGroupsWithSchoolsQuery,
  GetActiveContentGroupsWithSchoolsQueryVariables
>;
export const AddStudentToClassesDocument = gql`
  mutation addStudentToClasses($input: addStudentToClassesInput!) {
    addStudentToClasses(input: $input) {
      classes {
        id
      }
    }
  }
`;
export type AddStudentToClassesMutationFn = Apollo.MutationFunction<
  AddStudentToClassesMutation,
  AddStudentToClassesMutationVariables
>;

/**
 * __useAddStudentToClassesMutation__
 *
 * To run a mutation, you first call `useAddStudentToClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentToClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentToClassesMutation, { data, loading, error }] = useAddStudentToClassesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStudentToClassesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStudentToClassesMutation,
    AddStudentToClassesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddStudentToClassesMutation,
    AddStudentToClassesMutationVariables
  >(AddStudentToClassesDocument, options);
}
export type AddStudentToClassesMutationHookResult = ReturnType<
  typeof useAddStudentToClassesMutation
>;
export type AddStudentToClassesMutationResult =
  Apollo.MutationResult<AddStudentToClassesMutation>;
export type AddStudentToClassesMutationOptions = Apollo.BaseMutationOptions<
  AddStudentToClassesMutation,
  AddStudentToClassesMutationVariables
>;
export const AddStudentsToClassDocument = gql`
  mutation addStudentsToClass($input: addStudentsToClassInput!) {
    addStudentsToClass(input: $input) {
      class {
        id
      }
    }
  }
`;
export type AddStudentsToClassMutationFn = Apollo.MutationFunction<
  AddStudentsToClassMutation,
  AddStudentsToClassMutationVariables
>;

/**
 * __useAddStudentsToClassMutation__
 *
 * To run a mutation, you first call `useAddStudentsToClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentsToClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentsToClassMutation, { data, loading, error }] = useAddStudentsToClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStudentsToClassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStudentsToClassMutation,
    AddStudentsToClassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddStudentsToClassMutation,
    AddStudentsToClassMutationVariables
  >(AddStudentsToClassDocument, options);
}
export type AddStudentsToClassMutationHookResult = ReturnType<
  typeof useAddStudentsToClassMutation
>;
export type AddStudentsToClassMutationResult =
  Apollo.MutationResult<AddStudentsToClassMutation>;
export type AddStudentsToClassMutationOptions = Apollo.BaseMutationOptions<
  AddStudentsToClassMutation,
  AddStudentsToClassMutationVariables
>;
export const CreateClassDocument = gql`
  mutation createClass($input: createClassInput!) {
    createClass(input: $input) {
      class {
        _id
      }
    }
  }
`;
export type CreateClassMutationFn = Apollo.MutationFunction<
  CreateClassMutation,
  CreateClassMutationVariables
>;

/**
 * __useCreateClassMutation__
 *
 * To run a mutation, you first call `useCreateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassMutation, { data, loading, error }] = useCreateClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClassMutation,
    CreateClassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateClassMutation, CreateClassMutationVariables>(
    CreateClassDocument,
    options
  );
}
export type CreateClassMutationHookResult = ReturnType<
  typeof useCreateClassMutation
>;
export type CreateClassMutationResult =
  Apollo.MutationResult<CreateClassMutation>;
export type CreateClassMutationOptions = Apollo.BaseMutationOptions<
  CreateClassMutation,
  CreateClassMutationVariables
>;
export const RemoveStudentFromClassesDocument = gql`
  mutation removeStudentFromClasses($input: removeStudentFromClassesInput!) {
    removeStudentFromClasses(input: $input) {
      classes {
        id
      }
    }
  }
`;
export type RemoveStudentFromClassesMutationFn = Apollo.MutationFunction<
  RemoveStudentFromClassesMutation,
  RemoveStudentFromClassesMutationVariables
>;

/**
 * __useRemoveStudentFromClassesMutation__
 *
 * To run a mutation, you first call `useRemoveStudentFromClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentFromClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentFromClassesMutation, { data, loading, error }] = useRemoveStudentFromClassesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStudentFromClassesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveStudentFromClassesMutation,
    RemoveStudentFromClassesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveStudentFromClassesMutation,
    RemoveStudentFromClassesMutationVariables
  >(RemoveStudentFromClassesDocument, options);
}
export type RemoveStudentFromClassesMutationHookResult = ReturnType<
  typeof useRemoveStudentFromClassesMutation
>;
export type RemoveStudentFromClassesMutationResult =
  Apollo.MutationResult<RemoveStudentFromClassesMutation>;
export type RemoveStudentFromClassesMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveStudentFromClassesMutation,
    RemoveStudentFromClassesMutationVariables
  >;
export const RemoveStudentsFromClassDocument = gql`
  mutation removeStudentsFromClass($input: removeStudentsFromClassInput!) {
    removeStudentsFromClass(input: $input) {
      class {
        id
      }
    }
  }
`;
export type RemoveStudentsFromClassMutationFn = Apollo.MutationFunction<
  RemoveStudentsFromClassMutation,
  RemoveStudentsFromClassMutationVariables
>;

/**
 * __useRemoveStudentsFromClassMutation__
 *
 * To run a mutation, you first call `useRemoveStudentsFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentsFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentsFromClassMutation, { data, loading, error }] = useRemoveStudentsFromClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStudentsFromClassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveStudentsFromClassMutation,
    RemoveStudentsFromClassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveStudentsFromClassMutation,
    RemoveStudentsFromClassMutationVariables
  >(RemoveStudentsFromClassDocument, options);
}
export type RemoveStudentsFromClassMutationHookResult = ReturnType<
  typeof useRemoveStudentsFromClassMutation
>;
export type RemoveStudentsFromClassMutationResult =
  Apollo.MutationResult<RemoveStudentsFromClassMutation>;
export type RemoveStudentsFromClassMutationOptions = Apollo.BaseMutationOptions<
  RemoveStudentsFromClassMutation,
  RemoveStudentsFromClassMutationVariables
>;
export const GetDashboardEmbedUrlDocument = gql`
  query getDashboardEmbedUrl(
    $dashboardID: String!
    $sheetID: String
    $visualID: String
  ) {
    dashboardEmbedUrl(
      dashboardID: $dashboardID
      sheetID: $sheetID
      visualID: $visualID
    ) {
      EmbedUrl
    }
  }
`;

/**
 * __useGetDashboardEmbedUrlQuery__
 *
 * To run a query within a React component, call `useGetDashboardEmbedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardEmbedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardEmbedUrlQuery({
 *   variables: {
 *      dashboardID: // value for 'dashboardID'
 *      sheetID: // value for 'sheetID'
 *      visualID: // value for 'visualID'
 *   },
 * });
 */
export function useGetDashboardEmbedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardEmbedUrlQuery,
    GetDashboardEmbedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardEmbedUrlQuery,
    GetDashboardEmbedUrlQueryVariables
  >(GetDashboardEmbedUrlDocument, options);
}
export function useGetDashboardEmbedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardEmbedUrlQuery,
    GetDashboardEmbedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardEmbedUrlQuery,
    GetDashboardEmbedUrlQueryVariables
  >(GetDashboardEmbedUrlDocument, options);
}
export type GetDashboardEmbedUrlQueryHookResult = ReturnType<
  typeof useGetDashboardEmbedUrlQuery
>;
export type GetDashboardEmbedUrlLazyQueryHookResult = ReturnType<
  typeof useGetDashboardEmbedUrlLazyQuery
>;
export type GetDashboardEmbedUrlQueryResult = Apollo.QueryResult<
  GetDashboardEmbedUrlQuery,
  GetDashboardEmbedUrlQueryVariables
>;
export const GetClassesForSelectInputDocument = gql`
  query getClassesForSelectInput(
    $teachersId: String
    $schoolId: String
    $archived: Boolean
    $itemsPerPage: Int = 9999
  ) {
    classes(
      teachersId: $teachersId
      schoolId: $schoolId
      archived: $archived
      itemsPerPage: $itemsPerPage
      status: Active
      hasLmsId: false
    ) {
      collection {
        _id
        id
        name
        schoolId
        classTeachers {
          collection {
            teacherId
            mainTeacher
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassesForSelectInputQuery__
 *
 * To run a query within a React component, call `useGetClassesForSelectInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesForSelectInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesForSelectInputQuery({
 *   variables: {
 *      teachersId: // value for 'teachersId'
 *      schoolId: // value for 'schoolId'
 *      archived: // value for 'archived'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetClassesForSelectInputQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassesForSelectInputQuery,
    GetClassesForSelectInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassesForSelectInputQuery,
    GetClassesForSelectInputQueryVariables
  >(GetClassesForSelectInputDocument, options);
}
export function useGetClassesForSelectInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassesForSelectInputQuery,
    GetClassesForSelectInputQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassesForSelectInputQuery,
    GetClassesForSelectInputQueryVariables
  >(GetClassesForSelectInputDocument, options);
}
export type GetClassesForSelectInputQueryHookResult = ReturnType<
  typeof useGetClassesForSelectInputQuery
>;
export type GetClassesForSelectInputLazyQueryHookResult = ReturnType<
  typeof useGetClassesForSelectInputLazyQuery
>;
export type GetClassesForSelectInputQueryResult = Apollo.QueryResult<
  GetClassesForSelectInputQuery,
  GetClassesForSelectInputQueryVariables
>;
export const GetClassesNamesDocument = gql`
  query getClassesNames(
    $schoolId: String
    $itemsPerPage: Int = 9999
    $archived: Boolean = false
    $studentsId: String
  ) {
    classes(
      schoolId: $schoolId
      itemsPerPage: $itemsPerPage
      archived: $archived
      status: Active
      studentsId: $studentsId
    ) {
      collection {
        _id
        id
        name
        schoolId
        curriculumArea
        gradeOrCourse
      }
    }
  }
`;

/**
 * __useGetClassesNamesQuery__
 *
 * To run a query within a React component, call `useGetClassesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesNamesQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      archived: // value for 'archived'
 *      studentsId: // value for 'studentsId'
 *   },
 * });
 */
export function useGetClassesNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassesNamesQuery,
    GetClassesNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClassesNamesQuery, GetClassesNamesQueryVariables>(
    GetClassesNamesDocument,
    options
  );
}
export function useGetClassesNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassesNamesQuery,
    GetClassesNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassesNamesQuery,
    GetClassesNamesQueryVariables
  >(GetClassesNamesDocument, options);
}
export type GetClassesNamesQueryHookResult = ReturnType<
  typeof useGetClassesNamesQuery
>;
export type GetClassesNamesLazyQueryHookResult = ReturnType<
  typeof useGetClassesNamesLazyQuery
>;
export type GetClassesNamesQueryResult = Apollo.QueryResult<
  GetClassesNamesQuery,
  GetClassesNamesQueryVariables
>;
export const GetClassesForDaDetailsDocument = gql`
  query getClassesForDaDetails(
    $schoolId: String
    $teachersId: String
    $archived: Boolean = false
    $id_list: [String]
  ) {
    classes(
      schoolId: $schoolId
      teachersId: $teachersId
      archived: $archived
      id_list: $id_list
      status: Active
    ) {
      collection {
        _id
        name
      }
    }
  }
`;

/**
 * __useGetClassesForDaDetailsQuery__
 *
 * To run a query within a React component, call `useGetClassesForDaDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesForDaDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesForDaDetailsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      teachersId: // value for 'teachersId'
 *      archived: // value for 'archived'
 *      id_list: // value for 'id_list'
 *   },
 * });
 */
export function useGetClassesForDaDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassesForDaDetailsQuery,
    GetClassesForDaDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassesForDaDetailsQuery,
    GetClassesForDaDetailsQueryVariables
  >(GetClassesForDaDetailsDocument, options);
}
export function useGetClassesForDaDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassesForDaDetailsQuery,
    GetClassesForDaDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassesForDaDetailsQuery,
    GetClassesForDaDetailsQueryVariables
  >(GetClassesForDaDetailsDocument, options);
}
export type GetClassesForDaDetailsQueryHookResult = ReturnType<
  typeof useGetClassesForDaDetailsQuery
>;
export type GetClassesForDaDetailsLazyQueryHookResult = ReturnType<
  typeof useGetClassesForDaDetailsLazyQuery
>;
export type GetClassesForDaDetailsQueryResult = Apollo.QueryResult<
  GetClassesForDaDetailsQuery,
  GetClassesForDaDetailsQueryVariables
>;
export const GetStudentsInClassesDocument = gql`
  query getStudentsInClasses(
    $schoolId: String
    $teachersId: String!
    $archived: Boolean = false
  ) {
    classes(
      schoolId: $schoolId
      teachersId: $teachersId
      archived: $archived
      page: 1
      itemsPerPage: 9999
      status: Active
    ) {
      collection {
        id
        gradeOrCourse
        classStudents(itemsPerPage: 9999) {
          collection {
            student {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentsInClassesQuery__
 *
 * To run a query within a React component, call `useGetStudentsInClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsInClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsInClassesQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      teachersId: // value for 'teachersId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetStudentsInClassesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentsInClassesQuery,
    GetStudentsInClassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentsInClassesQuery,
    GetStudentsInClassesQueryVariables
  >(GetStudentsInClassesDocument, options);
}
export function useGetStudentsInClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentsInClassesQuery,
    GetStudentsInClassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentsInClassesQuery,
    GetStudentsInClassesQueryVariables
  >(GetStudentsInClassesDocument, options);
}
export type GetStudentsInClassesQueryHookResult = ReturnType<
  typeof useGetStudentsInClassesQuery
>;
export type GetStudentsInClassesLazyQueryHookResult = ReturnType<
  typeof useGetStudentsInClassesLazyQuery
>;
export type GetStudentsInClassesQueryResult = Apollo.QueryResult<
  GetStudentsInClassesQuery,
  GetStudentsInClassesQueryVariables
>;
export const GetStudentTeachersDocument = gql`
  query getStudentTeachers($filter: [ClassFilter]) {
    classes(filter: $filter, status: Active) {
      collection {
        classTeachers {
          collection {
            teacher {
              firstName
              lastName
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentTeachersQuery__
 *
 * To run a query within a React component, call `useGetStudentTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentTeachersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetStudentTeachersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStudentTeachersQuery,
    GetStudentTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentTeachersQuery,
    GetStudentTeachersQueryVariables
  >(GetStudentTeachersDocument, options);
}
export function useGetStudentTeachersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentTeachersQuery,
    GetStudentTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentTeachersQuery,
    GetStudentTeachersQueryVariables
  >(GetStudentTeachersDocument, options);
}
export type GetStudentTeachersQueryHookResult = ReturnType<
  typeof useGetStudentTeachersQuery
>;
export type GetStudentTeachersLazyQueryHookResult = ReturnType<
  typeof useGetStudentTeachersLazyQuery
>;
export type GetStudentTeachersQueryResult = Apollo.QueryResult<
  GetStudentTeachersQuery,
  GetStudentTeachersQueryVariables
>;
export const GetClassesForReportFilterDocument = gql`
  query getClassesForReportFilter(
    $teachersId: String
    $schoolId: String
    $itemsPerPage: Int = 9999
    $archived: Boolean = false
  ) {
    classes(
      schoolId: $schoolId
      itemsPerPage: $itemsPerPage
      archived: $archived
      status: Active
      teachersId: $teachersId
    ) {
      collection {
        _id
        id
        name
        schoolId
      }
    }
  }
`;

/**
 * __useGetClassesForReportFilterQuery__
 *
 * To run a query within a React component, call `useGetClassesForReportFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesForReportFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesForReportFilterQuery({
 *   variables: {
 *      teachersId: // value for 'teachersId'
 *      schoolId: // value for 'schoolId'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetClassesForReportFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassesForReportFilterQuery,
    GetClassesForReportFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassesForReportFilterQuery,
    GetClassesForReportFilterQueryVariables
  >(GetClassesForReportFilterDocument, options);
}
export function useGetClassesForReportFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassesForReportFilterQuery,
    GetClassesForReportFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassesForReportFilterQuery,
    GetClassesForReportFilterQueryVariables
  >(GetClassesForReportFilterDocument, options);
}
export type GetClassesForReportFilterQueryHookResult = ReturnType<
  typeof useGetClassesForReportFilterQuery
>;
export type GetClassesForReportFilterLazyQueryHookResult = ReturnType<
  typeof useGetClassesForReportFilterLazyQuery
>;
export type GetClassesForReportFilterQueryResult = Apollo.QueryResult<
  GetClassesForReportFilterQuery,
  GetClassesForReportFilterQueryVariables
>;
export const PutAssignmentDocument = gql`
  mutation putAssignment($assignmentParams: PutAssignmentInput!) {
    putAssignment(assignmentParams: $assignmentParams) {
      ... on AssignmentId {
        assignmentId
      }
      ... on StemscopesAssignment {
        learnosityReferenceId
      }
      ... on DistrictAssignmentType {
        daId
      }
    }
  }
`;
export type PutAssignmentMutationFn = Apollo.MutationFunction<
  PutAssignmentMutation,
  PutAssignmentMutationVariables
>;

/**
 * __usePutAssignmentMutation__
 *
 * To run a mutation, you first call `usePutAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putAssignmentMutation, { data, loading, error }] = usePutAssignmentMutation({
 *   variables: {
 *      assignmentParams: // value for 'assignmentParams'
 *   },
 * });
 */
export function usePutAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PutAssignmentMutation,
    PutAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PutAssignmentMutation,
    PutAssignmentMutationVariables
  >(PutAssignmentDocument, options);
}
export type PutAssignmentMutationHookResult = ReturnType<
  typeof usePutAssignmentMutation
>;
export type PutAssignmentMutationResult =
  Apollo.MutationResult<PutAssignmentMutation>;
export type PutAssignmentMutationOptions = Apollo.BaseMutationOptions<
  PutAssignmentMutation,
  PutAssignmentMutationVariables
>;
export const DistrictDocument = gql`
  query district($id: ID!) {
    district(id: $id) {
      externalId
      state
      globalName
      localName
    }
  }
`;

/**
 * __useDistrictQuery__
 *
 * To run a query within a React component, call `useDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistrictQuery(
  baseOptions: Apollo.QueryHookOptions<DistrictQuery, DistrictQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DistrictQuery, DistrictQueryVariables>(
    DistrictDocument,
    options
  );
}
export function useDistrictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistrictQuery,
    DistrictQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DistrictQuery, DistrictQueryVariables>(
    DistrictDocument,
    options
  );
}
export type DistrictQueryHookResult = ReturnType<typeof useDistrictQuery>;
export type DistrictLazyQueryHookResult = ReturnType<
  typeof useDistrictLazyQuery
>;
export type DistrictQueryResult = Apollo.QueryResult<
  DistrictQuery,
  DistrictQueryVariables
>;
export const GetAllAssignmentsDocument = gql`
  query getAllAssignments(
    $teachersId: String!
    $schoolId: String!
    $archivedClasses: Boolean = false
    $archivedAssignments: Boolean = false
    $itemsPerPage: Int = 10000
    $assignmentDateFilter: AssignmentDateFilterInput
    $paginationInfo: StemscopesAssignmentPageInfoInput
  ) {
    ownerAssignments(
      schoolId: $schoolId
      assignmentDateFilter: $assignmentDateFilter
      archived: $archivedAssignments
      paginationInfo: $paginationInfo
    ) {
      assignments {
        ownerId
        classId
        assignmentListId
        assignmentId
        learnosityReferenceId
        name
        releaseFeedback
        startDate
        endDate
        status
        subgroupIds
        studentIds
        labels
        notes
      }
      studentAssignments {
        classId
        assignmentId
        studentId
        name
        id
        grade
        startedAt
        submittedAt
        completedAt
        status
        releaseFeedback
        lateTurnIn
        answers
        learnosityReferenceId
      }
      pageInfo {
        itemsPerPage
        totalCount
      }
    }
    classes(
      teachersId: $teachersId
      archived: $archivedClasses
      status: Active
    ) {
      collection {
        _id
        name
        schoolId
        classStudents(itemsPerPage: $itemsPerPage) {
          collection {
            student {
              _id
            }
          }
        }
      }
    }
    teacherStudents(
      schoolId: $schoolId
      teachersId: $teachersId
      itemsPerPage: $itemsPerPage
    ) {
      collection {
        _id
        firstName
        lastName
        username
      }
    }
  }
`;

/**
 * __useGetAllAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetAllAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssignmentsQuery({
 *   variables: {
 *      teachersId: // value for 'teachersId'
 *      schoolId: // value for 'schoolId'
 *      archivedClasses: // value for 'archivedClasses'
 *      archivedAssignments: // value for 'archivedAssignments'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      assignmentDateFilter: // value for 'assignmentDateFilter'
 *      paginationInfo: // value for 'paginationInfo'
 *   },
 * });
 */
export function useGetAllAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables
  >(GetAllAssignmentsDocument, options);
}
export function useGetAllAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllAssignmentsQuery,
    GetAllAssignmentsQueryVariables
  >(GetAllAssignmentsDocument, options);
}
export type GetAllAssignmentsQueryHookResult = ReturnType<
  typeof useGetAllAssignmentsQuery
>;
export type GetAllAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetAllAssignmentsLazyQuery
>;
export type GetAllAssignmentsQueryResult = Apollo.QueryResult<
  GetAllAssignmentsQuery,
  GetAllAssignmentsQueryVariables
>;
export const GetClassesAndStudentsDocument = gql`
  query getClassesAndStudents(
    $teachersId: String!
    $schoolId: String!
    $archivedClasses: Boolean = false
    $itemsPerPage: Int = 10000
  ) {
    classes(
      teachersId: $teachersId
      archived: $archivedClasses
      status: Active
    ) {
      collection {
        _id
        name
        schoolId
        classStudents(itemsPerPage: $itemsPerPage) {
          collection {
            student {
              _id
            }
          }
        }
      }
    }
    teacherStudents(
      schoolId: $schoolId
      teachersId: $teachersId
      itemsPerPage: $itemsPerPage
    ) {
      collection {
        _id
        firstName
        lastName
        username
      }
    }
  }
`;

/**
 * __useGetClassesAndStudentsQuery__
 *
 * To run a query within a React component, call `useGetClassesAndStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesAndStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesAndStudentsQuery({
 *   variables: {
 *      teachersId: // value for 'teachersId'
 *      schoolId: // value for 'schoolId'
 *      archivedClasses: // value for 'archivedClasses'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetClassesAndStudentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassesAndStudentsQuery,
    GetClassesAndStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassesAndStudentsQuery,
    GetClassesAndStudentsQueryVariables
  >(GetClassesAndStudentsDocument, options);
}
export function useGetClassesAndStudentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassesAndStudentsQuery,
    GetClassesAndStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassesAndStudentsQuery,
    GetClassesAndStudentsQueryVariables
  >(GetClassesAndStudentsDocument, options);
}
export type GetClassesAndStudentsQueryHookResult = ReturnType<
  typeof useGetClassesAndStudentsQuery
>;
export type GetClassesAndStudentsLazyQueryHookResult = ReturnType<
  typeof useGetClassesAndStudentsLazyQuery
>;
export type GetClassesAndStudentsQueryResult = Apollo.QueryResult<
  GetClassesAndStudentsQuery,
  GetClassesAndStudentsQueryVariables
>;
export const GetAllAssignmentsNewDocument = gql`
  query getAllAssignmentsNew(
    $schoolId: String!
    $archivedAssignments: Boolean = false
    $assignmentDateFilter: AssignmentDateFilterInput
    $paginationInfo: StemscopesAssignmentPageInfoInput
    $classIds: [String!]
  ) {
    ownerAssignments(
      schoolId: $schoolId
      assignmentDateFilter: $assignmentDateFilter
      archived: $archivedAssignments
      paginationInfo: $paginationInfo
      classIds: $classIds
    ) {
      assignments {
        ownerId
        classId
        assignmentListId
        assignmentId
        learnosityReferenceId
        name
        releaseFeedback
        startDate
        endDate
        status
        subgroupIds
        studentIds
        labels
        notes
      }
      studentAssignments {
        classId
        assignmentId
        studentId
        name
        id
        grade
        startedAt
        submittedAt
        completedAt
        status
        releaseFeedback
        lateTurnIn
        answers
        learnosityReferenceId
      }
      pageInfo {
        itemsPerPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetAllAssignmentsNewQuery__
 *
 * To run a query within a React component, call `useGetAllAssignmentsNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssignmentsNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssignmentsNewQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      archivedAssignments: // value for 'archivedAssignments'
 *      assignmentDateFilter: // value for 'assignmentDateFilter'
 *      paginationInfo: // value for 'paginationInfo'
 *      classIds: // value for 'classIds'
 *   },
 * });
 */
export function useGetAllAssignmentsNewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllAssignmentsNewQuery,
    GetAllAssignmentsNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllAssignmentsNewQuery,
    GetAllAssignmentsNewQueryVariables
  >(GetAllAssignmentsNewDocument, options);
}
export function useGetAllAssignmentsNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssignmentsNewQuery,
    GetAllAssignmentsNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllAssignmentsNewQuery,
    GetAllAssignmentsNewQueryVariables
  >(GetAllAssignmentsNewDocument, options);
}
export type GetAllAssignmentsNewQueryHookResult = ReturnType<
  typeof useGetAllAssignmentsNewQuery
>;
export type GetAllAssignmentsNewLazyQueryHookResult = ReturnType<
  typeof useGetAllAssignmentsNewLazyQuery
>;
export type GetAllAssignmentsNewQueryResult = Apollo.QueryResult<
  GetAllAssignmentsNewQuery,
  GetAllAssignmentsNewQueryVariables
>;
export const PutDistrictStudentAssignmentsDocument = gql`
  mutation putDistrictStudentAssignments(
    $input: PutDistrictStudentAssignmentsInput!
  ) {
    putDistrictStudentAssignments(params: $input) {
      ... on DistrictStudentAssignmentsType {
        collection {
          assessmentId
        }
      }
    }
  }
`;
export type PutDistrictStudentAssignmentsMutationFn = Apollo.MutationFunction<
  PutDistrictStudentAssignmentsMutation,
  PutDistrictStudentAssignmentsMutationVariables
>;

/**
 * __usePutDistrictStudentAssignmentsMutation__
 *
 * To run a mutation, you first call `usePutDistrictStudentAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutDistrictStudentAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putDistrictStudentAssignmentsMutation, { data, loading, error }] = usePutDistrictStudentAssignmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePutDistrictStudentAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PutDistrictStudentAssignmentsMutation,
    PutDistrictStudentAssignmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PutDistrictStudentAssignmentsMutation,
    PutDistrictStudentAssignmentsMutationVariables
  >(PutDistrictStudentAssignmentsDocument, options);
}
export type PutDistrictStudentAssignmentsMutationHookResult = ReturnType<
  typeof usePutDistrictStudentAssignmentsMutation
>;
export type PutDistrictStudentAssignmentsMutationResult =
  Apollo.MutationResult<PutDistrictStudentAssignmentsMutation>;
export type PutDistrictStudentAssignmentsMutationOptions =
  Apollo.BaseMutationOptions<
    PutDistrictStudentAssignmentsMutation,
    PutDistrictStudentAssignmentsMutationVariables
  >;
export const GetClassDocument = gql`
  query getClass($id: ID!) {
    class(id: $id) {
      _id
      classTeachers {
        collection {
          _id
          teacherId
          accesses
          mainTeacher
          subgroups {
            collection {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassQuery__
 *
 * To run a query within a React component, call `useGetClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassQuery(
  baseOptions: Apollo.QueryHookOptions<GetClassQuery, GetClassQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClassQuery, GetClassQueryVariables>(
    GetClassDocument,
    options
  );
}
export function useGetClassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassQuery,
    GetClassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClassQuery, GetClassQueryVariables>(
    GetClassDocument,
    options
  );
}
export type GetClassQueryHookResult = ReturnType<typeof useGetClassQuery>;
export type GetClassLazyQueryHookResult = ReturnType<
  typeof useGetClassLazyQuery
>;
export type GetClassQueryResult = Apollo.QueryResult<
  GetClassQuery,
  GetClassQueryVariables
>;
export const GetClassStudentsDocument = gql`
  query getClassStudents(
    $id: ID!
    $page: Int = 1
    $itemsPerPage: Int = 999
    $active: Boolean = true
  ) {
    class(id: $id) {
      _id
      id
      name
      classStudents(page: $page, itemsPerPage: $itemsPerPage, active: $active) {
        collection {
          student {
            id
            _id
            sisId
            firstName
            lastName
            username
            gradeLevel
            plainPassword
            authenticationToken
          }
          subgroups {
            collection {
              _id
              name
            }
          }
        }
        paginationInfo {
          itemsPerPage
          totalCount
          lastPage
        }
      }
    }
  }
`;

/**
 * __useGetClassStudentsQuery__
 *
 * To run a query within a React component, call `useGetClassStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetClassStudentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassStudentsQuery,
    GetClassStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClassStudentsQuery, GetClassStudentsQueryVariables>(
    GetClassStudentsDocument,
    options
  );
}
export function useGetClassStudentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassStudentsQuery,
    GetClassStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassStudentsQuery,
    GetClassStudentsQueryVariables
  >(GetClassStudentsDocument, options);
}
export type GetClassStudentsQueryHookResult = ReturnType<
  typeof useGetClassStudentsQuery
>;
export type GetClassStudentsLazyQueryHookResult = ReturnType<
  typeof useGetClassStudentsLazyQuery
>;
export type GetClassStudentsQueryResult = Apollo.QueryResult<
  GetClassStudentsQuery,
  GetClassStudentsQueryVariables
>;
export const GetClassStudentsForManageDocument = gql`
  query getClassStudentsForManage(
    $id: ID!
    $page: Int = 1
    $itemsPerPage: Int = 999
    $active: Boolean = true
  ) {
    class(id: $id) {
      id
      name
      classStudents(page: $page, itemsPerPage: $itemsPerPage, active: $active) {
        collection {
          student {
            _id
            firstName
            lastName
            gradeLevel
            username
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassStudentsForManageQuery__
 *
 * To run a query within a React component, call `useGetClassStudentsForManageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassStudentsForManageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassStudentsForManageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetClassStudentsForManageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassStudentsForManageQuery,
    GetClassStudentsForManageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassStudentsForManageQuery,
    GetClassStudentsForManageQueryVariables
  >(GetClassStudentsForManageDocument, options);
}
export function useGetClassStudentsForManageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassStudentsForManageQuery,
    GetClassStudentsForManageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassStudentsForManageQuery,
    GetClassStudentsForManageQueryVariables
  >(GetClassStudentsForManageDocument, options);
}
export type GetClassStudentsForManageQueryHookResult = ReturnType<
  typeof useGetClassStudentsForManageQuery
>;
export type GetClassStudentsForManageLazyQueryHookResult = ReturnType<
  typeof useGetClassStudentsForManageLazyQuery
>;
export type GetClassStudentsForManageQueryResult = Apollo.QueryResult<
  GetClassStudentsForManageQuery,
  GetClassStudentsForManageQueryVariables
>;
export const GetClassNameDocument = gql`
  query getClassName($id: ID!) {
    class(id: $id) {
      name
    }
  }
`;

/**
 * __useGetClassNameQuery__
 *
 * To run a query within a React component, call `useGetClassNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassNameQuery,
    GetClassNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClassNameQuery, GetClassNameQueryVariables>(
    GetClassNameDocument,
    options
  );
}
export function useGetClassNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassNameQuery,
    GetClassNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClassNameQuery, GetClassNameQueryVariables>(
    GetClassNameDocument,
    options
  );
}
export type GetClassNameQueryHookResult = ReturnType<
  typeof useGetClassNameQuery
>;
export type GetClassNameLazyQueryHookResult = ReturnType<
  typeof useGetClassNameLazyQuery
>;
export type GetClassNameQueryResult = Apollo.QueryResult<
  GetClassNameQuery,
  GetClassNameQueryVariables
>;
export const GetClassTeachersDocument = gql`
  query getClassTeachers($id: ID!) {
    class(id: $id) {
      id
      name
      classTeachers {
        collection {
          teacherId
          mainTeacher
        }
      }
    }
  }
`;

/**
 * __useGetClassTeachersQuery__
 *
 * To run a query within a React component, call `useGetClassTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassTeachersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassTeachersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassTeachersQuery,
    GetClassTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClassTeachersQuery, GetClassTeachersQueryVariables>(
    GetClassTeachersDocument,
    options
  );
}
export function useGetClassTeachersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassTeachersQuery,
    GetClassTeachersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassTeachersQuery,
    GetClassTeachersQueryVariables
  >(GetClassTeachersDocument, options);
}
export type GetClassTeachersQueryHookResult = ReturnType<
  typeof useGetClassTeachersQuery
>;
export type GetClassTeachersLazyQueryHookResult = ReturnType<
  typeof useGetClassTeachersLazyQuery
>;
export type GetClassTeachersQueryResult = Apollo.QueryResult<
  GetClassTeachersQuery,
  GetClassTeachersQueryVariables
>;
export const GetClassForStudentNotebookDocument = gql`
  query getClassForStudentNotebook($id: ID!) {
    class(id: $id) {
      name
      gradeOrCourse
      classTeachers {
        collection {
          mainTeacher
          teacher {
            lastName
            firstName
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassForStudentNotebookQuery__
 *
 * To run a query within a React component, call `useGetClassForStudentNotebookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassForStudentNotebookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassForStudentNotebookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassForStudentNotebookQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassForStudentNotebookQuery,
    GetClassForStudentNotebookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassForStudentNotebookQuery,
    GetClassForStudentNotebookQueryVariables
  >(GetClassForStudentNotebookDocument, options);
}
export function useGetClassForStudentNotebookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassForStudentNotebookQuery,
    GetClassForStudentNotebookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassForStudentNotebookQuery,
    GetClassForStudentNotebookQueryVariables
  >(GetClassForStudentNotebookDocument, options);
}
export type GetClassForStudentNotebookQueryHookResult = ReturnType<
  typeof useGetClassForStudentNotebookQuery
>;
export type GetClassForStudentNotebookLazyQueryHookResult = ReturnType<
  typeof useGetClassForStudentNotebookLazyQuery
>;
export type GetClassForStudentNotebookQueryResult = Apollo.QueryResult<
  GetClassForStudentNotebookQuery,
  GetClassForStudentNotebookQueryVariables
>;
export const GetClassForClassFormDocument = gql`
  query getClassForClassForm($id: ID!, $activeTeachers: Boolean) {
    class(id: $id) {
      _id
      id
      name
      language
      curriculumArea
      gradeOrCourse
      archived
      externalId
      subgroups {
        collection {
          id
          name
        }
      }
      classTeachers(active: $activeTeachers) {
        collection {
          _id
          teacherId
          accesses
          subgroups {
            collection {
              id
              name
            }
          }
          mainTeacher
          orderListStudents
          teacher {
            _id
            lastName
            firstName
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassForClassFormQuery__
 *
 * To run a query within a React component, call `useGetClassForClassFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassForClassFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassForClassFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *      activeTeachers: // value for 'activeTeachers'
 *   },
 * });
 */
export function useGetClassForClassFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassForClassFormQuery,
    GetClassForClassFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassForClassFormQuery,
    GetClassForClassFormQueryVariables
  >(GetClassForClassFormDocument, options);
}
export function useGetClassForClassFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassForClassFormQuery,
    GetClassForClassFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassForClassFormQuery,
    GetClassForClassFormQueryVariables
  >(GetClassForClassFormDocument, options);
}
export type GetClassForClassFormQueryHookResult = ReturnType<
  typeof useGetClassForClassFormQuery
>;
export type GetClassForClassFormLazyQueryHookResult = ReturnType<
  typeof useGetClassForClassFormLazyQuery
>;
export type GetClassForClassFormQueryResult = Apollo.QueryResult<
  GetClassForClassFormQuery,
  GetClassForClassFormQueryVariables
>;
export const GetClassForAssignmentFormDocument = gql`
  query getClassForAssignmentForm($classId: ID!) {
    class(id: $classId) {
      curriculumArea
      gradeOrCourse
      name
    }
  }
`;

/**
 * __useGetClassForAssignmentFormQuery__
 *
 * To run a query within a React component, call `useGetClassForAssignmentFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassForAssignmentFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassForAssignmentFormQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassForAssignmentFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassForAssignmentFormQuery,
    GetClassForAssignmentFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassForAssignmentFormQuery,
    GetClassForAssignmentFormQueryVariables
  >(GetClassForAssignmentFormDocument, options);
}
export function useGetClassForAssignmentFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassForAssignmentFormQuery,
    GetClassForAssignmentFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassForAssignmentFormQuery,
    GetClassForAssignmentFormQueryVariables
  >(GetClassForAssignmentFormDocument, options);
}
export type GetClassForAssignmentFormQueryHookResult = ReturnType<
  typeof useGetClassForAssignmentFormQuery
>;
export type GetClassForAssignmentFormLazyQueryHookResult = ReturnType<
  typeof useGetClassForAssignmentFormLazyQuery
>;
export type GetClassForAssignmentFormQueryResult = Apollo.QueryResult<
  GetClassForAssignmentFormQuery,
  GetClassForAssignmentFormQueryVariables
>;
export const GetClassStudentsForAssignmentFormDocument = gql`
  query getClassStudentsForAssignmentForm(
    $classId: ID!
    $itemsPerPage: Int = 10000
    $active: Boolean = true
  ) {
    class(id: $classId) {
      _id
      id
      name
      curriculumArea
      gradeOrCourse
      classStudents(itemsPerPage: $itemsPerPage, active: $active) {
        collection {
          _id
          student {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassStudentsForAssignmentFormQuery__
 *
 * To run a query within a React component, call `useGetClassStudentsForAssignmentFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassStudentsForAssignmentFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassStudentsForAssignmentFormQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetClassStudentsForAssignmentFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassStudentsForAssignmentFormQuery,
    GetClassStudentsForAssignmentFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassStudentsForAssignmentFormQuery,
    GetClassStudentsForAssignmentFormQueryVariables
  >(GetClassStudentsForAssignmentFormDocument, options);
}
export function useGetClassStudentsForAssignmentFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassStudentsForAssignmentFormQuery,
    GetClassStudentsForAssignmentFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassStudentsForAssignmentFormQuery,
    GetClassStudentsForAssignmentFormQueryVariables
  >(GetClassStudentsForAssignmentFormDocument, options);
}
export type GetClassStudentsForAssignmentFormQueryHookResult = ReturnType<
  typeof useGetClassStudentsForAssignmentFormQuery
>;
export type GetClassStudentsForAssignmentFormLazyQueryHookResult = ReturnType<
  typeof useGetClassStudentsForAssignmentFormLazyQuery
>;
export type GetClassStudentsForAssignmentFormQueryResult = Apollo.QueryResult<
  GetClassStudentsForAssignmentFormQuery,
  GetClassStudentsForAssignmentFormQueryVariables
>;
export const GetClassSubgroupsForAssignmentFormDocument = gql`
  query getClassSubgroupsForAssignmentForm($classId: ID!) {
    class(id: $classId) {
      curriculumArea
      gradeOrCourse
      name
      subgroups {
        collection {
          _id
          name
        }
      }
    }
  }
`;

/**
 * __useGetClassSubgroupsForAssignmentFormQuery__
 *
 * To run a query within a React component, call `useGetClassSubgroupsForAssignmentFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassSubgroupsForAssignmentFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassSubgroupsForAssignmentFormQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassSubgroupsForAssignmentFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassSubgroupsForAssignmentFormQuery,
    GetClassSubgroupsForAssignmentFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassSubgroupsForAssignmentFormQuery,
    GetClassSubgroupsForAssignmentFormQueryVariables
  >(GetClassSubgroupsForAssignmentFormDocument, options);
}
export function useGetClassSubgroupsForAssignmentFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassSubgroupsForAssignmentFormQuery,
    GetClassSubgroupsForAssignmentFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassSubgroupsForAssignmentFormQuery,
    GetClassSubgroupsForAssignmentFormQueryVariables
  >(GetClassSubgroupsForAssignmentFormDocument, options);
}
export type GetClassSubgroupsForAssignmentFormQueryHookResult = ReturnType<
  typeof useGetClassSubgroupsForAssignmentFormQuery
>;
export type GetClassSubgroupsForAssignmentFormLazyQueryHookResult = ReturnType<
  typeof useGetClassSubgroupsForAssignmentFormLazyQuery
>;
export type GetClassSubgroupsForAssignmentFormQueryResult = Apollo.QueryResult<
  GetClassSubgroupsForAssignmentFormQuery,
  GetClassSubgroupsForAssignmentFormQueryVariables
>;
export const GetDistrictsDocument = gql`
  query getDistricts(
    $id: String
    $page: Int
    $perPage: Int
    $sorting: [DistrictFilter_order]
    $filter: [DistrictFilter]
  ) {
    districts(
      id: $id
      page: $page
      itemsPerPage: $perPage
      order: $sorting
      filter: $filter
    ) {
      collection {
        _id
        globalName
        state
        mathNationId
      }
      paginationInfo {
        totalCount
        lastPage
        itemsPerPage
      }
    }
  }
`;

/**
 * __useGetDistrictsQuery__
 *
 * To run a query within a React component, call `useGetDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDistrictsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDistrictsQuery,
    GetDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDistrictsQuery, GetDistrictsQueryVariables>(
    GetDistrictsDocument,
    options
  );
}
export function useGetDistrictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDistrictsQuery,
    GetDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDistrictsQuery, GetDistrictsQueryVariables>(
    GetDistrictsDocument,
    options
  );
}
export type GetDistrictsQueryHookResult = ReturnType<
  typeof useGetDistrictsQuery
>;
export type GetDistrictsLazyQueryHookResult = ReturnType<
  typeof useGetDistrictsLazyQuery
>;
export type GetDistrictsQueryResult = Apollo.QueryResult<
  GetDistrictsQuery,
  GetDistrictsQueryVariables
>;
export const AllDistrictAssignmentDocument = gql`
  query allDistrictAssignment(
    $archivedClasses: Boolean = false
    $teachersId: String!
    $schoolId: String!
    $itemsPerPage: Int = 10000
    $filter: DistrictAssignmentsFilter
  ) {
    districtAssignments(filter: $filter) {
      collection {
        assessmentId
        assignmentId
        availableDate
        classId
        daId
        districtId
        endDate
        assignmentSource {
          assessment {
            assessmentId
          }
        }
        gradeLevel
        name
        schoolId
        startDate
        studentAssignments {
          name
          studentId
          answers
          grade
          classId
          status
          completedAt
          submittedAt
        }
        teacherId
      }
    }
    classes(
      teachersId: $teachersId
      archived: $archivedClasses
      status: Active
    ) {
      collection {
        _id
        name
        schoolId
        classStudents(itemsPerPage: $itemsPerPage) {
          collection {
            student {
              _id
              firstName
              lastName
            }
          }
        }
      }
    }
    teacherStudents(
      schoolId: $schoolId
      teachersId: $teachersId
      itemsPerPage: $itemsPerPage
    ) {
      collection {
        _id
        firstName
        lastName
        username
      }
    }
  }
`;

/**
 * __useAllDistrictAssignmentQuery__
 *
 * To run a query within a React component, call `useAllDistrictAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDistrictAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDistrictAssignmentQuery({
 *   variables: {
 *      archivedClasses: // value for 'archivedClasses'
 *      teachersId: // value for 'teachersId'
 *      schoolId: // value for 'schoolId'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllDistrictAssignmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllDistrictAssignmentQuery,
    AllDistrictAssignmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllDistrictAssignmentQuery,
    AllDistrictAssignmentQueryVariables
  >(AllDistrictAssignmentDocument, options);
}
export function useAllDistrictAssignmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllDistrictAssignmentQuery,
    AllDistrictAssignmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllDistrictAssignmentQuery,
    AllDistrictAssignmentQueryVariables
  >(AllDistrictAssignmentDocument, options);
}
export type AllDistrictAssignmentQueryHookResult = ReturnType<
  typeof useAllDistrictAssignmentQuery
>;
export type AllDistrictAssignmentLazyQueryHookResult = ReturnType<
  typeof useAllDistrictAssignmentLazyQuery
>;
export type AllDistrictAssignmentQueryResult = Apollo.QueryResult<
  AllDistrictAssignmentQuery,
  AllDistrictAssignmentQueryVariables
>;
export const GetPublicDistrictDocument = gql`
  query getPublicDistrict($id: ID!) {
    publicDistrict(id: $id) {
      id
      globalName
      state
    }
  }
`;

/**
 * __useGetPublicDistrictQuery__
 *
 * To run a query within a React component, call `useGetPublicDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicDistrictQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicDistrictQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicDistrictQuery,
    GetPublicDistrictQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPublicDistrictQuery,
    GetPublicDistrictQueryVariables
  >(GetPublicDistrictDocument, options);
}
export function useGetPublicDistrictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicDistrictQuery,
    GetPublicDistrictQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicDistrictQuery,
    GetPublicDistrictQueryVariables
  >(GetPublicDistrictDocument, options);
}
export type GetPublicDistrictQueryHookResult = ReturnType<
  typeof useGetPublicDistrictQuery
>;
export type GetPublicDistrictLazyQueryHookResult = ReturnType<
  typeof useGetPublicDistrictLazyQuery
>;
export type GetPublicDistrictQueryResult = Apollo.QueryResult<
  GetPublicDistrictQuery,
  GetPublicDistrictQueryVariables
>;
export const GetGradesDocument = gql`
  query getGrades($schoolId: String, $schoolIds: [String!]! = []) {
    grades(schoolId: $schoolId, schoolIds: $schoolIds) {
      grades
    }
  }
`;

/**
 * __useGetGradesQuery__
 *
 * To run a query within a React component, call `useGetGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradesQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetGradesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGradesQuery, GetGradesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGradesQuery, GetGradesQueryVariables>(
    GetGradesDocument,
    options
  );
}
export function useGetGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGradesQuery,
    GetGradesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGradesQuery, GetGradesQueryVariables>(
    GetGradesDocument,
    options
  );
}
export type GetGradesQueryHookResult = ReturnType<typeof useGetGradesQuery>;
export type GetGradesLazyQueryHookResult = ReturnType<
  typeof useGetGradesLazyQuery
>;
export type GetGradesQueryResult = Apollo.QueryResult<
  GetGradesQuery,
  GetGradesQueryVariables
>;
export const GetPublicDistrictsDocument = gql`
  query getPublicDistricts(
    $name: String
    $state: StateEnum
    $itemsPerPage: Int!
    $page: Int!
  ) {
    newDistricts(
      name: $name
      state: $state
      itemsPerPage: $itemsPerPage
      page: $page
    ) {
      collection {
        globalName
        id
        city
      }
    }
  }
`;

/**
 * __useGetPublicDistrictsQuery__
 *
 * To run a query within a React component, call `useGetPublicDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicDistrictsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      state: // value for 'state'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetPublicDistrictsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicDistrictsQuery,
    GetPublicDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPublicDistrictsQuery,
    GetPublicDistrictsQueryVariables
  >(GetPublicDistrictsDocument, options);
}
export function useGetPublicDistrictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicDistrictsQuery,
    GetPublicDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicDistrictsQuery,
    GetPublicDistrictsQueryVariables
  >(GetPublicDistrictsDocument, options);
}
export type GetPublicDistrictsQueryHookResult = ReturnType<
  typeof useGetPublicDistrictsQuery
>;
export type GetPublicDistrictsLazyQueryHookResult = ReturnType<
  typeof useGetPublicDistrictsLazyQuery
>;
export type GetPublicDistrictsQueryResult = Apollo.QueryResult<
  GetPublicDistrictsQuery,
  GetPublicDistrictsQueryVariables
>;
export const GetPreviewDistrictsDocument = gql`
  query getPreviewDistricts($page: Int = 1, $itemsPerPage: Int = 10000) {
    previewAccountDistricts(page: $page, itemsPerPage: $itemsPerPage) {
      collection {
        id
        globalName
        state
        urlCode
      }
    }
  }
`;

/**
 * __useGetPreviewDistrictsQuery__
 *
 * To run a query within a React component, call `useGetPreviewDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewDistrictsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetPreviewDistrictsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPreviewDistrictsQuery,
    GetPreviewDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPreviewDistrictsQuery,
    GetPreviewDistrictsQueryVariables
  >(GetPreviewDistrictsDocument, options);
}
export function useGetPreviewDistrictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPreviewDistrictsQuery,
    GetPreviewDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPreviewDistrictsQuery,
    GetPreviewDistrictsQueryVariables
  >(GetPreviewDistrictsDocument, options);
}
export type GetPreviewDistrictsQueryHookResult = ReturnType<
  typeof useGetPreviewDistrictsQuery
>;
export type GetPreviewDistrictsLazyQueryHookResult = ReturnType<
  typeof useGetPreviewDistrictsLazyQuery
>;
export type GetPreviewDistrictsQueryResult = Apollo.QueryResult<
  GetPreviewDistrictsQuery,
  GetPreviewDistrictsQueryVariables
>;
export const OpenLtiAssignmentLinkDocument = gql`
  mutation openLtiAssignmentLink(
    $assignmentParams: PutAssignmentInput!
    $assignmentId: String!
    $ownerId: String!
    $curriculumArea: String!
    $gradeLevel: String!
  ) {
    openLtiAssignmentLink(
      assignmentParams: $assignmentParams
      assignmentId: $assignmentId
      ownerId: $ownerId
      gradeLevel: $gradeLevel
      curriculumArea: $curriculumArea
    ) {
      ... on StemscopesAssignment {
        assignmentId
        classId
      }
    }
  }
`;
export type OpenLtiAssignmentLinkMutationFn = Apollo.MutationFunction<
  OpenLtiAssignmentLinkMutation,
  OpenLtiAssignmentLinkMutationVariables
>;

/**
 * __useOpenLtiAssignmentLinkMutation__
 *
 * To run a mutation, you first call `useOpenLtiAssignmentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenLtiAssignmentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openLtiAssignmentLinkMutation, { data, loading, error }] = useOpenLtiAssignmentLinkMutation({
 *   variables: {
 *      assignmentParams: // value for 'assignmentParams'
 *      assignmentId: // value for 'assignmentId'
 *      ownerId: // value for 'ownerId'
 *      curriculumArea: // value for 'curriculumArea'
 *      gradeLevel: // value for 'gradeLevel'
 *   },
 * });
 */
export function useOpenLtiAssignmentLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenLtiAssignmentLinkMutation,
    OpenLtiAssignmentLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OpenLtiAssignmentLinkMutation,
    OpenLtiAssignmentLinkMutationVariables
  >(OpenLtiAssignmentLinkDocument, options);
}
export type OpenLtiAssignmentLinkMutationHookResult = ReturnType<
  typeof useOpenLtiAssignmentLinkMutation
>;
export type OpenLtiAssignmentLinkMutationResult =
  Apollo.MutationResult<OpenLtiAssignmentLinkMutation>;
export type OpenLtiAssignmentLinkMutationOptions = Apollo.BaseMutationOptions<
  OpenLtiAssignmentLinkMutation,
  OpenLtiAssignmentLinkMutationVariables
>;
export const GetAthenaDistrictsDocument = gql`
  query getAthenaDistricts(
    $page: Int
    $perPage: Int
    $ordering: DistrictSort
    $filter: DistrictFilter
  ) {
    athenaDistricts(
      page: $page
      itemsPerPage: $perPage
      order: $ordering
      filter: $filter
    ) {
      collection {
        _id
        globalName
        state
        source
        mathnationId
      }
      paginationInfo {
        totalCount
        lastPage
        itemsPerPage
      }
    }
  }
`;

/**
 * __useGetAthenaDistrictsQuery__
 *
 * To run a query within a React component, call `useGetAthenaDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAthenaDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAthenaDistrictsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      ordering: // value for 'ordering'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAthenaDistrictsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAthenaDistrictsQuery,
    GetAthenaDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAthenaDistrictsQuery,
    GetAthenaDistrictsQueryVariables
  >(GetAthenaDistrictsDocument, options);
}
export function useGetAthenaDistrictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAthenaDistrictsQuery,
    GetAthenaDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAthenaDistrictsQuery,
    GetAthenaDistrictsQueryVariables
  >(GetAthenaDistrictsDocument, options);
}
export type GetAthenaDistrictsQueryHookResult = ReturnType<
  typeof useGetAthenaDistrictsQuery
>;
export type GetAthenaDistrictsLazyQueryHookResult = ReturnType<
  typeof useGetAthenaDistrictsLazyQuery
>;
export type GetAthenaDistrictsQueryResult = Apollo.QueryResult<
  GetAthenaDistrictsQuery,
  GetAthenaDistrictsQueryVariables
>;
export const GetHelpPageDocument = gql`
  query getHelpPage($input: HelpPageInput!) {
    helpPage(input: $input) {
      link
    }
  }
`;

/**
 * __useGetHelpPageQuery__
 *
 * To run a query within a React component, call `useGetHelpPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHelpPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHelpPageQuery,
    GetHelpPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHelpPageQuery, GetHelpPageQueryVariables>(
    GetHelpPageDocument,
    options
  );
}
export function useGetHelpPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHelpPageQuery,
    GetHelpPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHelpPageQuery, GetHelpPageQueryVariables>(
    GetHelpPageDocument,
    options
  );
}
export type GetHelpPageQueryHookResult = ReturnType<typeof useGetHelpPageQuery>;
export type GetHelpPageLazyQueryHookResult = ReturnType<
  typeof useGetHelpPageLazyQuery
>;
export type GetHelpPageQueryResult = Apollo.QueryResult<
  GetHelpPageQuery,
  GetHelpPageQueryVariables
>;
export const SubmitContentItemLinksDocument = gql`
  mutation submitContentItemLinks(
    $contentItems: [ContentItem!]!
    $schoolId: String!
  ) {
    submitContentItemLinks(contentItems: $contentItems, schoolId: $schoolId) {
      fields {
        JWT
      }
      ltiVersion
      url
    }
  }
`;
export type SubmitContentItemLinksMutationFn = Apollo.MutationFunction<
  SubmitContentItemLinksMutation,
  SubmitContentItemLinksMutationVariables
>;

/**
 * __useSubmitContentItemLinksMutation__
 *
 * To run a mutation, you first call `useSubmitContentItemLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitContentItemLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitContentItemLinksMutation, { data, loading, error }] = useSubmitContentItemLinksMutation({
 *   variables: {
 *      contentItems: // value for 'contentItems'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useSubmitContentItemLinksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitContentItemLinksMutation,
    SubmitContentItemLinksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitContentItemLinksMutation,
    SubmitContentItemLinksMutationVariables
  >(SubmitContentItemLinksDocument, options);
}
export type SubmitContentItemLinksMutationHookResult = ReturnType<
  typeof useSubmitContentItemLinksMutation
>;
export type SubmitContentItemLinksMutationResult =
  Apollo.MutationResult<SubmitContentItemLinksMutation>;
export type SubmitContentItemLinksMutationOptions = Apollo.BaseMutationOptions<
  SubmitContentItemLinksMutation,
  SubmitContentItemLinksMutationVariables
>;
export const PublishEventDocument = gql`
  mutation publishEvent($input: EventInput!) {
    publishEvent(input: $input) {
      success
    }
  }
`;
export type PublishEventMutationFn = Apollo.MutationFunction<
  PublishEventMutation,
  PublishEventMutationVariables
>;

/**
 * __usePublishEventMutation__
 *
 * To run a mutation, you first call `usePublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEventMutation, { data, loading, error }] = usePublishEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishEventMutation,
    PublishEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishEventMutation,
    PublishEventMutationVariables
  >(PublishEventDocument, options);
}
export type PublishEventMutationHookResult = ReturnType<
  typeof usePublishEventMutation
>;
export type PublishEventMutationResult =
  Apollo.MutationResult<PublishEventMutation>;
export type PublishEventMutationOptions = Apollo.BaseMutationOptions<
  PublishEventMutation,
  PublishEventMutationVariables
>;
export const GetNotebooksDocument = gql`
  query getNotebooks($schoolId: String, $studentId: String) {
    studentNotebooks(schoolId: $schoolId, studentId: $studentId) {
      collection {
        classId
        scopeIds
      }
    }
  }
`;

/**
 * __useGetNotebooksQuery__
 *
 * To run a query within a React component, call `useGetNotebooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotebooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotebooksQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetNotebooksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotebooksQuery,
    GetNotebooksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotebooksQuery, GetNotebooksQueryVariables>(
    GetNotebooksDocument,
    options
  );
}
export function useGetNotebooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotebooksQuery,
    GetNotebooksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotebooksQuery, GetNotebooksQueryVariables>(
    GetNotebooksDocument,
    options
  );
}
export type GetNotebooksQueryHookResult = ReturnType<
  typeof useGetNotebooksQuery
>;
export type GetNotebooksLazyQueryHookResult = ReturnType<
  typeof useGetNotebooksLazyQuery
>;
export type GetNotebooksQueryResult = Apollo.QueryResult<
  GetNotebooksQuery,
  GetNotebooksQueryVariables
>;
export const GetStudentParentsDocument = gql`
  query getStudentParents($studentId: String!) {
    parents(studentId: $studentId) {
      _id
      id
      email
      inviteCount
      inviteStatus
      invitedAt
    }
  }
`;

/**
 * __useGetStudentParentsQuery__
 *
 * To run a query within a React component, call `useGetStudentParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentParentsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentParentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentParentsQuery,
    GetStudentParentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentParentsQuery,
    GetStudentParentsQueryVariables
  >(GetStudentParentsDocument, options);
}
export function useGetStudentParentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentParentsQuery,
    GetStudentParentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentParentsQuery,
    GetStudentParentsQueryVariables
  >(GetStudentParentsDocument, options);
}
export type GetStudentParentsQueryHookResult = ReturnType<
  typeof useGetStudentParentsQuery
>;
export type GetStudentParentsLazyQueryHookResult = ReturnType<
  typeof useGetStudentParentsLazyQuery
>;
export type GetStudentParentsQueryResult = Apollo.QueryResult<
  GetStudentParentsQuery,
  GetStudentParentsQueryVariables
>;
export const GetIconDocument = gql`
  query getIcon($filter: FilterIconInput) {
    icons(itemsPerPage: 1, page: 1, filter: $filter) {
      collection {
        filePath
        languageFilePath {
          languageCode
          filePath
        }
      }
    }
  }
`;

/**
 * __useGetIconQuery__
 *
 * To run a query within a React component, call `useGetIconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIconQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetIconQuery(
  baseOptions?: Apollo.QueryHookOptions<GetIconQuery, GetIconQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIconQuery, GetIconQueryVariables>(
    GetIconDocument,
    options
  );
}
export function useGetIconLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIconQuery, GetIconQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIconQuery, GetIconQueryVariables>(
    GetIconDocument,
    options
  );
}
export type GetIconQueryHookResult = ReturnType<typeof useGetIconQuery>;
export type GetIconLazyQueryHookResult = ReturnType<typeof useGetIconLazyQuery>;
export type GetIconQueryResult = Apollo.QueryResult<
  GetIconQuery,
  GetIconQueryVariables
>;
export const VerifyEmailsParentDocument = gql`
  mutation verifyEmailsParent($input: verifyEmailsParentInput!) {
    verifyEmailsParent(input: $input) {
      parent {
        results
      }
    }
  }
`;
export type VerifyEmailsParentMutationFn = Apollo.MutationFunction<
  VerifyEmailsParentMutation,
  VerifyEmailsParentMutationVariables
>;

/**
 * __useVerifyEmailsParentMutation__
 *
 * To run a mutation, you first call `useVerifyEmailsParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailsParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailsParentMutation, { data, loading, error }] = useVerifyEmailsParentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailsParentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailsParentMutation,
    VerifyEmailsParentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyEmailsParentMutation,
    VerifyEmailsParentMutationVariables
  >(VerifyEmailsParentDocument, options);
}
export type VerifyEmailsParentMutationHookResult = ReturnType<
  typeof useVerifyEmailsParentMutation
>;
export type VerifyEmailsParentMutationResult =
  Apollo.MutationResult<VerifyEmailsParentMutation>;
export type VerifyEmailsParentMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailsParentMutation,
  VerifyEmailsParentMutationVariables
>;
export const InviteParentDocument = gql`
  mutation inviteParent($input: inviteParentInput!) {
    inviteParent(input: $input) {
      parent {
        id
      }
    }
  }
`;
export type InviteParentMutationFn = Apollo.MutationFunction<
  InviteParentMutation,
  InviteParentMutationVariables
>;

/**
 * __useInviteParentMutation__
 *
 * To run a mutation, you first call `useInviteParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteParentMutation, { data, loading, error }] = useInviteParentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteParentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteParentMutation,
    InviteParentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteParentMutation,
    InviteParentMutationVariables
  >(InviteParentDocument, options);
}
export type InviteParentMutationHookResult = ReturnType<
  typeof useInviteParentMutation
>;
export type InviteParentMutationResult =
  Apollo.MutationResult<InviteParentMutation>;
export type InviteParentMutationOptions = Apollo.BaseMutationOptions<
  InviteParentMutation,
  InviteParentMutationVariables
>;
export const GetAssignmentsDocument = gql`
  query getAssignments($filter: StudentAssignmentsFilter, $schoolId: String) {
    studentStudentAssignments(filter: $filter, schoolId: $schoolId) {
      collection {
        answers
        assignmentId
        assessmentId
        randomizeQuestionsOrder
        startDate
        endDate
        lateTurnIn
        id
        learnosityReferenceId
        otherAssignmentScope
        name
        shareResults
        ownerId
        returnedAt
        submittedAt
        resetted
        status
        studentNotes
        archived
        classId
        assignmentSource {
          element {
            elementId
            scopeId
            sectionId
            viewId
          }
        }
      }
    }
  }
`;

/**
 * __useGetAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssignmentsQuery,
    GetAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssignmentsQuery, GetAssignmentsQueryVariables>(
    GetAssignmentsDocument,
    options
  );
}
export function useGetAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignmentsQuery,
    GetAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssignmentsQuery, GetAssignmentsQueryVariables>(
    GetAssignmentsDocument,
    options
  );
}
export type GetAssignmentsQueryHookResult = ReturnType<
  typeof useGetAssignmentsQuery
>;
export type GetAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetAssignmentsLazyQuery
>;
export type GetAssignmentsQueryResult = Apollo.QueryResult<
  GetAssignmentsQuery,
  GetAssignmentsQueryVariables
>;
export const CheckAssignmentsOnExistenceDocument = gql`
  query checkAssignmentsOnExistence(
    $filter: StudentAssignmentsFilter
    $schoolId: String!
    $studentId: String
  ) {
    studentStudentAssignments(filter: $filter, schoolId: $schoolId) {
      collection {
        id
        archived
      }
    }
    districtStudentAssignments(studentId: $studentId, schoolId: $schoolId) {
      collection {
        assignmentId
      }
    }
  }
`;

/**
 * __useCheckAssignmentsOnExistenceQuery__
 *
 * To run a query within a React component, call `useCheckAssignmentsOnExistenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAssignmentsOnExistenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAssignmentsOnExistenceQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      schoolId: // value for 'schoolId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useCheckAssignmentsOnExistenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckAssignmentsOnExistenceQuery,
    CheckAssignmentsOnExistenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckAssignmentsOnExistenceQuery,
    CheckAssignmentsOnExistenceQueryVariables
  >(CheckAssignmentsOnExistenceDocument, options);
}
export function useCheckAssignmentsOnExistenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckAssignmentsOnExistenceQuery,
    CheckAssignmentsOnExistenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckAssignmentsOnExistenceQuery,
    CheckAssignmentsOnExistenceQueryVariables
  >(CheckAssignmentsOnExistenceDocument, options);
}
export type CheckAssignmentsOnExistenceQueryHookResult = ReturnType<
  typeof useCheckAssignmentsOnExistenceQuery
>;
export type CheckAssignmentsOnExistenceLazyQueryHookResult = ReturnType<
  typeof useCheckAssignmentsOnExistenceLazyQuery
>;
export type CheckAssignmentsOnExistenceQueryResult = Apollo.QueryResult<
  CheckAssignmentsOnExistenceQuery,
  CheckAssignmentsOnExistenceQueryVariables
>;
export const RestrictInvitationDocument = gql`
  mutation restrictInvitation($input: restrictInvitationParentInput!) {
    restrictInvitationParent(input: $input) {
      parent {
        id
      }
    }
  }
`;
export type RestrictInvitationMutationFn = Apollo.MutationFunction<
  RestrictInvitationMutation,
  RestrictInvitationMutationVariables
>;

/**
 * __useRestrictInvitationMutation__
 *
 * To run a mutation, you first call `useRestrictInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestrictInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restrictInvitationMutation, { data, loading, error }] = useRestrictInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestrictInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestrictInvitationMutation,
    RestrictInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestrictInvitationMutation,
    RestrictInvitationMutationVariables
  >(RestrictInvitationDocument, options);
}
export type RestrictInvitationMutationHookResult = ReturnType<
  typeof useRestrictInvitationMutation
>;
export type RestrictInvitationMutationResult =
  Apollo.MutationResult<RestrictInvitationMutation>;
export type RestrictInvitationMutationOptions = Apollo.BaseMutationOptions<
  RestrictInvitationMutation,
  RestrictInvitationMutationVariables
>;
export const CalendarTemplatesDocument = gql`
  query calendarTemplates(
    $schoolIds: [String]
    $userRoleCode: String!
    $itemsPerPage: Int = 9999
  ) {
    calendarTemplates(
      calendarType: Template
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      itemsPerPage: $itemsPerPage
    ) {
      collection {
        _id
        id
        name
        creator
        calendarEntries {
          collection {
            id
            _id
            eventId
            name
            type
            startDate
            endDate
            editable
          }
        }
      }
    }
  }
`;

/**
 * __useCalendarTemplatesQuery__
 *
 * To run a query within a React component, call `useCalendarTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarTemplatesQuery({
 *   variables: {
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useCalendarTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarTemplatesQuery,
    CalendarTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalendarTemplatesQuery,
    CalendarTemplatesQueryVariables
  >(CalendarTemplatesDocument, options);
}
export function useCalendarTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarTemplatesQuery,
    CalendarTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarTemplatesQuery,
    CalendarTemplatesQueryVariables
  >(CalendarTemplatesDocument, options);
}
export type CalendarTemplatesQueryHookResult = ReturnType<
  typeof useCalendarTemplatesQuery
>;
export type CalendarTemplatesLazyQueryHookResult = ReturnType<
  typeof useCalendarTemplatesLazyQuery
>;
export type CalendarTemplatesQueryResult = Apollo.QueryResult<
  CalendarTemplatesQuery,
  CalendarTemplatesQueryVariables
>;
export const GetParentStudentsDocument = gql`
  query getParentStudents {
    parentStudents {
      student {
        _id
        firstName
        lastName
        gradeLevel
        classes {
          collection {
            _id
            gradeOrCourse
            schoolId
            school {
              id
              localName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetParentStudentsQuery__
 *
 * To run a query within a React component, call `useGetParentStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentStudentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParentStudentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetParentStudentsQuery,
    GetParentStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParentStudentsQuery,
    GetParentStudentsQueryVariables
  >(GetParentStudentsDocument, options);
}
export function useGetParentStudentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentStudentsQuery,
    GetParentStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentStudentsQuery,
    GetParentStudentsQueryVariables
  >(GetParentStudentsDocument, options);
}
export type GetParentStudentsQueryHookResult = ReturnType<
  typeof useGetParentStudentsQuery
>;
export type GetParentStudentsLazyQueryHookResult = ReturnType<
  typeof useGetParentStudentsLazyQuery
>;
export type GetParentStudentsQueryResult = Apollo.QueryResult<
  GetParentStudentsQuery,
  GetParentStudentsQueryVariables
>;
export const CalendarUsersDocument = gql`
  query calendarUsers(
    $calendarId: String
    $accessType: String
    $schoolIds: [String]
    $userRoleCode: String!
  ) {
    userCalendars(
      calendarId: $calendarId
      accessType: $accessType
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
    ) {
      collection {
        userId
      }
    }
  }
`;

/**
 * __useCalendarUsersQuery__
 *
 * To run a query within a React component, call `useCalendarUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarUsersQuery({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *      accessType: // value for 'accessType'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *   },
 * });
 */
export function useCalendarUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarUsersQuery,
    CalendarUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarUsersQuery, CalendarUsersQueryVariables>(
    CalendarUsersDocument,
    options
  );
}
export function useCalendarUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarUsersQuery,
    CalendarUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalendarUsersQuery, CalendarUsersQueryVariables>(
    CalendarUsersDocument,
    options
  );
}
export type CalendarUsersQueryHookResult = ReturnType<
  typeof useCalendarUsersQuery
>;
export type CalendarUsersLazyQueryHookResult = ReturnType<
  typeof useCalendarUsersLazyQuery
>;
export type CalendarUsersQueryResult = Apollo.QueryResult<
  CalendarUsersQuery,
  CalendarUsersQueryVariables
>;
export const CalendarsDocument = gql`
  query calendars(
    $calendarId: [String]
    $startDate: String
    $endDate: String
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    calendars(
      calendarId: $calendarId
      startDate: $startDate
      endDate: $endDate
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        _id
        pk
        name
        isDefault
        calendarTemplateId
        version
        editable
        calendarType
        userId
        calendarEntries {
          collection {
            id
            _id
            eventId
            eventTemplateId
            name
            type
            startDate
            endDate
            notes
            editable
            version
          }
        }
      }
    }
  }
`;

/**
 * __useCalendarsQuery__
 *
 * To run a query within a React component, call `useCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarsQuery({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useCalendarsQuery(
  baseOptions: Apollo.QueryHookOptions<CalendarsQuery, CalendarsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarsQuery, CalendarsQueryVariables>(
    CalendarsDocument,
    options
  );
}
export function useCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarsQuery,
    CalendarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalendarsQuery, CalendarsQueryVariables>(
    CalendarsDocument,
    options
  );
}
export type CalendarsQueryHookResult = ReturnType<typeof useCalendarsQuery>;
export type CalendarsLazyQueryHookResult = ReturnType<
  typeof useCalendarsLazyQuery
>;
export type CalendarsQueryResult = Apollo.QueryResult<
  CalendarsQuery,
  CalendarsQueryVariables
>;
export const CreateEntryDocument = gql`
  mutation createEntry($input: createEntryInput!) {
    createEntry(input: $input) {
      entry {
        _id
      }
    }
  }
`;
export type CreateEntryMutationFn = Apollo.MutationFunction<
  CreateEntryMutation,
  CreateEntryMutationVariables
>;

/**
 * __useCreateEntryMutation__
 *
 * To run a mutation, you first call `useCreateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryMutation, { data, loading, error }] = useCreateEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEntryMutation,
    CreateEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEntryMutation, CreateEntryMutationVariables>(
    CreateEntryDocument,
    options
  );
}
export type CreateEntryMutationHookResult = ReturnType<
  typeof useCreateEntryMutation
>;
export type CreateEntryMutationResult =
  Apollo.MutationResult<CreateEntryMutation>;
export type CreateEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateEntryMutation,
  CreateEntryMutationVariables
>;
export const CreateCalendarDocument = gql`
  mutation createCalendar($input: createCalendarInput!) {
    createCalendar(input: $input) {
      calendar {
        _id
        name
        pk
      }
    }
  }
`;
export type CreateCalendarMutationFn = Apollo.MutationFunction<
  CreateCalendarMutation,
  CreateCalendarMutationVariables
>;

/**
 * __useCreateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarMutation, { data, loading, error }] = useCreateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarMutation,
    CreateCalendarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarMutation,
    CreateCalendarMutationVariables
  >(CreateCalendarDocument, options);
}
export type CreateCalendarMutationHookResult = ReturnType<
  typeof useCreateCalendarMutation
>;
export type CreateCalendarMutationResult =
  Apollo.MutationResult<CreateCalendarMutation>;
export type CreateCalendarMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarMutation,
  CreateCalendarMutationVariables
>;
export const CreatePacingGuideDocument = gql`
  mutation createPacingGuide($input: createPacingGuideInput!) {
    createPacingGuide(input: $input) {
      pacingGuide {
        _id
        id
        name
        version
        pacingGuideId
        selectedCalendar
        curriculumArea
        grade
        scopeEditable
        pacingGuideType
      }
    }
  }
`;
export type CreatePacingGuideMutationFn = Apollo.MutationFunction<
  CreatePacingGuideMutation,
  CreatePacingGuideMutationVariables
>;

/**
 * __useCreatePacingGuideMutation__
 *
 * To run a mutation, you first call `useCreatePacingGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePacingGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPacingGuideMutation, { data, loading, error }] = useCreatePacingGuideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePacingGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePacingGuideMutation,
    CreatePacingGuideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePacingGuideMutation,
    CreatePacingGuideMutationVariables
  >(CreatePacingGuideDocument, options);
}
export type CreatePacingGuideMutationHookResult = ReturnType<
  typeof useCreatePacingGuideMutation
>;
export type CreatePacingGuideMutationResult =
  Apollo.MutationResult<CreatePacingGuideMutation>;
export type CreatePacingGuideMutationOptions = Apollo.BaseMutationOptions<
  CreatePacingGuideMutation,
  CreatePacingGuideMutationVariables
>;
export const CalendarTemplateDocument = gql`
  query calendarTemplate($id: ID!) {
    calendarTemplate(id: $id) {
      _id
      id
      name
      version
      creator
      calendarEntries {
        collection {
          id
          _id
          eventId
          name
          type
          startDate
          endDate
          editable
        }
      }
    }
  }
`;

/**
 * __useCalendarTemplateQuery__
 *
 * To run a query within a React component, call `useCalendarTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarTemplateQuery,
    CalendarTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarTemplateQuery, CalendarTemplateQueryVariables>(
    CalendarTemplateDocument,
    options
  );
}
export function useCalendarTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarTemplateQuery,
    CalendarTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarTemplateQuery,
    CalendarTemplateQueryVariables
  >(CalendarTemplateDocument, options);
}
export type CalendarTemplateQueryHookResult = ReturnType<
  typeof useCalendarTemplateQuery
>;
export type CalendarTemplateLazyQueryHookResult = ReturnType<
  typeof useCalendarTemplateLazyQuery
>;
export type CalendarTemplateQueryResult = Apollo.QueryResult<
  CalendarTemplateQuery,
  CalendarTemplateQueryVariables
>;
export const GetCalendarDocument = gql`
  query getCalendar($id: ID!) {
    calendar(id: $id) {
      id
      _id
      pk
      sk
      name
      isDefault
      version
      editable
      calendarType
      userId
      creator
      calendarEntries {
        collection {
          id
          _id
          eventId
          name
          type
          startDate
          endDate
          notes
          version
        }
      }
    }
  }
`;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a React component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarQuery,
    GetCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options
  );
}
export function useGetCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarQuery,
    GetCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options
  );
}
export type GetCalendarQueryHookResult = ReturnType<typeof useGetCalendarQuery>;
export type GetCalendarLazyQueryHookResult = ReturnType<
  typeof useGetCalendarLazyQuery
>;
export type GetCalendarQueryResult = Apollo.QueryResult<
  GetCalendarQuery,
  GetCalendarQueryVariables
>;
export const ClearTemplateUpdatedNotificationDocument = gql`
  mutation clearTemplateUpdatedNotification(
    $notificationCalendar: Iterable
    $notificationPacingGuide: Iterable
    $version: Int!
    $schoolIds: Iterable
    $userRoleCode: String
    $userTeamIds: Iterable
  ) {
    updateUserSelection(
      input: {
        notificationCalendar: $notificationCalendar
        notificationPacingGuide: $notificationPacingGuide
        version: $version
        schoolIds: $schoolIds
        userRoleCode: $userRoleCode
        userTeamIds: $userTeamIds
      }
    ) {
      userSelection {
        id
      }
    }
  }
`;
export type ClearTemplateUpdatedNotificationMutationFn =
  Apollo.MutationFunction<
    ClearTemplateUpdatedNotificationMutation,
    ClearTemplateUpdatedNotificationMutationVariables
  >;

/**
 * __useClearTemplateUpdatedNotificationMutation__
 *
 * To run a mutation, you first call `useClearTemplateUpdatedNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearTemplateUpdatedNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearTemplateUpdatedNotificationMutation, { data, loading, error }] = useClearTemplateUpdatedNotificationMutation({
 *   variables: {
 *      notificationCalendar: // value for 'notificationCalendar'
 *      notificationPacingGuide: // value for 'notificationPacingGuide'
 *      version: // value for 'version'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useClearTemplateUpdatedNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearTemplateUpdatedNotificationMutation,
    ClearTemplateUpdatedNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearTemplateUpdatedNotificationMutation,
    ClearTemplateUpdatedNotificationMutationVariables
  >(ClearTemplateUpdatedNotificationDocument, options);
}
export type ClearTemplateUpdatedNotificationMutationHookResult = ReturnType<
  typeof useClearTemplateUpdatedNotificationMutation
>;
export type ClearTemplateUpdatedNotificationMutationResult =
  Apollo.MutationResult<ClearTemplateUpdatedNotificationMutation>;
export type ClearTemplateUpdatedNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    ClearTemplateUpdatedNotificationMutation,
    ClearTemplateUpdatedNotificationMutationVariables
  >;
export const GetElementsFromPlanDocument = gql`
  query getElementsFromPlan(
    $scopeId: String
    $scopePlanId: String!
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    scopePlanScopes(
      scopeId: $scopeId
      scopePlanId: $scopePlanId
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        elements
        startDate
        endDate
        version
      }
    }
  }
`;

/**
 * __useGetElementsFromPlanQuery__
 *
 * To run a query within a React component, call `useGetElementsFromPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElementsFromPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElementsFromPlanQuery({
 *   variables: {
 *      scopeId: // value for 'scopeId'
 *      scopePlanId: // value for 'scopePlanId'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useGetElementsFromPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetElementsFromPlanQuery,
    GetElementsFromPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetElementsFromPlanQuery,
    GetElementsFromPlanQueryVariables
  >(GetElementsFromPlanDocument, options);
}
export function useGetElementsFromPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetElementsFromPlanQuery,
    GetElementsFromPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetElementsFromPlanQuery,
    GetElementsFromPlanQueryVariables
  >(GetElementsFromPlanDocument, options);
}
export type GetElementsFromPlanQueryHookResult = ReturnType<
  typeof useGetElementsFromPlanQuery
>;
export type GetElementsFromPlanLazyQueryHookResult = ReturnType<
  typeof useGetElementsFromPlanLazyQuery
>;
export type GetElementsFromPlanQueryResult = Apollo.QueryResult<
  GetElementsFromPlanQuery,
  GetElementsFromPlanQueryVariables
>;
export const GetPacingGuideDocument = gql`
  query getPacingGuide($id: ID!) {
    pacingGuide(id: $id) {
      _id
      id
      name
      pacingGuideId
      pacingGuideType
      grade
      userId
      curriculumArea
      selectedCalendar
      startDate
      version
      sharedType
      sharedOption
      sharedData
    }
  }
`;

/**
 * __useGetPacingGuideQuery__
 *
 * To run a query within a React component, call `useGetPacingGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPacingGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPacingGuideQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPacingGuideQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPacingGuideQuery,
    GetPacingGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPacingGuideQuery, GetPacingGuideQueryVariables>(
    GetPacingGuideDocument,
    options
  );
}
export function useGetPacingGuideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPacingGuideQuery,
    GetPacingGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPacingGuideQuery, GetPacingGuideQueryVariables>(
    GetPacingGuideDocument,
    options
  );
}
export type GetPacingGuideQueryHookResult = ReturnType<
  typeof useGetPacingGuideQuery
>;
export type GetPacingGuideLazyQueryHookResult = ReturnType<
  typeof useGetPacingGuideLazyQuery
>;
export type GetPacingGuideQueryResult = Apollo.QueryResult<
  GetPacingGuideQuery,
  GetPacingGuideQueryVariables
>;
export const GetPlanFromFieldsDocument = gql`
  query getPlanFromFields(
    $curriculumArea: String
    $grade: String
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    scopePlans(
      curriculumArea: $curriculumArea
      grade: $grade
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        name
        scopePlanId
        scopePlanType
      }
    }
  }
`;

/**
 * __useGetPlanFromFieldsQuery__
 *
 * To run a query within a React component, call `useGetPlanFromFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanFromFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanFromFieldsQuery({
 *   variables: {
 *      curriculumArea: // value for 'curriculumArea'
 *      grade: // value for 'grade'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useGetPlanFromFieldsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlanFromFieldsQuery,
    GetPlanFromFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlanFromFieldsQuery,
    GetPlanFromFieldsQueryVariables
  >(GetPlanFromFieldsDocument, options);
}
export function useGetPlanFromFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlanFromFieldsQuery,
    GetPlanFromFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlanFromFieldsQuery,
    GetPlanFromFieldsQueryVariables
  >(GetPlanFromFieldsDocument, options);
}
export type GetPlanFromFieldsQueryHookResult = ReturnType<
  typeof useGetPlanFromFieldsQuery
>;
export type GetPlanFromFieldsLazyQueryHookResult = ReturnType<
  typeof useGetPlanFromFieldsLazyQuery
>;
export type GetPlanFromFieldsQueryResult = Apollo.QueryResult<
  GetPlanFromFieldsQuery,
  GetPlanFromFieldsQueryVariables
>;
export const GetPlanTemplatesListDocument = gql`
  query getPlanTemplatesList(
    $curriculumAreas: Iterable
    $grades: Iterable
    $userRoleCode: String!
    $schoolIds: Iterable
    $userTeamIds: Iterable
  ) {
    scopePlanTemplates(
      curriculumAreas: $curriculumAreas
      grades: $grades
      userRoleCode: $userRoleCode
      schoolIds: $schoolIds
      userTeamIds: $userTeamIds
    ) {
      collection {
        _id
        id
        curriculumArea
        name
        grade
      }
    }
  }
`;

/**
 * __useGetPlanTemplatesListQuery__
 *
 * To run a query within a React component, call `useGetPlanTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanTemplatesListQuery({
 *   variables: {
 *      curriculumAreas: // value for 'curriculumAreas'
 *      grades: // value for 'grades'
 *      userRoleCode: // value for 'userRoleCode'
 *      schoolIds: // value for 'schoolIds'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useGetPlanTemplatesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlanTemplatesListQuery,
    GetPlanTemplatesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlanTemplatesListQuery,
    GetPlanTemplatesListQueryVariables
  >(GetPlanTemplatesListDocument, options);
}
export function useGetPlanTemplatesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlanTemplatesListQuery,
    GetPlanTemplatesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlanTemplatesListQuery,
    GetPlanTemplatesListQueryVariables
  >(GetPlanTemplatesListDocument, options);
}
export type GetPlanTemplatesListQueryHookResult = ReturnType<
  typeof useGetPlanTemplatesListQuery
>;
export type GetPlanTemplatesListLazyQueryHookResult = ReturnType<
  typeof useGetPlanTemplatesListLazyQuery
>;
export type GetPlanTemplatesListQueryResult = Apollo.QueryResult<
  GetPlanTemplatesListQuery,
  GetPlanTemplatesListQueryVariables
>;
export const GetGradesFromSchoolIdDocument = gql`
  query getGradesFromSchoolId($schoolId: String, $schoolIds: [String!]! = []) {
    grades(schoolId: $schoolId, schoolIds: $schoolIds) {
      grades
    }
  }
`;

/**
 * __useGetGradesFromSchoolIdQuery__
 *
 * To run a query within a React component, call `useGetGradesFromSchoolIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradesFromSchoolIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradesFromSchoolIdQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetGradesFromSchoolIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGradesFromSchoolIdQuery,
    GetGradesFromSchoolIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGradesFromSchoolIdQuery,
    GetGradesFromSchoolIdQueryVariables
  >(GetGradesFromSchoolIdDocument, options);
}
export function useGetGradesFromSchoolIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGradesFromSchoolIdQuery,
    GetGradesFromSchoolIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGradesFromSchoolIdQuery,
    GetGradesFromSchoolIdQueryVariables
  >(GetGradesFromSchoolIdDocument, options);
}
export type GetGradesFromSchoolIdQueryHookResult = ReturnType<
  typeof useGetGradesFromSchoolIdQuery
>;
export type GetGradesFromSchoolIdLazyQueryHookResult = ReturnType<
  typeof useGetGradesFromSchoolIdLazyQuery
>;
export type GetGradesFromSchoolIdQueryResult = Apollo.QueryResult<
  GetGradesFromSchoolIdQuery,
  GetGradesFromSchoolIdQueryVariables
>;
export const GetScopePlanDocument = gql`
  query getScopePlan($id: ID!) {
    scopePlan(id: $id) {
      _id
      id
      scopePlanId
      name
      scopePlanType
      curriculumArea
      grade
      userId
      pacingGuideId
      approachType
      classSize
      classTime
      teacherExp
      sharedData
      sharedOption
      scopes {
        collection {
          id
          _id
          elements
          startDate
          endDate
          instructionalDays
          order
          version
        }
      }
      version
    }
  }
`;

/**
 * __useGetScopePlanQuery__
 *
 * To run a query within a React component, call `useGetScopePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScopePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScopePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScopePlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScopePlanQuery,
    GetScopePlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScopePlanQuery, GetScopePlanQueryVariables>(
    GetScopePlanDocument,
    options
  );
}
export function useGetScopePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScopePlanQuery,
    GetScopePlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScopePlanQuery, GetScopePlanQueryVariables>(
    GetScopePlanDocument,
    options
  );
}
export type GetScopePlanQueryHookResult = ReturnType<
  typeof useGetScopePlanQuery
>;
export type GetScopePlanLazyQueryHookResult = ReturnType<
  typeof useGetScopePlanLazyQuery
>;
export type GetScopePlanQueryResult = Apollo.QueryResult<
  GetScopePlanQuery,
  GetScopePlanQueryVariables
>;
export const CreateScopePlanDocument = gql`
  mutation createScopePlan($input: createScopePlanInput!) {
    createScopePlan(input: $input) {
      scopePlan {
        id
        _id
        version
      }
    }
  }
`;
export type CreateScopePlanMutationFn = Apollo.MutationFunction<
  CreateScopePlanMutation,
  CreateScopePlanMutationVariables
>;

/**
 * __useCreateScopePlanMutation__
 *
 * To run a mutation, you first call `useCreateScopePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScopePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScopePlanMutation, { data, loading, error }] = useCreateScopePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScopePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScopePlanMutation,
    CreateScopePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScopePlanMutation,
    CreateScopePlanMutationVariables
  >(CreateScopePlanDocument, options);
}
export type CreateScopePlanMutationHookResult = ReturnType<
  typeof useCreateScopePlanMutation
>;
export type CreateScopePlanMutationResult =
  Apollo.MutationResult<CreateScopePlanMutation>;
export type CreateScopePlanMutationOptions = Apollo.BaseMutationOptions<
  CreateScopePlanMutation,
  CreateScopePlanMutationVariables
>;
export const GetScope2ForPlanDocument = gql`
  query getScope2ForPlan($id: String!, $schoolId: String) {
    baseScope(id: $id, schoolId: $schoolId) {
      id
      subscriptionContentGroups
      jsonUrl
      projectId
    }
  }
`;

/**
 * __useGetScope2ForPlanQuery__
 *
 * To run a query within a React component, call `useGetScope2ForPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScope2ForPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScope2ForPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetScope2ForPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScope2ForPlanQuery,
    GetScope2ForPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScope2ForPlanQuery, GetScope2ForPlanQueryVariables>(
    GetScope2ForPlanDocument,
    options
  );
}
export function useGetScope2ForPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScope2ForPlanQuery,
    GetScope2ForPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScope2ForPlanQuery,
    GetScope2ForPlanQueryVariables
  >(GetScope2ForPlanDocument, options);
}
export type GetScope2ForPlanQueryHookResult = ReturnType<
  typeof useGetScope2ForPlanQuery
>;
export type GetScope2ForPlanLazyQueryHookResult = ReturnType<
  typeof useGetScope2ForPlanLazyQuery
>;
export type GetScope2ForPlanQueryResult = Apollo.QueryResult<
  GetScope2ForPlanQuery,
  GetScope2ForPlanQueryVariables
>;
export const GetPlannerCurriculumAreasDocument = gql`
  query getPlannerCurriculumAreas(
    $schoolId: String
    $schoolIds: [String!]! = []
  ) {
    curriculumAreas(schoolId: $schoolId, schoolIds: $schoolIds) {
      curriculumAreas
    }
  }
`;

/**
 * __useGetPlannerCurriculumAreasQuery__
 *
 * To run a query within a React component, call `useGetPlannerCurriculumAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlannerCurriculumAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlannerCurriculumAreasQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetPlannerCurriculumAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlannerCurriculumAreasQuery,
    GetPlannerCurriculumAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlannerCurriculumAreasQuery,
    GetPlannerCurriculumAreasQueryVariables
  >(GetPlannerCurriculumAreasDocument, options);
}
export function useGetPlannerCurriculumAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlannerCurriculumAreasQuery,
    GetPlannerCurriculumAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlannerCurriculumAreasQuery,
    GetPlannerCurriculumAreasQueryVariables
  >(GetPlannerCurriculumAreasDocument, options);
}
export type GetPlannerCurriculumAreasQueryHookResult = ReturnType<
  typeof useGetPlannerCurriculumAreasQuery
>;
export type GetPlannerCurriculumAreasLazyQueryHookResult = ReturnType<
  typeof useGetPlannerCurriculumAreasLazyQuery
>;
export type GetPlannerCurriculumAreasQueryResult = Apollo.QueryResult<
  GetPlannerCurriculumAreasQuery,
  GetPlannerCurriculumAreasQueryVariables
>;
export const GetScopesForPlanDocument = gql`
  query getScopesForPlan($filter: ScopeFilter, $schoolId: String!) {
    scopes(filter: $filter, schoolId: $schoolId) {
      collection {
        id
        displayName
      }
    }
  }
`;

/**
 * __useGetScopesForPlanQuery__
 *
 * To run a query within a React component, call `useGetScopesForPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScopesForPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScopesForPlanQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetScopesForPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScopesForPlanQuery,
    GetScopesForPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScopesForPlanQuery, GetScopesForPlanQueryVariables>(
    GetScopesForPlanDocument,
    options
  );
}
export function useGetScopesForPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScopesForPlanQuery,
    GetScopesForPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScopesForPlanQuery,
    GetScopesForPlanQueryVariables
  >(GetScopesForPlanDocument, options);
}
export type GetScopesForPlanQueryHookResult = ReturnType<
  typeof useGetScopesForPlanQuery
>;
export type GetScopesForPlanLazyQueryHookResult = ReturnType<
  typeof useGetScopesForPlanLazyQuery
>;
export type GetScopesForPlanQueryResult = Apollo.QueryResult<
  GetScopesForPlanQuery,
  GetScopesForPlanQueryVariables
>;
export const GetUniqueTemplateByNameDocument = gql`
  query getUniqueTemplateByName(
    $name: String
    $schoolIds: [String]
    $userRoleCode: String!
  ) {
    userCalendars(
      calendarType: Template
      name: $name
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
    ) {
      collection {
        _id
      }
    }
  }
`;

/**
 * __useGetUniqueTemplateByNameQuery__
 *
 * To run a query within a React component, call `useGetUniqueTemplateByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueTemplateByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueTemplateByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *   },
 * });
 */
export function useGetUniqueTemplateByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUniqueTemplateByNameQuery,
    GetUniqueTemplateByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUniqueTemplateByNameQuery,
    GetUniqueTemplateByNameQueryVariables
  >(GetUniqueTemplateByNameDocument, options);
}
export function useGetUniqueTemplateByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUniqueTemplateByNameQuery,
    GetUniqueTemplateByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUniqueTemplateByNameQuery,
    GetUniqueTemplateByNameQueryVariables
  >(GetUniqueTemplateByNameDocument, options);
}
export type GetUniqueTemplateByNameQueryHookResult = ReturnType<
  typeof useGetUniqueTemplateByNameQuery
>;
export type GetUniqueTemplateByNameLazyQueryHookResult = ReturnType<
  typeof useGetUniqueTemplateByNameLazyQuery
>;
export type GetUniqueTemplateByNameQueryResult = Apollo.QueryResult<
  GetUniqueTemplateByNameQuery,
  GetUniqueTemplateByNameQueryVariables
>;
export const GetTemplateUpdatedNotificationsDocument = gql`
  query getTemplateUpdatedNotifications($id: ID!) {
    userSelection(id: $id) {
      id
      _id
      notificationCalendar
      notificationPacingGuide
      version
    }
  }
`;

/**
 * __useGetTemplateUpdatedNotificationsQuery__
 *
 * To run a query within a React component, call `useGetTemplateUpdatedNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateUpdatedNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateUpdatedNotificationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateUpdatedNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTemplateUpdatedNotificationsQuery,
    GetTemplateUpdatedNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTemplateUpdatedNotificationsQuery,
    GetTemplateUpdatedNotificationsQueryVariables
  >(GetTemplateUpdatedNotificationsDocument, options);
}
export function useGetTemplateUpdatedNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTemplateUpdatedNotificationsQuery,
    GetTemplateUpdatedNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTemplateUpdatedNotificationsQuery,
    GetTemplateUpdatedNotificationsQueryVariables
  >(GetTemplateUpdatedNotificationsDocument, options);
}
export type GetTemplateUpdatedNotificationsQueryHookResult = ReturnType<
  typeof useGetTemplateUpdatedNotificationsQuery
>;
export type GetTemplateUpdatedNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetTemplateUpdatedNotificationsLazyQuery
>;
export type GetTemplateUpdatedNotificationsQueryResult = Apollo.QueryResult<
  GetTemplateUpdatedNotificationsQuery,
  GetTemplateUpdatedNotificationsQueryVariables
>;
export const GetSuggestedRequiredPacingGuidesDocument = gql`
  query getSuggestedRequiredPacingGuides(
    $curriculumAreas: Iterable
    $grades: Iterable
    $userRoleCode: String!
    $schoolIds: Iterable
    $userTeamIds: Iterable
  ) {
    pacingGuideTemplates(
      curriculumAreas: $curriculumAreas
      grades: $grades
      userRoleCode: $userRoleCode
      schoolIds: $schoolIds
      userTeamIds: $userTeamIds
    ) {
      collection {
        id
        _id
        pacingGuideId
        pacingGuideType
        name
        curriculumArea
        grade
        selectedCalendar
        startDate
        userId
        editable
        scopeEditable
        userRoleCode
        sharedType
        sharedData
        sharedOption
        schoolIds
        creator
        version
        pacingGuideTemplateId
        scopes {
          collection {
            id
            _id
            pacingGuideId
            pacingGuideType
            scopeId
            name
            instructionalDays
            order
            type
            version
          }
        }
      }
    }
  }
`;

/**
 * __useGetSuggestedRequiredPacingGuidesQuery__
 *
 * To run a query within a React component, call `useGetSuggestedRequiredPacingGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedRequiredPacingGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedRequiredPacingGuidesQuery({
 *   variables: {
 *      curriculumAreas: // value for 'curriculumAreas'
 *      grades: // value for 'grades'
 *      userRoleCode: // value for 'userRoleCode'
 *      schoolIds: // value for 'schoolIds'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useGetSuggestedRequiredPacingGuidesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSuggestedRequiredPacingGuidesQuery,
    GetSuggestedRequiredPacingGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSuggestedRequiredPacingGuidesQuery,
    GetSuggestedRequiredPacingGuidesQueryVariables
  >(GetSuggestedRequiredPacingGuidesDocument, options);
}
export function useGetSuggestedRequiredPacingGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSuggestedRequiredPacingGuidesQuery,
    GetSuggestedRequiredPacingGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSuggestedRequiredPacingGuidesQuery,
    GetSuggestedRequiredPacingGuidesQueryVariables
  >(GetSuggestedRequiredPacingGuidesDocument, options);
}
export type GetSuggestedRequiredPacingGuidesQueryHookResult = ReturnType<
  typeof useGetSuggestedRequiredPacingGuidesQuery
>;
export type GetSuggestedRequiredPacingGuidesLazyQueryHookResult = ReturnType<
  typeof useGetSuggestedRequiredPacingGuidesLazyQuery
>;
export type GetSuggestedRequiredPacingGuidesQueryResult = Apollo.QueryResult<
  GetSuggestedRequiredPacingGuidesQuery,
  GetSuggestedRequiredPacingGuidesQueryVariables
>;
export const PlannerTeamsDocument = gql`
  query plannerTeams($itemsPerPage: Int!, $page: Int!) {
    teams(
      itemsPerPage: $itemsPerPage
      page: $page
      filter: { archived: false }
    ) {
      collection {
        id
        name
        archived
      }
    }
  }
`;

/**
 * __usePlannerTeamsQuery__
 *
 * To run a query within a React component, call `usePlannerTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannerTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannerTeamsQuery({
 *   variables: {
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePlannerTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlannerTeamsQuery,
    PlannerTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlannerTeamsQuery, PlannerTeamsQueryVariables>(
    PlannerTeamsDocument,
    options
  );
}
export function usePlannerTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlannerTeamsQuery,
    PlannerTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlannerTeamsQuery, PlannerTeamsQueryVariables>(
    PlannerTeamsDocument,
    options
  );
}
export type PlannerTeamsQueryHookResult = ReturnType<
  typeof usePlannerTeamsQuery
>;
export type PlannerTeamsLazyQueryHookResult = ReturnType<
  typeof usePlannerTeamsLazyQuery
>;
export type PlannerTeamsQueryResult = Apollo.QueryResult<
  PlannerTeamsQuery,
  PlannerTeamsQueryVariables
>;
export const PacingGuidesDocument = gql`
  query pacingGuides(
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    pacingGuides(
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        id
        _id
        name
        pacingGuideId
        pacingGuideType
        curriculumArea
        grade
        editable
        isSelected
        creator
        schoolIds
        userId
        scopeEditable
        scopes {
          collection {
            _id
            id
            scopeId
            name
            instructionalDays
            version
          }
        }
        version
      }
    }
  }
`;

/**
 * __usePacingGuidesQuery__
 *
 * To run a query within a React component, call `usePacingGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePacingGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePacingGuidesQuery({
 *   variables: {
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function usePacingGuidesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PacingGuidesQuery,
    PacingGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PacingGuidesQuery, PacingGuidesQueryVariables>(
    PacingGuidesDocument,
    options
  );
}
export function usePacingGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PacingGuidesQuery,
    PacingGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PacingGuidesQuery, PacingGuidesQueryVariables>(
    PacingGuidesDocument,
    options
  );
}
export type PacingGuidesQueryHookResult = ReturnType<
  typeof usePacingGuidesQuery
>;
export type PacingGuidesLazyQueryHookResult = ReturnType<
  typeof usePacingGuidesLazyQuery
>;
export type PacingGuidesQueryResult = Apollo.QueryResult<
  PacingGuidesQuery,
  PacingGuidesQueryVariables
>;
export const RemoveCalendarDocument = gql`
  mutation removeCalendar($input: removeCalendarInput!) {
    removeCalendar(input: $input) {
      calendar {
        id
      }
    }
  }
`;
export type RemoveCalendarMutationFn = Apollo.MutationFunction<
  RemoveCalendarMutation,
  RemoveCalendarMutationVariables
>;

/**
 * __useRemoveCalendarMutation__
 *
 * To run a mutation, you first call `useRemoveCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCalendarMutation, { data, loading, error }] = useRemoveCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCalendarMutation,
    RemoveCalendarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCalendarMutation,
    RemoveCalendarMutationVariables
  >(RemoveCalendarDocument, options);
}
export type RemoveCalendarMutationHookResult = ReturnType<
  typeof useRemoveCalendarMutation
>;
export type RemoveCalendarMutationResult =
  Apollo.MutationResult<RemoveCalendarMutation>;
export type RemoveCalendarMutationOptions = Apollo.BaseMutationOptions<
  RemoveCalendarMutation,
  RemoveCalendarMutationVariables
>;
export const RemoveEntryDocument = gql`
  mutation removeEntry($input: removeEntryInput!) {
    removeEntry(input: $input) {
      entry {
        _id
      }
    }
  }
`;
export type RemoveEntryMutationFn = Apollo.MutationFunction<
  RemoveEntryMutation,
  RemoveEntryMutationVariables
>;

/**
 * __useRemoveEntryMutation__
 *
 * To run a mutation, you first call `useRemoveEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEntryMutation, { data, loading, error }] = useRemoveEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEntryMutation,
    RemoveEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveEntryMutation, RemoveEntryMutationVariables>(
    RemoveEntryDocument,
    options
  );
}
export type RemoveEntryMutationHookResult = ReturnType<
  typeof useRemoveEntryMutation
>;
export type RemoveEntryMutationResult =
  Apollo.MutationResult<RemoveEntryMutation>;
export type RemoveEntryMutationOptions = Apollo.BaseMutationOptions<
  RemoveEntryMutation,
  RemoveEntryMutationVariables
>;
export const InitiateUserSelectionDocument = gql`
  mutation initiateUserSelection($input: initiateUserSelectionInput!) {
    initiateUserSelection(input: $input) {
      userSelection {
        calendarSettingsPopup
      }
    }
  }
`;
export type InitiateUserSelectionMutationFn = Apollo.MutationFunction<
  InitiateUserSelectionMutation,
  InitiateUserSelectionMutationVariables
>;

/**
 * __useInitiateUserSelectionMutation__
 *
 * To run a mutation, you first call `useInitiateUserSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateUserSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateUserSelectionMutation, { data, loading, error }] = useInitiateUserSelectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateUserSelectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateUserSelectionMutation,
    InitiateUserSelectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateUserSelectionMutation,
    InitiateUserSelectionMutationVariables
  >(InitiateUserSelectionDocument, options);
}
export type InitiateUserSelectionMutationHookResult = ReturnType<
  typeof useInitiateUserSelectionMutation
>;
export type InitiateUserSelectionMutationResult =
  Apollo.MutationResult<InitiateUserSelectionMutation>;
export type InitiateUserSelectionMutationOptions = Apollo.BaseMutationOptions<
  InitiateUserSelectionMutation,
  InitiateUserSelectionMutationVariables
>;
export const RemovePacingGuideDocument = gql`
  mutation removePacingGuide($input: removePacingGuideInput!) {
    removePacingGuide(input: $input) {
      pacingGuide {
        name
      }
    }
  }
`;
export type RemovePacingGuideMutationFn = Apollo.MutationFunction<
  RemovePacingGuideMutation,
  RemovePacingGuideMutationVariables
>;

/**
 * __useRemovePacingGuideMutation__
 *
 * To run a mutation, you first call `useRemovePacingGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePacingGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePacingGuideMutation, { data, loading, error }] = useRemovePacingGuideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePacingGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePacingGuideMutation,
    RemovePacingGuideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePacingGuideMutation,
    RemovePacingGuideMutationVariables
  >(RemovePacingGuideDocument, options);
}
export type RemovePacingGuideMutationHookResult = ReturnType<
  typeof useRemovePacingGuideMutation
>;
export type RemovePacingGuideMutationResult =
  Apollo.MutationResult<RemovePacingGuideMutation>;
export type RemovePacingGuideMutationOptions = Apollo.BaseMutationOptions<
  RemovePacingGuideMutation,
  RemovePacingGuideMutationVariables
>;
export const RemoveScopePlanDocument = gql`
  mutation removeScopePlan($input: removeScopePlanInput!) {
    removeScopePlan(input: $input) {
      scopePlan {
        name
      }
    }
  }
`;
export type RemoveScopePlanMutationFn = Apollo.MutationFunction<
  RemoveScopePlanMutation,
  RemoveScopePlanMutationVariables
>;

/**
 * __useRemoveScopePlanMutation__
 *
 * To run a mutation, you first call `useRemoveScopePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScopePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScopePlanMutation, { data, loading, error }] = useRemoveScopePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveScopePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveScopePlanMutation,
    RemoveScopePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveScopePlanMutation,
    RemoveScopePlanMutationVariables
  >(RemoveScopePlanDocument, options);
}
export type RemoveScopePlanMutationHookResult = ReturnType<
  typeof useRemoveScopePlanMutation
>;
export type RemoveScopePlanMutationResult =
  Apollo.MutationResult<RemoveScopePlanMutation>;
export type RemoveScopePlanMutationOptions = Apollo.BaseMutationOptions<
  RemoveScopePlanMutation,
  RemoveScopePlanMutationVariables
>;
export const GetSchoolsToShareDocument = gql`
  query getSchoolsToShare($itemsPerPage: Int, $page: Int) {
    schools(itemsPerPage: $itemsPerPage, page: $page) {
      collection {
        _id
        globalName
      }
    }
  }
`;

/**
 * __useGetSchoolsToShareQuery__
 *
 * To run a query within a React component, call `useGetSchoolsToShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsToShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsToShareQuery({
 *   variables: {
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetSchoolsToShareQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolsToShareQuery,
    GetSchoolsToShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolsToShareQuery,
    GetSchoolsToShareQueryVariables
  >(GetSchoolsToShareDocument, options);
}
export function useGetSchoolsToShareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsToShareQuery,
    GetSchoolsToShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolsToShareQuery,
    GetSchoolsToShareQueryVariables
  >(GetSchoolsToShareDocument, options);
}
export type GetSchoolsToShareQueryHookResult = ReturnType<
  typeof useGetSchoolsToShareQuery
>;
export type GetSchoolsToShareLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsToShareLazyQuery
>;
export type GetSchoolsToShareQueryResult = Apollo.QueryResult<
  GetSchoolsToShareQuery,
  GetSchoolsToShareQueryVariables
>;
export const ScopePlansDocument = gql`
  query scopePlans(
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    scopePlans(
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        _id
        id
        name
        grade
        curriculumArea
        editable
        isSelected
        version
        scopePlanType
        approachType
        pacingGuideId
      }
    }
  }
`;

/**
 * __useScopePlansQuery__
 *
 * To run a query within a React component, call `useScopePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useScopePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScopePlansQuery({
 *   variables: {
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useScopePlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScopePlansQuery,
    ScopePlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScopePlansQuery, ScopePlansQueryVariables>(
    ScopePlansDocument,
    options
  );
}
export function useScopePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScopePlansQuery,
    ScopePlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScopePlansQuery, ScopePlansQueryVariables>(
    ScopePlansDocument,
    options
  );
}
export type ScopePlansQueryHookResult = ReturnType<typeof useScopePlansQuery>;
export type ScopePlansLazyQueryHookResult = ReturnType<
  typeof useScopePlansLazyQuery
>;
export type ScopePlansQueryResult = Apollo.QueryResult<
  ScopePlansQuery,
  ScopePlansQueryVariables
>;
export const ScopesPacingJoinedDocument = gql`
  query scopesPacingJoined(
    $filter: ScopeFilter
    $perPage: Int
    $id: ID!
    $schoolId: String!
  ) {
    scopes(filter: $filter, perPage: $perPage, schoolId: $schoolId) {
      collection {
        id
        displayName
        editionName
        image
        metadata
      }
    }
    pacingGuide(id: $id) {
      scopes {
        collection {
          _id
          id
          scopeId
          name
          instructionalDays
          order
          version
        }
      }
      version
    }
  }
`;

/**
 * __useScopesPacingJoinedQuery__
 *
 * To run a query within a React component, call `useScopesPacingJoinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useScopesPacingJoinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScopesPacingJoinedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      perPage: // value for 'perPage'
 *      id: // value for 'id'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useScopesPacingJoinedQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScopesPacingJoinedQuery,
    ScopesPacingJoinedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScopesPacingJoinedQuery,
    ScopesPacingJoinedQueryVariables
  >(ScopesPacingJoinedDocument, options);
}
export function useScopesPacingJoinedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScopesPacingJoinedQuery,
    ScopesPacingJoinedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScopesPacingJoinedQuery,
    ScopesPacingJoinedQueryVariables
  >(ScopesPacingJoinedDocument, options);
}
export type ScopesPacingJoinedQueryHookResult = ReturnType<
  typeof useScopesPacingJoinedQuery
>;
export type ScopesPacingJoinedLazyQueryHookResult = ReturnType<
  typeof useScopesPacingJoinedLazyQuery
>;
export type ScopesPacingJoinedQueryResult = Apollo.QueryResult<
  ScopesPacingJoinedQuery,
  ScopesPacingJoinedQueryVariables
>;
export const PacingGuideTemplatesDocument = gql`
  query pacingGuideTemplates(
    $name: String!
    $schoolIds: Iterable!
    $userRoleCode: String!
  ) {
    pacingGuideTemplates(
      name: $name
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
    ) {
      collection {
        _id
      }
    }
  }
`;

/**
 * __usePacingGuideTemplatesQuery__
 *
 * To run a query within a React component, call `usePacingGuideTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePacingGuideTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePacingGuideTemplatesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *   },
 * });
 */
export function usePacingGuideTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PacingGuideTemplatesQuery,
    PacingGuideTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PacingGuideTemplatesQuery,
    PacingGuideTemplatesQueryVariables
  >(PacingGuideTemplatesDocument, options);
}
export function usePacingGuideTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PacingGuideTemplatesQuery,
    PacingGuideTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PacingGuideTemplatesQuery,
    PacingGuideTemplatesQueryVariables
  >(PacingGuideTemplatesDocument, options);
}
export type PacingGuideTemplatesQueryHookResult = ReturnType<
  typeof usePacingGuideTemplatesQuery
>;
export type PacingGuideTemplatesLazyQueryHookResult = ReturnType<
  typeof usePacingGuideTemplatesLazyQuery
>;
export type PacingGuideTemplatesQueryResult = Apollo.QueryResult<
  PacingGuideTemplatesQuery,
  PacingGuideTemplatesQueryVariables
>;
export const ShareCalendarDocument = gql`
  mutation shareCalendar($input: shareCalendarInput!) {
    shareCalendar(input: $input) {
      calendar {
        _id
      }
    }
  }
`;
export type ShareCalendarMutationFn = Apollo.MutationFunction<
  ShareCalendarMutation,
  ShareCalendarMutationVariables
>;

/**
 * __useShareCalendarMutation__
 *
 * To run a mutation, you first call `useShareCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareCalendarMutation, { data, loading, error }] = useShareCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareCalendarMutation,
    ShareCalendarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareCalendarMutation,
    ShareCalendarMutationVariables
  >(ShareCalendarDocument, options);
}
export type ShareCalendarMutationHookResult = ReturnType<
  typeof useShareCalendarMutation
>;
export type ShareCalendarMutationResult =
  Apollo.MutationResult<ShareCalendarMutation>;
export type ShareCalendarMutationOptions = Apollo.BaseMutationOptions<
  ShareCalendarMutation,
  ShareCalendarMutationVariables
>;
export const TeamsDocument = gql`
  query teams($itemsPerPage: Int!, $page: Int!, $filter: FilterTeamInput!) {
    teams(itemsPerPage: $itemsPerPage, page: $page, filter: $filter) {
      collection {
        id
        name
        archived
        members {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(
    TeamsDocument,
    options
  );
}
export function useTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(
    TeamsDocument,
    options
  );
}
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<
  TeamsQuery,
  TeamsQueryVariables
>;
export const ShareTemplatePacingGuideDocument = gql`
  mutation shareTemplatePacingGuide($input: shareTemplatePacingGuideInput!) {
    shareTemplatePacingGuide(input: $input) {
      pacingGuide {
        _id
      }
    }
  }
`;
export type ShareTemplatePacingGuideMutationFn = Apollo.MutationFunction<
  ShareTemplatePacingGuideMutation,
  ShareTemplatePacingGuideMutationVariables
>;

/**
 * __useShareTemplatePacingGuideMutation__
 *
 * To run a mutation, you first call `useShareTemplatePacingGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareTemplatePacingGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareTemplatePacingGuideMutation, { data, loading, error }] = useShareTemplatePacingGuideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareTemplatePacingGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareTemplatePacingGuideMutation,
    ShareTemplatePacingGuideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareTemplatePacingGuideMutation,
    ShareTemplatePacingGuideMutationVariables
  >(ShareTemplatePacingGuideDocument, options);
}
export type ShareTemplatePacingGuideMutationHookResult = ReturnType<
  typeof useShareTemplatePacingGuideMutation
>;
export type ShareTemplatePacingGuideMutationResult =
  Apollo.MutationResult<ShareTemplatePacingGuideMutation>;
export type ShareTemplatePacingGuideMutationOptions =
  Apollo.BaseMutationOptions<
    ShareTemplatePacingGuideMutation,
    ShareTemplatePacingGuideMutationVariables
  >;
export const ShareTemplateScopePlanDocument = gql`
  mutation shareTemplateScopePlan($input: shareTemplateScopePlanInput!) {
    shareTemplateScopePlan(input: $input) {
      scopePlan {
        _id
        id
      }
    }
  }
`;
export type ShareTemplateScopePlanMutationFn = Apollo.MutationFunction<
  ShareTemplateScopePlanMutation,
  ShareTemplateScopePlanMutationVariables
>;

/**
 * __useShareTemplateScopePlanMutation__
 *
 * To run a mutation, you first call `useShareTemplateScopePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareTemplateScopePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareTemplateScopePlanMutation, { data, loading, error }] = useShareTemplateScopePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareTemplateScopePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareTemplateScopePlanMutation,
    ShareTemplateScopePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareTemplateScopePlanMutation,
    ShareTemplateScopePlanMutationVariables
  >(ShareTemplateScopePlanDocument, options);
}
export type ShareTemplateScopePlanMutationHookResult = ReturnType<
  typeof useShareTemplateScopePlanMutation
>;
export type ShareTemplateScopePlanMutationResult =
  Apollo.MutationResult<ShareTemplateScopePlanMutation>;
export type ShareTemplateScopePlanMutationOptions = Apollo.BaseMutationOptions<
  ShareTemplateScopePlanMutation,
  ShareTemplateScopePlanMutationVariables
>;
export const UpdateCalendarDocument = gql`
  mutation updateCalendar($input: updateCalendarInput!) {
    updateCalendar(input: $input) {
      calendar {
        _id
        name
      }
    }
  }
`;
export type UpdateCalendarMutationFn = Apollo.MutationFunction<
  UpdateCalendarMutation,
  UpdateCalendarMutationVariables
>;

/**
 * __useUpdateCalendarMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarMutation, { data, loading, error }] = useUpdateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarMutation,
    UpdateCalendarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarMutation,
    UpdateCalendarMutationVariables
  >(UpdateCalendarDocument, options);
}
export type UpdateCalendarMutationHookResult = ReturnType<
  typeof useUpdateCalendarMutation
>;
export type UpdateCalendarMutationResult =
  Apollo.MutationResult<UpdateCalendarMutation>;
export type UpdateCalendarMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarMutation,
  UpdateCalendarMutationVariables
>;
export const UpdateEntryDocument = gql`
  mutation updateEntry($input: updateEntryInput!) {
    updateEntry(input: $input) {
      entry {
        _id
      }
    }
  }
`;
export type UpdateEntryMutationFn = Apollo.MutationFunction<
  UpdateEntryMutation,
  UpdateEntryMutationVariables
>;

/**
 * __useUpdateEntryMutation__
 *
 * To run a mutation, you first call `useUpdateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryMutation, { data, loading, error }] = useUpdateEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEntryMutation,
    UpdateEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEntryMutation, UpdateEntryMutationVariables>(
    UpdateEntryDocument,
    options
  );
}
export type UpdateEntryMutationHookResult = ReturnType<
  typeof useUpdateEntryMutation
>;
export type UpdateEntryMutationResult =
  Apollo.MutationResult<UpdateEntryMutation>;
export type UpdateEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateEntryMutation,
  UpdateEntryMutationVariables
>;
export const UpdatePacingGuideScopeDocument = gql`
  mutation updatePacingGuideScope($input: updatePacingGuideScopeInput!) {
    updatePacingGuideScope(input: $input) {
      pacingGuideScope {
        id
      }
    }
  }
`;
export type UpdatePacingGuideScopeMutationFn = Apollo.MutationFunction<
  UpdatePacingGuideScopeMutation,
  UpdatePacingGuideScopeMutationVariables
>;

/**
 * __useUpdatePacingGuideScopeMutation__
 *
 * To run a mutation, you first call `useUpdatePacingGuideScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePacingGuideScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePacingGuideScopeMutation, { data, loading, error }] = useUpdatePacingGuideScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePacingGuideScopeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePacingGuideScopeMutation,
    UpdatePacingGuideScopeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePacingGuideScopeMutation,
    UpdatePacingGuideScopeMutationVariables
  >(UpdatePacingGuideScopeDocument, options);
}
export type UpdatePacingGuideScopeMutationHookResult = ReturnType<
  typeof useUpdatePacingGuideScopeMutation
>;
export type UpdatePacingGuideScopeMutationResult =
  Apollo.MutationResult<UpdatePacingGuideScopeMutation>;
export type UpdatePacingGuideScopeMutationOptions = Apollo.BaseMutationOptions<
  UpdatePacingGuideScopeMutation,
  UpdatePacingGuideScopeMutationVariables
>;
export const SearchPlanTemplatesDocument = gql`
  query searchPlanTemplates(
    $name: String!
    $schoolIds: Iterable!
    $userRoleCode: String!
    $scopePlanType: ScopePlanTypeEnum!
  ) {
    scopePlanTemplates(
      name: $name
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      scopePlanType: $scopePlanType
    ) {
      collection {
        _id
      }
    }
  }
`;

/**
 * __useSearchPlanTemplatesQuery__
 *
 * To run a query within a React component, call `useSearchPlanTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlanTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlanTemplatesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      scopePlanType: // value for 'scopePlanType'
 *   },
 * });
 */
export function useSearchPlanTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPlanTemplatesQuery,
    SearchPlanTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchPlanTemplatesQuery,
    SearchPlanTemplatesQueryVariables
  >(SearchPlanTemplatesDocument, options);
}
export function useSearchPlanTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPlanTemplatesQuery,
    SearchPlanTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchPlanTemplatesQuery,
    SearchPlanTemplatesQueryVariables
  >(SearchPlanTemplatesDocument, options);
}
export type SearchPlanTemplatesQueryHookResult = ReturnType<
  typeof useSearchPlanTemplatesQuery
>;
export type SearchPlanTemplatesLazyQueryHookResult = ReturnType<
  typeof useSearchPlanTemplatesLazyQuery
>;
export type SearchPlanTemplatesQueryResult = Apollo.QueryResult<
  SearchPlanTemplatesQuery,
  SearchPlanTemplatesQueryVariables
>;
export const UpdateScopePlanScopeDocument = gql`
  mutation updateScopePlanScope($input: updateScopePlanScopeInput!) {
    updateScopePlanScope(input: $input) {
      scopePlanScope {
        _id
      }
    }
  }
`;
export type UpdateScopePlanScopeMutationFn = Apollo.MutationFunction<
  UpdateScopePlanScopeMutation,
  UpdateScopePlanScopeMutationVariables
>;

/**
 * __useUpdateScopePlanScopeMutation__
 *
 * To run a mutation, you first call `useUpdateScopePlanScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScopePlanScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScopePlanScopeMutation, { data, loading, error }] = useUpdateScopePlanScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScopePlanScopeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScopePlanScopeMutation,
    UpdateScopePlanScopeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScopePlanScopeMutation,
    UpdateScopePlanScopeMutationVariables
  >(UpdateScopePlanScopeDocument, options);
}
export type UpdateScopePlanScopeMutationHookResult = ReturnType<
  typeof useUpdateScopePlanScopeMutation
>;
export type UpdateScopePlanScopeMutationResult =
  Apollo.MutationResult<UpdateScopePlanScopeMutation>;
export type UpdateScopePlanScopeMutationOptions = Apollo.BaseMutationOptions<
  UpdateScopePlanScopeMutation,
  UpdateScopePlanScopeMutationVariables
>;
export const UpdateUserSelectionDocument = gql`
  mutation updateUserSelection($input: updateUserSelectionInput!) {
    updateUserSelection(input: $input) {
      userSelection {
        viewMode
        selectedCalendar
        selectedPacingGuide
        selectedScopePlan
      }
    }
  }
`;
export type UpdateUserSelectionMutationFn = Apollo.MutationFunction<
  UpdateUserSelectionMutation,
  UpdateUserSelectionMutationVariables
>;

/**
 * __useUpdateUserSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateUserSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSelectionMutation, { data, loading, error }] = useUpdateUserSelectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSelectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSelectionMutation,
    UpdateUserSelectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserSelectionMutation,
    UpdateUserSelectionMutationVariables
  >(UpdateUserSelectionDocument, options);
}
export type UpdateUserSelectionMutationHookResult = ReturnType<
  typeof useUpdateUserSelectionMutation
>;
export type UpdateUserSelectionMutationResult =
  Apollo.MutationResult<UpdateUserSelectionMutation>;
export type UpdateUserSelectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSelectionMutation,
  UpdateUserSelectionMutationVariables
>;
export const UpdateScopePlanDocument = gql`
  mutation updateScopePlan($input: updateScopePlanInput!) {
    updateScopePlan(input: $input) {
      scopePlan {
        _id
        id
        name
        scopePlanId
        scopes {
          collection {
            id
            _id
            elements
            scopePlanId
            startDate
            endDate
            instructionalDays
            order
            version
          }
        }
        version
      }
    }
  }
`;
export type UpdateScopePlanMutationFn = Apollo.MutationFunction<
  UpdateScopePlanMutation,
  UpdateScopePlanMutationVariables
>;

/**
 * __useUpdateScopePlanMutation__
 *
 * To run a mutation, you first call `useUpdateScopePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScopePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScopePlanMutation, { data, loading, error }] = useUpdateScopePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScopePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScopePlanMutation,
    UpdateScopePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScopePlanMutation,
    UpdateScopePlanMutationVariables
  >(UpdateScopePlanDocument, options);
}
export type UpdateScopePlanMutationHookResult = ReturnType<
  typeof useUpdateScopePlanMutation
>;
export type UpdateScopePlanMutationResult =
  Apollo.MutationResult<UpdateScopePlanMutation>;
export type UpdateScopePlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateScopePlanMutation,
  UpdateScopePlanMutationVariables
>;
export const UpdatePacingGuideDocument = gql`
  mutation updatePacingGuide($input: updatePacingGuideInput!) {
    updatePacingGuide(input: $input) {
      pacingGuide {
        _id
        id
        name
        pacingGuideId
        grade
        curriculumArea
        pacingGuideType
        version
      }
    }
  }
`;
export type UpdatePacingGuideMutationFn = Apollo.MutationFunction<
  UpdatePacingGuideMutation,
  UpdatePacingGuideMutationVariables
>;

/**
 * __useUpdatePacingGuideMutation__
 *
 * To run a mutation, you first call `useUpdatePacingGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePacingGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePacingGuideMutation, { data, loading, error }] = useUpdatePacingGuideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePacingGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePacingGuideMutation,
    UpdatePacingGuideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePacingGuideMutation,
    UpdatePacingGuideMutationVariables
  >(UpdatePacingGuideDocument, options);
}
export type UpdatePacingGuideMutationHookResult = ReturnType<
  typeof useUpdatePacingGuideMutation
>;
export type UpdatePacingGuideMutationResult =
  Apollo.MutationResult<UpdatePacingGuideMutation>;
export type UpdatePacingGuideMutationOptions = Apollo.BaseMutationOptions<
  UpdatePacingGuideMutation,
  UpdatePacingGuideMutationVariables
>;
export const UserCalendarsDocument = gql`
  query userCalendars(
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    userCalendars(
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        _id
        name
        calendarId
        calendarType
        isDefault
        isSelected
        editable
        userId
        creator
        userSelection {
          calendarSettingsPopup
          selectedCalendar
          viewMode
        }
      }
    }
  }
`;

/**
 * __useUserCalendarsQuery__
 *
 * To run a query within a React component, call `useUserCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCalendarsQuery({
 *   variables: {
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useUserCalendarsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserCalendarsQuery,
    UserCalendarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserCalendarsQuery, UserCalendarsQueryVariables>(
    UserCalendarsDocument,
    options
  );
}
export function useUserCalendarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCalendarsQuery,
    UserCalendarsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserCalendarsQuery, UserCalendarsQueryVariables>(
    UserCalendarsDocument,
    options
  );
}
export type UserCalendarsQueryHookResult = ReturnType<
  typeof useUserCalendarsQuery
>;
export type UserCalendarsLazyQueryHookResult = ReturnType<
  typeof useUserCalendarsLazyQuery
>;
export type UserCalendarsQueryResult = Apollo.QueryResult<
  UserCalendarsQuery,
  UserCalendarsQueryVariables
>;
export const GetDistrictSettingsDocument = gql`
  query getDistrictSettings(
    $withContentGroups: Boolean!
    $withWizardStatus: Boolean!
    $withUMSettings: Boolean!
    $withRosterSettings: Boolean!
    $withAISettings: Boolean!
    $withKnowledgeSettings: Boolean!
  ) {
    activeContentGroups @include(if: $withContentGroups) {
      id
    }
    districtSetupStatus @include(if: $withWizardStatus) {
      status
    }
    districtSettingsRoster @include(if: $withRosterSettings) {
      teacherCanAddAdditionalTeacher
      teacherCanCreateClasses
      teacherCanEditClassRoster
    }
    districtSettingsUserManagement @include(if: $withUMSettings) {
      teacherCanCreateStudents
      teacherCanEditStudentPassword
      teacherCanImpersonateStudents
    }
    districtSettingsLessonPlanner @include(if: $withAISettings) {
      teacherCanUseAiAssistedLessonPlan
    }
    districtSettingsKnowledgeBase @include(if: $withKnowledgeSettings) {
      teacherCanUsePerryBot
      studentCanUsePerryBot
    }
  }
`;

/**
 * __useGetDistrictSettingsQuery__
 *
 * To run a query within a React component, call `useGetDistrictSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictSettingsQuery({
 *   variables: {
 *      withContentGroups: // value for 'withContentGroups'
 *      withWizardStatus: // value for 'withWizardStatus'
 *      withUMSettings: // value for 'withUMSettings'
 *      withRosterSettings: // value for 'withRosterSettings'
 *      withAISettings: // value for 'withAISettings'
 *      withKnowledgeSettings: // value for 'withKnowledgeSettings'
 *   },
 * });
 */
export function useGetDistrictSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDistrictSettingsQuery,
    GetDistrictSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDistrictSettingsQuery,
    GetDistrictSettingsQueryVariables
  >(GetDistrictSettingsDocument, options);
}
export function useGetDistrictSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDistrictSettingsQuery,
    GetDistrictSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDistrictSettingsQuery,
    GetDistrictSettingsQueryVariables
  >(GetDistrictSettingsDocument, options);
}
export type GetDistrictSettingsQueryHookResult = ReturnType<
  typeof useGetDistrictSettingsQuery
>;
export type GetDistrictSettingsLazyQueryHookResult = ReturnType<
  typeof useGetDistrictSettingsLazyQuery
>;
export type GetDistrictSettingsQueryResult = Apollo.QueryResult<
  GetDistrictSettingsQuery,
  GetDistrictSettingsQueryVariables
>;
export const GetRecentlyViewedDocument = gql`
  query getRecentlyViewed($schoolId: String!) {
    recentlyViewed(schoolId: $schoolId) {
      displayedNames
      objectIds
      projectIds
    }
  }
`;

/**
 * __useGetRecentlyViewedQuery__
 *
 * To run a query within a React component, call `useGetRecentlyViewedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentlyViewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentlyViewedQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetRecentlyViewedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecentlyViewedQuery,
    GetRecentlyViewedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecentlyViewedQuery,
    GetRecentlyViewedQueryVariables
  >(GetRecentlyViewedDocument, options);
}
export function useGetRecentlyViewedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentlyViewedQuery,
    GetRecentlyViewedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecentlyViewedQuery,
    GetRecentlyViewedQueryVariables
  >(GetRecentlyViewedDocument, options);
}
export type GetRecentlyViewedQueryHookResult = ReturnType<
  typeof useGetRecentlyViewedQuery
>;
export type GetRecentlyViewedLazyQueryHookResult = ReturnType<
  typeof useGetRecentlyViewedLazyQuery
>;
export type GetRecentlyViewedQueryResult = Apollo.QueryResult<
  GetRecentlyViewedQuery,
  GetRecentlyViewedQueryVariables
>;
export const GetBaseDistrictAssignmentsDocument = gql`
  query getBaseDistrictAssignments {
    baseDistrictAssignments {
      collection {
        daId
        assignType
        districtId
        endDate
        name
        startDate
        schoolGradeLevels {
          gradeLevels
          schoolId
        }
      }
    }
  }
`;

/**
 * __useGetBaseDistrictAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetBaseDistrictAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseDistrictAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseDistrictAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseDistrictAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseDistrictAssignmentsQuery,
    GetBaseDistrictAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBaseDistrictAssignmentsQuery,
    GetBaseDistrictAssignmentsQueryVariables
  >(GetBaseDistrictAssignmentsDocument, options);
}
export function useGetBaseDistrictAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseDistrictAssignmentsQuery,
    GetBaseDistrictAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBaseDistrictAssignmentsQuery,
    GetBaseDistrictAssignmentsQueryVariables
  >(GetBaseDistrictAssignmentsDocument, options);
}
export type GetBaseDistrictAssignmentsQueryHookResult = ReturnType<
  typeof useGetBaseDistrictAssignmentsQuery
>;
export type GetBaseDistrictAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetBaseDistrictAssignmentsLazyQuery
>;
export type GetBaseDistrictAssignmentsQueryResult = Apollo.QueryResult<
  GetBaseDistrictAssignmentsQuery,
  GetBaseDistrictAssignmentsQueryVariables
>;
export const GetLeaderDistrictAssignmentsDocument = gql`
  query getLeaderDistrictAssignments(
    $filter: LeaderDistrictAssignmentsFilter!
  ) {
    districtAssignmentsForLeader(filter: $filter) {
      ... on DistrictClassAssignmentsType {
        collection {
          assignmentId
          startDate
          endDate
          schoolId
          gradeLevel
          daId
          districtId
          name
          availableDate
          studentAssignments {
            assignmentId
            startDate
            endDate
            schoolId
            grade
            completedAt
            submittedAt
            name
            status
            studentId
            studentNotes
            reopenDate
            availableDate
            shareResults
            learnosityReferenceId
            classId
            answers
            startedAt
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetLeaderDistrictAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetLeaderDistrictAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaderDistrictAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaderDistrictAssignmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetLeaderDistrictAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaderDistrictAssignmentsQuery,
    GetLeaderDistrictAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeaderDistrictAssignmentsQuery,
    GetLeaderDistrictAssignmentsQueryVariables
  >(GetLeaderDistrictAssignmentsDocument, options);
}
export function useGetLeaderDistrictAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaderDistrictAssignmentsQuery,
    GetLeaderDistrictAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeaderDistrictAssignmentsQuery,
    GetLeaderDistrictAssignmentsQueryVariables
  >(GetLeaderDistrictAssignmentsDocument, options);
}
export type GetLeaderDistrictAssignmentsQueryHookResult = ReturnType<
  typeof useGetLeaderDistrictAssignmentsQuery
>;
export type GetLeaderDistrictAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetLeaderDistrictAssignmentsLazyQuery
>;
export type GetLeaderDistrictAssignmentsQueryResult = Apollo.QueryResult<
  GetLeaderDistrictAssignmentsQuery,
  GetLeaderDistrictAssignmentsQueryVariables
>;
export const GetAclApplicationsDocument = gql`
  query getAclApplications {
    applicationSettings {
      value
      id
    }
    aclApplications {
      name
      modules {
        ...Module
      }
      schools {
        schoolIds
        modules {
          ...Module
        }
      }
    }
  }
  ${ModuleFragmentDoc}
`;

/**
 * __useGetAclApplicationsQuery__
 *
 * To run a query within a React component, call `useGetAclApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAclApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAclApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAclApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAclApplicationsQuery,
    GetAclApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAclApplicationsQuery,
    GetAclApplicationsQueryVariables
  >(GetAclApplicationsDocument, options);
}
export function useGetAclApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAclApplicationsQuery,
    GetAclApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAclApplicationsQuery,
    GetAclApplicationsQueryVariables
  >(GetAclApplicationsDocument, options);
}
export type GetAclApplicationsQueryHookResult = ReturnType<
  typeof useGetAclApplicationsQuery
>;
export type GetAclApplicationsLazyQueryHookResult = ReturnType<
  typeof useGetAclApplicationsLazyQuery
>;
export type GetAclApplicationsQueryResult = Apollo.QueryResult<
  GetAclApplicationsQuery,
  GetAclApplicationsQueryVariables
>;
export const CheckPasswordRecoveryTokenDocument = gql`
  query checkPasswordRecoveryToken($id: ID!) {
    passwordRecoveryToken(id: $id) {
      expired
    }
  }
`;

/**
 * __useCheckPasswordRecoveryTokenQuery__
 *
 * To run a query within a React component, call `useCheckPasswordRecoveryTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordRecoveryTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPasswordRecoveryTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckPasswordRecoveryTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckPasswordRecoveryTokenQuery,
    CheckPasswordRecoveryTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckPasswordRecoveryTokenQuery,
    CheckPasswordRecoveryTokenQueryVariables
  >(CheckPasswordRecoveryTokenDocument, options);
}
export function useCheckPasswordRecoveryTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckPasswordRecoveryTokenQuery,
    CheckPasswordRecoveryTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckPasswordRecoveryTokenQuery,
    CheckPasswordRecoveryTokenQueryVariables
  >(CheckPasswordRecoveryTokenDocument, options);
}
export type CheckPasswordRecoveryTokenQueryHookResult = ReturnType<
  typeof useCheckPasswordRecoveryTokenQuery
>;
export type CheckPasswordRecoveryTokenLazyQueryHookResult = ReturnType<
  typeof useCheckPasswordRecoveryTokenLazyQuery
>;
export type CheckPasswordRecoveryTokenQueryResult = Apollo.QueryResult<
  CheckPasswordRecoveryTokenQuery,
  CheckPasswordRecoveryTokenQueryVariables
>;
export const CreatePasswordChangeDocument = gql`
  mutation createPasswordChange($input: createPasswordChangeInput!) {
    createPasswordChange(input: $input) {
      passwordChange {
        id
      }
    }
  }
`;
export type CreatePasswordChangeMutationFn = Apollo.MutationFunction<
  CreatePasswordChangeMutation,
  CreatePasswordChangeMutationVariables
>;

/**
 * __useCreatePasswordChangeMutation__
 *
 * To run a mutation, you first call `useCreatePasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordChangeMutation, { data, loading, error }] = useCreatePasswordChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePasswordChangeMutation,
    CreatePasswordChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePasswordChangeMutation,
    CreatePasswordChangeMutationVariables
  >(CreatePasswordChangeDocument, options);
}
export type CreatePasswordChangeMutationHookResult = ReturnType<
  typeof useCreatePasswordChangeMutation
>;
export type CreatePasswordChangeMutationResult =
  Apollo.MutationResult<CreatePasswordChangeMutation>;
export type CreatePasswordChangeMutationOptions = Apollo.BaseMutationOptions<
  CreatePasswordChangeMutation,
  CreatePasswordChangeMutationVariables
>;
export const CreatePasswordRecoveryRequestDocument = gql`
  mutation createPasswordRecoveryRequest(
    $input: PasswordRecoveryRequestInput!
  ) {
    createPasswordRecoveryRequest(input: $input)
  }
`;
export type CreatePasswordRecoveryRequestMutationFn = Apollo.MutationFunction<
  CreatePasswordRecoveryRequestMutation,
  CreatePasswordRecoveryRequestMutationVariables
>;

/**
 * __useCreatePasswordRecoveryRequestMutation__
 *
 * To run a mutation, you first call `useCreatePasswordRecoveryRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordRecoveryRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordRecoveryRequestMutation, { data, loading, error }] = useCreatePasswordRecoveryRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordRecoveryRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePasswordRecoveryRequestMutation,
    CreatePasswordRecoveryRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePasswordRecoveryRequestMutation,
    CreatePasswordRecoveryRequestMutationVariables
  >(CreatePasswordRecoveryRequestDocument, options);
}
export type CreatePasswordRecoveryRequestMutationHookResult = ReturnType<
  typeof useCreatePasswordRecoveryRequestMutation
>;
export type CreatePasswordRecoveryRequestMutationResult =
  Apollo.MutationResult<CreatePasswordRecoveryRequestMutation>;
export type CreatePasswordRecoveryRequestMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePasswordRecoveryRequestMutation,
    CreatePasswordRecoveryRequestMutationVariables
  >;
export const CreatePasswordRecoveryDocument = gql`
  mutation createPasswordRecovery($input: PasswordRecoveryInput!) {
    createPasswordRecovery(input: $input)
  }
`;
export type CreatePasswordRecoveryMutationFn = Apollo.MutationFunction<
  CreatePasswordRecoveryMutation,
  CreatePasswordRecoveryMutationVariables
>;

/**
 * __useCreatePasswordRecoveryMutation__
 *
 * To run a mutation, you first call `useCreatePasswordRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordRecoveryMutation, { data, loading, error }] = useCreatePasswordRecoveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordRecoveryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePasswordRecoveryMutation,
    CreatePasswordRecoveryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePasswordRecoveryMutation,
    CreatePasswordRecoveryMutationVariables
  >(CreatePasswordRecoveryDocument, options);
}
export type CreatePasswordRecoveryMutationHookResult = ReturnType<
  typeof useCreatePasswordRecoveryMutation
>;
export type CreatePasswordRecoveryMutationResult =
  Apollo.MutationResult<CreatePasswordRecoveryMutation>;
export type CreatePasswordRecoveryMutationOptions = Apollo.BaseMutationOptions<
  CreatePasswordRecoveryMutation,
  CreatePasswordRecoveryMutationVariables
>;
export const RegisterPreviewAccountTeacherDocument = gql`
  mutation registerPreviewAccountTeacher(
    $input: RegisterPreviewAccountTeacherInput!
  ) {
    registerPreviewAccountTeacher(input: $input) {
      previewAccount {
        district {
          urlCode
        }
      }
    }
  }
`;
export type RegisterPreviewAccountTeacherMutationFn = Apollo.MutationFunction<
  RegisterPreviewAccountTeacherMutation,
  RegisterPreviewAccountTeacherMutationVariables
>;

/**
 * __useRegisterPreviewAccountTeacherMutation__
 *
 * To run a mutation, you first call `useRegisterPreviewAccountTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPreviewAccountTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPreviewAccountTeacherMutation, { data, loading, error }] = useRegisterPreviewAccountTeacherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPreviewAccountTeacherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPreviewAccountTeacherMutation,
    RegisterPreviewAccountTeacherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterPreviewAccountTeacherMutation,
    RegisterPreviewAccountTeacherMutationVariables
  >(RegisterPreviewAccountTeacherDocument, options);
}
export type RegisterPreviewAccountTeacherMutationHookResult = ReturnType<
  typeof useRegisterPreviewAccountTeacherMutation
>;
export type RegisterPreviewAccountTeacherMutationResult =
  Apollo.MutationResult<RegisterPreviewAccountTeacherMutation>;
export type RegisterPreviewAccountTeacherMutationOptions =
  Apollo.BaseMutationOptions<
    RegisterPreviewAccountTeacherMutation,
    RegisterPreviewAccountTeacherMutationVariables
  >;
export const GetReportDocument = gql`
  query getReport(
    $containerId: String!
    $activityId: String
    $activities: Iterable
    $limit: Int
    $displayUser: Boolean
    $users: Iterable
    $userId: String
    $type: String!
    $sessionId: String
  ) {
    getReport(
      containerId: $containerId
      activityId: $activityId
      activities: $activities
      users: $users
      userId: $userId
      type: $type
      limit: $limit
      displayUser: $displayUser
      sessionId: $sessionId
    ) {
      id
      signedRequest
    }
  }
`;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *      activityId: // value for 'activityId'
 *      activities: // value for 'activities'
 *      limit: // value for 'limit'
 *      displayUser: // value for 'displayUser'
 *      users: // value for 'users'
 *      userId: // value for 'userId'
 *      type: // value for 'type'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    options
  );
}
export function useGetReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportQuery,
    GetReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    options
  );
}
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<
  typeof useGetReportLazyQuery
>;
export type GetReportQueryResult = Apollo.QueryResult<
  GetReportQuery,
  GetReportQueryVariables
>;
export const SignRequestReportDocument = gql`
  mutation signRequestReport($params: SignRequestInput!) {
    signRequest(params: $params) {
      ... on ReportSignedRequest {
        signedRequest
      }
    }
  }
`;
export type SignRequestReportMutationFn = Apollo.MutationFunction<
  SignRequestReportMutation,
  SignRequestReportMutationVariables
>;

/**
 * __useSignRequestReportMutation__
 *
 * To run a mutation, you first call `useSignRequestReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignRequestReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signRequestReportMutation, { data, loading, error }] = useSignRequestReportMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignRequestReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignRequestReportMutation,
    SignRequestReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignRequestReportMutation,
    SignRequestReportMutationVariables
  >(SignRequestReportDocument, options);
}
export type SignRequestReportMutationHookResult = ReturnType<
  typeof useSignRequestReportMutation
>;
export type SignRequestReportMutationResult =
  Apollo.MutationResult<SignRequestReportMutation>;
export type SignRequestReportMutationOptions = Apollo.BaseMutationOptions<
  SignRequestReportMutation,
  SignRequestReportMutationVariables
>;
export const RegisterParentDocument = gql`
  mutation registerParent($input: RegisterParentInput!) {
    registerParent(input: $input) {
      districtId
      id
    }
  }
`;
export type RegisterParentMutationFn = Apollo.MutationFunction<
  RegisterParentMutation,
  RegisterParentMutationVariables
>;

/**
 * __useRegisterParentMutation__
 *
 * To run a mutation, you first call `useRegisterParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerParentMutation, { data, loading, error }] = useRegisterParentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterParentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterParentMutation,
    RegisterParentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterParentMutation,
    RegisterParentMutationVariables
  >(RegisterParentDocument, options);
}
export type RegisterParentMutationHookResult = ReturnType<
  typeof useRegisterParentMutation
>;
export type RegisterParentMutationResult =
  Apollo.MutationResult<RegisterParentMutation>;
export type RegisterParentMutationOptions = Apollo.BaseMutationOptions<
  RegisterParentMutation,
  RegisterParentMutationVariables
>;
export const GetScope2Document = gql`
  query getScope2($id: String!, $schoolId: String) {
    baseScope(id: $id, schoolId: $schoolId) {
      id
      subscriptionContentGroups
      jsonUrl
      projectId
    }
  }
`;

/**
 * __useGetScope2Query__
 *
 * To run a query within a React component, call `useGetScope2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetScope2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScope2Query({
 *   variables: {
 *      id: // value for 'id'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetScope2Query(
  baseOptions: Apollo.QueryHookOptions<GetScope2Query, GetScope2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScope2Query, GetScope2QueryVariables>(
    GetScope2Document,
    options
  );
}
export function useGetScope2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScope2Query,
    GetScope2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScope2Query, GetScope2QueryVariables>(
    GetScope2Document,
    options
  );
}
export type GetScope2QueryHookResult = ReturnType<typeof useGetScope2Query>;
export type GetScope2LazyQueryHookResult = ReturnType<
  typeof useGetScope2LazyQuery
>;
export type GetScope2QueryResult = Apollo.QueryResult<
  GetScope2Query,
  GetScope2QueryVariables
>;
export const RegisterAdoptionReviewerDocument = gql`
  mutation registerAdoptionReviewer($input: RegisterAdoptionReviewerInput!) {
    registerAdoptionReviewer(input: $input) {
      eventUrlCode
      districtName
    }
  }
`;
export type RegisterAdoptionReviewerMutationFn = Apollo.MutationFunction<
  RegisterAdoptionReviewerMutation,
  RegisterAdoptionReviewerMutationVariables
>;

/**
 * __useRegisterAdoptionReviewerMutation__
 *
 * To run a mutation, you first call `useRegisterAdoptionReviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAdoptionReviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAdoptionReviewerMutation, { data, loading, error }] = useRegisterAdoptionReviewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAdoptionReviewerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterAdoptionReviewerMutation,
    RegisterAdoptionReviewerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterAdoptionReviewerMutation,
    RegisterAdoptionReviewerMutationVariables
  >(RegisterAdoptionReviewerDocument, options);
}
export type RegisterAdoptionReviewerMutationHookResult = ReturnType<
  typeof useRegisterAdoptionReviewerMutation
>;
export type RegisterAdoptionReviewerMutationResult =
  Apollo.MutationResult<RegisterAdoptionReviewerMutation>;
export type RegisterAdoptionReviewerMutationOptions =
  Apollo.BaseMutationOptions<
    RegisterAdoptionReviewerMutation,
    RegisterAdoptionReviewerMutationVariables
  >;
export const AskChatbotDocument = gql`
  query askChatbot($userId: String!, $query: String!, $threadId: String) {
    askChatbot(userId: $userId, query: $query, threadId: $threadId) {
      answer
      threadId
    }
  }
`;

/**
 * __useAskChatbotQuery__
 *
 * To run a query within a React component, call `useAskChatbotQuery` and pass it any options that fit your needs.
 * When your component renders, `useAskChatbotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskChatbotQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      query: // value for 'query'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useAskChatbotQuery(
  baseOptions: Apollo.QueryHookOptions<
    AskChatbotQuery,
    AskChatbotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AskChatbotQuery, AskChatbotQueryVariables>(
    AskChatbotDocument,
    options
  );
}
export function useAskChatbotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AskChatbotQuery,
    AskChatbotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AskChatbotQuery, AskChatbotQueryVariables>(
    AskChatbotDocument,
    options
  );
}
export type AskChatbotQueryHookResult = ReturnType<typeof useAskChatbotQuery>;
export type AskChatbotLazyQueryHookResult = ReturnType<
  typeof useAskChatbotLazyQuery
>;
export type AskChatbotQueryResult = Apollo.QueryResult<
  AskChatbotQuery,
  AskChatbotQueryVariables
>;
export const CreateAiChatDocument = gql`
  mutation createAiChat($input: AiChatInput!) {
    createAiChat(input: $input) {
      answer
    }
  }
`;
export type CreateAiChatMutationFn = Apollo.MutationFunction<
  CreateAiChatMutation,
  CreateAiChatMutationVariables
>;

/**
 * __useCreateAiChatMutation__
 *
 * To run a mutation, you first call `useCreateAiChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiChatMutation, { data, loading, error }] = useCreateAiChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAiChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiChatMutation,
    CreateAiChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAiChatMutation,
    CreateAiChatMutationVariables
  >(CreateAiChatDocument, options);
}
export type CreateAiChatMutationHookResult = ReturnType<
  typeof useCreateAiChatMutation
>;
export type CreateAiChatMutationResult =
  Apollo.MutationResult<CreateAiChatMutation>;
export type CreateAiChatMutationOptions = Apollo.BaseMutationOptions<
  CreateAiChatMutation,
  CreateAiChatMutationVariables
>;
export const GetStandardsItemsDocument = gql`
  query getStandardsItems($filter: SsStandardsItemFilter) {
    ssStandardsItems(filter: $filter) {
      standard
    }
  }
`;

/**
 * __useGetStandardsItemsQuery__
 *
 * To run a query within a React component, call `useGetStandardsItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardsItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardsItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetStandardsItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStandardsItemsQuery,
    GetStandardsItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStandardsItemsQuery,
    GetStandardsItemsQueryVariables
  >(GetStandardsItemsDocument, options);
}
export function useGetStandardsItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStandardsItemsQuery,
    GetStandardsItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStandardsItemsQuery,
    GetStandardsItemsQueryVariables
  >(GetStandardsItemsDocument, options);
}
export type GetStandardsItemsQueryHookResult = ReturnType<
  typeof useGetStandardsItemsQuery
>;
export type GetStandardsItemsLazyQueryHookResult = ReturnType<
  typeof useGetStandardsItemsLazyQuery
>;
export type GetStandardsItemsQueryResult = Apollo.QueryResult<
  GetStandardsItemsQuery,
  GetStandardsItemsQueryVariables
>;
export const CreateResourceDocument = gql`
  mutation createResource($params: CreateResourceInput!, $schoolId: String) {
    createResource(params: $params, schoolId: $schoolId) {
      learnosityReferenceId
    }
  }
`;
export type CreateResourceMutationFn = Apollo.MutationFunction<
  CreateResourceMutation,
  CreateResourceMutationVariables
>;

/**
 * __useCreateResourceMutation__
 *
 * To run a mutation, you first call `useCreateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceMutation, { data, loading, error }] = useCreateResourceMutation({
 *   variables: {
 *      params: // value for 'params'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useCreateResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateResourceMutation,
    CreateResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateResourceMutation,
    CreateResourceMutationVariables
  >(CreateResourceDocument, options);
}
export type CreateResourceMutationHookResult = ReturnType<
  typeof useCreateResourceMutation
>;
export type CreateResourceMutationResult =
  Apollo.MutationResult<CreateResourceMutation>;
export type CreateResourceMutationOptions = Apollo.BaseMutationOptions<
  CreateResourceMutation,
  CreateResourceMutationVariables
>;
export const GetStandardsByNameDocument = gql`
  query getStandardsByName(
    $standardNames: [String!]!
    $currentSchoolId: String!
  ) {
    standardsByName(
      standardNames: $standardNames
      currentSchoolId: $currentSchoolId
    ) {
      description
      standard
    }
  }
`;

/**
 * __useGetStandardsByNameQuery__
 *
 * To run a query within a React component, call `useGetStandardsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardsByNameQuery({
 *   variables: {
 *      standardNames: // value for 'standardNames'
 *      currentSchoolId: // value for 'currentSchoolId'
 *   },
 * });
 */
export function useGetStandardsByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStandardsByNameQuery,
    GetStandardsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStandardsByNameQuery,
    GetStandardsByNameQueryVariables
  >(GetStandardsByNameDocument, options);
}
export function useGetStandardsByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStandardsByNameQuery,
    GetStandardsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStandardsByNameQuery,
    GetStandardsByNameQueryVariables
  >(GetStandardsByNameDocument, options);
}
export type GetStandardsByNameQueryHookResult = ReturnType<
  typeof useGetStandardsByNameQuery
>;
export type GetStandardsByNameLazyQueryHookResult = ReturnType<
  typeof useGetStandardsByNameLazyQuery
>;
export type GetStandardsByNameQueryResult = Apollo.QueryResult<
  GetStandardsByNameQuery,
  GetStandardsByNameQueryVariables
>;
export const GetScopesDocument = gql`
  query getScopes(
    $filter: ScopeFilter
    $page: Int
    $perPage: Int
    $search: String
    $schoolIds: [String!]
  ) {
    scopes(
      filter: $filter
      page: $page
      perPage: $perPage
      search: $search
      schoolIds: $schoolIds
    ) {
      collection {
        displayName
        editionName
        id
        image
        metadata
        projectId
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

/**
 * __useGetScopesQuery__
 *
 * To run a query within a React component, call `useGetScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScopesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      search: // value for 'search'
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetScopesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetScopesQuery, GetScopesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScopesQuery, GetScopesQueryVariables>(
    GetScopesDocument,
    options
  );
}
export function useGetScopesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScopesQuery,
    GetScopesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScopesQuery, GetScopesQueryVariables>(
    GetScopesDocument,
    options
  );
}
export type GetScopesQueryHookResult = ReturnType<typeof useGetScopesQuery>;
export type GetScopesLazyQueryHookResult = ReturnType<
  typeof useGetScopesLazyQuery
>;
export type GetScopesQueryResult = Apollo.QueryResult<
  GetScopesQuery,
  GetScopesQueryVariables
>;
export const GetScopesForAssignmentFormDocument = gql`
  query getScopesForAssignmentForm($filter: ScopeFilter, $schoolId: String!) {
    scopes(filter: $filter, schoolId: $schoolId) {
      collection {
        displayName
        id
      }
    }
  }
`;

/**
 * __useGetScopesForAssignmentFormQuery__
 *
 * To run a query within a React component, call `useGetScopesForAssignmentFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScopesForAssignmentFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScopesForAssignmentFormQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetScopesForAssignmentFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScopesForAssignmentFormQuery,
    GetScopesForAssignmentFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScopesForAssignmentFormQuery,
    GetScopesForAssignmentFormQueryVariables
  >(GetScopesForAssignmentFormDocument, options);
}
export function useGetScopesForAssignmentFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScopesForAssignmentFormQuery,
    GetScopesForAssignmentFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScopesForAssignmentFormQuery,
    GetScopesForAssignmentFormQueryVariables
  >(GetScopesForAssignmentFormDocument, options);
}
export type GetScopesForAssignmentFormQueryHookResult = ReturnType<
  typeof useGetScopesForAssignmentFormQuery
>;
export type GetScopesForAssignmentFormLazyQueryHookResult = ReturnType<
  typeof useGetScopesForAssignmentFormLazyQuery
>;
export type GetScopesForAssignmentFormQueryResult = Apollo.QueryResult<
  GetScopesForAssignmentFormQuery,
  GetScopesForAssignmentFormQueryVariables
>;
export const GetTeachersStandardsListDocument = gql`
  query getTeachersStandardsList($input: StandardListInput!) {
    teacherStandardList(input: $input) {
      standards
    }
  }
`;

/**
 * __useGetTeachersStandardsListQuery__
 *
 * To run a query within a React component, call `useGetTeachersStandardsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeachersStandardsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeachersStandardsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTeachersStandardsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeachersStandardsListQuery,
    GetTeachersStandardsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeachersStandardsListQuery,
    GetTeachersStandardsListQueryVariables
  >(GetTeachersStandardsListDocument, options);
}
export function useGetTeachersStandardsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeachersStandardsListQuery,
    GetTeachersStandardsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeachersStandardsListQuery,
    GetTeachersStandardsListQueryVariables
  >(GetTeachersStandardsListDocument, options);
}
export type GetTeachersStandardsListQueryHookResult = ReturnType<
  typeof useGetTeachersStandardsListQuery
>;
export type GetTeachersStandardsListLazyQueryHookResult = ReturnType<
  typeof useGetTeachersStandardsListLazyQuery
>;
export type GetTeachersStandardsListQueryResult = Apollo.QueryResult<
  GetTeachersStandardsListQuery,
  GetTeachersStandardsListQueryVariables
>;
export const AddStudentToSchoolDocument = gql`
  mutation addStudentToSchool($input: addSchoolToStudentInput!) {
    addSchoolToStudent(input: $input) {
      clientMutationId
    }
  }
`;
export type AddStudentToSchoolMutationFn = Apollo.MutationFunction<
  AddStudentToSchoolMutation,
  AddStudentToSchoolMutationVariables
>;

/**
 * __useAddStudentToSchoolMutation__
 *
 * To run a mutation, you first call `useAddStudentToSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentToSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentToSchoolMutation, { data, loading, error }] = useAddStudentToSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStudentToSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStudentToSchoolMutation,
    AddStudentToSchoolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddStudentToSchoolMutation,
    AddStudentToSchoolMutationVariables
  >(AddStudentToSchoolDocument, options);
}
export type AddStudentToSchoolMutationHookResult = ReturnType<
  typeof useAddStudentToSchoolMutation
>;
export type AddStudentToSchoolMutationResult =
  Apollo.MutationResult<AddStudentToSchoolMutation>;
export type AddStudentToSchoolMutationOptions = Apollo.BaseMutationOptions<
  AddStudentToSchoolMutation,
  AddStudentToSchoolMutationVariables
>;
export const FindStudentAssignmentsDocument = gql`
  query findStudentAssignments(
    $schoolId: String!
    $filter: StudentAssignmentsFilter
  ) {
    studentStudentAssignments(schoolId: $schoolId, filter: $filter) {
      collection {
        id
      }
    }
  }
`;

/**
 * __useFindStudentAssignmentsQuery__
 *
 * To run a query within a React component, call `useFindStudentAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStudentAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStudentAssignmentsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindStudentAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindStudentAssignmentsQuery,
    FindStudentAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindStudentAssignmentsQuery,
    FindStudentAssignmentsQueryVariables
  >(FindStudentAssignmentsDocument, options);
}
export function useFindStudentAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindStudentAssignmentsQuery,
    FindStudentAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindStudentAssignmentsQuery,
    FindStudentAssignmentsQueryVariables
  >(FindStudentAssignmentsDocument, options);
}
export type FindStudentAssignmentsQueryHookResult = ReturnType<
  typeof useFindStudentAssignmentsQuery
>;
export type FindStudentAssignmentsLazyQueryHookResult = ReturnType<
  typeof useFindStudentAssignmentsLazyQuery
>;
export type FindStudentAssignmentsQueryResult = Apollo.QueryResult<
  FindStudentAssignmentsQuery,
  FindStudentAssignmentsQueryVariables
>;
export const GetStudentDetailsDocument = gql`
  query getStudentDetails($id: ID!) {
    student(id: $id) {
      _id
      id
      classes {
        collection {
          id
          _id
          name
          gradeOrCourse
          archived
        }
      }
    }
  }
`;

/**
 * __useGetStudentDetailsQuery__
 *
 * To run a query within a React component, call `useGetStudentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentDetailsQuery,
    GetStudentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentDetailsQuery,
    GetStudentDetailsQueryVariables
  >(GetStudentDetailsDocument, options);
}
export function useGetStudentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentDetailsQuery,
    GetStudentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentDetailsQuery,
    GetStudentDetailsQueryVariables
  >(GetStudentDetailsDocument, options);
}
export type GetStudentDetailsQueryHookResult = ReturnType<
  typeof useGetStudentDetailsQuery
>;
export type GetStudentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetStudentDetailsLazyQuery
>;
export type GetStudentDetailsQueryResult = Apollo.QueryResult<
  GetStudentDetailsQuery,
  GetStudentDetailsQueryVariables
>;
export const GetStudentResourcesDocument = gql`
  query getStudentResources(
    $filter: StudentResourcesFilter
    $schoolId: String!
  ) {
    studentResources(filter: $filter, schoolId: $schoolId) {
      collection {
        learnosityReferenceId
        originLearnosityId
        name
        resourceId
        resourceSource {
          element {
            elementId
            elementName
            projectId
            scopeId
            scopeName
            sectionId
            sectionName
            viewId
          }
        }
        grade: srGrade
        id: srId
        status: srStatus
      }
    }
  }
`;

/**
 * __useGetStudentResourcesQuery__
 *
 * To run a query within a React component, call `useGetStudentResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentResourcesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetStudentResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentResourcesQuery,
    GetStudentResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentResourcesQuery,
    GetStudentResourcesQueryVariables
  >(GetStudentResourcesDocument, options);
}
export function useGetStudentResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentResourcesQuery,
    GetStudentResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentResourcesQuery,
    GetStudentResourcesQueryVariables
  >(GetStudentResourcesDocument, options);
}
export type GetStudentResourcesQueryHookResult = ReturnType<
  typeof useGetStudentResourcesQuery
>;
export type GetStudentResourcesLazyQueryHookResult = ReturnType<
  typeof useGetStudentResourcesLazyQuery
>;
export type GetStudentResourcesQueryResult = Apollo.QueryResult<
  GetStudentResourcesQuery,
  GetStudentResourcesQueryVariables
>;
export const GetElementsDocument = gql`
  query getElements(
    $filter: ElementFilter
    $page: Int
    $perPage: Int
    $schoolId: String
  ) {
    elements(
      filter: $filter
      page: $page
      perPage: $perPage
      schoolId: $schoolId
    ) {
      collection {
        id
        scopeId
        name
        metadata
        studentView
      }
    }
  }
`;

/**
 * __useGetElementsQuery__
 *
 * To run a query within a React component, call `useGetElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetElementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetElementsQuery,
    GetElementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetElementsQuery, GetElementsQueryVariables>(
    GetElementsDocument,
    options
  );
}
export function useGetElementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetElementsQuery,
    GetElementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetElementsQuery, GetElementsQueryVariables>(
    GetElementsDocument,
    options
  );
}
export type GetElementsQueryHookResult = ReturnType<typeof useGetElementsQuery>;
export type GetElementsLazyQueryHookResult = ReturnType<
  typeof useGetElementsLazyQuery
>;
export type GetElementsQueryResult = Apollo.QueryResult<
  GetElementsQuery,
  GetElementsQueryVariables
>;
export const GetTeacherStudentsDocument = gql`
  query getTeacherStudents(
    $schoolId: String!
    $teachersId: String!
    $page: Int
    $itemsPerPage: Int
  ) {
    teacherStudents(
      schoolId: $schoolId
      teachersId: $teachersId
      page: $page
      itemsPerPage: $itemsPerPage
    ) {
      collection {
        id
        _id
        sisId
        lastName
        firstName
        username
        gradeLevel
        plainPassword
        authenticationToken
        classes(filter: { status: Active, hasLmsId: false }) {
          collection {
            name
            schoolId
            archived
            classTeachers {
              collection {
                teacherId
                mainTeacher
              }
            }
          }
        }
      }
      paginationInfo {
        lastPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetTeacherStudentsQuery__
 *
 * To run a query within a React component, call `useGetTeacherStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherStudentsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      teachersId: // value for 'teachersId'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetTeacherStudentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeacherStudentsQuery,
    GetTeacherStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeacherStudentsQuery,
    GetTeacherStudentsQueryVariables
  >(GetTeacherStudentsDocument, options);
}
export function useGetTeacherStudentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeacherStudentsQuery,
    GetTeacherStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeacherStudentsQuery,
    GetTeacherStudentsQueryVariables
  >(GetTeacherStudentsDocument, options);
}
export type GetTeacherStudentsQueryHookResult = ReturnType<
  typeof useGetTeacherStudentsQuery
>;
export type GetTeacherStudentsLazyQueryHookResult = ReturnType<
  typeof useGetTeacherStudentsLazyQuery
>;
export type GetTeacherStudentsQueryResult = Apollo.QueryResult<
  GetTeacherStudentsQuery,
  GetTeacherStudentsQueryVariables
>;
export const GetTeacherStudentsForAddingToDaDocument = gql`
  query getTeacherStudentsForAddingToDa(
    $schoolId: String!
    $teachersId: String!
    $page: Int
    $itemsPerPage: Int
  ) {
    teacherStudents(
      schoolId: $schoolId
      teachersId: $teachersId
      page: $page
      itemsPerPage: $itemsPerPage
      active: true
    ) {
      collection {
        id
        firstName
        lastName
        gradeLevel
      }
    }
  }
`;

/**
 * __useGetTeacherStudentsForAddingToDaQuery__
 *
 * To run a query within a React component, call `useGetTeacherStudentsForAddingToDaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherStudentsForAddingToDaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherStudentsForAddingToDaQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      teachersId: // value for 'teachersId'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetTeacherStudentsForAddingToDaQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeacherStudentsForAddingToDaQuery,
    GetTeacherStudentsForAddingToDaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeacherStudentsForAddingToDaQuery,
    GetTeacherStudentsForAddingToDaQueryVariables
  >(GetTeacherStudentsForAddingToDaDocument, options);
}
export function useGetTeacherStudentsForAddingToDaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeacherStudentsForAddingToDaQuery,
    GetTeacherStudentsForAddingToDaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeacherStudentsForAddingToDaQuery,
    GetTeacherStudentsForAddingToDaQueryVariables
  >(GetTeacherStudentsForAddingToDaDocument, options);
}
export type GetTeacherStudentsForAddingToDaQueryHookResult = ReturnType<
  typeof useGetTeacherStudentsForAddingToDaQuery
>;
export type GetTeacherStudentsForAddingToDaLazyQueryHookResult = ReturnType<
  typeof useGetTeacherStudentsForAddingToDaLazyQuery
>;
export type GetTeacherStudentsForAddingToDaQueryResult = Apollo.QueryResult<
  GetTeacherStudentsForAddingToDaQuery,
  GetTeacherStudentsForAddingToDaQueryVariables
>;
export const GetStudentClassAssignmentsDocument = gql`
  query getStudentClassAssignments(
    $filter: StudentAssignmentsFilter
    $schoolId: String
  ) {
    studentStudentAssignments(filter: $filter, schoolId: $schoolId) {
      collection {
        answers
        classId
        grade
        status
        endDate
        completedAt
        startDate
        releaseFeedback
        studentId
        name
        returnedAt
        assignmentId
        id
        lateTurnIn
        shareResults
        otherAssignmentScope
        archived
        assignmentSource {
          element {
            elementId
            elementName
            projectId
            scopeId
            scopeName
            sectionId
            sectionName
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentClassAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetStudentClassAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentClassAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentClassAssignmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetStudentClassAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStudentClassAssignmentsQuery,
    GetStudentClassAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStudentClassAssignmentsQuery,
    GetStudentClassAssignmentsQueryVariables
  >(GetStudentClassAssignmentsDocument, options);
}
export function useGetStudentClassAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentClassAssignmentsQuery,
    GetStudentClassAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStudentClassAssignmentsQuery,
    GetStudentClassAssignmentsQueryVariables
  >(GetStudentClassAssignmentsDocument, options);
}
export type GetStudentClassAssignmentsQueryHookResult = ReturnType<
  typeof useGetStudentClassAssignmentsQuery
>;
export type GetStudentClassAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetStudentClassAssignmentsLazyQuery
>;
export type GetStudentClassAssignmentsQueryResult = Apollo.QueryResult<
  GetStudentClassAssignmentsQuery,
  GetStudentClassAssignmentsQueryVariables
>;
export const SystemAdminsDocument = gql`
  query systemAdmins(
    $districtUuid: String!
    $itemsPerPage: Int = 1000
    $page: Int = 1
  ) {
    systemAdmins(
      districtUuid: $districtUuid
      itemsPerPage: $itemsPerPage
      page: $page
    ) {
      collection {
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useSystemAdminsQuery__
 *
 * To run a query within a React component, call `useSystemAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemAdminsQuery({
 *   variables: {
 *      districtUuid: // value for 'districtUuid'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSystemAdminsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SystemAdminsQuery,
    SystemAdminsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SystemAdminsQuery, SystemAdminsQueryVariables>(
    SystemAdminsDocument,
    options
  );
}
export function useSystemAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SystemAdminsQuery,
    SystemAdminsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SystemAdminsQuery, SystemAdminsQueryVariables>(
    SystemAdminsDocument,
    options
  );
}
export type SystemAdminsQueryHookResult = ReturnType<
  typeof useSystemAdminsQuery
>;
export type SystemAdminsLazyQueryHookResult = ReturnType<
  typeof useSystemAdminsLazyQuery
>;
export type SystemAdminsQueryResult = Apollo.QueryResult<
  SystemAdminsQuery,
  SystemAdminsQueryVariables
>;
export const AddStudentsToStudentSubgroupDocument = gql`
  mutation addStudentsToStudentSubgroup(
    $input: addStudentsToStudentSubgroupInput!
  ) {
    addStudentsToStudentSubgroup(input: $input) {
      studentSubgroup {
        id
      }
    }
  }
`;
export type AddStudentsToStudentSubgroupMutationFn = Apollo.MutationFunction<
  AddStudentsToStudentSubgroupMutation,
  AddStudentsToStudentSubgroupMutationVariables
>;

/**
 * __useAddStudentsToStudentSubgroupMutation__
 *
 * To run a mutation, you first call `useAddStudentsToStudentSubgroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentsToStudentSubgroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentsToStudentSubgroupMutation, { data, loading, error }] = useAddStudentsToStudentSubgroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStudentsToStudentSubgroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStudentsToStudentSubgroupMutation,
    AddStudentsToStudentSubgroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddStudentsToStudentSubgroupMutation,
    AddStudentsToStudentSubgroupMutationVariables
  >(AddStudentsToStudentSubgroupDocument, options);
}
export type AddStudentsToStudentSubgroupMutationHookResult = ReturnType<
  typeof useAddStudentsToStudentSubgroupMutation
>;
export type AddStudentsToStudentSubgroupMutationResult =
  Apollo.MutationResult<AddStudentsToStudentSubgroupMutation>;
export type AddStudentsToStudentSubgroupMutationOptions =
  Apollo.BaseMutationOptions<
    AddStudentsToStudentSubgroupMutation,
    AddStudentsToStudentSubgroupMutationVariables
  >;
export const RemoveStudentsFromStudentSubgroupDocument = gql`
  mutation removeStudentsFromStudentSubgroup(
    $input: removeStudentsFromStudentSubgroupInput!
  ) {
    removeStudentsFromStudentSubgroup(input: $input) {
      studentSubgroup {
        id
      }
    }
  }
`;
export type RemoveStudentsFromStudentSubgroupMutationFn =
  Apollo.MutationFunction<
    RemoveStudentsFromStudentSubgroupMutation,
    RemoveStudentsFromStudentSubgroupMutationVariables
  >;

/**
 * __useRemoveStudentsFromStudentSubgroupMutation__
 *
 * To run a mutation, you first call `useRemoveStudentsFromStudentSubgroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentsFromStudentSubgroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentsFromStudentSubgroupMutation, { data, loading, error }] = useRemoveStudentsFromStudentSubgroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStudentsFromStudentSubgroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveStudentsFromStudentSubgroupMutation,
    RemoveStudentsFromStudentSubgroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveStudentsFromStudentSubgroupMutation,
    RemoveStudentsFromStudentSubgroupMutationVariables
  >(RemoveStudentsFromStudentSubgroupDocument, options);
}
export type RemoveStudentsFromStudentSubgroupMutationHookResult = ReturnType<
  typeof useRemoveStudentsFromStudentSubgroupMutation
>;
export type RemoveStudentsFromStudentSubgroupMutationResult =
  Apollo.MutationResult<RemoveStudentsFromStudentSubgroupMutation>;
export type RemoveStudentsFromStudentSubgroupMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveStudentsFromStudentSubgroupMutation,
    RemoveStudentsFromStudentSubgroupMutationVariables
  >;
export const GetTeacherDocument = gql`
  query getTeacher($id: ID!) {
    teacher(id: $id) {
      lastName
      firstName
    }
  }
`;

/**
 * __useGetTeacherQuery__
 *
 * To run a query within a React component, call `useGetTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeacherQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeacherQuery,
    GetTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeacherQuery, GetTeacherQueryVariables>(
    GetTeacherDocument,
    options
  );
}
export function useGetTeacherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeacherQuery,
    GetTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeacherQuery, GetTeacherQueryVariables>(
    GetTeacherDocument,
    options
  );
}
export type GetTeacherQueryHookResult = ReturnType<typeof useGetTeacherQuery>;
export type GetTeacherLazyQueryHookResult = ReturnType<
  typeof useGetTeacherLazyQuery
>;
export type GetTeacherQueryResult = Apollo.QueryResult<
  GetTeacherQuery,
  GetTeacherQueryVariables
>;
export const GetClassSubgroupsDocument = gql`
  query getClassSubgroups($classId: ID!, $activeTeachers: Boolean) {
    class(id: $classId) {
      name
      _id
      subgroups {
        collection {
          name
          id
          _id
          students {
            collection {
              id
            }
          }
          teachers {
            collection {
              _id
              id
            }
          }
        }
      }
      classTeachers(active: $activeTeachers) {
        collection {
          teacherId
          mainTeacher
          subgroups {
            collection {
              _id
              id
              name
            }
          }
          teacher {
            _id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassSubgroupsQuery__
 *
 * To run a query within a React component, call `useGetClassSubgroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassSubgroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassSubgroupsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      activeTeachers: // value for 'activeTeachers'
 *   },
 * });
 */
export function useGetClassSubgroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassSubgroupsQuery,
    GetClassSubgroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassSubgroupsQuery,
    GetClassSubgroupsQueryVariables
  >(GetClassSubgroupsDocument, options);
}
export function useGetClassSubgroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassSubgroupsQuery,
    GetClassSubgroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassSubgroupsQuery,
    GetClassSubgroupsQueryVariables
  >(GetClassSubgroupsDocument, options);
}
export type GetClassSubgroupsQueryHookResult = ReturnType<
  typeof useGetClassSubgroupsQuery
>;
export type GetClassSubgroupsLazyQueryHookResult = ReturnType<
  typeof useGetClassSubgroupsLazyQuery
>;
export type GetClassSubgroupsQueryResult = Apollo.QueryResult<
  GetClassSubgroupsQuery,
  GetClassSubgroupsQueryVariables
>;
export const GetClassAndSubgroupDocument = gql`
  query getClassAndSubgroup(
    $subgroupId: ID!
    $classId: ID!
    $itemsPerPage: Int = 10000
    $active: Boolean = true
  ) {
    studentSubgroup(id: $subgroupId) {
      _id
      id
      name
      students(itemsPerPage: $itemsPerPage) {
        collection {
          _id
        }
      }
    }
    class(id: $classId) {
      name
      _id
      subgroups {
        collection {
          id
          name
        }
      }
      classStudents(itemsPerPage: $itemsPerPage, active: $active) {
        collection {
          _id
          studentId
          student {
            _id
            firstName
            lastName
            username
            gradeLevel
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassAndSubgroupQuery__
 *
 * To run a query within a React component, call `useGetClassAndSubgroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAndSubgroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAndSubgroupQuery({
 *   variables: {
 *      subgroupId: // value for 'subgroupId'
 *      classId: // value for 'classId'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetClassAndSubgroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassAndSubgroupQuery,
    GetClassAndSubgroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassAndSubgroupQuery,
    GetClassAndSubgroupQueryVariables
  >(GetClassAndSubgroupDocument, options);
}
export function useGetClassAndSubgroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassAndSubgroupQuery,
    GetClassAndSubgroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassAndSubgroupQuery,
    GetClassAndSubgroupQueryVariables
  >(GetClassAndSubgroupDocument, options);
}
export type GetClassAndSubgroupQueryHookResult = ReturnType<
  typeof useGetClassAndSubgroupQuery
>;
export type GetClassAndSubgroupLazyQueryHookResult = ReturnType<
  typeof useGetClassAndSubgroupLazyQuery
>;
export type GetClassAndSubgroupQueryResult = Apollo.QueryResult<
  GetClassAndSubgroupQuery,
  GetClassAndSubgroupQueryVariables
>;
export const CreateQrcodeTemplateDocument = gql`
  mutation createQrcodeTemplate(
    $createQrcodeTemplateInput: CreateQrcodeTemplateInput!
  ) {
    createQrcodeTemplate(input: $createQrcodeTemplateInput) {
      content
    }
  }
`;
export type CreateQrcodeTemplateMutationFn = Apollo.MutationFunction<
  CreateQrcodeTemplateMutation,
  CreateQrcodeTemplateMutationVariables
>;

/**
 * __useCreateQrcodeTemplateMutation__
 *
 * To run a mutation, you first call `useCreateQrcodeTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQrcodeTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQrcodeTemplateMutation, { data, loading, error }] = useCreateQrcodeTemplateMutation({
 *   variables: {
 *      createQrcodeTemplateInput: // value for 'createQrcodeTemplateInput'
 *   },
 * });
 */
export function useCreateQrcodeTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQrcodeTemplateMutation,
    CreateQrcodeTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQrcodeTemplateMutation,
    CreateQrcodeTemplateMutationVariables
  >(CreateQrcodeTemplateDocument, options);
}
export type CreateQrcodeTemplateMutationHookResult = ReturnType<
  typeof useCreateQrcodeTemplateMutation
>;
export type CreateQrcodeTemplateMutationResult =
  Apollo.MutationResult<CreateQrcodeTemplateMutation>;
export type CreateQrcodeTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateQrcodeTemplateMutation,
  CreateQrcodeTemplateMutationVariables
>;
export const GetTeacherResourceDocument = gql`
  query getTeacherResource($id: String!, $schoolId: String!) {
    teacherResource(id: $id, schoolId: $schoolId) {
      displayName
      id
      positionNumber
      projectType
      projectId
      sectionTitles
      metadata
      sections
    }
  }
`;

/**
 * __useGetTeacherResourceQuery__
 *
 * To run a query within a React component, call `useGetTeacherResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetTeacherResourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeacherResourceQuery,
    GetTeacherResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeacherResourceQuery,
    GetTeacherResourceQueryVariables
  >(GetTeacherResourceDocument, options);
}
export function useGetTeacherResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeacherResourceQuery,
    GetTeacherResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeacherResourceQuery,
    GetTeacherResourceQueryVariables
  >(GetTeacherResourceDocument, options);
}
export type GetTeacherResourceQueryHookResult = ReturnType<
  typeof useGetTeacherResourceQuery
>;
export type GetTeacherResourceLazyQueryHookResult = ReturnType<
  typeof useGetTeacherResourceLazyQuery
>;
export type GetTeacherResourceQueryResult = Apollo.QueryResult<
  GetTeacherResourceQuery,
  GetTeacherResourceQueryVariables
>;
export const GetTeacherResourcesDocument = gql`
  query getTeacherResources(
    $page: Int
    $perPage: Int
    $search: String
    $schoolIds: [String!]
  ) {
    teacherResources(
      page: $page
      perPage: $perPage
      search: $search
      schoolIds: $schoolIds
    ) {
      collection {
        displayName
        id
        positionNumber
        projectId
        sectionTitles
        metadata
      }
    }
  }
`;

/**
 * __useGetTeacherResourcesQuery__
 *
 * To run a query within a React component, call `useGetTeacherResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherResourcesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      search: // value for 'search'
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetTeacherResourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTeacherResourcesQuery,
    GetTeacherResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeacherResourcesQuery,
    GetTeacherResourcesQueryVariables
  >(GetTeacherResourcesDocument, options);
}
export function useGetTeacherResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeacherResourcesQuery,
    GetTeacherResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeacherResourcesQuery,
    GetTeacherResourcesQueryVariables
  >(GetTeacherResourcesDocument, options);
}
export type GetTeacherResourcesQueryHookResult = ReturnType<
  typeof useGetTeacherResourcesQuery
>;
export type GetTeacherResourcesLazyQueryHookResult = ReturnType<
  typeof useGetTeacherResourcesLazyQuery
>;
export type GetTeacherResourcesQueryResult = Apollo.QueryResult<
  GetTeacherResourcesQuery,
  GetTeacherResourcesQueryVariables
>;
export const GetTopicsDocument = gql`
  query getTopics($schoolIds: [String!]) {
    topics(schoolIds: $schoolIds) {
      topics
    }
  }
`;

/**
 * __useGetTopicsQuery__
 *
 * To run a query within a React component, call `useGetTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsQuery({
 *   variables: {
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTopicsQuery, GetTopicsQueryVariables>(
    GetTopicsDocument,
    options
  );
}
export function useGetTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopicsQuery,
    GetTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTopicsQuery, GetTopicsQueryVariables>(
    GetTopicsDocument,
    options
  );
}
export type GetTopicsQueryHookResult = ReturnType<typeof useGetTopicsQuery>;
export type GetTopicsLazyQueryHookResult = ReturnType<
  typeof useGetTopicsLazyQuery
>;
export type GetTopicsQueryResult = Apollo.QueryResult<
  GetTopicsQuery,
  GetTopicsQueryVariables
>;
export const GetTeamsDocument = gql`
  query getTeams($perPage: Int!, $page: Int!) {
    teams(itemsPerPage: $perPage, page: $page, filter: { archived: false }) {
      collection {
        id
        name
      }
    }
  }
`;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(
    GetTeamsDocument,
    options
  );
}
export function useGetTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamsQuery,
    GetTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(
    GetTeamsDocument,
    options
  );
}
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<
  typeof useGetTeamsLazyQuery
>;
export type GetTeamsQueryResult = Apollo.QueryResult<
  GetTeamsQuery,
  GetTeamsQueryVariables
>;
export const UpdateTeamDocument = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(updateTeamInput: $input) {
      team {
        name
      }
    }
  }
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options
  );
}
export type UpdateTeamMutationHookResult = ReturnType<
  typeof useUpdateTeamMutation
>;
export type UpdateTeamMutationResult =
  Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const CreateTeamDocument = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(createTeamInput: $input) {
      team {
        name
      }
    }
  }
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamMutation,
    CreateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options
  );
}
export type CreateTeamMutationHookResult = ReturnType<
  typeof useCreateTeamMutation
>;
export type CreateTeamMutationResult =
  Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const CancelImpersonationDocument = gql`
  mutation cancelImpersonation($input: cancelImpersonationInput!) {
    cancelImpersonation(input: $input) {
      impersonation {
        user {
          id
        }
      }
    }
  }
`;
export type CancelImpersonationMutationFn = Apollo.MutationFunction<
  CancelImpersonationMutation,
  CancelImpersonationMutationVariables
>;

/**
 * __useCancelImpersonationMutation__
 *
 * To run a mutation, you first call `useCancelImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelImpersonationMutation, { data, loading, error }] = useCancelImpersonationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelImpersonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelImpersonationMutation,
    CancelImpersonationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelImpersonationMutation,
    CancelImpersonationMutationVariables
  >(CancelImpersonationDocument, options);
}
export type CancelImpersonationMutationHookResult = ReturnType<
  typeof useCancelImpersonationMutation
>;
export type CancelImpersonationMutationResult =
  Apollo.MutationResult<CancelImpersonationMutation>;
export type CancelImpersonationMutationOptions = Apollo.BaseMutationOptions<
  CancelImpersonationMutation,
  CancelImpersonationMutationVariables
>;
export const CheckStudentOnExistenceDocument = gql`
  query checkStudentOnExistence(
    $sisId: String
    $withSisId: Boolean!
    $withStateId: Boolean!
    $stateId: String
  ) {
    usersBySisId: identifyUsers(sisId: $sisId) @include(if: $withSisId) {
      id
      _id
      firstName
      lastName
      userRoles {
        collection {
          schoolIds
        }
      }
    }
    usersByStateId: identifyUsers(stateId: $stateId)
      @include(if: $withStateId) {
      id
      _id
      firstName
      lastName
      userRoles {
        collection {
          schoolIds
        }
      }
    }
  }
`;

/**
 * __useCheckStudentOnExistenceQuery__
 *
 * To run a query within a React component, call `useCheckStudentOnExistenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckStudentOnExistenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckStudentOnExistenceQuery({
 *   variables: {
 *      sisId: // value for 'sisId'
 *      withSisId: // value for 'withSisId'
 *      withStateId: // value for 'withStateId'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useCheckStudentOnExistenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckStudentOnExistenceQuery,
    CheckStudentOnExistenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckStudentOnExistenceQuery,
    CheckStudentOnExistenceQueryVariables
  >(CheckStudentOnExistenceDocument, options);
}
export function useCheckStudentOnExistenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckStudentOnExistenceQuery,
    CheckStudentOnExistenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckStudentOnExistenceQuery,
    CheckStudentOnExistenceQueryVariables
  >(CheckStudentOnExistenceDocument, options);
}
export type CheckStudentOnExistenceQueryHookResult = ReturnType<
  typeof useCheckStudentOnExistenceQuery
>;
export type CheckStudentOnExistenceLazyQueryHookResult = ReturnType<
  typeof useCheckStudentOnExistenceLazyQuery
>;
export type CheckStudentOnExistenceQueryResult = Apollo.QueryResult<
  CheckStudentOnExistenceQuery,
  CheckStudentOnExistenceQueryVariables
>;
export const CreateImpersonationDocument = gql`
  mutation createImpersonation($input: createImpersonationInput!) {
    createImpersonation(input: $input) {
      impersonation {
        user {
          gradeLevel
          email
          firstName
          lastName
          _id
        }
      }
    }
  }
`;
export type CreateImpersonationMutationFn = Apollo.MutationFunction<
  CreateImpersonationMutation,
  CreateImpersonationMutationVariables
>;

/**
 * __useCreateImpersonationMutation__
 *
 * To run a mutation, you first call `useCreateImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImpersonationMutation, { data, loading, error }] = useCreateImpersonationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImpersonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImpersonationMutation,
    CreateImpersonationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateImpersonationMutation,
    CreateImpersonationMutationVariables
  >(CreateImpersonationDocument, options);
}
export type CreateImpersonationMutationHookResult = ReturnType<
  typeof useCreateImpersonationMutation
>;
export type CreateImpersonationMutationResult =
  Apollo.MutationResult<CreateImpersonationMutation>;
export type CreateImpersonationMutationOptions = Apollo.BaseMutationOptions<
  CreateImpersonationMutation,
  CreateImpersonationMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($input: createUserInput!) {
    createUser(input: $input) {
      user {
        id
        lastName
        firstName
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const CreateStudentDocument = gql`
  mutation createStudent($input: createUserInput!) {
    createUser(input: $input) {
      user {
        lastName
        _id
        firstName
      }
    }
  }
`;
export type CreateStudentMutationFn = Apollo.MutationFunction<
  CreateStudentMutation,
  CreateStudentMutationVariables
>;

/**
 * __useCreateStudentMutation__
 *
 * To run a mutation, you first call `useCreateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentMutation, { data, loading, error }] = useCreateStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStudentMutation,
    CreateStudentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStudentMutation,
    CreateStudentMutationVariables
  >(CreateStudentDocument, options);
}
export type CreateStudentMutationHookResult = ReturnType<
  typeof useCreateStudentMutation
>;
export type CreateStudentMutationResult =
  Apollo.MutationResult<CreateStudentMutation>;
export type CreateStudentMutationOptions = Apollo.BaseMutationOptions<
  CreateStudentMutation,
  CreateStudentMutationVariables
>;
export const GetPasswordDocument = gql`
  query getPassword($id: ID!) {
    user(id: $id) {
      plainPassword
    }
  }
`;

/**
 * __useGetPasswordQuery__
 *
 * To run a query within a React component, call `useGetPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPasswordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPasswordQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPasswordQuery,
    GetPasswordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPasswordQuery, GetPasswordQueryVariables>(
    GetPasswordDocument,
    options
  );
}
export function useGetPasswordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPasswordQuery,
    GetPasswordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPasswordQuery, GetPasswordQueryVariables>(
    GetPasswordDocument,
    options
  );
}
export type GetPasswordQueryHookResult = ReturnType<typeof useGetPasswordQuery>;
export type GetPasswordLazyQueryHookResult = ReturnType<
  typeof useGetPasswordLazyQuery
>;
export type GetPasswordQueryResult = Apollo.QueryResult<
  GetPasswordQuery,
  GetPasswordQueryVariables
>;
export const GetUserRolesDocument = gql`
  query getUserRoles {
    roles(page: 1, itemsPerPage: 1000) {
      collection {
        id
        name
        code
      }
    }
  }
`;

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserRolesQuery,
    GetUserRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(
    GetUserRolesDocument,
    options
  );
}
export function useGetUserRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRolesQuery,
    GetUserRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(
    GetUserRolesDocument,
    options
  );
}
export type GetUserRolesQueryHookResult = ReturnType<
  typeof useGetUserRolesQuery
>;
export type GetUserRolesLazyQueryHookResult = ReturnType<
  typeof useGetUserRolesLazyQuery
>;
export type GetUserRolesQueryResult = Apollo.QueryResult<
  GetUserRolesQuery,
  GetUserRolesQueryVariables
>;
export const GetUserForUpdateDocument = gql`
  query getUserForUpdate(
    $id: ID!
    $schoolId: String!
    $teachersId: String!
    $archived: Boolean
    $itemsPerPage: Int = 999
  ) {
    user(id: $id) {
      id
      _id
      sisId
      stateId
      username
      lastName
      firstName
      middleName
      plainPassword
      email
      gradeLevel
      language
    }
    teacherStudents(
      schoolId: $schoolId
      teachersId: $teachersId
      itemsPerPage: $itemsPerPage
    ) {
      collection {
        classes(filter: { status: Active, hasLmsId: false }) {
          collection {
            _id
          }
        }
        _id
      }
    }
    classes(
      teachersId: $teachersId
      archived: $archived
      itemsPerPage: $itemsPerPage
      status: Active
      hasLmsId: false
    ) {
      collection {
        _id
        name
        schoolId
        classTeachers {
          collection {
            teacherId
            mainTeacher
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserForUpdateQuery__
 *
 * To run a query within a React component, call `useGetUserForUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForUpdateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      schoolId: // value for 'schoolId'
 *      teachersId: // value for 'teachersId'
 *      archived: // value for 'archived'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetUserForUpdateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForUpdateQuery,
    GetUserForUpdateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserForUpdateQuery, GetUserForUpdateQueryVariables>(
    GetUserForUpdateDocument,
    options
  );
}
export function useGetUserForUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForUpdateQuery,
    GetUserForUpdateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserForUpdateQuery,
    GetUserForUpdateQueryVariables
  >(GetUserForUpdateDocument, options);
}
export type GetUserForUpdateQueryHookResult = ReturnType<
  typeof useGetUserForUpdateQuery
>;
export type GetUserForUpdateLazyQueryHookResult = ReturnType<
  typeof useGetUserForUpdateLazyQuery
>;
export type GetUserForUpdateQueryResult = Apollo.QueryResult<
  GetUserForUpdateQuery,
  GetUserForUpdateQueryVariables
>;
export const GetUserDocument = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      lastName
      firstName
      gradeLevel
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUserForCampusLeaderDocument = gql`
  query getUserForCampusLeader($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      middleName
      plainPassword
      username
      staffId
      email
      language
      stateId
      gradeLevel
      userRoles {
        collection {
          role {
            id
            _id
            code
          }
          schoolIds
        }
      }
    }
  }
`;

/**
 * __useGetUserForCampusLeaderQuery__
 *
 * To run a query within a React component, call `useGetUserForCampusLeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForCampusLeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForCampusLeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserForCampusLeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserForCampusLeaderQuery,
    GetUserForCampusLeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserForCampusLeaderQuery,
    GetUserForCampusLeaderQueryVariables
  >(GetUserForCampusLeaderDocument, options);
}
export function useGetUserForCampusLeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserForCampusLeaderQuery,
    GetUserForCampusLeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserForCampusLeaderQuery,
    GetUserForCampusLeaderQueryVariables
  >(GetUserForCampusLeaderDocument, options);
}
export type GetUserForCampusLeaderQueryHookResult = ReturnType<
  typeof useGetUserForCampusLeaderQuery
>;
export type GetUserForCampusLeaderLazyQueryHookResult = ReturnType<
  typeof useGetUserForCampusLeaderLazyQuery
>;
export type GetUserForCampusLeaderQueryResult = Apollo.QueryResult<
  GetUserForCampusLeaderQuery,
  GetUserForCampusLeaderQueryVariables
>;
export const UpdateStudentDocument = gql`
  mutation updateStudent($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        _id
        active
        email
        firstName
        gradeLevel
        language
        lastName
        middleName
        plainPassword
        preferences
        sisId
        stateId
        username
        createdByUsername
        updatedByUsername
        createdAt
        updatedAt
        userRoles {
          collection {
            roleId
            schoolIds
            id
          }
        }
      }
    }
  }
`;
export type UpdateStudentMutationFn = Apollo.MutationFunction<
  UpdateStudentMutation,
  UpdateStudentMutationVariables
>;

/**
 * __useUpdateStudentMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMutation, { data, loading, error }] = useUpdateStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudentMutation,
    UpdateStudentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStudentMutation,
    UpdateStudentMutationVariables
  >(UpdateStudentDocument, options);
}
export type UpdateStudentMutationHookResult = ReturnType<
  typeof useUpdateStudentMutation
>;
export type UpdateStudentMutationResult =
  Apollo.MutationResult<UpdateStudentMutation>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudentMutation,
  UpdateStudentMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const GetUsersDocument = gql`
  query getUsers(
    $page: Int
    $itemsPerPage: Int
    $fullName: String
    $type: UserTypeEnum
    $schoolId: String
    $id_list: [String]
    $active: Boolean = true
  ) {
    users(
      page: $page
      itemsPerPage: $itemsPerPage
      type: $type
      schoolsId: $schoolId
      fullName: $fullName
      id_list: $id_list
      active: $active
    ) {
      collection {
        _id
        firstName
        lastName
        username
      }
      paginationInfo {
        itemsPerPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      fullName: // value for 'fullName'
 *      type: // value for 'type'
 *      schoolId: // value for 'schoolId'
 *      id_list: // value for 'id_list'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const GetManageStudentsUsersDocument = gql`
  query getManageStudentsUsers(
    $page: Int
    $itemsPerPage: Int
    $type: UserTypeEnum
    $schoolId: String
    $username: String
    $sorting: [UserFilter_order]
    $gradeLevel: GradeLevelFilterEnum
    $exclude: [UserFilter_exclude]
    $active: Boolean = true
  ) {
    users(
      page: $page
      itemsPerPage: $itemsPerPage
      type: $type
      schoolsId: $schoolId
      order: $sorting
      username: $username
      gradeLevel: $gradeLevel
      exclude: $exclude
      active: $active
    ) {
      collection {
        _id
        id
        firstName
        lastName
        username
        gradeLevel
      }
      paginationInfo {
        itemsPerPage
        totalCount
        lastPage
      }
    }
  }
`;

/**
 * __useGetManageStudentsUsersQuery__
 *
 * To run a query within a React component, call `useGetManageStudentsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageStudentsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageStudentsUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      type: // value for 'type'
 *      schoolId: // value for 'schoolId'
 *      username: // value for 'username'
 *      sorting: // value for 'sorting'
 *      gradeLevel: // value for 'gradeLevel'
 *      exclude: // value for 'exclude'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetManageStudentsUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManageStudentsUsersQuery,
    GetManageStudentsUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManageStudentsUsersQuery,
    GetManageStudentsUsersQueryVariables
  >(GetManageStudentsUsersDocument, options);
}
export function useGetManageStudentsUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManageStudentsUsersQuery,
    GetManageStudentsUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManageStudentsUsersQuery,
    GetManageStudentsUsersQueryVariables
  >(GetManageStudentsUsersDocument, options);
}
export type GetManageStudentsUsersQueryHookResult = ReturnType<
  typeof useGetManageStudentsUsersQuery
>;
export type GetManageStudentsUsersLazyQueryHookResult = ReturnType<
  typeof useGetManageStudentsUsersLazyQuery
>;
export type GetManageStudentsUsersQueryResult = Apollo.QueryResult<
  GetManageStudentsUsersQuery,
  GetManageStudentsUsersQueryVariables
>;
export const GetUsersForClassesDocument = gql`
  query getUsersForClasses(
    $page: Int
    $itemsPerPage: Int
    $fullName: String
    $type: UserTypeEnum
    $schoolId: String
    $active: Boolean
  ) {
    users(
      page: $page
      itemsPerPage: $itemsPerPage
      type: $type
      schoolsId: $schoolId
      fullName: $fullName
      active: $active
    ) {
      collection {
        _id
        id
        firstName
        lastName
      }
      paginationInfo {
        itemsPerPage
        totalCount
      }
    }
  }
`;

/**
 * __useGetUsersForClassesQuery__
 *
 * To run a query within a React component, call `useGetUsersForClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForClassesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      fullName: // value for 'fullName'
 *      type: // value for 'type'
 *      schoolId: // value for 'schoolId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetUsersForClassesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersForClassesQuery,
    GetUsersForClassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersForClassesQuery,
    GetUsersForClassesQueryVariables
  >(GetUsersForClassesDocument, options);
}
export function useGetUsersForClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForClassesQuery,
    GetUsersForClassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersForClassesQuery,
    GetUsersForClassesQueryVariables
  >(GetUsersForClassesDocument, options);
}
export type GetUsersForClassesQueryHookResult = ReturnType<
  typeof useGetUsersForClassesQuery
>;
export type GetUsersForClassesLazyQueryHookResult = ReturnType<
  typeof useGetUsersForClassesLazyQuery
>;
export type GetUsersForClassesQueryResult = Apollo.QueryResult<
  GetUsersForClassesQuery,
  GetUsersForClassesQueryVariables
>;
export const GetUsersForCampusLeaderDocument = gql`
  query getUsersForCampusLeader(
    $page: Int
    $itemsPerPage: Int
    $schoolsIds: [ID]
    $type: UserTypeEnum
    $filter: [UserFilter]
    $id: String
  ) {
    users(
      page: $page
      itemsPerPage: $itemsPerPage
      schoolsIds: $schoolsIds
      type: $type
      filter: $filter
      id: $id
    ) {
      collection {
        _id
        id
        firstName
        lastName
        middleName
        plainPassword
        username
        staffId
        curriculumAreas
        gradeLevel
        userRoles {
          collection {
            role {
              id
              _id
              code
            }
            schoolIds
          }
        }
      }
      paginationInfo {
        itemsPerPage
        totalCount
        lastPage
      }
    }
  }
`;

/**
 * __useGetUsersForCampusLeaderQuery__
 *
 * To run a query within a React component, call `useGetUsersForCampusLeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForCampusLeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForCampusLeaderQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      schoolsIds: // value for 'schoolsIds'
 *      type: // value for 'type'
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsersForCampusLeaderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersForCampusLeaderQuery,
    GetUsersForCampusLeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersForCampusLeaderQuery,
    GetUsersForCampusLeaderQueryVariables
  >(GetUsersForCampusLeaderDocument, options);
}
export function useGetUsersForCampusLeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersForCampusLeaderQuery,
    GetUsersForCampusLeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersForCampusLeaderQuery,
    GetUsersForCampusLeaderQueryVariables
  >(GetUsersForCampusLeaderDocument, options);
}
export type GetUsersForCampusLeaderQueryHookResult = ReturnType<
  typeof useGetUsersForCampusLeaderQuery
>;
export type GetUsersForCampusLeaderLazyQueryHookResult = ReturnType<
  typeof useGetUsersForCampusLeaderLazyQuery
>;
export type GetUsersForCampusLeaderQueryResult = Apollo.QueryResult<
  GetUsersForCampusLeaderQuery,
  GetUsersForCampusLeaderQueryVariables
>;
export const PacingGuideListDocument = gql`
  query pacingGuideList(
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    pacingGuides(
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        id
        _id
        name
        curriculumArea
        grade
        startDate
        selectedCalendar
      }
    }
  }
`;

/**
 * __usePacingGuideListQuery__
 *
 * To run a query within a React component, call `usePacingGuideListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePacingGuideListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePacingGuideListQuery({
 *   variables: {
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function usePacingGuideListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PacingGuideListQuery,
    PacingGuideListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PacingGuideListQuery, PacingGuideListQueryVariables>(
    PacingGuideListDocument,
    options
  );
}
export function usePacingGuideListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PacingGuideListQuery,
    PacingGuideListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PacingGuideListQuery,
    PacingGuideListQueryVariables
  >(PacingGuideListDocument, options);
}
export type PacingGuideListQueryHookResult = ReturnType<
  typeof usePacingGuideListQuery
>;
export type PacingGuideListLazyQueryHookResult = ReturnType<
  typeof usePacingGuideListLazyQuery
>;
export type PacingGuideListQueryResult = Apollo.QueryResult<
  PacingGuideListQuery,
  PacingGuideListQueryVariables
>;
export const PacingGuideWithScopesDocument = gql`
  query pacingGuideWithScopes(
    $pacingGuideId: String!
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    pacingGuides(
      pacingGuideId: $pacingGuideId
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        id
        _id
        name
        curriculumArea
        grade
        pacingGuideType
        selectedCalendar
        scopes {
          collection {
            _id
            id
            scopeId
            name
            instructionalDays
            order
          }
        }
      }
    }
  }
`;

/**
 * __usePacingGuideWithScopesQuery__
 *
 * To run a query within a React component, call `usePacingGuideWithScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePacingGuideWithScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePacingGuideWithScopesQuery({
 *   variables: {
 *      pacingGuideId: // value for 'pacingGuideId'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function usePacingGuideWithScopesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PacingGuideWithScopesQuery,
    PacingGuideWithScopesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PacingGuideWithScopesQuery,
    PacingGuideWithScopesQueryVariables
  >(PacingGuideWithScopesDocument, options);
}
export function usePacingGuideWithScopesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PacingGuideWithScopesQuery,
    PacingGuideWithScopesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PacingGuideWithScopesQuery,
    PacingGuideWithScopesQueryVariables
  >(PacingGuideWithScopesDocument, options);
}
export type PacingGuideWithScopesQueryHookResult = ReturnType<
  typeof usePacingGuideWithScopesQuery
>;
export type PacingGuideWithScopesLazyQueryHookResult = ReturnType<
  typeof usePacingGuideWithScopesLazyQuery
>;
export type PacingGuideWithScopesQueryResult = Apollo.QueryResult<
  PacingGuideWithScopesQuery,
  PacingGuideWithScopesQueryVariables
>;
export const GetCalendarScopeEventsDocument = gql`
  query getCalendarScopeEvents(
    $calendarId: [String]
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    calendars(
      calendarId: $calendarId
      schoolIds: $schoolIds
      userTeamIds: $userTeamIds
      userRoleCode: $userRoleCode
    ) {
      collection {
        _id
        calendarEntries(entryType: Scope) {
          collection {
            id
            pacingGuideId
            startDate
            endDate
            scopeId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetCalendarScopeEventsQuery__
 *
 * To run a query within a React component, call `useGetCalendarScopeEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarScopeEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarScopeEventsQuery({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function useGetCalendarScopeEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarScopeEventsQuery,
    GetCalendarScopeEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCalendarScopeEventsQuery,
    GetCalendarScopeEventsQueryVariables
  >(GetCalendarScopeEventsDocument, options);
}
export function useGetCalendarScopeEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarScopeEventsQuery,
    GetCalendarScopeEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarScopeEventsQuery,
    GetCalendarScopeEventsQueryVariables
  >(GetCalendarScopeEventsDocument, options);
}
export type GetCalendarScopeEventsQueryHookResult = ReturnType<
  typeof useGetCalendarScopeEventsQuery
>;
export type GetCalendarScopeEventsLazyQueryHookResult = ReturnType<
  typeof useGetCalendarScopeEventsLazyQuery
>;
export type GetCalendarScopeEventsQueryResult = Apollo.QueryResult<
  GetCalendarScopeEventsQuery,
  GetCalendarScopeEventsQueryVariables
>;
export const CreateWidgetDocument = gql`
  mutation createWidget(
    $x: Int!
    $y: Int!
    $w: Int!
    $h: Int!
    $collapsed: Boolean!
    $expandedH: Int!
    $expandedW: Int!
    $minH: Int!
    $minW: Int!
    $type: WidgetType!
    $config: JSONObject
  ) {
    createWidget(
      input: {
        x: $x
        y: $y
        w: $w
        h: $h
        collapsed: $collapsed
        expandedH: $expandedH
        expandedW: $expandedW
        minH: $minH
        minW: $minW
        type: $type
        config: $config
      }
    ) {
      h
      i
      id
      minH
      minW
      w
      x
      y
      expandedW
      expandedH
      collapsed
      type
      config
    }
  }
`;
export type CreateWidgetMutationFn = Apollo.MutationFunction<
  CreateWidgetMutation,
  CreateWidgetMutationVariables
>;

/**
 * __useCreateWidgetMutation__
 *
 * To run a mutation, you first call `useCreateWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWidgetMutation, { data, loading, error }] = useCreateWidgetMutation({
 *   variables: {
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      w: // value for 'w'
 *      h: // value for 'h'
 *      collapsed: // value for 'collapsed'
 *      expandedH: // value for 'expandedH'
 *      expandedW: // value for 'expandedW'
 *      minH: // value for 'minH'
 *      minW: // value for 'minW'
 *      type: // value for 'type'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useCreateWidgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWidgetMutation,
    CreateWidgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWidgetMutation,
    CreateWidgetMutationVariables
  >(CreateWidgetDocument, options);
}
export type CreateWidgetMutationHookResult = ReturnType<
  typeof useCreateWidgetMutation
>;
export type CreateWidgetMutationResult =
  Apollo.MutationResult<CreateWidgetMutation>;
export type CreateWidgetMutationOptions = Apollo.BaseMutationOptions<
  CreateWidgetMutation,
  CreateWidgetMutationVariables
>;
export const PlansByCurriculumAndGradeDocument = gql`
  query plansByCurriculumAndGrade(
    $curriculumArea: String
    $grade: String
    $scopePlanType: ScopePlanTypeEnum
    $schoolIds: [String]
    $userRoleCode: String!
    $userTeamIds: [String]
  ) {
    scopePlans(
      curriculumArea: $curriculumArea
      grade: $grade
      scopePlanType: $scopePlanType
      schoolIds: $schoolIds
      userRoleCode: $userRoleCode
      userTeamIds: $userTeamIds
    ) {
      collection {
        id
        _id
        pacingGuideId
        name
        scopes {
          collection {
            scopeId
            startDate
            endDate
            elements
          }
        }
      }
    }
  }
`;

/**
 * __usePlansByCurriculumAndGradeQuery__
 *
 * To run a query within a React component, call `usePlansByCurriculumAndGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansByCurriculumAndGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansByCurriculumAndGradeQuery({
 *   variables: {
 *      curriculumArea: // value for 'curriculumArea'
 *      grade: // value for 'grade'
 *      scopePlanType: // value for 'scopePlanType'
 *      schoolIds: // value for 'schoolIds'
 *      userRoleCode: // value for 'userRoleCode'
 *      userTeamIds: // value for 'userTeamIds'
 *   },
 * });
 */
export function usePlansByCurriculumAndGradeQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlansByCurriculumAndGradeQuery,
    PlansByCurriculumAndGradeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlansByCurriculumAndGradeQuery,
    PlansByCurriculumAndGradeQueryVariables
  >(PlansByCurriculumAndGradeDocument, options);
}
export function usePlansByCurriculumAndGradeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlansByCurriculumAndGradeQuery,
    PlansByCurriculumAndGradeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlansByCurriculumAndGradeQuery,
    PlansByCurriculumAndGradeQueryVariables
  >(PlansByCurriculumAndGradeDocument, options);
}
export type PlansByCurriculumAndGradeQueryHookResult = ReturnType<
  typeof usePlansByCurriculumAndGradeQuery
>;
export type PlansByCurriculumAndGradeLazyQueryHookResult = ReturnType<
  typeof usePlansByCurriculumAndGradeLazyQuery
>;
export type PlansByCurriculumAndGradeQueryResult = Apollo.QueryResult<
  PlansByCurriculumAndGradeQuery,
  PlansByCurriculumAndGradeQueryVariables
>;
export const GetWidgetDocument = gql`
  query getWidget($id: String!) {
    widget(id: $id) {
      h
      i
      id
      minH
      minW
      w
      x
      y
      expandedW
      expandedH
      collapsed
      type
      config
    }
  }
`;

/**
 * __useGetWidgetQuery__
 *
 * To run a query within a React component, call `useGetWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWidgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWidgetQuery(
  baseOptions: Apollo.QueryHookOptions<GetWidgetQuery, GetWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWidgetQuery, GetWidgetQueryVariables>(
    GetWidgetDocument,
    options
  );
}
export function useGetWidgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWidgetQuery,
    GetWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWidgetQuery, GetWidgetQueryVariables>(
    GetWidgetDocument,
    options
  );
}
export type GetWidgetQueryHookResult = ReturnType<typeof useGetWidgetQuery>;
export type GetWidgetLazyQueryHookResult = ReturnType<
  typeof useGetWidgetLazyQuery
>;
export type GetWidgetQueryResult = Apollo.QueryResult<
  GetWidgetQuery,
  GetWidgetQueryVariables
>;
export const GetDashboardEmbedUrlForAnonymousUserDocument = gql`
  query getDashboardEmbedUrlForAnonymousUser(
    $dashboardID: String!
    $sheetID: String
    $visualID: String
  ) {
    dashboardEmbedUrlForAnonymousUser(
      dashboardID: $dashboardID
      sheetID: $sheetID
      visualID: $visualID
    ) {
      EmbedUrl
    }
  }
`;

/**
 * __useGetDashboardEmbedUrlForAnonymousUserQuery__
 *
 * To run a query within a React component, call `useGetDashboardEmbedUrlForAnonymousUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardEmbedUrlForAnonymousUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardEmbedUrlForAnonymousUserQuery({
 *   variables: {
 *      dashboardID: // value for 'dashboardID'
 *      sheetID: // value for 'sheetID'
 *      visualID: // value for 'visualID'
 *   },
 * });
 */
export function useGetDashboardEmbedUrlForAnonymousUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardEmbedUrlForAnonymousUserQuery,
    GetDashboardEmbedUrlForAnonymousUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardEmbedUrlForAnonymousUserQuery,
    GetDashboardEmbedUrlForAnonymousUserQueryVariables
  >(GetDashboardEmbedUrlForAnonymousUserDocument, options);
}
export function useGetDashboardEmbedUrlForAnonymousUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardEmbedUrlForAnonymousUserQuery,
    GetDashboardEmbedUrlForAnonymousUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardEmbedUrlForAnonymousUserQuery,
    GetDashboardEmbedUrlForAnonymousUserQueryVariables
  >(GetDashboardEmbedUrlForAnonymousUserDocument, options);
}
export type GetDashboardEmbedUrlForAnonymousUserQueryHookResult = ReturnType<
  typeof useGetDashboardEmbedUrlForAnonymousUserQuery
>;
export type GetDashboardEmbedUrlForAnonymousUserLazyQueryHookResult =
  ReturnType<typeof useGetDashboardEmbedUrlForAnonymousUserLazyQuery>;
export type GetDashboardEmbedUrlForAnonymousUserQueryResult =
  Apollo.QueryResult<
    GetDashboardEmbedUrlForAnonymousUserQuery,
    GetDashboardEmbedUrlForAnonymousUserQueryVariables
  >;
export const GetWidgetsDocument = gql`
  query getWidgets {
    widgets {
      h
      i
      id
      minH
      minW
      w
      x
      y
      expandedW
      expandedH
      collapsed
      type
      config
    }
  }
`;

/**
 * __useGetWidgetsQuery__
 *
 * To run a query within a React component, call `useGetWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWidgetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWidgetsQuery,
    GetWidgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWidgetsQuery, GetWidgetsQueryVariables>(
    GetWidgetsDocument,
    options
  );
}
export function useGetWidgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWidgetsQuery,
    GetWidgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWidgetsQuery, GetWidgetsQueryVariables>(
    GetWidgetsDocument,
    options
  );
}
export type GetWidgetsQueryHookResult = ReturnType<typeof useGetWidgetsQuery>;
export type GetWidgetsLazyQueryHookResult = ReturnType<
  typeof useGetWidgetsLazyQuery
>;
export type GetWidgetsQueryResult = Apollo.QueryResult<
  GetWidgetsQuery,
  GetWidgetsQueryVariables
>;
export const UpdateWidgetsDocument = gql`
  mutation updateWidgets($input: [updateWidgetInput]!) {
    updateWidgets(input: $input) {
      h
      i
      id
      minH
      minW
      w
      x
      y
      expandedW
      expandedH
      collapsed
      type
    }
  }
`;
export type UpdateWidgetsMutationFn = Apollo.MutationFunction<
  UpdateWidgetsMutation,
  UpdateWidgetsMutationVariables
>;

/**
 * __useUpdateWidgetsMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetsMutation, { data, loading, error }] = useUpdateWidgetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWidgetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWidgetsMutation,
    UpdateWidgetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWidgetsMutation,
    UpdateWidgetsMutationVariables
  >(UpdateWidgetsDocument, options);
}
export type UpdateWidgetsMutationHookResult = ReturnType<
  typeof useUpdateWidgetsMutation
>;
export type UpdateWidgetsMutationResult =
  Apollo.MutationResult<UpdateWidgetsMutation>;
export type UpdateWidgetsMutationOptions = Apollo.BaseMutationOptions<
  UpdateWidgetsMutation,
  UpdateWidgetsMutationVariables
>;
export const UpdateWidgetDocument = gql`
  mutation updateWidget(
    $x: Int
    $y: Int
    $w: Int
    $h: Int
    $i: String!
    $id: String!
    $collapsed: Boolean
    $expandedH: Int
    $expandedW: Int
    $minH: Int
    $minW: Int
    $type: WidgetType
    $config: JSONObject
  ) {
    updateWidget(
      input: {
        x: $x
        y: $y
        w: $w
        h: $h
        i: $i
        id: $id
        collapsed: $collapsed
        expandedH: $expandedH
        expandedW: $expandedW
        minH: $minH
        minW: $minW
        type: $type
        config: $config
      }
    ) {
      h
      i
      id
      minH
      minW
      w
      x
      y
      expandedW
      expandedH
      collapsed
      type
      config
    }
  }
`;
export type UpdateWidgetMutationFn = Apollo.MutationFunction<
  UpdateWidgetMutation,
  UpdateWidgetMutationVariables
>;

/**
 * __useUpdateWidgetMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetMutation, { data, loading, error }] = useUpdateWidgetMutation({
 *   variables: {
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      w: // value for 'w'
 *      h: // value for 'h'
 *      i: // value for 'i'
 *      id: // value for 'id'
 *      collapsed: // value for 'collapsed'
 *      expandedH: // value for 'expandedH'
 *      expandedW: // value for 'expandedW'
 *      minH: // value for 'minH'
 *      minW: // value for 'minW'
 *      type: // value for 'type'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useUpdateWidgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWidgetMutation,
    UpdateWidgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWidgetMutation,
    UpdateWidgetMutationVariables
  >(UpdateWidgetDocument, options);
}
export type UpdateWidgetMutationHookResult = ReturnType<
  typeof useUpdateWidgetMutation
>;
export type UpdateWidgetMutationResult =
  Apollo.MutationResult<UpdateWidgetMutation>;
export type UpdateWidgetMutationOptions = Apollo.BaseMutationOptions<
  UpdateWidgetMutation,
  UpdateWidgetMutationVariables
>;
export const DeleteWidgetDocument = gql`
  mutation deleteWidget($id: String!) {
    deleteWidget(input: { id: $id }) {
      type
    }
  }
`;
export type DeleteWidgetMutationFn = Apollo.MutationFunction<
  DeleteWidgetMutation,
  DeleteWidgetMutationVariables
>;

/**
 * __useDeleteWidgetMutation__
 *
 * To run a mutation, you first call `useDeleteWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWidgetMutation, { data, loading, error }] = useDeleteWidgetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWidgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWidgetMutation,
    DeleteWidgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWidgetMutation,
    DeleteWidgetMutationVariables
  >(DeleteWidgetDocument, options);
}
export type DeleteWidgetMutationHookResult = ReturnType<
  typeof useDeleteWidgetMutation
>;
export type DeleteWidgetMutationResult =
  Apollo.MutationResult<DeleteWidgetMutation>;
export type DeleteWidgetMutationOptions = Apollo.BaseMutationOptions<
  DeleteWidgetMutation,
  DeleteWidgetMutationVariables
>;
